import SplitPane from "react-split-pane";
import CodingEditor from "./CodingEditor";
import CodingOutput from "./CodingOutput";
import React, { useContext } from "react";
import CodingProblem from "./CodingProblem";
import Pane from "react-split-pane/lib/Pane";
import AlertContext from "../../../context/AlertContext";
import CodingSplitHooks from "../../../Hooks/CodingPage/CodingSplitHooks";

export default function CodingSplit() {
  const Alert = useContext(AlertContext);

  const {
    heightFind,
    verticalSize,
    handleProblem,
    outputWindowHeight,
    toggleOutputWindowHeight,
  } = CodingSplitHooks();

  return (
    <SplitPane
      minSize={50}
      split="vertical"
      primary="second"
      size={verticalSize}
      onChange={handleProblem}
      className="codingpageVerticalSplitPane"
    >
      {!Alert.isFullScreen && (
        <Pane
          minSize="304px"
          maxSize="504px"
          initialSize={verticalSize}
          className="codingProblemPane"
        >
          <CodingProblem />
        </Pane>
      )}
      <SplitPane
        split="horizontal"
        onChange={heightFind}
        className="codingpageHorizontalSplitPane"
      >
        <Pane
          className={
            !Alert.theme ? "codingEditorPane" : "codingEditorPaneDarkTheme"
          }
        >
          <CodingEditor />
        </Pane>
        {!Alert.isFullScreen && (
          <Pane className="codingOutputPane" initialSize={outputWindowHeight}>
            <CodingOutput toggleOutputWindowHeight={toggleOutputWindowHeight} />
          </Pane>
        )}
      </SplitPane>
    </SplitPane>
  );
}
