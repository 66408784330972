import React from "react";

export default function ChevronUpSvg(Props) {
  return Props.from === "menu" ? (
    <svg
      width="16"
      height="16"
      fill="none"
      viewBox="0 0 16 16"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        stroke="#667085"
        stroke-width="2"
        d="M12 10L8 6L4 10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  ) : (
    <svg
      width="16"
      height="16"
      fill="none"
      viewBox="0 0 16 16"
      className="chevronUpSvgIcon"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        stroke="#344054"
        d="M4 6L8 10L12 6"
        stroke-width="1.2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
}
