import constants from "../constants";
import TestDueHooks from "./TestDueHooks";
import { getDataFromStorage } from "../util";
import useAxiosPrivate from "./useAxiosPrivate";
import ContentPageHooks from "./ContentPageHooks";
import AlertContext from "../context/AlertContext";
import { useContext, useRef, useState } from "react";
import AssignmentPageHooks from "./AssignmentPageHooks";
import NotifyBody from "../Components/Notify/NotifyBody";
import useCommonFunctionHooks from "./useCommonFunctionHooks";

export default function LeftNavBarHooks() {
  const Alert = useContext(AlertContext);

  const axios = useAxiosPrivate();
  const { currentDueLabel } = TestDueHooks();
  const { handleContent } = ContentPageHooks();
  const { handleAssignment } = AssignmentPageHooks();

  const { handleRegistration, getCatchBlockDetails, getHeaderDetailsForAxios } =
    useCommonFunctionHooks();

  const activeTestRef = useRef(null);

  const [collapsed, setCollapsed] = useState(false);

  const openModule = (module) => {
    Alert.setModuleActive(true);
    Alert.setModule({ id: module.modid, name: module.modname });
  };

  const openTest = (test) => {
    Alert.register.current = Alert.lpData.registered;
    Alert.setLpId(parseInt(sessionStorage.getItem("lpId")));

    if (
      document.fullscreenElement &&
      (test.testtype !== constants.libTypeIds.MCQ ||
        test.testtype !== constants.libTypeIds.DESCRIPTIVE) &&
      test.status !== constants.PROGRESS_STATUS.UNLOCKED &&
      test.status !== constants.PROGRESS_STATUS.REATTEMPT &&
      test.status !== constants.PROGRESS_STATUS.QN_CONTINUE
    ) {
      document.exitFullscreen();
    }

    Alert.setTestData(test);
    Alert.setContentData({});
    Alert.setShowMain(false);
    Alert.setTestActive(true);
    Alert.setStatus(test.status);
    Alert.setShowInstruction(false);
    Alert.setTestId(test.testid !== null ? test.testid : test.libid);

    if (test.libtype === constants.libTypeIds.CONTENT) {
      handleContent(
        test.libtype,
        test.lpconfigid,
        test.libid,
        test.status,
        test.duedate
      );
    } else if (test.libtype === constants.libTypeIds.ASSIGNMENT) {
      if (document.fullscreenElement) {
        document.exitFullscreen();

        Alert.setShowInstruction(false);
      }

      handleAssignment(
        test.libtype,
        test.lpconfigid,
        test.libid,
        test.status,
        test.duedate
      );
    } else if (test.testtype === constants.libTypeIds.CODING_CHALLENGE) {
      if (document.fullscreenElement) {
        document.exitFullscreen();

        Alert.setShowInstruction(false);
      }
    } else {
      if (
        (localStorage.getItem("mockLp") ||
          Alert.lpData.batchType === constants.BATCH_TYPE.SELF ||
          currentDueLabel(test.duedate) !== "Past Due") &&
        test.status !== constants.PROGRESS_STATUS.EVALUATED &&
        test.status !== constants.PROGRESS_STATUS.TO_BE_EVALUATED &&
        test.status !== constants.PROGRESS_STATUS.PARTIAL_EVALUATED
      ) {
        document.documentElement.requestFullscreen().catch((err) => err);
        Alert.setShowInstruction(true);
      }
    }

    Alert.setShowMain(true);
  };

  const calculatePercentage = (completedItems, totalItems) => {
    if (totalItems !== 0) {
      let percentage = Math.round((completedItems / totalItems) * 100);

      return percentage;
    } else {
      return 0;
    }
  };

  const gotToDashboard = () => {
    if (document.fullscreenElement) {
      document.exitFullscreen();
    }

    Alert.setLpData({});
    Alert.setTestData({});
    Alert.navigate(
      localStorage.getItem("mockLp") ? "main/mock/library" : "/main/dashboard"
    );

    if (localStorage.getItem("mockLp")) {
      localStorage.removeItem("mockLp");
    }
  };

  const getTestForOrderMandate = (moduleData) => {
    let testOpened = false;

    moduleData.forEach((module) => {
      if (testOpened) return;

      module.data.some((test) => {
        if (
          test?.status === constants.PROGRESS_STATUS.UNLOCKED ||
          test?.status === constants.PROGRESS_STATUS.REATTEMPT ||
          test?.status === constants.PROGRESS_STATUS.QN_CONTINUE ||
          test?.status === constants.PROGRESS_STATUS.IN_PROGRESS
        ) {
          Alert.setModuleActive(true);
          Alert.setModule({
            id: module.modid,
            name: module.modname,
          });
          openTest(test);
          testOpened = true;

          return true;
        }

        if (module.modid === Alert.module.id && test.testid === Alert.testId) {
          openTest(test);
          testOpened = true;

          return true;
        }

        return false;
      });
    });

    if (!testOpened) {
      Alert.setModuleActive(true);
      Alert.setModule({
        id: moduleData[0].modid,
        name: moduleData[0].modname,
      });
      openTest(moduleData[0].data[0]);
      testOpened = true;

      return true;
    }
  };

  const getTest = (lpData) => {
    for (let i = 0; i < lpData.modules.length; i++) {
      Alert.setModuleActive(true);
      Alert.setModule({
        id: lpData.modules[i].modid,
        name: lpData.modules[i].modname,
      });

      for (let j = 0; j < lpData.modules[i].data.length; j++) {
        if (
          lpData.modules[i].data[j].status ===
            constants.PROGRESS_STATUS.UNLOCKED ||
          lpData.modules[i].data[j].status ===
            constants.PROGRESS_STATUS.REATTEMPT ||
          lpData.modules[i].data[j].status ===
            constants.PROGRESS_STATUS.QN_CONTINUE ||
          lpData.modules[i].data[j].status ===
            constants.PROGRESS_STATUS.IN_PROGRESS
        ) {
          return lpData.modules[i].data[j];
        }
      }
    }

    Alert.setModuleActive(true);
    Alert.setModule({
      id: lpData.modules[0].modid,
      name: lpData.modules[0].modname,
    });

    return lpData.modules[0].data[0];
  };

  const handleGetDataForHome = () => {
    if (
      sessionStorage.getItem("lpId") != null &&
      sessionStorage.getItem("lpId") !== "undefined" &&
      sessionStorage.getItem("lpId") !== undefined
    ) {
      Alert.setLpId(parseInt(sessionStorage.getItem("lpId")));
      Alert.setUpdateLeftNav(false);
      Alert.setIsLoaded(true);
      axios
        .post(
          "node/learner/course/get",
          JSON.stringify({
            lpid: sessionStorage.getItem("lpId"),
            mocklp: localStorage.getItem("mockLp"),
            lmpid: sessionStorage.getItem("lmpid"),
            learnerid: getDataFromStorage("learnerid"),
            mockstart: sessionStorage.getItem("mockStart"),
          }),
          getHeaderDetailsForAxios()
        )
        .then((res) => {
          Alert.setIsLoaded(false);

          if (res.data.resultCode === constants.RESULT_STATUS.TECHNICAL_ERROR) {
            Alert.setShowNotify({
              show: true,
              title: "Info",
              msg: res.data.msg,
            });
          } else {
            if (res.data.resultCode === constants.RESULT_STATUS.NO_DATA) {
              Alert.setShowNotify({
                show: true,
                title: "Info",
                msg: "Learningpath is not published",
              });
            } else {
              if (res.data.data.courses[0].registered === 0) {
                Alert.setShowNotify({
                  show: true,
                  buttonStyle: "fill",
                  primaryButton: "Proceed",
                  secondaryButton: "Skip for now",
                  primaryButtonFunction: handleRegistration,
                  title: "Welcome to the Gradious LEAP Platform!",
                  msg: (
                    <>
                      To enable proctoring, we'll capture your photo for face
                      verification. <br />
                      If you don't have a webcam, you can skip this step for
                      now.
                    </>
                  ),
                });
              }

              sessionStorage.removeItem("mockStart");
              Alert.setLpId(res.data.data.courses[0].lpid);
              Alert.setShowBlink(res.data.data.markAttendance);
              res.data.data.courses[0].modules.sort(
                (module1, module2) =>
                  module1.moduleorderno - module2.moduleorderno
              );

              res.data.data.courses[0].modules.forEach((module) => {
                module.data.sort(
                  (item1, item2) => item1.orderno - item2.orderno
                );
              });

              if (
                res.data.data.courses[0].useorder !== 0 &&
                parseInt(localStorage.getItem("batchType")) !==
                  constants.BATCH_TYPE.SELF
              ) {
                getTestForOrderMandate(res.data.data.courses[0].modules);
              } else {
                if (Alert.testData.pathName === "dashBoard") {
                  res.data.data.courses[0].modules.map((moduleData) =>
                    moduleData.data.filter(
                      (test) =>
                        (test.testid === Alert.testData.testId ||
                          test.libid === Alert.testData.testId) &&
                        moduleData.modid === Alert.testData.moduleId &&
                        test?.status !== constants.PROGRESS_STATUS.LOCKED &&
                        (Alert.setModuleActive(true),
                        Alert.setModule({
                          id: moduleData.modid,
                          name: moduleData.modname,
                        }),
                        openTest(test))
                    )
                  );
                } else {
                  openTest(getTest(res.data.data.courses[0]));
                }
              }

              res.data.data.courses[0].batchType = parseInt(
                localStorage.getItem("batchType")
              );
              Alert.setLpData(res.data.data.courses[0]);
            }
          }
        })
        .catch((error) => {
          getCatchBlockDetails(error);
        });
    } else {
      Alert.navigate("/main/dashboard");
    }
  };

  return {
    openTest,
    collapsed,
    openModule,
    setCollapsed,
    activeTestRef,
    gotToDashboard,
    calculatePercentage,
    handleGetDataForHome,
  };
}
