import dayjs from "dayjs";
import moment from "moment";
import Select from "react-select";
import Button from "../../Button";
import Form from "react-bootstrap/Form";
import constants from "../../../constants";
import { Editor } from "@tinymce/tinymce-react";
import Radio from "../../CommonComponents/Radio";
import AlertContext from "../../../context/AlertContext";
import InputField from "../../CommonComponents/InputField";
import Datepicker from "../../CommonComponents/Datepicker";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import useAxiosPrivate from "../../../Hooks/useAxiosPrivate";
import React, { useState, useContext, useEffect, useRef } from "react";
import DragAndDropUpload from "../../CommonComponents/DragAndDropUpload";
import useUploadLargeFileToS3 from "../../../Hooks/useUploadLargeFileToS3";
import useCommonFunctionHooks from "../../../Hooks/useCommonFunctionHooks";

const supportedFormat = ["jpg", "jpeg", "png"];

export default function JobForm({ openForm, setOpenForm }) {
  const Alert = useContext(AlertContext);

  const axios = useAxiosPrivate();
  const useCommonFunctionHks = useCommonFunctionHooks();
  const { uploadFileToS3, getS3FileAsFile } = useUploadLargeFileToS3();

  const editorRef = useRef(null);

  const [logo, setlogo] = useState(null);
  const [location, setLocation] = useState([]);
  const [passedOut, setPassedOut] = useState({});
  const [isChecked, setIsChecked] = useState(false);
  const [previewUrl, setPreviewUrl] = useState(null);
  const [selectedQualifications, setSelectedQualifications] = useState([]);

  const { currentEditJob, isEditJob, setIsEditJob, setShowNotify } =
    useContext(AlertContext);

  const [locationOptions, setLocationOptions] = useState([
    { label: "Gurugram", value: 10 },
    { label: "Mumbai", value: 9 },
    { label: "Pune", value: 8 },
    { label: "Delhi", value: 4 },
    { label: "Noida", value: 7 },
    { label: "Chennai", value: 5 },
    { label: "Kolkata", value: 6 },
    { label: "Hyderabad", value: 1 },
    { label: "Bengaluru", value: 3 },
    { label: "Visakhapatnam", value: 2 },
  ]);

  const [jobInfo, setjobInfo] = useState(
    isEditJob
      ? currentEditJob
      : {
          type: 1,
          link: "",
          logo: "",
          isjob: 1,
          name: "",
          jobid: "",
          minexp: "",
          maxexp: "",
          skills: "",
          minctc: null,
          maxctc: null,
          workmode: 1,
          company: "",
          duration: "",
          location: "",
          paymentMode: 0,
          posteddate: "",
          startdate: null,
          description: "",
          qualification: "",
          noofopenings: null,
        }
  );

  const currentYear = new Date().getFullYear();

  const years = Array.from({ length: 5 }, (_, index) => ({
    value: currentYear + index,
    label: currentYear + index,
  }));

  const handleToggleChange = (event) => {
    const target = event.target;

    let value;

    const name = target.name;

    let checked = event.target.checked;

    if (checked === false) {
      value = 0;
    } else {
      value = 1;
    }

    setjobInfo({ ...jobInfo, [name]: value });
  };

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setOpenForm(open);
  };

  const handlePostJob = async (e) => {
    e.preventDefault();

    jobInfo.qualification =
      selectedQualifications.length > 0
        ? selectedQualifications.join(",")
        : null;

    jobInfo.passedOut = passedOut.value || null;
    jobInfo.location = location.map((i) => i?.value) || null;

    let urlForJobPost = isEditJob
      ? "node/learner/update/job"
      : "node/learner/job";

    if (jobInfo.name === "") {
      setShowNotify({
        show: true,
        title: "Warning !",
        msg: `Please fill the designation field`,
      });

      return;
    } else if (jobInfo.company === "") {
      setShowNotify({
        show: true,
        title: "Warning !",
        msg: `Please fill the hiring company name`,
      });

      return;
    } else if (jobInfo.skills === "") {
      setShowNotify({
        show: true,
        title: "Warning !",
        msg: `Please fill the skills required field`,
      });

      return;
    }

    if (jobInfo.isjob) {
      if (jobInfo.minexp === "" || jobInfo.maxexp === "") {
        setShowNotify({
          show: true,
          title: "Warning !",
          msg: `Please fill the minimum and maximum experience field`,
        });

        return;
      }
    } else if (!jobInfo.isjob) {
      if (jobInfo.passedOut == null) {
        setShowNotify({
          show: true,
          title: "Warning !",
          msg: `Please fill the passing out year field`,
        });

        return;
      } else if (jobInfo.duration === "") {
        setShowNotify({
          show: true,
          title: "Warning !",
          msg: `Please fill the duration field`,
        });

        return;
      }
    }

    if (
      jobInfo.logo &&
      jobInfo.logo !== "" &&
      jobInfo.logo.name !== undefined
    ) {
      let imageName = jobInfo.logo.name,
        path = `jobs/logo/${imageName?.substring(
          0,
          imageName.lastIndexOf(".")
        )}_${moment().toISOString()}${imageName?.substring(
          imageName.lastIndexOf(".")
        )}`;

      await uploadFileToS3(
        jobInfo.logo,
        path,
        imageName?.split(".").pop().toLowerCase(),
        "uploadAssignment"
      );

      jobInfo.logo = path;
    } else {
      if (!isEditJob && jobInfo.logo === "") {
        setShowNotify({
          show: true,
          title: "Warning !",
          msg: `Please upload logo`,
        });
        
        return;
      }
    }

    axios
      .post(urlForJobPost, jobInfo)
      .then((res) => {
        if (res.data.resultCode === constants.RESULT_STATUS.SUCCESS) {
          setjobInfo({});
          setIsEditJob(false);
          Alert.navigate("/jobs");
        } else {
          Alert.setShowNotify({
            show: true,
            title: "Error",
            msg: res.data.msg,
          });
        }
      })
      .catch((error) => {
        useCommonFunctionHks.getCatchBlockDetails(error);
      });
  };

  const handleLocationChange = (e) => {
    setLocation(e);
  };

  const handlePassedOutYear = (e) => {
    setPassedOut(e);
  };

  const handleDesc = (e) => {
    setjobInfo({ ...jobInfo, description: e });
  };

  const handleChange = (e, field) => {
    e.preventDefault();

    if (field === "posteddate") {
      const formattedDate = e ? dayjs(e).format("YYYY-MM-DD") : null;

      setjobInfo({ ...jobInfo, [field]: formattedDate });
    } else if (field) {
      setjobInfo({ ...jobInfo, [field]: e.target.value });
    } else {
      setjobInfo({ ...jobInfo, [e.target.name]: e.target.value });
    }
  };

  useEffect(() => {
    if (isEditJob) {
      let updtaedLocations = [];
      let locationsfromdb = jobInfo.location?.split(",");

      for (let i = 0; i < locationsfromdb.length; i++) {
        let curLoc = locationOptions.filter(
          (item) => item.value == locationsfromdb[i]
        );

        updtaedLocations.push(curLoc[0]);
      }

      setLocation(updtaedLocations);
      setIsChecked(jobInfo.Paid === 1);
      setPreviewUrl(currentEditJob.logo);

      setPassedOut({
        value: jobInfo.PassingOutYear,
        label: jobInfo.PassingOutYear,
      });

      const getS3pic = async () => {
        if (jobInfo?.logo && jobInfo?.logo !== "") {
          const result = await getS3FileAsFile(
            jobInfo.logo,
            process.env.REACT_APP_S3_WEBSITE_HOST_BUCKET_NAME
          );

          setlogo(result ?? null);
        }
      };
      getS3pic();
    }
  }, [isEditJob]);

  const onDragOver = (event) => event.preventDefault();

  const onDrop = (event) => {
    const files = Array.from(event.dataTransfer.files);

    uploadFiles(files);
  };

  const uploadFiles = (files = []) => {
    if (files.length > 1) {
      setShowNotify({
        show: true,
        title: "Warning !",
        msg: `Multiple files are not allowed.`,
      });

      return;
    }

    const isUploadableFormat = files.every((file) =>
      supportedFormat.includes(file.type.split("/")[1])
    );

    if (!isUploadableFormat) return;

    setjobInfo({ ...jobInfo, logo: files[0] });
    setlogo(files[0] ?? null);
  };

  const onRemovelogo = () => {
    setlogo(null);
    setjobInfo({ ...jobInfo, logo: null });
  };

  const onUpload = (event) => {
    const files = Array.from(event.target.files);

    uploadFiles(files);
    event.target.value = "";
  };

  // const onDragOver = (event) => event.preventDefault();

  // const onDrop = (event) => {
  //   const files = event.dataTransfer.files;
  // };

  // const clearImage = () => {
  //   setSelectedFile(null);
  //   setPreviewUrl("");
  //   // Reset the file input
  //   if (document.getElementById("fileInput")) {
  //     document.getElementById("fileInput").value = "";
  //   }
  // };

  // const onUpload = (event) => {
  //   const file = event.target.files[0];
  //   if (file && file.type.startsWith("image/")) {
  //     setSelectedFile(file);
  //     const reader = new FileReader();
  //     reader.onloadend = () => {
  //       setPreviewUrl(reader.result);
  //     };
  //     reader.readAsDataURL(file);
  //   } else if (file) {
  //     clearImage();
  //   }
  // };

  return (
    <SwipeableDrawer
      anchor="bottom"
      open={openForm}
      className="jobFormMainContainer"
      onOpen={toggleDrawer("bottom", true)}
      onClose={toggleDrawer("bottom", false)}
    >
      <div className="jobFormDrawer">
        <div className="jobFormHeaderSec">
          <div className="jobFormHeader">Add Job / Internship</div>
          <div className="jobFormHeaderBtn">
            <Button
              size="sm"
              hierarchy={{ type: "tertiaryGrey", buttonText: "Cancel" }}
              onClick={() => {
                setOpenForm(false);
                Alert.navigate("/jobs");
              }}
            />
            <Button
              size="sm"
              onClick={handlePostJob}
              hierarchy={{
                type: "primary",
                buttonText: isEditJob ? "Update" : "Add",
              }}
            />
          </div>
        </div>
        <div className="jobFormContainer">
          <form className="jobForm">
            <div className="jobMode">
              <p>Type</p>
              <div className="jobModeRadioBtn">
                <Radio
                  value={1}
                  size="sm"
                  text="Job"
                  checked={jobInfo.isjob === 1}
                  onChange={() => setjobInfo({ ...jobInfo, isjob: 1 })}
                />
                <Radio
                  size="sm"
                  value={0}
                  text="Internship"
                  checked={jobInfo.isjob === 0}
                  onChange={() => setjobInfo({ ...jobInfo, isjob: 0 })}
                />
              </div>
            </div>
            <InputField
              name="name"
              label="Designation"
              value={jobInfo.name}
              onChange={handleChange}
              placeholder="Enter job title"
            />
            <InputField
              name="jobid"
              label="Job Id"
              value={jobInfo.jobid}
              onChange={handleChange}
              placeholder="Enter job Id"
            />
            <InputField
              name="company"
              label="Hiring Company"
              onChange={handleChange}
              value={jobInfo.company}
              placeholder="Enter Company name"
            />
            <DragAndDropUpload
              file={logo}
              onDrop={onDrop}
              onUpload={onUpload}
              onDragOver={onDragOver}
              onRemoveFile={onRemovelogo}
              label="Hiring Company Logo"
            />
            {jobInfo.isjob ? (
              <div className="jobMode">
                <p>Job Type</p>
                <div className="jobModeRadioBtn">
                  <Radio
                    size="sm"
                    text="Permanent"
                    onChange={() => setjobInfo({ ...jobInfo, type: 1 })}
                    checked={jobInfo.type === constants.jobtype.Permanent}
                  />
                  <Radio
                    size="sm"
                    text="Contract"
                    onChange={() => setjobInfo({ ...jobInfo, type: 2 })}
                    checked={jobInfo.type === constants.jobtype.Contract}
                  />
                  <Radio
                    size="sm"
                    text="Part Time"
                    onChange={() => setjobInfo({ ...jobInfo, type: 3 })}
                    checked={jobInfo.type === constants.jobtype.PartTime}
                  />
                </div>
              </div>
            ) : null}
            <div className="jobMode">
              <p>Work Mode </p>
              <div className="jobModeRadioBtn">
                <Radio
                  size="sm"
                  text="Hybrid"
                  checked={jobInfo.workmode === 1}
                  onChange={() => setjobInfo({ ...jobInfo, workmode: 1 })}
                />
                <Radio
                  size="sm"
                  text="Remote"
                  checked={jobInfo.workmode === 2}
                  onChange={() => setjobInfo({ ...jobInfo, workmode: 2 })}
                />
                <Radio
                  size="sm"
                  text="In Office"
                  checked={jobInfo.workmode === 3}
                  onChange={() => setjobInfo({ ...jobInfo, workmode: 3 })}
                />
              </div>
            </div>
            {jobInfo.isjob ? (
              <div className="jobMode">
                <p>Experience Required</p>
                <div style={{ display: "flex", gap: "48px" }}>
                  <InputField
                    name="minexp"
                    placeholder="0"
                    value={jobInfo.minexp}
                    onChange={handleChange}
                    label="Minimum Experience"
                  />
                  <InputField
                    name="maxexp"
                    placeholder="2"
                    value={jobInfo.maxexp}
                    onChange={handleChange}
                    label="Maximum Experience"
                  />
                </div>
              </div>
            ) : null}
            {jobInfo.workmode !== 2 && (
              <div className="jobMode">
                <p>Location</p>
                <Select
                  isMulti
                  isClearable
                  value={location}
                  options={locationOptions}
                  onChange={handleLocationChange}
                  placeholder={"Choose Locations"}
                />
              </div>
            )}
            {!jobInfo.isjob && (
              <div className="jobMode">
                <p>Applicable Passing out year</p>
                <Select
                  options={years}
                  value={passedOut}
                  onChange={handlePassedOutYear}
                  placeholder={"Choose Passing out year"}
                />
              </div>
            )}
            {!jobInfo.isjob && (
              <div className="jobPayment">
                <p>Paid: </p>
                <Form.Check
                  reverse
                  type="switch"
                  id="customSwitch"
                  name="paymentMode"
                  checked={isChecked}
                  style={{ boxShadow: "none" }}
                  onChange={(e) => {
                    setIsChecked((prev) => !prev);
                    handleToggleChange(e);
                  }}
                />
              </div>
            )}

            <div className="jobMode">
              <p>{jobInfo.isjob ? "Salary" : "Stipend"} Range</p>
              <div style={{ display: "flex", gap: "48px" }}>
                <InputField
                  name="minctc"
                  label="Minimum CTC"
                  placeholder="400000"
                  value={jobInfo.minctc}
                  onChange={handleChange}
                />
                <InputField
                  name="maxctc"
                  label="Maximum CTC"
                  placeholder="1200000"
                  value={jobInfo.maxctc}
                  onChange={handleChange}
                />
              </div>
            </div>

            <InputField
              name="skills"
              placeholder="Skills"
              value={jobInfo.skills}
              label="Skills Required"
              onChange={handleChange}
            />
            {!jobInfo.isjob && (
              <div className="internshipStartDate">
                <label>Starting Date</label>

                <Datepicker
                  value={dayjs(jobInfo.startdate)}
                  onChange={(newValue) =>
                    setjobInfo({ ...jobInfo, startdate: newValue })
                  }
                />
              </div>
            )}
            {!jobInfo.isjob && (
              <InputField
                name="duration"
                onChange={handleChange}
                value={jobInfo.duration}
                label="Total Duration of Internship"
                placeholder="Enter Internship Duration in No of Months"
              />
            )}
            <InputField
              label="Openings"
              name="noofopenings"
              onChange={handleChange}
              value={jobInfo.noofopenings}
              placeholder="Enter  No of Openings"
            />
            <InputField
              name="link"
              value={jobInfo.link}
              label="Link to apply"
              onChange={handleChange}
              placeholder="Add your Job link here"
            />
            <div className="jobMode">
              <p>Description</p>
              <Editor
                value={jobInfo.description}
                onEditorChange={handleDesc}
                tinymceScriptSrc="/tinymce/tinymce.min.js"
                onInit={(evt, editor) => (editorRef.current = editor)}
                init={{
                  readonly: true,
                  menubar: false,
                  min_height: 150,
                  branding: false,
                  statusbar: false,
                  license_key: "gpl",
                  elementpath: false,
                  selector: "textarea",
                  allow_html_in_named_anchor: true,
                  plugins: [
                    "link",
                    "help",
                    "code",
                    "lists",
                    "image",
                    "table",
                    "media",
                    "anchor",
                    "editor",
                    "advlist",
                    "preview",
                    "charmap",
                    "wordcount",
                    "fullscreen",
                    "autoresize",
                    "visualblocks",
                    "searchreplace",
                    "insertdatetime",
                  ],
                  toolbar:
                    "undo redo | blocks | " +
                    "bold italic underline forecolor | link | alignleft aligncenter " +
                    "alignright alignjustify | bullist numlist outdent indent | " +
                    "removeformat | help |",
                  content_style:
                    "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
                }}
              />
            </div>
          </form>
        </div>
      </div>
    </SwipeableDrawer>
  );
}
