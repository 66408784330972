import React, { useContext, useEffect, useRef, useState } from "react";
// import Modal from "../../CommonComponents/Modal";
// import ModalBody from "../../CommonComponents/ModalBody";
import DoubtForm from "./DoubtForm";
import Button from "../../Button";
import Table from "../../CommonComponents/Table";
import AlertContext from "../../../context/AlertContext";
import constants from "../../../constants";
import useAxiosPrivate from "../../../Hooks/useAxiosPrivate";
import useUploadLargeFileToS3 from "../../../Hooks/useUploadLargeFileToS3";
import Toast from "../../CommonComponents/Toast";
import { Modal } from "@mui/material";
import IconButton from "../../CommonComponents/IconButton";

const GetIssueStatus = ({ value }) =>
  Object.entries(constants.ISSUE_STATUS).map(
    (data, index) =>
      data[1] === value && (
        <p key={index} className={data[0]?.toLowerCase()}>
          {data[0]?.toLowerCase()}
        </p>
      )
  );

const columns = [
    { key: "sno", title: "S.No", className: "serialNo" },
    // { key: "typename", title: "Doubt Type", className: "issueType" },
    { key: "bname", title: "Batch", className: "batch" },
    {
      key: "title",
      title: "Doubt Title",
      className: "issueTitle",
    },
    {
      key: "description",
      title: "Description",
      className: "description",
    },
    {
      key: "status",
      title: "Status",
      className: "status",
      render: (value, row) => <GetIssueStatus value={value} />,
    },
  ],
  initialDetails = {
    title: "",
    type: 1,
    bid: null,
    description: "",
    attachment: null,
  };

const DoubtsTable = () => {
  const { isLoaded, setShowNotify, setIsLoaded } = useContext(AlertContext);
  const axios = useAxiosPrivate();
  const { getS3FileAsFile } = useUploadLargeFileToS3();

  const [hasMore, setHasMore] = useState(false);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [issues, setIssues] = useState([]);
  const [showForm, setShowForm] = useState(false);
  const [details, setDetails] = useState(initialDetails);
  const [selectedBatch, setSelectedBatch] = useState({});
  const [selectedType, setSelectedType] = useState({
    value: "Concept Learning",
    id: 1,
  });
  const [isSuccess, setIsSuccess] = useState(false);
  const tableRef = useRef(null);

  const isEdit = details?.id && Number.isInteger(Number(details.id));

  const calculatePageSize = () => {
    const viewportHeight = window.innerHeight;
    const itemHeight = 48;
    return Math.floor(viewportHeight / itemHeight);
  };

  useEffect(() => {
    const handleResize = () => {
      setPageSize(calculatePageSize());
    };

    handleResize();
    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    const fetchRaisedIssues = async () => {
      // const pageSize = 10;
      if (!hasMore) return;
      try {
        setIsLoaded(true);
        const response = await axios.get(
          `node/learner/issue?page=${page}&limit=${pageSize}&isdoubt=1`,
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
        if (response.data.resultCode === constants.RESULT_STATUS.SUCCESS) {
          const data = response.data.data ?? [];
          setIssues([
            ...data.map((item, index) => ({
              sno: index + 1,
              type: item.category,
              title: item.summary,
              batch: item.batchname,
              ...item,
            })),
          ]);
          if (data.length < pageSize) setHasMore(false);
        } else {
          setShowNotify({
            show: true,
            title: "Error!",
            msg:
              response.data.msg ??
              "Something went wrong. Please try again later or contact support.",
          });
        }
      } catch (error) {
        setShowNotify({
          show: true,
          title: error.message.includes("403") ? "Warning!" : "Error!",
          msg: error.message.includes("403")
            ? "You have been logged-out due to inactivity. Login again."
            : error.message ??
              "Something went wrong. Please try again later or contact support.",
          isUnAuth: error.message.includes("403"),
        });
      } finally {
        setIsLoaded(false);
      }
    };
    if (!showForm) fetchRaisedIssues();
  }, [axios, setIsLoaded, setShowNotify, showForm, hasMore, page, pageSize]);

  useEffect(() => {
    setHasMore(true);
    setPage(1);
  }, [showForm]);

  const onClickAddBtn = () => {
    setShowForm(true);
    setDetails(initialDetails);
    setSelectedBatch({});
  };

  const onCloseForm = () => {
    setShowForm(false);
    setDetails(initialDetails);
    setSelectedBatch({});
  };

  const onRowClick = async (row) => {
    setShowForm(true);
    let attachmentPath = null;
    if (row.attachment) {
      attachmentPath = await getS3FileAsFile(
        row.attachment,
        process.env.REACT_APP_S3_WEBSITE_HOST_BUCKET_NAME
      );
    }
    setDetails({
      id: row.id,
      title: row.title,
      type: row.type,
      bid: row.bid,
      mid: row.mid,
      description: row.description,
      attachment: attachmentPath,
      status: row.status,
    });
    setSelectedBatch({
      label: row.bname,
      value: row.bname,
      id: row.bid,
    });
    setSelectedType({
      id: row.type,
      value: row.typename,
      label: row.typename,
    });
    setShowForm(true);
  };

  const handleScroll = () => {
    if (!tableRef.current || isLoaded || !hasMore) return;

    const { scrollTop, scrollHeight, clientHeight } = tableRef.current;

    if (scrollTop + clientHeight >= scrollHeight - 10) {
      setPage((prev) => prev + 1);
    }
  };

  return (
    <div className="raisedIssueContainer">
      <div className="raisedIssueHeaderSec">
        <div className="">
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M7.82502 7.49935C8.02094 6.94241 8.40765 6.47277 8.91665 6.17363C9.42565 5.87448 10.0241 5.76513 10.606 5.86494C11.1879 5.96475 11.7157 6.26729 12.0959 6.71896C12.4761 7.17063 12.6842 7.74228 12.6834 8.33268C12.6834 9.99935 10.1834 10.8327 10.1834 10.8327M10.25 14.166H10.2584M18.5834 9.99935C18.5834 14.6017 14.8524 18.3327 10.25 18.3327C5.64765 18.3327 1.91669 14.6017 1.91669 9.99935C1.91669 5.39698 5.64765 1.66602 10.25 1.66602C14.8524 1.66602 18.5834 5.39698 18.5834 9.99935Z"
              stroke="#344054"
              stroke-width="1.6"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
          <p className="raisedIssueHeader">Ask Doubts</p>
        </div>
        <Button
          size="sm"
          hierarchy={{ type: "secondaryGrey", buttonText: "Ask Doubt" }}
          icon={{
            type: "leading",
            icon: (
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M10.0001 4.16699V15.8337M4.16675 10.0003H15.8334"
                  stroke="#344054"
                  strokeWidth="1.67"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            ),
          }}
          onClick={onClickAddBtn}
        />
      </div>
      <Modal open={showForm} className="raisedIssueFormModalContainer">
        <div className="raisedIssueFormModal">
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <div className="modalTitle">
              {`${isEdit ? "Edit" : "New"} Doubt`}{" "}
            </div>
            <div className="modalCloseIcon">
              <IconButton
                onClick={onCloseForm}
                size="sm"
                hierarchy="tertiaryGrey"
              >
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M15 5L5 15M5 5L15 15"
                    stroke="#667085"
                    strokeWidth="1.66667"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </IconButton>
            </div>
          </div>
          <DoubtForm
            setShowForm={setShowForm}
            isEdit={isEdit}
            details={details}
            setDetails={setDetails}
            selectedBatch={selectedBatch}
            setSelectedBatch={setSelectedBatch}
            selectedType={selectedType}
            setSelectedType={setSelectedType}
            initialDetails={initialDetails}
            setIsSuccess={setIsSuccess}
          />
        </div>
      </Modal>
      {/* <Modal
        isOpen={showForm}
        showCloseIcon
        onClose={onCloseForm}
        title={`${isEdit ? "Edit" : "New"} Doubt`}
        closeIcon={
          <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M15 5L5 15M5 5L15 15"
              stroke="#667085"
              strokeWidth="1.66667"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        }
      >
        <ModalBody
          content={
            <DoubtForm
              setShowForm={setShowForm}
              isEdit={isEdit}
              details={details}
              setDetails={setDetails}
              selectedBatch={selectedBatch}
              setSelectedBatch={setSelectedBatch}
              selectedType={selectedType}
              setSelectedType={setSelectedType}
              initialDetails={initialDetails}
              setIsSuccess={setIsSuccess}
            />
          }
        />
      </Modal> */}
      <Toast
        open={isSuccess}
        icon={
          <svg
            width="46"
            height="46"
            viewBox="0 0 46 46"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect x="3" y="3" width="40" height="40" rx="20" fill="#D1FADF" />
            <rect
              x="3"
              y="3"
              width="40"
              height="40"
              rx="20"
              stroke="#ECFDF3"
              strokeWidth="6"
            />
            <g clip-path="url(#clip0_9645_95814)">
              <path
                d="M20.5001 23L22.1667 24.6667L25.9167 20.9167M19.1116 16.1822C19.7814 16.1288 20.4173 15.8654 20.9288 15.4295C22.1224 14.4124 23.8778 14.4124 25.0714 15.4295C25.5828 15.8654 26.2187 16.1288 26.8886 16.1822C28.4518 16.307 29.6931 17.5483 29.8178 19.1115C29.8713 19.7813 30.1347 20.4173 30.5706 20.9287C31.5877 22.1223 31.5877 23.8777 30.5706 25.0713C30.1347 25.5827 29.8713 26.2187 29.8178 26.8885C29.6931 28.4517 28.4518 29.693 26.8886 29.8178C26.2187 29.8712 25.5828 30.1346 25.0714 30.5705C23.8778 31.5876 22.1224 31.5876 20.9288 30.5705C20.4173 30.1346 19.7814 29.8712 19.1116 29.8178C17.5484 29.693 16.3071 28.4517 16.1823 26.8885C16.1289 26.2187 15.8655 25.5827 15.4296 25.0713C14.4125 23.8777 14.4125 22.1223 15.4296 20.9287C15.8655 20.4173 16.1289 19.7813 16.1823 19.1115C16.3071 17.5483 17.5484 16.307 19.1116 16.1822Z"
                stroke="#039855"
                strokeWidth="1.66667"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </g>
            <defs>
              <clipPath id="clip0_9645_95814">
                <rect
                  width="20"
                  height="20"
                  fill="white"
                  transform="translate(13 13)"
                />
              </clipPath>
            </defs>
          </svg>
        }
        autoHideDuration={3000}
        TransitionComponent={"Fade"}
        message={`Doubt submitted successfully`}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        closeIcon={
          <svg
            width="36"
            height="36"
            viewBox="0 0 36 36"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            onClick={() => setIsSuccess(false)}
          >
            <path
              d="M23 13L13 23M13 13L23 23"
              stroke="#667085"
              stroke-width="1.66667"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        }
      />
      <div
        ref={tableRef}
        onScroll={handleScroll}
        className="raisedIssueTableContainer"
      >
        <Table columns={columns} data={issues} onRowClick={onRowClick} />
      </div>
    </div>
  );
};

export default DoubtsTable;
