import React from "react";

export default function ClockSvg(Props) {
  return Props.component === "interview" ? (
    <svg
      width="20"
      height="20"
      fill="none"
      viewBox="0 0 20 20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_9357_30741)">
        <path
          stroke-width="1.6"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke="url(#paint0_linear_9357_30741)"
          d="M10 4.99996V9.99996L13.3334 11.6666M18.3334 9.99996C18.3334 14.6023 14.6024 18.3333 10 18.3333C5.39765 18.3333 1.66669 14.6023 1.66669 9.99996C1.66669 5.39759 5.39765 1.66663 10 1.66663C14.6024 1.66663 18.3334 5.39759 18.3334 9.99996Z"
        />
      </g>
      <defs>
        <linearGradient
          x1="3.33335"
          y1="1.66663"
          x2="18.3334"
          y2="27.9166"
          id="paint0_linear_9357_30741"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#FF5533" />
          <stop offset="1" stop-color="#99331F" />
        </linearGradient>
        <clipPath id="clip0_9357_30741">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  ) : (
    <svg
      width="20"
      height="20"
      fill="none"
      viewBox="0 0 20 20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_2265_12590)">
        <path
          stroke="#344054"
          stroke-width="1.6"
          stroke-linecap="round"
          stroke-linejoin="round"
          d="M9.99935 4.99984V9.99984L13.3327 11.6665M18.3327 9.99984C18.3327 14.6022 14.6017 18.3332 9.99935 18.3332C5.39698 18.3332 1.66602 14.6022 1.66602 9.99984C1.66602 5.39746 5.39698 1.6665 9.99935 1.6665C14.6017 1.6665 18.3327 5.39746 18.3327 9.99984Z"
        />
      </g>
      <defs>
        <clipPath id="clip0_2265_12590">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
