import { useContext, useState } from "react";
import AlertContext from "../../context/AlertContext";
import { UserReportContext } from "../../context/UserReportContext";

export default function UserReportHooks() {
  const Alert = useContext(AlertContext);
  const UserReportCxt = useContext(UserReportContext);

  const customStyle = {
    control: (provided) => ({
      ...provided,
      minWidth: 150,
      maxWidth: 200,
      outline: "none",
      cursor: "pointer",
      boxShadow: "none",
      border: "0px solid white",
      backgroundColor: "#fafafa",
    }),
    option: (styles) => {
      return {
        ...styles,
        color: "black",
        fontSize: "12px",
        textAlign: "left",
        cursor: "pointer",
        padding: "2px 12px",
        backgroundColor: "white",
      };
    },
  };

  const handleChangeLearnerByBtn = (direction) => {
    let newIndex = UserReportCxt.currentIndex;

    if (direction === "prev" && UserReportCxt.currentIndex > 0) {
      newIndex -= 1;
    } else if (
      direction === "next" &&
      UserReportCxt.currentIndex < UserReportCxt.learnerList.length - 1
    ) {
      newIndex += 1;
    }

    handleStudentChange(UserReportCxt.learnerList[newIndex]);
  };

  const handleStudentChange = (e) => {
    Alert.setLearnerId(e.value);
    UserReportCxt.setCurrentIndex(e.ind);
    UserReportCxt.setCurrentLearnerDetails(e);
  };

  return { customStyle, handleStudentChange, handleChangeLearnerByBtn };
}
