import React from "react";
import PropTypes from "prop-types";

const Textarea = ({
  label,
  hintText,
  destructive = false,
  value,
  onChange,
  disabled = false,
  placeholder = "Enter a description...",
  className = "",
  ...props
}) => {
  return (
    <div className={`textareaContainer ${destructive ? "destructive" : ""}`}>
      <div className="textareaSec">
        {label && <label className="textareaLabel">{label}</label>}
        <textarea
          className={`textareaField ${className}`}
          value={value}
          onChange={onChange}
          disabled={disabled}
          placeholder={placeholder}
          {...props}
        ></textarea>
      </div>
      {hintText && <div className="textareaHint">{hintText}</div>}
    </div>
  );
};

export default Textarea;

Textarea.propTypes = {
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  hintText: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  destructive: PropTypes.bool,
  value: PropTypes.string,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  placeholder: PropTypes.string,
};
