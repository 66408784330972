import constants from "../../../constants";
import Profile from "../Home/LandingPage/Profile";
import { getDataFromStorage } from "../../../util";
import SandPackEditorTools from "./SandPackEditorTools";
import RecommendedWebsites from "./RecommendedWebsites";
import AlertContext from "../../../context/AlertContext";
import useAxiosPrivate from "../../../Hooks/useAxiosPrivate";
import { SandpackProvider } from "@codesandbox/sandpack-react";
import Loading from "../../Notification-Loading/Loading/Loading";
import useSubmitAssignment from "../../../Hooks/useSubmitAssignment";
import useCommonFunctionHooks from "../../../Hooks/useCommonFunctionHooks";
import React, { memo, useContext, useEffect, useState, useRef } from "react";
import SandpackAssignment from "./SandpackAssignment";
import ModuleHooks from "../../../Hooks/ModuleHooks";
export default memo(function SandPackEditor(Props) {
  const Alert = useContext(AlertContext);

  const {
    lpId,
    isAdmin,
    isLoaded,
    testData,
    navigate,
    setLpData,
    lpConfigId,
    setIsLoaded,
    contentData,
    learnerNotes,
    camAvailable,
    sandpackFiles,
    setContentData,
    setIsSubmitted,
    isFullScreensp,
    setCamAvailable,
    allWindowsBlurred,
    openSandpackEditor,
    setIsAddSubmission,
    setIsEditSubmission,
    setOpenSandpackEditor,
    showInstructions,
    setShowInstructions,
  } = useContext(AlertContext);

  const axios = useAxiosPrivate();
  const submitAssignment = useSubmitAssignment();
  const useCommonFunctionHks = useCommonFunctionHooks();
  const { handlePopupStates, clearPopupStates } = ModuleHooks();
  const { electronAPI } = window;
  const focusOutSec = useRef(0);
  const focusOutTimer = useRef(null);

  const [startTime, setStartTime] = useState(null);

  const currentDueLabel = (date) => {
    const dueDate = new Date(date);
    const currentDate = new Date();
    const timeDifference = dueDate.getTime() - currentDate.getTime();
    const dayDifference = Math.floor(timeDifference / (1000 * 3600 * 24));

    if (dayDifference === 0) {
      return "Due Today";
    } else if (
      dayDifference === 1 ||
      (dayDifference === 0 && dueDate !== currentDate)
    ) {
      return "Due Tomorrow";
    } else if (dayDifference < 0) {
      return "Past Due";
    } else {
      return "Upcoming";
    }
  };


  
  const handleWarningResponsePopupClose = () => {
    clearPopupStates();
    // navigate("/home");
  };

  const handleResponse = (title, msg) => {
    let popupdata = {};
    popupdata.showpopup = true;
    popupdata.title = title;
    popupdata.bodydesc = msg;
    popupdata.singlebtn = true;
    popupdata.singlebtntxt = "Ok";
    popupdata.singlebtnsize = "small";
    popupdata.singlebtnfunt = handleWarningResponsePopupClose;
    handlePopupStates(popupdata);
  };

  const closeAssignment = async () => {
    Alert.stopProctoring.current.stop = true;

    try {
      setIsLoaded(true);

      let response = await submitAssignment(
        {
          lpId,
          isSave: false,
          libId: testData.libid,
          template: contentData.template,
          lpConfigId: testData.lpconfigid,
          learnerNotes: learnerNotes ? learnerNotes : "",
          file: {
            file: sandpackFiles,
            contentType: "json",
            name: `${getDataFromStorage("learnerid")}.json`,
          },
        },
        true
      );

      if (response.data.resultCode !== constants.RESULT_STATUS.SUCCESS) {
        Alert.setShowNotify({
          show: true,
          title: "Error",
          msg:
            response.data === ""
              ? "Something went wrong. Please try again later"
              : response.data.message,
        });
      } else {
        let newFiles = [],
          filePath = "";

        if (process.env.REACT_APP_DEPLOYMENT === "AWS")
          filePath = `SandpackFiles/library/submission/${
            testData.libid
          }/${getDataFromStorage("learnerid")}.json`;
        else
          filePath = `library/submission/${getDataFromStorage(
            "learnerid"
          )}/${getDataFromStorage("learnerid")}.json`;

        newFiles.push(filePath);

        let assignmentData = JSON.parse(JSON.stringify(contentData));

        assignmentData.commentsdata = [];
        assignmentData.anslinks = JSON.stringify(newFiles);
        assignmentData.answer = learnerNotes;
        assignmentData.submitteddate = new Date();
        setContentData(assignmentData);

        // need to look
        // setStatus(
        //   isSave
        //     ? constants.PROGRESS_STATUS.TO_BE_EVALUATED
        //     : constants.PROGRESS_STATUS.IN_PROGRESS
        // );

        setIsSubmitted(true);
        setIsEditSubmission(false);
        setIsAddSubmission(false);

        // if (isFullScreensp) {
        //   document.exitFullscreen();
        //   setIsFullScreensp(false);
        // }

        setLpData({});
        handleResponse("Success", response.data.message);
        // if(isSave){

        // navigate("/home");

        // }
      }
    } catch (err) {
      useCommonFunctionHks.getCatchBlockDetails(err);
    } finally {
      setIsLoaded(false);
    }
  };

  const onFocus = () => {
    if (!allWindowsBlurred.current || !electronAPI) {
      focusOutSec.current = 0;
    }
    if (!electronAPI) {
      clearInterval(focusOutTimer.current);
    }
    if (electronAPI) {
      allWindowsBlurred.current = false;
    }
    window.removeEventListener("blur", onBlur);
  };

  const onBlur = () => {
    focusOutTimer.current = setInterval(() => {
      let second = focusOutSec.current;
      if (second === 10) {
        clearInterval(focusOutTimer.current);
        closeAssignment();
      } else if (allWindowsBlurred.current || !electronAPI) {
        second++;
        focusOutSec.current = second;
      }
    }, 1000);
  };

  useEffect(() => {
    if (
      !isAdmin &&
      Props.status !== constants.PROGRESS_STATUS.EVALUATED &&
      testData.proctored !== constants.PROCTORING_MODE.NONE &&
      (currentDueLabel(testData.duedate) !== "Past Due" ||
        localStorage.getItem("batchType") == constants.BATCH_TYPE.SELF)
    ) {
      window.addEventListener("blur", onBlur);
      window.addEventListener("focus", onFocus);

      return () => {
        window.removeEventListener("blur", onBlur);
        window.removeEventListener("focus", onFocus);
        clearInterval(focusOutTimer.current);
      };
    }
  }, [onBlur]);

  // useEffect(() => {
  //   if (document.documentElement.requestFullscreen) {
  //     document.documentElement.requestFullscreen();
  //   } else if (document.documentElement.mozRequestFullScreen) {
  //     document.documentElement.mozRequestFullScreen();
  //   } else if (document.documentElement.webkitRequestFullscreen) {
  //     document.documentElement.webkitRequestFullscreen();
  //   } else if (document.documentElement.msRequestFullscreen) {
  //     document.documentElement.msRequestFullscreen();
  //   }
  //   return () => {
  //     if (document.fullscreenElement) {
  //       document.exitFullscreen();
  //     } else if (document.mozFullScreenElement) {
  //       document.mozCancelFullScreen();
  //     } else if (document.webkitFullscreenElement) {
  //       document.webkitExitFullscreen();
  //     } else if (document.msFullscreenElement) {
  //       document.msExitFullscreen();
  //     }
  //   };
  // }, []);

  // useEffect(() => {
  //   const handleFullscreenChange = () => {
  //     if (!document.fullscreenElement) {
  //       if (isAdmin) {
  //         navigate("/assignment");
  //       } else {
  //         if (Props.status !== constants.PROGRESS_STATUS.EVALUATED && testData.proctored!==constants.PROCTORING_MODE.NONE) {
  //           // setTimeout(async ()=>{
  //            closeAssignment();
  //         // },1000)
  //         } else if(testData.proctored!==constants.PROCTORING_MODE.NONE) {
  //           navigate("/home");
  //         }
  //       }
  //     }
  //   };

  //   document.addEventListener("fullscreenchange", handleFullscreenChange);
  //   return () => {
  //     document.removeEventListener("fullscreenchange", handleFullscreenChange);
  //   };
  // }, []);

  useEffect(() => {
    // When component mounts

    let role = getDataFromStorage("role");

    if (
      role === constants.Roles.learner &&
      ![
        constants.PROGRESS_STATUS.EVALUATED &&
          constants.PROGRESS_STATUS.TO_BE_EVALUATED,
      ].includes(Props.status) &&
      (allWindowsBlurred.current || !electronAPI)
    ) {
      const newStartTime = Date.now();

      let storedTime;
      let learnerId = getDataFromStorage("learnerid");

      async function duration() {
        // storedTime = localStorage.getItem('timeSpent');

        let response = await axios.get(
          `node/learner/content/timespent/${learnerId}/${lpConfigId}`,
          useCommonFunctionHks.getHeaderDetailsForAxios()
        );

        storedTime = response?.data?.data?.duration;
        storedTime = storedTime * 1000 * 60;
        setStartTime(newStartTime);
      }

      duration();

      // When component unmounts

      return () => {
        async function save() {
          const endTime = Date.now();

          let timeSpent =
            endTime - newStartTime + (storedTime ? storedTime : 0);
          timeSpent = Math.round(timeSpent / (60 * 1000));

          let learnerId = getDataFromStorage("learnerid");
          let requestData = {
            duration: timeSpent,
            learnerid: learnerId,
            lpconfigid: lpConfigId,
          };

          // localStorage.setItem('timeSpent', timeSpent.toString());

          let response = await axios.post(
            `node/learner/content/timespent`,
            requestData,
            useCommonFunctionHks.getHeaderDetailsForAxios()
          );
        }

        if (
          role === constants.Roles.learner &&
          ![
            constants.PROGRESS_STATUS.EVALUATED &&
              constants.PROGRESS_STATUS.TO_BE_EVALUATED,
          ].includes(Props.status) &&
          (allWindowsBlurred.current || !electronAPI)
        ) {
          save();
        }
      };
    }
  }, []);

  return (
    <>
      {Alert.openRecommendedList && (
        <div className="recommendedwebsitescont">
          <RecommendedWebsites />
        </div>
      )}
      {
        <div className="recommendedwebsitescont">
          <SandpackAssignment
            showAssignmentInstructions={showInstructions}
            setShowAssignmentInstructions={setShowInstructions}
            description={Alert.assignmentTestData.description}
            name={Alert.testData.libname}
          />
        </div>
      }
      <div
        className={
          isFullScreensp
            ? "sandpackEditorContainer1"
            : "sandpackEditorContainer2"
        }
      >
        {!isAdmin &&
          Props.status !== constants.PROGRESS_STATUS.EVALUATED &&
          testData.proctored == constants.PROCTORING_MODE.TAB_CAM &&
          (currentDueLabel(testData.duedate) !== "Past Due" ||
            localStorage.getItem("batchType") == constants.BATCH_TYPE.SELF) && (
          <div className="profileSandpack">
            <Profile
              type="verfypic"
              questionid={lpConfigId}
              camAvailable={camAvailable}
              isTakeTest={Alert.isTakeTest}
              setCamAvailable={setCamAvailable}
              libtypeid={constants.libTypeIds.ASSIGNMENT}
            />
          </div>
          )}
        {isLoaded && <Loading />}
        <SandpackProvider
          template={Props.selectedTemplate?.value?.toLowerCase()}
          options={{
            autorun: false,
            autoReload: false,
            showConsole: false,
          }}
        >
          <SandPackEditorTools
            status={Props.status}
            focusOutSec={focusOutSec}
            learnerId={Props.learnerId}
            libDetails={Props.libDetails}
            isqueReport={Props.isqueReport}
            template={Props.selectedTemplate}
            isAddSubmission={Props.isAddSubmission}
            openSandpackEditor={openSandpackEditor}
            setOpenSandpackEditor={setOpenSandpackEditor}
          />
        </SandpackProvider>
      </div>
    </>
  );
});
