import Button from "../../Button";
import React, { useContext } from "react";
import constants from "../../../constants";
import HalfDonutChart from "../../HalfDonutChart";
import IconButton from "@mui/material/IconButton";
import BookmarkSvg from "../../../Svg/BookmarkSvg";
import RightSvgIcon from "../../../Svg/RightSvgIcon";
import BookmarkedSvg from "../../../Svg/BookmarkedSvg";
import AlertContext from "../../../context/AlertContext";
import WobackCloseSvg from "../../../Svg/WobackCloseSvg";
import { TextareaAutosize } from "@mui/base/TextareaAutosize";
import { StudentTestPageContext } from "../../../context/StudentTestPageContext";
import StudentTestPageHooks from "../../../Hooks/StudentTestPage/StudentTestPageHooks";
import StudentTestBodyPageHooks from "../../../Hooks/StudentTestPage/StudentTestBodyPageHooks";
import CodingHeaderLearnerSideHooks from "../../../Hooks/CodingPage/CodingHeaderLearnerSideHooks";
import StudentTestBodyPageSingleViewHooks from "../../../Hooks/StudentTestPage/StudentTestBodyPageSingleViewHooks";
import Loading from "../../Notification-Loading/Loading/Loading";
const parse = require("html-react-parser");

export default function StudentTestBodyPageSingleView() {
  const Alert = useContext(AlertContext);
  const StudentTestPageCxt = useContext(StudentTestPageContext);

  const StudentTestPageHks = StudentTestPageHooks();
  const CodingHeaderLearnerSideHks = CodingHeaderLearnerSideHooks();

  const StudentTestBodyPageHks = StudentTestBodyPageHooks();
  const StudentTestBodyPageSingleViewHks = StudentTestBodyPageSingleViewHooks();

  return (
    <div className="studentTestBodyPageSingleViewMainContainer">
      {
        /* Alert.PractSingleQnFinalSubmit.current */ Alert.showHalfDonut ? (
          <>
            {!Alert.halfDonutChart.hasOwnProperty("target") ? (
              <div className="loading-chart">
                <Loading />
              </div>
            ) : (
              <div className="half-donut-chart-div">
                <HalfDonutChart
                  achievedPercentage={Alert.halfDonutChart?.achievedpercent}
                  targetPercentage={Alert.halfDonutChart?.targetPercent}
                />
              </div>
            )}
          </>
        ) : (
          <>
            <div className="studentTestBodyPageSingleViewQuestionHeader">
              <div className="studentTestBodyPageSingleViewQuestionHeaderDiv">
                <div className="studentTestBodyPageSingleViewQuestionHeaderTitleDiv">
                  {Alert.testData.testmode !== constants.MODE.PRACTICE_MODE && (
                    <>
                      {Object.keys(StudentTestPageCxt.curObj)?.length > 0 &&
                        StudentTestPageCxt.curObj.hasOwnProperty("review") &&
                        StudentTestPageCxt.curObj.review === -1 && (
                          <IconButton
                            className="studentTestBodyPageSingleViewQuestionHeaderTitleReviewLater"
                            onClick={() =>
                              StudentTestBodyPageSingleViewHks.reviewLater(
                                "single",
                                "noreview",
                                Alert.queObj
                              )
                            }
                          >
                            <BookmarkSvg />
                          </IconButton>
                        )}
                      {Object.keys(StudentTestPageCxt.curObj)?.length > 0 &&
                        StudentTestPageCxt.curObj.hasOwnProperty("review") &&
                        StudentTestPageCxt.curObj.review === 0 && (
                          <IconButton
                            className="studentTestBodyPageSingleViewQuestionHeaderTitleReviewLater"
                            onClick={() =>
                              StudentTestBodyPageSingleViewHks.reviewLater(
                                "single",
                                "review",
                                Alert.queObj
                              )
                            }
                          >
                            <BookmarkedSvg />
                          </IconButton>
                        )}
                    </>
                  )}
                  {
                    <div
                      className="studentTestBodyPageSingleViewQuestionHeaderTitleQnNo"
                      ref={(el) =>
                        (StudentTestPageCxt.questionRefs.current[
                          Alert.queInd + 1
                        ] = el)
                      }
                    >
                      {(Alert.comingObj.videoqn !== 1 || Alert.isAdmin) && (
                        <>
                          {Alert.comingObj.videoqn === 1
                            ? StudentTestPageCxt.videoQnNo.current
                            : Alert.queInd + 1}
                          .
                        </>
                      )}
                      <div
                        id="dynamicFont"
                        ref={StudentTestPageCxt.textRef}
                        className={
                          Alert.comingObj.videoqn === 1
                            ? "studentTestBodyPageSingleViewQuestionHeaderTitleQnNameForVideo"
                            : "studentTestBodyPageSingleViewQuestionHeaderTitleQnName"
                        }
                      >
                        {Alert.comingObj.videoqn === 1
                          ? Alert.videoQn.current?.filter(
                              (data) => data?.selected
                            )[0]?.name
                          : Alert.queObj?.name}
                      </div>
                      {(Alert.comingObj.videoqn !== 1 || Alert.isAdmin) && (
                        <div className="studentTestBodyPageSingleViewQuestionHeaderTitleQnMark">
                          {Alert.comingObj.videoqn === 1
                            ? "(" +
                              Alert.videoQn.current?.filter(
                                (data) => data?.selected
                              )[0]?.targetscore +
                              " Marks)"
                            : "(" + Alert.queObj?.targetscore + " Marks)"}
                        </div>
                      )}
                    </div>
                  }
                  {/* {Alert.comingObj.videoqn === 1 && (
                    <Button
                      size="sm"
                      onClick={() =>
                        StudentTestBodyPageSingleViewHks.playQns(
                          Alert.comingObj.videoqn === 1
                            ? Alert.videoQn.current?.filter(
                                (data) => data?.selected
                              )[0]?.name
                            : Alert.queObj?.name
                        )
                      }
                      hierarchy={{
                        type: "primary",
                        buttonText: "Play",
                      }}
                    />
                  )} */}
                </div>
              </div>
              <div className="studentTestBodyPageSingleViewQuestionDetailBox">
                {/* if desc is there to show */}

                {((Alert.comingObj.videoqn !== 1 &&
                  Alert.queObj?.hasOwnProperty("description") &&
                  Alert.queObj?.description != null &&
                  Alert.queObj?.description.length > 0) ||
                  (Alert.comingObj.videoqn === 1 &&
                    Alert.videoQn.current
                      ?.filter((data) => data?.selected)[0]
                      ?.hasOwnProperty("description") &&
                    Alert.videoQn.current?.filter((data) => data?.selected)[0]
                      ?.description !== null &&
                    Alert.videoQn.current?.filter((data) => data?.selected)[0]
                      ?.description.length > 0)) && (
                  <div className="studentTestBodyPageSingleViewQuestionDescription">
                    <br />
                    {Alert.comingObj.videoqn === 1
                      ? parse(
                          Alert.videoQn.current?.filter(
                            (data) => data?.selected
                          )[0]?.description
                        )
                      : parse(Alert.queObj?.description)}
                  </div>
                )}

                {/* if img is there to show */}

                {(Alert.comingObj.videoqn !== 1 &&
                  Alert.queObj?.links.length > 0) ||
                  ((Alert.comingObj.videoqn === 1 &&
                    Alert.videoQn.current?.filter((data) => data?.selected))[0]
                    ?.links.length > 0 && (
                    <div>
                      {Alert.comingObj.videoqn === 1
                        ? Alert.videoQn.current
                            ?.filter((data) => data?.selected)[0]
                            ?.links.map((link, index) => (
                              <span
                                key={link + "" + Alert.secObj.sectionid + index}
                                className="studentTestBodyPageSingleViewQuestionLinks"
                              >
                                <img
                                  width="400"
                                  height="300"
                                  alt="userImage"
                                  key={link + "img"}
                                  src={
                                    process.env.REACT_APP_DEPLOYMENT !== "AWS"
                                      ? process.env.REACT_APP_NODE_API +
                                        link.link
                                      : link.link
                                  }
                                />
                              </span>
                            ))
                        : Alert.queObj?.links.map((link, index) => (
                            <span
                              key={link + "" + Alert.secObj.sectionid + index}
                              className="studentTestBodyPageSingleViewQuestionLinks"
                            >
                              <img
                                width="400"
                                height="300"
                                alt="userImage"
                                key={link + "img"}
                                src={
                                  process.env.REACT_APP_DEPLOYMENT !== "AWS"
                                    ? process.env.REACT_APP_NODE_API + link.link
                                    : link.link
                                }
                              />
                            </span>
                          ))}
                    </div>
                  ))}

                {Alert.comingObj.videoqn !== 1 &&
                  (Alert.queObj?.libtypeid === constants.libTypeIds.SAQ ||
                  Alert.queObj?.libtypeid === constants.libTypeIds.LAQ ? (
                    <div
                      key={"textareadiv" + Alert.queObj.id}
                      className="studentTestBodyPageSingleViewQuestionAnsDiv"
                    >
                      <div
                        className="studentTestBodyPageSingleViewQuestionAnsDivInputWrapper"
                        data-required={
                          Alert.comingObj.videoqn !== 1
                            ? Alert.queObj?.libtypeid ===
                              constants.libTypeIds.SAQ
                              ? 1024 -
                                (StudentTestBodyPageSingleViewHks.answers[
                                  Alert.queObj.id
                                ] == null
                                  ? 0
                                  : StudentTestBodyPageSingleViewHks.answers[
                                      Alert.queObj.id
                                    ].length) +
                                " characters left"
                              : 10000 -
                                (StudentTestBodyPageSingleViewHks.answers[
                                  Alert.queObj.id
                                ] == null
                                  ? 0
                                  : StudentTestBodyPageSingleViewHks.answers[
                                      Alert.queObj.id
                                    ].length) +
                                " characters left"
                            : ""
                        }
                      >
                        {StudentTestPageCxt.errorMessage &&
                          Alert.queObj?.id ===
                            StudentTestPageCxt.questionIdOffError && (
                            <label style={{ color: "red" }}>
                              only numbers and decimals are allowed
                            </label>
                          )}

                        <TextareaAutosize
                          minRows={7}
                          placeholder=""
                          id={"inputs" + 1}
                          key={Alert.queObj?.id}
                          onCut={StudentTestBodyPageSingleViewHks.handleCPC}
                          onCopy={StudentTestBodyPageSingleViewHks.handleCPC}
                          onPaste={StudentTestBodyPageSingleViewHks.handleCPC}
                          name={Alert.queObj?.id + "" + Alert.secObj.sectionid}
                          className="studentTestBodyPageSingleViewQuestionAnsTextArea"
                          oncontextmenu={
                            StudentTestBodyPageSingleViewHks.handleCPC
                          }
                          input={
                            StudentTestBodyPageSingleViewHks.answers[
                              Alert.queObj?.id
                            ]
                          }
                          value={
                            StudentTestBodyPageSingleViewHks.answers[
                              Alert.queObj?.id
                            ]
                          }
                          onChange={(e) => {
                            StudentTestBodyPageSingleViewHks.collectAnswers(
                              Alert.queObj?.id,
                              e.target.value,
                              Alert.queObj?.libtypeid,
                              Alert.secInd,
                              Alert.queInd,
                              Alert.queObj?.datatype
                            );
                          }}
                          style={{
                            width: "100%",
                            resize: "none",
                            borderRadius: "5px",
                            marginBottom: "10px",
                            border:
                              StudentTestPageCxt.errorMessage &&
                              Alert.queObj?.id ===
                                StudentTestPageCxt.questionIdOffError
                                ? "1px solid red"
                                : StudentTestBodyPageSingleViewHks.answers[
                                    Alert.queObj?.id
                                  ] == null ||
                                  StudentTestBodyPageSingleViewHks.answers[
                                    Alert.queObj?.id
                                  ].length === 0
                                ? "1px solid rgba(55, 55, 57, 0.2)"
                                : "1px solid #D0D5DD",
                          }}
                          maxLength={
                            Alert.queObj?.libtypeid === constants.libTypeIds.SAQ
                              ? 1024
                              : 10000
                          }
                        />
                      </div>
                    </div>
                  ) : (
                    Alert.optObj?.map((option, index) => (
                      <>
                        <div
                          key={option.optionid + "" + index}
                          className={
                            Alert.isAdmin
                              ? "studentTestBodyPageSingleViewQuestionDetailBoxOptionsDivEachOptionAdmin"
                              : "studentTestBodyPageSingleViewQuestionOption"
                          }
                          onClick={() => {
                            StudentTestBodyPageSingleViewHks.collectAnswers(
                              Alert.queObj?.id,
                              option.optionid + "",
                              Alert.queObj?.libtypeid
                            );
                            Alert.PractSingleQnId.current = Alert.queObj?.id;
                          }}
                          style={{
                            borderColor:
                              StudentTestBodyPageSingleViewHks.answers[
                                Alert.queObj?.id
                              ]?.includes(option.optionid + "") ||
                              (Alert.practSingleQnAns &&
                                Alert.practSingleQnAns[0]?.answerid?.includes(
                                  option.optionid + ""
                                ))
                                ? Alert.mcqAndDesTestAndLpId.status !==
                                  constants.PROGRESS_STATUS.EVALUATED
                                  ? Alert.testData.testmode ===
                                      constants.MODE.PRACTICE_MODE &&
                                    Alert.practSingleQnAns &&
                                    Alert.practSingleQnAns[0]?.answerid?.includes(
                                      option.optionid + ""
                                    ) &&
                                    Alert.PractSingleQnAns.current
                                    ? "green"
                                    : "#F96343"
                                  : "green"
                                : "",
                            border:
                              StudentTestBodyPageSingleViewHks.answers[
                                Alert.queObj?.id
                              ]?.includes(option.optionid + "") ||
                              (Alert.practSingleQnAns &&
                                Alert.practSingleQnAns[0]?.answerid?.includes(
                                  option.optionid + ""
                                ))
                                ? Alert.mcqAndDesTestAndLpId.status !==
                                  constants.PROGRESS_STATUS.EVALUATED
                                  ? Alert.testData.testmode ===
                                      constants.MODE.PRACTICE_MODE &&
                                    Alert.practSingleQnAns &&
                                    Alert.practSingleQnAns[0]?.answerid?.includes(
                                      option.optionid + ""
                                    ) &&
                                    Alert.PractSingleQnAns.current
                                    ? "2px solid green"
                                    : "2px solid #F96343"
                                  : "2px solid green"
                                : "",
                          }}
                        >
                          <div
                            className={
                              Alert.isAdmin
                                ? "studentTestBodyPageSingleViewQuestionDetailBoxOptionsDivEachOptionAdmin"
                                : "studentTestBodyPageSingleViewQuestionOptionInputLabel"
                            }
                          >
                            <div className="input-label-mcq-container">
                              <>
                                <input
                                  id={option.optionid}
                                  value={option.optionid}
                                  name={Alert.queObj?.id + "" + Alert.secInd}
                                  className="studentTestBodyPageSingleViewQuestionOptionInput"
                                  key={
                                    option.optionid +
                                    "" +
                                    Alert.secInd +
                                    Alert.queInd
                                  }
                                  type={
                                    Alert.queObj?.libtypeid ===
                                    constants.libTypeIds.MCQ_SINGLE
                                      ? "radio"
                                      : "checkbox"
                                  }
                                  onChange={() => {
                                    StudentTestBodyPageSingleViewHks.collectAnswers(
                                      Alert.queObj?.id,
                                      option.optionid + "",
                                      Alert.queObj?.libtypeid
                                    );
                                  }}
                                  checked={
                                    StudentTestBodyPageSingleViewHks.answers[
                                      Alert.queObj?.id
                                    ]?.includes(option.optionid + "")
                                      ? true
                                      : false
                                  }
                                  style={{
                                    accentColor:
                                      StudentTestBodyPageSingleViewHks.answers[
                                        Alert.queObj?.id
                                      ]?.includes(option.optionid + "") ||
                                      (Alert.practSingleQnAns &&
                                        Alert.practSingleQnAns[0]?.answerid?.includes(
                                          option.optionid + ""
                                        ))
                                        ? Alert.testData.testmode ===
                                            constants.MODE.PRACTICE_MODE &&
                                          Alert.practSingleQnAns &&
                                          Alert.practSingleQnAns[0]?.answerid?.includes(
                                            option.optionid + ""
                                          ) &&
                                          Alert.PractSingleQnAns.current
                                          ? "green"
                                          : "#f55533"
                                        : "#D0D5DD",
                                  }}
                                />
                              </>
                              <>
                                <label
                                  className="studentTestBodyPageSingleViewQuestionOptionLabel"
                                  style={{
                                    color:
                                      StudentTestBodyPageSingleViewHks.answers[
                                        Alert.queObj?.id
                                      ]?.includes(option.optionid + "") ||
                                      (Alert.practSingleQnAns &&
                                        Alert.practSingleQnAns[0]?.answerid?.includes(
                                          option.optionid + ""
                                        ))
                                        ? Alert.testData.testmode ===
                                            constants.MODE.PRACTICE_MODE &&
                                          Alert.practSingleQnAns &&
                                          Alert.practSingleQnAns[0]?.answerid?.includes(
                                            option.optionid + ""
                                          ) &&
                                          Alert.PractSingleQnAns.current
                                          ? "#101828"
                                          : "#f55533"
                                        : "#101828",
                                  }}
                                >
                                  {option.optiontext}
                                </label>
                              </>
                            </div>
                            <div>
                              {StudentTestBodyPageSingleViewHks.answers[
                                Alert.queObj?.id
                              ]?.includes(option.optionid + "") ||
                              (Alert.practSingleQnAns &&
                                Alert.practSingleQnAns[0]?.answerid?.includes(
                                  option.optionid + ""
                                )) ? (
                                Alert.testData.testmode ===
                                  constants.MODE.PRACTICE_MODE &&
                                Alert.PractSingleQnAns.current === true ? (
                                  Alert.practSingleQnAns &&
                                  Alert.practSingleQnAns[0]?.answerid?.includes(
                                    option.optionid + ""
                                  ) ? (
                                    <RightSvgIcon />
                                  ) : (
                                    <WobackCloseSvg stoke={"#F04438"} />
                                  )
                                ) : (
                                  ""
                                )
                              ) : (
                                ""
                              )}
                            </div>
                          </div>
                        </div>
                      </>
                    ))
                  ))}
              </div>
            </div>
          </>
        )
      }

      {/* {Alert.optObj?.map((option, index) => (
        (StudentTestBodyPageSingleViewHks.answers[
          Alert.queObj?.id
	        ]?.includes(option.optionid + "")||(Alert.practSingleQnAns && Alert.practSingleQnAns[0]?.answerid?.includes(option.optionid +""))) 
        ? (Alert.testData.testmode === constants.MODE.PRACTICE_MODE && Alert.PractSingleQnAns.current===true && Alert.showHalfDonut === false) 
          ? (Alert.practSingleQnAns && Alert.practSingleQnAns[0]?.answerid?.includes(option.optionid +""))
            &&
            (<>
              <div className={`practicemode-ans-box${Alert.halfDonutChart.isCorrect?'-true':'-false'}`}>
                  <div className="ans-expla-container">
                    <div className="ans-expla-icon-container">{Alert.halfDonutChart.isCorrect ? <RightSvgIcon practice={true}/> : <WobackCloseSvg stoke={"#F04438"} practice={true} /> }</div>
                    <div className="ans-expla-text-container">
                      {Alert.halfDonutChart.isCorrect ? <span className="ans-expla-res-container">Correct</span> : <span className="ans-expla-res-container">Incorrect</span>}
                      <span className="ans-expla-restext-container">{`The correct answer is ${Alert.halfDonutChart.explanation}`}</span>
                      <span className="ans-expla-restext-container">{Alert.halfDonutChart.explanation}</span>
                    </div>
                  </div>
              </div>
            </>)
          :<></>
        :<></>
      ))} */}
      {Alert.testData.testmode === constants.MODE.PRACTICE_MODE &&
        Alert.PractSingleQnAns.current === true &&
        Alert.showHalfDonut === false && (
          <>
            <div
              className={`practicemode-ans-box${
                Alert.halfDonutChart.isCorrect ? "-true" : "-false"
              }`}
            >
              <div className="ans-expla-container">
                <div className="ans-expla-icon-container">
                  {Alert.halfDonutChart.isCorrect ? (
                    <RightSvgIcon practice={true} />
                  ) : (
                    <WobackCloseSvg stoke={"#F04438"} practice={true} />
                  )}
                </div>
                <div className="ans-expla-text-container">
                  {Alert.halfDonutChart.isCorrect ? (
                    <span className="ans-expla-res-container">Correct</span>
                  ) : (
                    <span className="ans-expla-res-container">Incorrect</span>
                  )}
                  <span className="ans-expla-restext-container">
                    The correct answer is{" "}
                    {Alert.optObj?.map(
                      (option, index) =>
                        Alert.practSingleQnAns &&
                        Alert.practSingleQnAns[0]?.answerid?.includes(
                          option.optionid + ""
                        ) && (
                          <li className="ans-expla-restext-container">
                            {option.optiontext}
                          </li>
                        )
                    )}
                  </span>
                  {/* <span className="ans-expla-restext-container">
                          {`The correct answer is ${option.optiontext}`}
                          </span> */}
                  {Alert.halfDonutChart.explanation && (
                    <span className="ans-expla-restext-container">
                      {Alert.halfDonutChart.explanation}
                    </span>
                  )}
                </div>
              </div>
            </div>
          </>
        )}

      {/* Buttons for single questions */}

      <div className="studentTestBodyPageSingleViewButtonGroup">
        {Alert.comingObj.videoqn !== 1 && Alert.questionContainerSingle && (
          <div className="studentTestBodyPageSingleViewButtonGroupDiv">
            <div>
              {((Alert.prevButton &&
                Alert.testData.testmode !== constants.MODE.PRACTICE_MODE) ||
                (Alert.testData.testmode === constants.MODE.PRACTICE_MODE &&
                  Alert.mcqAndDesTestAndLpId.libtypeid ===
                    constants.libTypeIds.DESCRIPTIVE &&
                  Alert.mcqAndDesTestAndLpId.autoEval !== 1)) && (
                <Button
                  size="sm"
                  disabled={Alert.prevButton}
                  onClick={() =>
                    StudentTestBodyPageHks.traversePrevious("previous")
                  }
                  hierarchy={{
                    type: "secondaryGrey",
                    buttonText: "Previous",
                  }}
                />
              )}
            </div>
            <div>
              {((Alert.nextButton &&
                Alert.testData.testmode !== constants.MODE.PRACTICE_MODE) ||
                (Alert.PractSingleQnAns.current === true &&
                  Alert.testData.testmode === constants.MODE.PRACTICE_MODE &&
                  (Alert.mcqAndDesTestAndLpId.libtypeid ===
                    constants.libTypeIds.MCQ ||
                    (Alert.mcqAndDesTestAndLpId.libtypeid ===
                      constants.libTypeIds.DESCRIPTIVE &&
                      Alert.mcqAndDesTestAndLpId.autoEval === 1)) &&
                  Alert.PractSingleQnFinalSubmit.current === false)) && (
                <Button
                  size="sm"
                  disabled={Alert.nextButton}
                  onClick={() => StudentTestBodyPageHks.traverseNext("next")}
                  hierarchy={{
                    type: "primary",
                    buttonText: "Next",
                  }}
                />
              )}
              {Alert.PractSingleQnFinalSubmit.current &&
                !Alert.showHalfDonut && (
                  <Button
                    size="sm"
                    disabled={Alert.nextButton}
                    onClick={() => {
                      Alert.setHalfDonut(true);
                      Alert.PractSingleQnId.current = "";
                      CodingHeaderLearnerSideHks.submitAnswers();
                    }}
                    hierarchy={{
                      type: "primary",
                      buttonText: "Next",
                    }}
                  />
                )}
            </div>
          </div>
        )}

        {/* for practice mode */}

        <div className="single-qn-submit-container">
          <div className="single-qn-submit-div">
            {Alert.testData.testmode === constants.MODE.PRACTICE_MODE &&
              (Alert.mcqAndDesTestAndLpId.libtypeid ===
                constants.libTypeIds.MCQ ||
                (Alert.mcqAndDesTestAndLpId.libtypeid ===
                  constants.libTypeIds.DESCRIPTIVE &&
                  Alert.mcqAndDesTestAndLpId.autoEval === 1)) &&
              Alert.PractSingleQnAns.current === false &&
              Alert.PractSingleQnFinalSubmit.current !== true && (
                <Button
                  size="sm"
                  disable={Alert.PractSingleQnId.current == "" ? true : false}
                  onClick={() => CodingHeaderLearnerSideHks.submitAnswers()}
                  hierarchy={{
                    type: "primary",
                    buttonText: "Submit",
                  }}
                />
              )}
          </div>
        </div>
        <div className="single-qn-after-submit-container">
          {
            /* Alert.PractSingleQnFinalSubmit.current */ Alert.showHalfDonut &&
              Alert.halfDonutChart.hasOwnProperty("target") && (
                <div className="single-qn-submit-div">
                  {Alert.halfDonutChart &&
                  Alert.halfDonutChart.target === false ? (
                    <div>
                      <div>
                        <div className="after-submit-header">Its Alright</div>
                        <div className="after-submit-msg">
                          You Scored {Alert.halfDonutChart?.achievedpercent}%.
                          The minimum criteria for passing is{" "}
                          {Alert.halfDonutChart?.targetPercent}%
                        </div>
                      </div>
                      <div className="after-submit-btn-div">
                        <div>
                          <Button
                            size="md"
                            disabled={Alert.nextButton}
                            onClick={() => {
                              CodingHeaderLearnerSideHks.handleNavigateToHome();
                              Alert.PractSingleQnFinalSubmit.current = false;
                              Alert.PractSingleQnAns.current = false;
                              Alert.setHalfDonutChart({});
                              Alert.answerRef.current = {};
                              Alert.setHalfDonut(false);
                              Alert.PractSingleQnId.current = "";
                            }}
                            hierarchy={{
                              type: "secondaryGrey",
                              buttonText: "Back to Learning Path",
                            }}
                          />
                        </div>
                        <div>
                          <Button
                            size="md"
                            disabled={Alert.nextButton}
                            onClick={() => {
                              Alert.setStatus(
                                constants.QUESTION_STATUS.CONTINUE
                              );
                              Alert.PractSingleQnFinalSubmit.current = false;
                              Alert.PractSingleQnAns.current = false;
                              Alert.setHalfDonutChart({});
                              Alert.answerRef.current = {};
                              Alert.setHalfDonut(false);
                              StudentTestPageHks.startTest("practiceRetake");
                              Alert.PractSingleQnId.current = "";
                            }}
                            hierarchy={{
                              type: "primary",
                              buttonText: "Retake Test",
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div>
                      <div>
                        <div className="after-submit-header">
                          Congratulations!!
                        </div>
                        <div className="after-submit-msg">
                          You Scored {Alert.halfDonutChart?.achievedpercent}%
                        </div>
                      </div>
                      <div className="after-submit-btn-div">
                        <Button
                          size="sm"
                          disabled={Alert.nextButton}
                          onClick={() => {
                            CodingHeaderLearnerSideHks.handleNavigateToHome();
                            Alert.PractSingleQnFinalSubmit.current = false;
                            Alert.PractSingleQnAns.current = false;
                            Alert.setHalfDonutChart({});
                            Alert.answerRef.current = {};
                            Alert.setHalfDonut(false);
                            Alert.PractSingleQnId.current = "";
                          }}
                          hierarchy={{
                            type: "primary",
                            buttonText: "Back to Learning Path",
                          }}
                        />
                      </div>
                    </div>
                  )}
                </div>
              )
          }
        </div>
      </div>
    </div>
  );
}
