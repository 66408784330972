import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import _ from "lodash";

export default function InputDropdown({
  label,
  type,
  placeholder,
  value,
  onChange,
  menuLists,
  name,
  icon,
  supportingText,
  disabled,
  searchIcon,
  hintText,
  destructive,
}) {
  const [focused, setFocused] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedItem, setSelectedItem] = useState(null);
  const [focusedIndex, setFocusedIndex] = useState(-1);
  const ref = useRef(null);

  const filteredMenuLists = menuLists.filter((list) =>
    list.label.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const handleInputChange = (e) => {
    setSearchTerm(e.target.value);
    if (type.toLowerCase() === "search" && onChange) onChange(e); // Call the onChange prop to lift the state
  };

  const handleOptionSelect = (list) => {
    setSearchTerm(list.label);
    setSelectedItem(list);
    onChange({ target: { value: list.label, name, id: list.value } }); // Update the parent component
    setFocused(false);
    setFocusedIndex(-1);
  };

  useEffect(() => {
    setSearchTerm(value); // Sync the search term with the value prop
    const selected = menuLists.find((list) => list.label === value);
    setSelectedItem(selected);
  }, [value, menuLists]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        setFocused(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref]);

  const handleKeyDown = (e) => {
    if (focused) {
      switch (e.key) {
        case "ArrowDown":
          e.preventDefault();
          setFocusedIndex((prevIndex) =>
            Math.min(prevIndex + 1, filteredMenuLists.length - 1)
          );
          break;
        case "ArrowUp":
          e.preventDefault();
          setFocusedIndex((prevIndex) => Math.max(prevIndex - 1, 0));
          break;
        case "Enter":
          e.preventDefault();
          if (focusedIndex >= 0 && focusedIndex < filteredMenuLists.length) {
            handleOptionSelect(filteredMenuLists[focusedIndex]);
          }
          break;
        default:
          break;
      }
    }
  };

  useEffect(() => {
    document.addEventListener("keydown", handleKeyDown);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [focused, focusedIndex, filteredMenuLists]);

  return (
    <div
      className={`inputDropdownContainer ${destructive ? "destructive" : ""}`}
      ref={ref}
    >
      <div className="inputDropdownInputContainer">
        {label && <label className="inputDropdownLabel">{label}</label>}
        <div
          className={`inputDropdownInputSec${focused ? " focused" : ""}${
            disabled ? " disabled" : ""
          }`}
        >
          <div className="inputDropdownInputWrapper">
            {type.toLowerCase() === "search" && searchIcon ? (
              <span className="inputDropdownInputIcon">
                <svg
                  className="search"
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M17.5 17.5L13.875 13.875M15.8333 9.16667C15.8333 12.8486 12.8486 15.8333 9.16667 15.8333C5.48477 15.8333 2.5 12.8486 2.5 9.16667C2.5 5.48477 5.48477 2.5 9.16667 2.5C12.8486 2.5 15.8333 5.48477 15.8333 9.16667Z"
                    stroke="#667085"
                    strokeWidth="1.66667"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </span>
            ) : type === "iconLeading" || (icon && icon !== "") ? (
              <span className="inputDropdownInputIcon">{icon}</span>
            ) : null}

            <input
              type="text"
              name={name}
              placeholder={placeholder}
              value={searchTerm}
              onChange={handleInputChange}
              onFocus={() => setFocused(true)}
              // onBlur={() => setFocused(false)}
              disabled={disabled}
              className="inputDropdownInput"
            />
          </div>
          {type?.toLowerCase() !== "search" ? (
            focused ? (
              <svg
                onClick={() => setFocused(disabled ? focused : false)}
                className="inputDropdownIcon chevronUp"
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M15 12.5L10 7.5L5 12.5"
                  stroke="#667085"
                  strokeWidth="1.66667"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            ) : (
              <svg
                onClick={() => setFocused(disabled ? focused : true)}
                className="inputDropdownIcon chevronDown"
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M5 7.5L10 12.5L15 7.5"
                  stroke="#667085"
                  strokeWidth="1.66667"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            )
          ) : null}
        </div>
        {hintText && <span className="inputDropdownHintText">{hintText}</span>}
      </div>
      {focused && type?.toLowerCase() !== "search" && (
        <div className="inputDropdownMenuContainer">
          {filteredMenuLists.length > 0 ? (
            <ul className="inputDropdownMenuList">
              {_.map(filteredMenuLists, (list, index) => (
                <li
                  id={list.value || index}
                  key={index}
                  onClick={() => handleOptionSelect(list)}
                  onMouseOver={() => setFocusedIndex(index)}
                  className={`inputDropdownMenuItem${
                    list.label === selectedItem?.label &&
                    list.value === selectedItem?.value
                      ? " selected"
                      : ""
                  }${focusedIndex === index ? " focusedItem" : ""}`}
                >
                  <div className="inputDropdownMenuItemContent">
                    {type === "iconLeading" ? list.icon : null}
                    <p className="inputDropdownMenuItemLabel">{list.label}</p>
                    {supportingText ? (
                      <span className="inputDropdownMenuItemSupportText">
                        {list.supportingText}
                      </span>
                    ) : null}
                  </div>
                  {list.label === selectedItem?.label &&
                    list.value === selectedItem?.value && (
                      <svg
                        className="inputDropdownMenuItemIcon tickIcon"
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M16.6668 5L7.50016 14.1667L3.3335 10"
                          stroke="#F55533"
                          strokeWidth="1.66667"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    )}
                </li>
              ))}
            </ul>
          ) : (
            <p className="inputDropdownNoData">No data</p>
          )}
        </div>
      )}
    </div>
  );
}

InputDropdown.propTypes = {
  label: PropTypes.string,
  type: PropTypes.string.isRequired,
  value: PropTypes.string,
  onChange: PropTypes.func,
  menuLists: PropTypes.array,
  name: PropTypes.string,
  supportingText: PropTypes.string,
  disabled: PropTypes.bool,
  placeholder: PropTypes.string,
  searchIcon: PropTypes.bool,
  hintText: PropTypes.string,
  destructive: PropTypes.bool,
};

InputDropdown.defaultProps = {
  type: "default",
  menuLists: [],
  disabled: false,
  placeholder: "Select team member",
  searchIcon: false,
  destructive: false,
};
