import React, { useContext, useEffect, useState } from "react";
import dayjs from "dayjs";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import InputField from "../../CommonComponents/InputField";
import Radio from "../../CommonComponents/Radio";
import Button from "../../Button";
import DragAndDropUpload from "../../CommonComponents/DragAndDropUpload";
import Datepicker from "../../CommonComponents/Datepicker";
import constants from "../../../constants";
import useAxiosPrivate from "../../../Hooks/useAxiosPrivate";
import AlertContext from "../../../context/AlertContext";
import useUploadLargeFileToS3 from "../../../Hooks/useUploadLargeFileToS3";
import moment from "moment";

const supportedFormat = ["jpg", "jpeg", "png"];
const requiredFields = ["title", "organizer", "poster", "link", "startdate"];

const validate = (name, value) => {
  switch (name) {
    case "title":
      if (!value || value.trim() === "") {
        return "Title is Required";
      }
      break;
    case "organizer":
      if (!value || value.trim() === "") {
        return "Organizer's name is required";
      }
      break;
    case "poster":
      if (!value) {
        return "The hackathon poster is required";
      }
      break;
    case "link":
      if (!value || value.trim() === "") {
        return "The hackathon link is required";
      }
      break;
    case "startdate":
      if (!value) {
        return "The hackathon startdate is required";
      }
      break;
    case "location":
      if (!value || value.trim() === "") {
        return "The hackathon location is required";
      }
      break;
    case "entryfees":
      if (!value || value.trim() === "") {
        return "The hackathon entry fees is required";
      }
      break;
    default: {
      return "";
    }
  }
};

const HackathonForm = ({
  openForm,
  setOpenForm,
  isEdit,
  id,
  setEditHackathonId,
}) => {
  const axios = useAxiosPrivate();
  const { uploadFileToS3, getS3FileAsFile } = useUploadLargeFileToS3();
  const { setIsLoaded, setShowNotify } = useContext(AlertContext);

  const [details, setDetails] = useState({
    mode: constants.HACKATHON_MODE.ONLINE,
    entry: constants.HACKATHON_ENTRY.FREE,
  });
  const [errors, setErrors] = useState({});
  const [isRemoved, setIsRemoved] = useState(false);

  useEffect(() => {
    const fetchHackathon = async () => {
      try {
        setIsLoaded(true);
        const response = await axios.get(`node/admin/hackathon/${id}`, {
          headers: {
            "Content-Type": "application/json",
          },
        });
        if (response.data.resultCode === constants.RESULT_STATUS.SUCCESS) {
          let data = response.data?.data[0];
          let result = null;
          if (data?.poster && data?.poster !== "") {
            result = await getS3FileAsFile(
              data.poster,
              process.env.REACT_APP_S3_WEBSITE_HOST_BUCKET_NAME
            );
          }
          setDetails({ ...data, poster: result });
        } else
          setShowNotify({
            show: true,
            title: "Error!",
            msg:
              response.data.msg ??
              "Something went wrong. Please try again later or contact support.",
          });
      } catch (error) {
        console.error("Error fetching hackathon:", error);
        setShowNotify({
          show: true,
          title: error.message.includes("403") ? "Warning!" : "Error!",
          msg: error.message.includes("403")
            ? "You have been logged-out due to inactivity. Login again."
            : error.message ??
              "Something went wrong. Please try again later or contact support.",
          isUnAuth: error.message.includes("403"),
        });
      } finally {
        setIsLoaded(false);
      }
    };
    if (isEdit) fetchHackathon();
  }, [isEdit, setIsLoaded, setShowNotify, axios, id]);

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setOpenForm(open);
  };

  const onDragOver = (event) => event.preventDefault();

  const onDrop = (event) => {
    const files = Array.from(event.dataTransfer.files);
    uploadFiles(files);
  };

  const uploadFiles = (files = []) => {
    if (files.length > 1) {
      setShowNotify({
        show: true,
        title: "Warning !",
        msg: `Multiple files are not allowed.`,
      });
      return;
    }
    const isUploadableFormat = files.every((file) =>
      supportedFormat.includes(file.type.split("/")[1])
    );
    if (!isUploadableFormat) {
      setErrors({
        ...errors,
        poster: `Unsupported file type: ${
          files[0].type.split("/")[1]
        }. Allowed formats are: ${supportedFormat}`,
      });
      return;
    }
    setErrors({ ...errors, poster: validate("poster", files[0]) });
    setDetails({ ...details, poster: files[0] });
  };

  const onUpload = (event) => {
    const files = Array.from(event.target.files);
    uploadFiles(files);
    event.target.value = "";
  };

  const onChange = (value, name) => {
    setDetails({ ...details, [name]: value });
    setErrors({ ...errors, [name]: validate(name, value) });
    if (name === "mode") {
      if (value === constants.HACKATHON_MODE.OFFLINE)
        requiredFields.push("location");
      else {
        const index = requiredFields.findIndex((item) => item === "location");
        setErrors({ ...errors, location: null });
        requiredFields.splice(index, 1);
      }
    }
    if (name === "entry") {
      if (value === constants.HACKATHON_ENTRY.PAID)
        requiredFields.push("entryfees");
      else {
        const index = requiredFields.findIndex((item) => item === "entryfees");
        setErrors({ ...errors, entryfees: null });
        requiredFields.splice(index, 1);
      }
    }
  };

  const onRemovePoster = () => {
    if (isEdit) setIsRemoved(true);
    setDetails({ ...details, poster: null });
    setErrors({ ...errors, poster: validate("poster", null) });
  };

  const onAddOrUpdate = async () => {
    try {
      let validationErrors = {};
      requiredFields.forEach((name) => {
        const error = validate(name, details[name]);
        if (error && error.length > 0) {
          validationErrors[name] = error;
        }
      });
      if (Object.keys(validationErrors).length > 0) {
        setErrors(validationErrors);
        return;
      }

      setIsLoaded(true);
      const method = isEdit ? "put" : "post";
      const url = isEdit
        ? `node/admin/hackathon/${id}`
        : "node/admin/hackathon";
      let path = null;
      if (details.poster && details.poster !== "") {
        let imageName = details.poster.name;
        path =
          !isEdit || (isEdit && isRemoved)
            ? `hackathons/poster/${imageName?.substring(
                0,
                imageName.lastIndexOf(".")
              )}_${moment().toISOString()}${imageName?.substring(
                imageName.lastIndexOf(".")
              )}`
            : `hackathons/poster/${imageName}`;
        if (!isEdit || (isEdit && isRemoved))
          await uploadFileToS3(
            details.poster,
            path,
            imageName?.split(".").pop().toLowerCase(),
            "uploadAssignment"
          );
      }
      const response = await axios({
        method,
        url,
        data: { ...details, poster: path },
        headers: {
          "Content-Type": "application/json",
        },
      });
      if (response.data.resultCode === constants.RESULT_STATUS.SUCCESS) {
        setShowNotify({
          show: true,
          title: "Info",
          msg:
            response.data.msg ??
            `The hackathon is ${isEdit ? "updated" : "created"} successfully`,
        });
        setOpenForm(false);
        setEditHackathonId(null);
        setDetails({
          mode: constants.HACKATHON_MODE.ONLINE,
          entry: constants.HACKATHON_ENTRY.FREE,
        });
        setIsRemoved(false);
      } else {
        setShowNotify({
          show: true,
          title: "Error!",
          msg:
            response.data.msg ??
            "Something went wrong. Please try again later or contact support.",
        });
      }
    } catch (error) {
      console.error("Error adding hackathon:", error);
      setShowNotify({
        show: true,
        title: error.message.includes("403") ? "Warning!" : "Error!",
        msg: error.message.includes("403")
          ? "You have been logged-out due to inactivity. Login again."
          : error.message ??
            "Something went wrong. Please try again later or contact support.",
        isUnAuth: error.message.includes("403"),
      });
    } finally {
      setIsLoaded(false);
    }
  };

  const onCancel = () => {
    setDetails({
      mode: constants.HACKATHON_MODE.ONLINE,
      entry: constants.HACKATHON_ENTRY.FREE,
    });
    setOpenForm(false);
    setEditHackathonId(null);
    setErrors({});
    setIsRemoved(false);
  };

  const onCloseForm = () => {
    toggleDrawer("bottom", false);
    setErrors({});
    setDetails({
      mode: constants.HACKATHON_MODE.ONLINE,
      entry: constants.HACKATHON_ENTRY.FREE,
    });
    setEditHackathonId(null);
    setIsRemoved(false);
  };

  if (!openForm) return null;

  return (
    <SwipeableDrawer
      anchor="bottom"
      className="hackathonFormMainContainer"
      open={openForm}
      onClose={onCloseForm}
      onOpen={toggleDrawer("bottom", true)}
      style={{ height: "calc(100vh - 50px)" }}
    >
      <div className="hackathonFormDrawer">
        <div className="hackathonFormHeaderSec">
          <div className="hackathonFormHeader">
            {isEdit ? "Edit" : "Add"} Hackathon
          </div>
          <div className="hackathonFormHeaderBtn">
            <Button
              size="sm"
              hierarchy={{ type: "tertiaryGrey", buttonText: "Cancel" }}
              onClick={onCancel}
            />
            <Button
              size="sm"
              hierarchy={{
                type: "primary",
                buttonText: isEdit ? "Update" : "Add",
              }}
              onClick={onAddOrUpdate}
            />
          </div>
        </div>
        <div className="hackathonFormContainer">
          <form className="hackathonForm">
            <InputField
              label="Hackathon title"
              name="title"
              placeholder="Enter hackathon title"
              onChange={(event) =>
                onChange(event.target.value, event.target.name)
              }
              value={details["title"] ?? ""}
              hintText={errors["title"]}
              destructive={!!errors["title"]}
            />
            <InputField
              label="Organizer"
              name="organizer"
              placeholder="Enter organizer's name"
              onChange={(event) =>
                onChange(event.target.value, event.target.name)
              }
              value={details["organizer"] ?? ""}
              hintText={errors["organizer"]}
              destructive={!!errors["organizer"]}
            />
            <div className="hackathonDateSec">
              <div className="hackathonDate">
                <label>Start Date</label>
                <Datepicker
                  value={details.startdate ?? null}
                  onChange={(value) => onChange(value, "startdate")}
                />
              </div>
              <div className="hackathonDate">
                <label>End Date</label>
                <Datepicker
                  minDate={dayjs(details.startdate ?? null)}
                  value={details.enddate ?? null}
                  onChange={(value) => onChange(value, "enddate")}
                />
              </div>
            </div>
            <div className="hackathonMode">
              <p>Mode</p>
              <div className="hackathonModeRadioBtn">
                <Radio
                  size="sm"
                  text="Online"
                  value={constants.HACKATHON_MODE.ONLINE}
                  checked={details.mode === constants.HACKATHON_MODE.ONLINE}
                  onChange={(value) => onChange(value, "mode")}
                />
                <Radio
                  size="sm"
                  text="Offline"
                  value={constants.HACKATHON_MODE.OFFLINE}
                  checked={details.mode === constants.HACKATHON_MODE.OFFLINE}
                  onChange={(value) => onChange(value, "mode")}
                />
              </div>
            </div>
            <InputField
              label="Location"
              name="location"
              disabled={details.mode !== constants.HACKATHON_MODE.OFFLINE}
              placeholder="Enter Hackathon Location"
              onChange={(event) =>
                onChange(event.target.value, event.target.name)
              }
              value={details["location"] ?? ""}
              hintText={errors["location"]}
              destructive={!!errors["location"]}
            />
            <div className="hackathonEntry">
              <p>Entry</p>
              <div className="hackathonEntryRadioBtn">
                <Radio
                  size="sm"
                  text="Paid"
                  value={constants.HACKATHON_ENTRY.PAID}
                  checked={details.entry === constants.HACKATHON_ENTRY.PAID}
                  onChange={(value) => onChange(value, "entry")}
                />
                <Radio
                  size="sm"
                  text="Free"
                  value={constants.HACKATHON_ENTRY.FREE}
                  checked={details.entry === constants.HACKATHON_ENTRY.FREE}
                  onChange={(value) => onChange(value, "entry")}
                />
              </div>
            </div>
            <InputField
              label="Entry Fee"
              disabled={details.entry !== constants.HACKATHON_ENTRY.PAID}
              leadingIcon={
                <svg
                  width="9"
                  height="12"
                  viewBox="0 0 9 12"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M8.20455 3.11364L7.81818 4.43182H0.454545L0.840909 3.11364H8.20455ZM4.75 12L0.590909 6.90909L0.568182 5.95455H2.63636C3.17424 5.95455 3.63258 5.87879 4.01136 5.72727C4.39015 5.57197 4.67992 5.34091 4.88068 5.03409C5.08144 4.72348 5.18182 4.33333 5.18182 3.86364C5.18182 3.17045 4.97349 2.62689 4.55682 2.23295C4.14015 1.83523 3.5 1.63636 2.63636 1.63636H0.454545L0.840909 0.363636H2.63636C3.56061 0.363636 4.31818 0.517045 4.90909 0.823863C5.50379 1.12689 5.94318 1.54356 6.22727 2.07386C6.51515 2.60038 6.65909 3.19697 6.65909 3.86364C6.65909 4.46591 6.52652 5.02083 6.26136 5.52841C6 6.0322 5.57955 6.4375 5 6.74432C4.42424 7.05114 3.66288 7.20455 2.71591 7.20455H2.68182L6.52273 11.9091V12H4.75ZM8.20455 0.363636L7.81818 1.68182L2.02273 1.63636L2.40909 0.363636H8.20455Z"
                    fill="#667085"
                  />
                </svg>
              }
              name="entryfees"
              placeholder=""
              onChange={(event) =>
                onChange(event.target.value, event.target.name)
              }
              value={details["entryfees"] ?? ""}
              hintText={errors["entryfees"]}
              destructive={!!errors["entryfees"]}
            />
            <InputField
              label="Prize Money"
              leadingIcon={
                <svg
                  width="9"
                  height="12"
                  viewBox="0 0 9 12"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M8.20455 3.11364L7.81818 4.43182H0.454545L0.840909 3.11364H8.20455ZM4.75 12L0.590909 6.90909L0.568182 5.95455H2.63636C3.17424 5.95455 3.63258 5.87879 4.01136 5.72727C4.39015 5.57197 4.67992 5.34091 4.88068 5.03409C5.08144 4.72348 5.18182 4.33333 5.18182 3.86364C5.18182 3.17045 4.97349 2.62689 4.55682 2.23295C4.14015 1.83523 3.5 1.63636 2.63636 1.63636H0.454545L0.840909 0.363636H2.63636C3.56061 0.363636 4.31818 0.517045 4.90909 0.823863C5.50379 1.12689 5.94318 1.54356 6.22727 2.07386C6.51515 2.60038 6.65909 3.19697 6.65909 3.86364C6.65909 4.46591 6.52652 5.02083 6.26136 5.52841C6 6.0322 5.57955 6.4375 5 6.74432C4.42424 7.05114 3.66288 7.20455 2.71591 7.20455H2.68182L6.52273 11.9091V12H4.75ZM8.20455 0.363636L7.81818 1.68182L2.02273 1.63636L2.40909 0.363636H8.20455Z"
                    fill="#667085"
                  />
                </svg>
              }
              name="prizemoney"
              placeholder=""
              onChange={(event) =>
                onChange(event.target.value, event.target.name)
              }
              value={details["prizemoney"] ?? ""}
            />
            <DragAndDropUpload
              label="Poster"
              onDragOver={onDragOver}
              onDrop={onDrop}
              onUpload={onUpload}
              file={details.poster}
              onRemoveFile={onRemovePoster}
              hintText={errors["poster"]}
              destructive={!!errors["poster"]}
            />
            <InputField
              label="Hackathon Link"
              name="link"
              placeholder="Paste your Hackathon link here"
              onChange={(event) =>
                onChange(event.target.value, event.target.name)
              }
              value={details["link"] ?? ""}
              hintText={errors["link"]}
              destructive={!!errors["link"]}
            />
          </form>
        </div>
      </div>
    </SwipeableDrawer>
  );
};

export default HackathonForm;
