import { useContext, useState } from "react";
import constants from "../../../../constants";
import { getDataFromStorage } from "../../../../util";
import AlertContext from "../../../../context/AlertContext";
import useAxiosPrivate from "../../../../Hooks/useAxiosPrivate";
import useCommonFunctionHooks from "../../../../Hooks/useCommonFunctionHooks";

export default function ResumeHooks() {
  const Alert = useContext(AlertContext);

  const axios = useAxiosPrivate();
  const commonFunction = useCommonFunctionHooks();

  const [resumeData, setResumeData] = useState();

  /* Resume */

  const getResumeData = (from, learnerid, role) => {
    const batchId =
      role !== "learner"
        ? sessionStorage.getItem("abatchid")
        : localStorage.getItem("batchid");

    const learnerId =
      from === "onpopup" ? learnerid : getDataFromStorage("learnerid");

    let baseUrl =
      role === "learner"
        ? `node/learner/batch/profile/${batchId}/${learnerId}`
        : `node/admin/batch/profile/${batchId}/${learnerId}`;

    if (batchId) {
      Alert.setIsLoaded(true);
      axios
        .get(baseUrl, commonFunction.getHeaderDetailsForAxios())
        .then(async (res) => {
          if (res.data.resultCode === constants.RESULT_STATUS.TECHNICAL_ERROR) {
            Alert.setIsLoaded(false);
            Alert.setShowNotify({
              show: true,
              title: "Info",
              msg: res.data.msg,
            });
          } else {
            if (res.data.resultCode === constants.RESULT_STATUS.NO_DATA) {
              Alert.setIsLoaded(false);
              Alert.setShowNotify({
                show: true,
                title: "Info",
                msg: "No data to display",
              });
            } else {
              let getSignedUrl = res.data;

              setResumeData(getSignedUrl);
              Alert.setIsLoaded(false);
            }
          }
        })
        .catch((err) => {
          Alert.setIsLoaded(false);
          commonFunction.getCatchBlockDetails(err);
        });
    } else {
      Alert.setShowNotify({
        show: true,
        title: "Info",
        msg: "Please select the learning batch to view the resume.",
      });
      Alert.navigate("/main/dashboard");
    }
  };

  const getValue = (data, attribute, action) => {
    const overrideValue = data[attribute]?.overrideValue;

    let value;

    if (action === "totalCount") {
      value = data[attribute]?.totalCount;

      return value !== null && value !== undefined && value !== ""
        ? typeof value === "string"
          ? parseInt(value)
          : value
        : 0;
    } else if (action === "targetScore") {
      value = data[attribute]?.targetScore;

      return value !== null && value !== undefined && value !== ""
        ? typeof value === "string"
          ? parseInt(value)
          : value
        : 0;
    } else {
      value = data[attribute]?.value;

      return overrideValue !== null &&
        overrideValue !== undefined &&
        overrideValue !== ""
        ? overrideValue
        : value !== null && value !== undefined && value !== ""
        ? typeof value === "string"
          ? parseInt(value)
          : value
        : 0;
    }
  };

  const getlistFromParagraph = (para) => {
    if (!para) return [];

    return para
      .split("#")
      .map((sentence) => sentence.trim())
      .filter((sentence) => sentence !== "");
  };

  const addSpaceToParagraph = (expl, contrib, learn) => {
    return (
      (expl ? expl : "") +
      (contrib ? " " + contrib : "") +
      (learn ? " " + learn : "")
    );
  };

  const getYear = (datestring, format) => {
    let date = new Date(datestring);

    if (format == "year") return date.getFullYear(date);

    return date.toLocaleString("en-US", { month: "short" });
  };

  const getSkillPercentage = (skillData) => {
    const assignmentScore = getValue(
      skillData,
      constants.PROFILE_ATTRIBUTES.ASSIGNMENT_SCORE
    );

    const assessmentScore = getValue(
      skillData,
      constants.PROFILE_ATTRIBUTES.ASSESSMENT_SCORE
    );

    const totalTargetScore =
      (skillData[constants.PROFILE_ATTRIBUTES.ASSIGNMENT_SCORE]?.targetScore ||
        0) +
      (skillData[constants.PROFILE_ATTRIBUTES.ASSESSMENT_SCORE]?.targetScore ||
        0);

    let percentage =
      totalTargetScore > 0
        ? ((assignmentScore + assessmentScore) / totalTargetScore) * 100
        : 0;

    return percentage;
  };

  const formatPercentage = (percentage) => {
    if (percentage === 0) {
      return "0";
    }

    return percentage % 1 === 0 ? `${percentage}` : `${percentage.toFixed(2)}`;
  };

  const handleProject = (githubLink) => {
    if (githubLink) {
      const absoluteLink = githubLink.startsWith("http")
        ? githubLink
        : `https://${githubLink}`;

      window.open(absoluteLink, "_blank");
    }
  };

  const getTodayDate = () => {
    let date = new Date();
    let dat = date.getDate();
    let month = date.getMonth() + 1;
    let year = date.getFullYear();

    return `${dat}/${month}/${year}`;
  };

  const handleVideoClick = (videoLink) => {
    if (videoLink) {
      const absoluteLink = videoLink.startsWith("http")
        ? videoLink
        : `https://${videoLink}`;

      window.open(absoluteLink, "_blank");
    }
  };

  /* Gradious Evaluation */

  const updateResume = (learnerid) => {
    axios
      .post(
        `node/admin/batch/profile/${Alert.batchDetails.id}/${learnerid}`,
        {
          skills: resumeData.skills,
          overallFeedback: resumeData.overallFeedback,
          GCAT:
            typeof resumeData?.GCAT === "string"
              ? JSON.parse(resumeData?.GCAT)
              : resumeData?.GCAT || {},
        },
        commonFunction.getHeaderDetailsForAxios()
      )
      .then((res) => {
        if (res.data.resultCode === constants.RESULT_STATUS.TECHNICAL_ERROR) {
          Alert.setShowNotify({
            show: true,
            title: "Info",
            msg: res.data.msg,
          });
        } else {
          if (res.data.resultCode === constants.RESULT_STATUS.NO_DATA) {
            Alert.setShowNotify({
              show: true,
              title: "Info",
              msg: "No data to display",
            });
          } else {
            Alert.setShowNotify({
              show: true,
              title: "Info",
              msg: res.data.msg,
            });
          }
        }
      })
      .catch((err) => {
        commonFunction.getCatchBlockDetails(err);
      });
  };

  const handleGcatChange = (e, skill) => {
    let { value } = e.target;

    if (value === "" || value === "0") {
      value = "0";
    } else {
      value = value.replace(/^0+/, "");
    }

    let updateGcat = { ...resumeData };

    let gcat =
      typeof updateGcat.GCAT == "string"
        ? JSON.parse(updateGcat.GCAT)
        : updateGcat.GCAT;

    if (!gcat) {
      gcat = {};
    }

    if (!gcat[skill]) {
      gcat[skill] = { value: "", overrideValue: value };
    } else {
      gcat[skill].overrideValue = value;
    }

    updateGcat.GCAT = JSON.stringify(gcat);
    setResumeData(updateGcat);
  };

  const handleSkillChange = (e, id, attrib, total) => {
    let { value } = e.target;

    if (
      attrib !== constants.PROFILE_ATTRIBUTES.SKILL_FEEDBACK &&
      value === ""
    ) {
      value = "0";
    } else {
      value = value.replace(/^0+/, "");
    }

    let updateSkillData = { ...resumeData };

    let updateSkill =
      typeof updateSkillData.skills == "string"
        ? JSON.parse(updateSkillData.skills)
        : updateSkillData.skills;

    if (attrib !== constants.PROFILE_ATTRIBUTES.SKILL_FEEDBACK) {
      updateSkill[id].attributes[attrib].overrideValue =
        value <= total ? value : 0;
    } else {
      updateSkill[id].attributes[attrib] = value;
    }

    updateSkillData.skills = updateSkill;
    setResumeData(updateSkillData);
  };

  const handleOverallFb = (e) => {
    let { value } = e.target;
    let overallFb = { ...resumeData };

    overallFb.overallFeedback = value;
    setResumeData(overallFb);
  };

  const removedFunction = (override, value) => {
    return override != null && override != "" && override != undefined
      ? typeof override === "string"
        ? override.trim() == ""
          ? 0
          : override.trim()
        : override
      : value != undefined && value != null && value != ""
      ? typeof value === "string"
        ? value.trim() == ""
          ? 0
          : value.trim()
        : value
      : 0;
  };

  return {
    getYear,
    getValue,
    resumeData,
    getTodayDate,
    updateResume,
    setResumeData,
    getResumeData,
    handleProject,
    removedFunction,
    handleOverallFb,
    handleGcatChange,
    handleVideoClick,
    formatPercentage,
    handleSkillChange,
    getSkillPercentage,
    addSpaceToParagraph,
    getlistFromParagraph,
  };
}
