import React, { useEffect, useState, useContext } from "react";
import FindJobButton from "./FindJobButton";
import "./searchJobs.css";
import JobsContext from "../../../context/AlertContext";
import { getLocation } from "../utils";
const SearchJobs = ({role}) => {
  const { isAdmin,initialJobs, setFilteredJobs, filters, setFilters } =
    useContext(JobsContext);
  const handleSearchChange = (event) => {
    setFilters({ ...filters, search: event.target.value });
  };
  const handleLocationChange = (event) => {
    setFilters({ ...filters, location: event.target.value });
  };

  const handleFindJobClick = () => {
    const updatedFilteredJobs = initialJobs.filter((job) => {
      const experience = parseInt(job.exp, 10) || 0;
      return (
        (filters.search === "" ||
          (job.name &&
            job.name.toLowerCase().includes(filters.search.toLowerCase())) ||
          (job.company &&
            job.company
              ?.toLowerCase()
              .includes(filters.search.toLowerCase()))) &&
        (filters.location === "" ||
          (job.location &&
            getLocation(job.location)
              ?.toLowerCase()
              .includes(filters.location.toLowerCase()))) &&
        (filters.jobType === "" ||
          (job.JobType &&
            job.JobType.toLowerCase().includes(filters.jobType.toLowerCase())))
      );
    });

    setFilteredJobs(updatedFilteredJobs);
  };

  return (
    <div className={isAdmin? "searchandfiltercontaineradmin":"searchandfiltercontainer"}>
      <div className="searchjob">
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M11 19C15.4183 19 19 15.4183 19 11C19 6.58172 15.4183 3 11 3C6.58172 3 3 6.58172 3 11C3 15.4183 6.58172 19 11 19Z"
            stroke="#F55533"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M20.9984 21.0004L16.6484 16.6504"
            stroke="#F55533"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>

        <input
          className="searchInp"
          type="text"
          value={filters.search}
          onChange={handleSearchChange}
          placeholder="Search by: Title or Position"
        />
      </div>
      <hr className="hrtagSearch"/>
      <div className="locatejob">
        <svg
          width="25"
          height="24"
          viewBox="0 0 25 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M12.668 12.75C14.3248 12.75 15.668 11.4069 15.668 9.75C15.668 8.09315 14.3248 6.75 12.668 6.75C11.0111 6.75 9.66797 8.09315 9.66797 9.75C9.66797 11.4069 11.0111 12.75 12.668 12.75Z"
            stroke="#F55533"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M20.168 9.75C20.168 16.5 12.668 21.75 12.668 21.75C12.668 21.75 5.16797 16.5 5.16797 9.75C5.16797 7.76088 5.95815 5.85322 7.36467 4.4467C8.77119 3.04018 10.6788 2.25 12.668 2.25C14.6571 2.25 16.5647 3.04018 17.9713 4.4467C19.3778 5.85322 20.168 7.76088 20.168 9.75V9.75Z"
            stroke="#F55533"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
        <input
          className="locationInp"
          type="text"
          value={filters.location}
          onChange={handleLocationChange}
          placeholder="City or state"
        />
      </div>
      <FindJobButton onClick={handleFindJobClick}>Find Job</FindJobButton>
    </div>
  );
};

export default SearchJobs;
