import React, { useContext } from "react";
import { getDataFromStorage } from "../../../util";
import AlertContext from "../../../context/AlertContext";
import UserTimeSpentReportChart from "./UserTimeSpentReportChart";
import UserTimeSpentReportTable from "./UserTimeSpentReportTable";

export default function UserTimeSpentReport() {
  const Alert = useContext(AlertContext);

  return (
    <div
      className={
        !Alert.isAdmin && getDataFromStorage("role") !== "hr"
          ? "userTimeSpentReportMainContainer"
          : "userTimeSpentReportMainContainerForAdmin"
      }
    >
      <div
        className={
          !Alert.isAdmin && getDataFromStorage("role") !== "hr"
            ? "userTimeSpentReportContainer"
            : "userTimeSpentReportContainerForAdmin"
        }
      >
        <UserTimeSpentReportChart />
        <UserTimeSpentReportTable />
      </div>
    </div>
  );
}
