import { Bar } from "react-chartjs-2";
import MonthPicker from "./MonthPicker";
import { getDataFromStorage } from "../../../util";
import React, { useContext, useEffect } from "react";
import AlertContext from "../../../context/AlertContext";
import useAxiosPrivate from "../../../Hooks/useAxiosPrivate";
import Container from "../../Admin/Batch/Report/DropdownContainer";
import { UserReportContext } from "../../../context/UserReportContext";
import useCommonFunctionHooks from "../../../Hooks/useCommonFunctionHooks";
import UserTimeSpentReportTableHooks from "../../../Hooks/UserReport/UserTimeSpentReport/UserTimeSpentReportTableHooks";
import UserTimeSpentReportChartHooks from "../../../Hooks/UserReport/UserTimeSpentReport/UserTimeSpentReportChartHooks";

import {
  Title,
  Legend,
  Tooltip,
  BarElement,
  LinearScale,
  CategoryScale,
  Chart as ChartJS,
} from "chart.js";

ChartJS.register(
  Title,
  Legend,
  Tooltip,
  BarElement,
  LinearScale,
  CategoryScale
);

export default function UserTimeSpentReportChart() {
  const Alert = useContext(AlertContext);
  const UserReportCxt = useContext(UserReportContext);

  const axios = useAxiosPrivate();
  const useCommonFunctionHks = useCommonFunctionHooks();
  const UserTimeSpentReportChartHks = UserTimeSpentReportChartHooks();
  const UserTimeSpentReportTableHks = UserTimeSpentReportTableHooks();

  useEffect(() => {
    const queryString = new URLSearchParams({
      year: UserReportCxt.selectedMonth.getFullYear(),
      month: UserReportCxt.selectedMonth.getMonth() + 1,
      lpid: UserReportCxt.userTimeSpentModuleData.lpIds,
      mid: UserReportCxt.userTimeSpentModuleData.moduleIds,
    }).toString();

    Alert.setIsLoaded(true);

    axios
      .get(
        `node/learner/${UserTimeSpentReportChartHks.params.learnerId}/batch/${UserTimeSpentReportChartHks.params.batchId}/timespent?${queryString}`,
        useCommonFunctionHks.getHeaderDetailsForAxios()
      )
      .then((res) => {
        res.data.data.sort((item1, item2) => item1.orderno - item2.orderno);
        UserReportCxt.setUserTimeSpentDetails(res.data.data);
        UserTimeSpentReportChartHks.prepareChartData(res.data.data);

        UserTimeSpentReportTableHks.generateDataForUserTimeSpentReportTable(
          res.data.data
        );
      })
      .catch((error) => {
        useCommonFunctionHks.getCatchBlockDetails(error);
      })
      .finally(() => Alert.setIsLoaded(false));
  }, [
    UserReportCxt.selectedMonth,
    localStorage.getItem("batchid"),
    UserReportCxt.userTimeSpentModuleData,
    UserReportCxt.currentLearnerDetails.value,
  ]);

  useEffect(() => {
    axios
      .get(
        `node/learner/${UserTimeSpentReportChartHks.params.learnerId}/batch/${UserTimeSpentReportChartHks.params.batchId}/learningpath/modules`,
        useCommonFunctionHks.getHeaderDetailsForAxios()
      )
      .then((res) => {
        UserTimeSpentReportChartHks.generateUserTimeSpentModuleData(
          JSON.parse(res.data.data[0].data)
        );
      })
      .catch((error) => {
        useCommonFunctionHks.getCatchBlockDetails(error);
      })
      .finally(() => Alert.setIsLoaded(false));
  }, []);

  return (
    <div
      className={
        !Alert.isAdmin && getDataFromStorage("role") !== "hr"
          ? "userTimeSpentReportChartMainContainer"
          : "userTimeSpentReportChartMainContainerForAdmin"
      }
    >
      <div className="userTimeSpentReportChartFilterDiv">
        <div className="userTimeSpentReportChartAverageTimeDiv">
          Daily Average
          <span className="userTimeSpentReportChartAverageTime">
            {UserTimeSpentReportChartHks.getAverageTimeSpent()}
          </span>
        </div>
        <div className="userTimeSpentReportChartSubFilterDiv">
          <MonthPicker />
          <Container
            showPartiallySelected={true}
            data={UserReportCxt.userTimeSpentModuleOption}
            onChange={UserTimeSpentReportChartHks.handleUserTimeSpentModuleData}
            texts={{
              placeholder: "Module",
            }}
          />
        </div>
      </div>
      {UserTimeSpentReportChartHks.getAverageTimeSpent(
        UserReportCxt.userTimeSpentDetails.map((data) => data.timespent)
      ) === "0 mins" ? (
        <div className="userTimeSpentReportChartNoData">
          There is no data to show the chart.
        </div>
      ) : (
        <Bar
          data={UserTimeSpentReportChartHks.data}
          options={UserTimeSpentReportChartHks.options}
          className="userTimeSpentReportChartTimeChart"
        />
      )}
    </div>
  );
}
