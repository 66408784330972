import React from "react";
import { Doughnut } from "react-chartjs-2";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";

ChartJS.register(ArcElement, Tooltip, Legend);

export default function HalfDonutChart({
  achievedPercentage,
  targetPercentage,
}) {
  const remainingPercentage =
    100 - Math.max(achievedPercentage, targetPercentage);

  const data = {
    labels: ["Achieved", "Target", "Remaining"],
    datasets: [
      {
        borderWidth: 0,
        backgroundColor: ["#12B76A", "#EAECF0", "#D0D5DD"],
        data: [
          achievedPercentage,
          Math.max(0, targetPercentage - achievedPercentage),
          remainingPercentage,
        ],
      },
    ],
  };

  const options = {
    rotation: -90,
    cutout: "90%",
    circumference: 180,
    plugins: {
      legend: { display: false },
      tooltip: { enabled: false },
    },

    maintainAspectRatio: false,
  };

  return (
    <div className="halfDonutChartMainContainer">
      <Doughnut data={data} options={options} />
      <div className="halfDonutChartInnerDiv">
        <div className="innerCircle">
          <div class="semicircle">{achievedPercentage}%</div>
        </div>
      </div>
    </div>
  );
}
