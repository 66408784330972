import { nanoid } from "nanoid";
import { useContext } from "react";
import constants from "../../../../constants";
import useAxiosPrivate from "../../../useAxiosPrivate";
import AlertContext from "../../../../context/AlertContext";
import useCommonFunctionHooks from "../../../useCommonFunctionHooks";
import { MockTestPageContext } from "../../../../context/MockTestPageContext";
import NotifyBody from "../../../../Components/Notify/NotifyBody";
import MockTestListHooks from "../MockTestListHooks";

export default function MockTestConfigButtonHooks() {
  const Alert = useContext(AlertContext);
  const MockTestPageCxt = useContext(MockTestPageContext);

  const axios = useAxiosPrivate();
  const { handleActiveMockTest } = MockTestListHooks();

  const { handleClose, getCatchBlockDetails, getHeaderDetailsForAxios } =
    useCommonFunctionHooks();

  const handleSuccessResponse = () => {
    if (Alert.fromMenu) {
      MockTestPageCxt.setIsAddTest(false);
      Alert.setFromMenuToEditTest(true);
      Alert.setOldTestConfig({
        oldTestData: {},
        isOldTestConfig: false,
      });
    } else if (Alert.oldTestConfig.isOldTestConfig) {
      let lpData = JSON.parse(JSON.stringify(Alert.lpData2));

      for (let i = 0; i < lpData.modules.length; i++) {
        for (let j = 0; j < lpData.modules[i].moduleData.length; j++) {
          if (
            MockTestPageCxt.testData.testId ===
            lpData.modules[i].moduleData[j].testId
          ) {
            lpData.modules[i].moduleData[j].title =
              MockTestPageCxt.testData.testName;
          }
        }
      }

      Alert.handleEditedTestConfig(lpData);
    } else {
      let newDetails = JSON.parse(JSON.stringify(Alert.lpData2)),
        uniqueId = 1,
        testData2 = {
          published: 0,
          isEdited: false,
          id: Alert.showNotify.testId,
          libTypeId: Alert.lpData2.libTypeId,
          title: MockTestPageCxt.testData.testName,
        };

      testData2.status = "new";
      testData2.testStatus = "old";

      for (let i = 0; i < newDetails.modules.length; i++) {
        if (newDetails.modules[i].id === newDetails.moduleId) {
          newDetails.modules[i].moduleData.push(MockTestPageCxt.testData);
        }

        for (let j = 0; j < newDetails.modules[i].moduleData.length; j++) {
          newDetails.modules[i].moduleData[j].moduleDataId = uniqueId;
          newDetails.modules[i].moduleData[j].key = nanoid();
          uniqueId++;
        }

        newDetails.modules[i].id = uniqueId;
        uniqueId++;
      }

      newDetails.isAddTest = false;

      if (Alert.isFromBatch) newDetails.status = "update";

      Alert.handleNavigateToLpAfterAddTest(newDetails);
    }

    handleClose();
  };

  const handleSaveTest = () => {
    let newDetails = { ...MockTestPageCxt.testData },
      found = 0,
      orderno = 1,
      totalDuration = 0;

    newDetails.sections = MockTestPageCxt.testConfig;
    newDetails.testTypeId = newDetails.testType.value;

    if (newDetails.testTypeId !== constants.libTypeIds.CODING_CHALLENGE) {
      if (newDetails.hasOwnProperty("testDuration") && !newDetails.videoQn) {
        totalDuration = newDetails.testDuration;
      }
    } else newDetails.testDuration = "";

    if (newDetails.sections.length > 0) {
      let uniqueData = [];

      for (let i = 0; i < newDetails.sections.length; i++) {
        if (newDetails.testTypeId === constants.libTypeIds.CODING_CHALLENGE) {
          newDetails.sections[i].secName = "Coding section";
        }

        if (newDetails.sections[i].secName === "") {
          Alert.setShowNotify({
            show: true,
            title: "Warning",
            msg: `Please give section name for section ${i + 1}.`,
          });

          found = 1;
          break;
        } else {
          if (newDetails.sections[i].secData.length > 0) {
            for (let j = 0; j < newDetails.sections[i].secData.length; j++) {
              newDetails.sections[i].secData[j].orderno = orderno;
              orderno++;
              if (
                newDetails.sections[i].secData[j].hasOwnProperty("desType") &&
                !newDetails.videoQn
              ) {
                if (newDetails.sections[i].secData[j].desType === "") {
                  Alert.setShowNotify({
                    show: true,
                    title: "Warning",
                    msg: "Please select the descriptive type .",
                  });

                  found = 1;

                  break;
                } else {
                  newDetails.sections[i].secData[j].desTypeName =
                    newDetails.sections[i].secData[j].desType.label;
                  newDetails.sections[i].secData[j].desTypeId =
                    newDetails.sections[i].secData[j].desType.value;
                }
              }

              if (newDetails.sections[i].secData[j].topic === "") {
                Alert.setShowNotify({
                  show: true,
                  title: "Warning",
                  msg: "Please select the topic.",
                });
                found = 1;

                break;
              } else {
                newDetails.sections[i].secData[j].topicName =
                  newDetails.sections[i].secData[j].topic.label;
                newDetails.sections[i].secData[j].topicId =
                  newDetails.sections[i].secData[j].topic.value;

                if (newDetails.sections[i].secData[j].complexity === "") {
                  Alert.setShowNotify({
                    show: true,
                    title: "Warning",
                    msg: "Please select the level.",
                  });

                  found = 1;

                  break;
                } else {
                  newDetails.sections[i].secData[j].complexityId =
                    newDetails.sections[i].secData[j].complexity.value;
                  newDetails.sections[i].secData[j].complexityName =
                    newDetails.sections[i].secData[j].complexity.label;
                }
              }

              if (
                (newDetails.sections[i].secData[j].randomQnCount === "" &&
                  newDetails.sections[i].secData[j].fixedQnCount === "") ||
                (newDetails.sections[i].secData[j].randomQnCount === 0 &&
                  newDetails.sections[i].secData[j].fixedQnCount === 0) ||
                (newDetails.sections[i].secData[j].randomQnCount ===
                  undefined &&
                  newDetails.sections[i].secData[j].fixedQnCount === undefined)
              ) {
                Alert.setShowNotify({
                  show: true,
                  title: "Warning",
                  msg: "Please give random or fixed question count .",
                });

                found = 1;

                break;
              }

              if (
                newDetails.sections[i].secData[j].marks === "" ||
                newDetails.sections[i].secData[j].marks === undefined
              ) {
                Alert.setShowNotify({
                  show: true,
                  title: "Warning",
                  msg: "Please give mark.",
                });

                found = 1;

                break;
              }

              if (
                newDetails.sections[i].secData[j].hasOwnProperty("durationNum")
              ) {
                if (
                  newDetails.sections[i].secData[j].durationNum === "" ||
                  newDetails.sections[i].secData[j].durationNum === undefined
                ) {
                  Alert.setShowNotify({
                    show: true,
                    title: "Warning",
                    msg: "Please give duration.",
                  });

                  found = 1;

                  break;
                } else {
                  let durationUnit =
                    newDetails.sections[i].secData[j].durationUnit;
                  let durationNum = parseInt(
                    newDetails.sections[i].secData[j].durationNum
                  );

                  let qCount =
                    (newDetails.sections[i].secData[j].randomQnCount === "" ||
                    newDetails.sections[i].secData[j].randomQnCount ===
                      undefined
                      ? 0
                      : parseInt(
                          newDetails.sections[i].secData[j].randomQnCount
                        )) +
                    (newDetails.sections[i].secData[j].fixedQnCount === "" ||
                    newDetails.sections[i].secData[j].fixedQnCount === undefined
                      ? 0
                      : parseInt(
                          newDetails.sections[i].secData[j].fixedQnCount
                        ));

                  newDetails.sections[i].secData[j].duration =
                    qCount * durationNum +
                    (durationUnit === "hour/s"
                      ? "h"
                      : durationUnit === "day/s"
                      ? "d"
                      : "m");

                  totalDuration +=
                    qCount *
                    (durationUnit === "hour/s"
                      ? durationNum * 60
                      : durationUnit === "day/s"
                      ? durationNum * 24 * 60
                      : durationNum);
                }
              }

              if (
                newDetails.sections[i].secData[j].hasOwnProperty("languages")
              ) {
                if (newDetails.sections[i].secData[j].languages === "") {
                  Alert.setShowNotify({
                    show: true,
                    title: "Warning",
                    msg: "Please select the language.",
                  });

                  found = 1;

                  break;
                } else {
                  for (
                    let k = 0;
                    k < newDetails.sections[i].secData[j].languages.length;
                    k++
                  ) {
                    newDetails.sections[i].secData[j].languages[k].name =
                      newDetails.sections[i].secData[j].languages[k].label;
                    newDetails.sections[i].secData[j].languages[k].id =
                      newDetails.sections[i].secData[j].languages[k].value;
                    newDetails.sections[i].secData[j].languages[k].key =
                      nanoid();
                  }
                }
              }

              if (
                newDetails.sections[i].secData[j].fixedQnCount === "" ||
                newDetails.sections[i].secData[j].fixedQnCount === undefined ||
                newDetails.sections[i].secData[j].fixedQnCount === 0
              ) {
                newDetails.sections[i].secData[j].fixedQnCount = 0;
                newDetails.sections[i].secData[j].fixedQnId = "";
              }

              if (
                newDetails.sections[i].secData[j].randomQnCount === "" ||
                newDetails.sections[i].secData[j].randomQnCount === undefined ||
                newDetails.sections[i].secData[j].randomQnCount === 0
              ) {
                newDetails.sections[i].secData[j].randomQnCount = 0;
              }

              if (newDetails.sections[i].secData[j].fixedQnId !== "") {
                uniqueData.push(newDetails.sections[i].secData[j].fixedQnId);
              }

              let uniqueValuesSet = new Set(uniqueData);
              let allValuesUnique = uniqueValuesSet.size === uniqueData.length;

              if (!allValuesUnique) {
                Alert.setShowNotify({
                  show: true,
                  title: "Warning",
                  msg: "There is some common question across the configuration",
                });

                found = 1;

                break;
              }
            }
          } else {
            Alert.setShowNotify({
              show: true,
              title: "Warning",
              msg: "Please add the topic configuration.",
            });

            found = 1;

            break;
          }

          if (found === 1) break;
        }
      }

      if (newDetails.videoQn && totalDuration > newDetails.testDuration) {
        Alert.setShowNotify({
          show: true,
          title: "Warning",
          msg: "Please give the duration less than or equal to  test duration.",
        });

        found = 1;

        return;
      }
    } else {
      Alert.setShowNotify({
        show: true,
        title: "Warning",
        msg: "Please add the section.",
      });

      found = 1;
    }

    if (found === 0) {
      if (newDetails.testTypeId === constants.libTypeIds.CODING_CHALLENGE)
        newDetails.testDuration = totalDuration;

      newDetails.tags = Alert.selectedTag;
      newDetails.aiMode = MockTestPageCxt.isChecked;

      if (!Alert.oldTestConfig.isOldTestConfig) {
        newDetails.mockuse = 1;

        Alert.setIsLoaded(true);

        axios
          .post(
            "node/admin/test/create",
            JSON.stringify(newDetails),
            getHeaderDetailsForAxios()
          )
          .then((createTestRes) => {
            let data = createTestRes.data;

            if (data.resultCode === constants.RESULT_STATUS.SUCCESS) {
              if (data.msg.includes("created")) {
                let row = {
                  from: "test",
                  id: data.data.testId,
                  mockuse: newDetails.mockuse,
                };

                MockTestPageCxt.setIsSufficientQuestions(true);

                Alert.setShowNotify({
                  show: true,
                  title: "Success",
                  primaryButton: "Publish",
                  secondaryButton: "Back to Mock",
                  msg: (
                    <NotifyBody
                      component="mockPage"
                      data={{ type: "Test", status: "created" }}
                    />
                  ),
                  secondaryButtonFunction: handleSuccessResponse,
                  primaryButtonFunction: () => handleActiveMockTest(row),
                });
              } else {
                MockTestPageCxt.setInsufficientQuestions(data.data);
                MockTestPageCxt.setIsSufficientQuestions(false);
                Alert.setShowNotify({
                  show: true,
                  msg: data.msg,
                  title: "Warning",
                });
              }
            } else if (
              data.msg.sqlMessage.includes("Duplicate entry") &&
              data.resultCode === constants.RESULT_STATUS.TECHNICAL_ERROR
            ) {
              let newDetails = { ...MockTestPageCxt.testData };

              newDetails.testConfig = MockTestPageCxt.testConfig;
              MockTestPageCxt.setTestData(newDetails);
              MockTestPageCxt.setIsNext(false);
              MockTestPageCxt.setIsBackDetails({
                isBack: true,
                testType: MockTestPageCxt.testData.testType,
              });
              Alert.setShowNotify({
                show: true,
                title: "Warning",
                msg: "The test name is already taken.",
              });
            } else if (
              data.resultCode === constants.RESULT_STATUS.FAILURE_ATTEMPT
            ) {
              Alert.setShowNotify({
                show: true,
                msg: data.msg,
                title: "Warning",
              });
            } else {
              Alert.setShowNotify({
                show: true,
                title: "Error",
                msg: "Something went wrong. Please try again later",
              });
            }
          })
          .catch((createTestErr) => {
            getCatchBlockDetails(createTestErr);
          })
          .finally(() => Alert.setIsLoaded(false));
      } else {
        let typeFound = 0;

        if (newDetails.type === "update") typeFound = 1;
        if (typeFound === 0) {
          for (let i = 0; i < newDetails.sections.length; i++) {
            if (newDetails.sections[i].testStatus === "new") {
              typeFound = 1;

              break;
            }
            for (let j = 0; j < newDetails.sections[i].secData.length; j++) {
              if (
                newDetails.sections[i].secData[j].type === "update" ||
                newDetails.sections[i].secData[j].type === "insert"
              ) {
                typeFound = 1;

                break;
              }
            }

            if (typeFound === 1) break;
          }
        }

        if (typeFound === 1) {
          Alert.setIsLoaded(true);
          axios
            .post(
              "node/admin/test/update",
              JSON.stringify(newDetails),
              getHeaderDetailsForAxios()
            )
            .then((updateTestRes) => {
              let data = updateTestRes.data;

              if (data.resultCode === constants.RESULT_STATUS.SUCCESS) {
                if (data.msg.includes("updated")) {
                  let row = {
                    from: "test",
                    id: newDetails.testId,
                    mockuse: newDetails.mockuse,
                  };

                  MockTestPageCxt.setIsSufficientQuestions(true);
                  Alert.setShowNotify({
                    show: true,
                    title: "Success",
                    primaryButton: "Publish",
                    secondaryButton: "Back to Mock",
                    msg: (
                      <NotifyBody
                        component="mockPage"
                        data={{ type: "Test", status: "updated" }}
                      />
                    ),
                    secondaryButtonFunction: handleSuccessResponse,
                    primaryButtonFunction: () => handleActiveMockTest(row),
                  });
                } else {
                  MockTestPageCxt.setInsufficientQuestions(data.data);
                  MockTestPageCxt.setIsSufficientQuestions(false);
                  Alert.setShowNotify({
                    show: true,
                    title: "Warning",
                    msg: data.msg,
                  });
                }
              } else if (
                data.resultCode === 2050 &&
                data.msg.sqlMessage.includes("Duplicate entry")
              ) {
                MockTestPageCxt.setIsNext(false);
                MockTestPageCxt.setIsBackDetails({
                  isBack: true,
                  testType: MockTestPageCxt.testData.testType,
                });
                let newDetails = { ...MockTestPageCxt.testData };

                newDetails.testConfig = MockTestPageCxt.testConfig;
                MockTestPageCxt.setTestData(newDetails);
                Alert.setShowNotify({
                  show: true,
                  title: "Warning",
                  msg: "The test name is already taken.",
                });
              } else if (
                data.resultCode === constants.RESULT_STATUS.FAILURE_ATTEMPT
              ) {
                Alert.setShowNotify({
                  show: true,
                  msg: data.msg,
                  title: "Warning",
                });
              } else {
                Alert.setShowNotify({
                  show: true,
                  title: "Error",
                  msg: "Something went wrong. Please try again later",
                });
              }
            })
            .catch((updateTestErr) => {
              getCatchBlockDetails(updateTestErr);
            })
            .finally(() => Alert.setIsLoaded(false));
        } else {
          Alert.setShowNotify({
            show: true,
            title: "Warning",
            msg: "There is no data to update",
          });
        }
      }
    }
  };

  return { handleSaveTest };
}
