import { nanoid } from "nanoid";
import Select from "react-select";
import Button from "../../../Button";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import constants from "../../../../constants";
import { Editor } from "@tinymce/tinymce-react";
import Tag from "../../../CommonComponents/Tag";
import React, { useContext, useEffect } from "react";
import AlertContext from "../../../../context/AlertContext";
import MockTestConfig from "./MockTestConfig/MockTestConfig";
import useAxiosPrivate from "../../../../Hooks/useAxiosPrivate";
import MockTestHooks from "../../../../Hooks/Mock/Admin/MockTestHooks";
import useCommonFunctionHooks from "../../../../Hooks/useCommonFunctionHooks";
import { MockTestPageContext } from "../../../../context/MockTestPageContext";

export default function MockTest() {
  const Alert = useContext(AlertContext);
  const MockTestPageCxt = useContext(MockTestPageContext);

  const axios = useAxiosPrivate();
  const MockTestHks = MockTestHooks();
  const useCommonFunctionHks = useCommonFunctionHooks();

  useEffect(() => {
    if (!Alert.oldTestConfig.isOldTestConfig) {
      Alert.setIsLoaded(true);
      axios
        .get(
          "node/admin/library/gettypes",
          useCommonFunctionHks.getHeaderDetailsForAxios()
        )
        .then((response) => {
          let responseData = JSON.stringify(response);

          responseData = JSON.parse(responseData).data;

          let descriptiveOptions = [];

          for (let i = 0; i < responseData.length; i++) {
            let temp = {
              value: responseData[i].id,
              label: responseData[i].name,
            };

            if (
              responseData[i].parenttype === constants.libTypeIds.DESCRIPTIVE
            ) {
              descriptiveOptions.push(temp);
            }
          }

          MockTestPageCxt.testData.testType = MockTestHks.selectedTestType;
          MockTestPageCxt.setDescriptiveOptions(descriptiveOptions);
        })
        .catch((error) => {
          useCommonFunctionHks.getCatchBlockDetails(error);
        })
        .finally(() => Alert.setIsLoaded(false));
    } else {
      MockTestPageCxt.setDescriptiveOptions([
        { label: "SAQ", value: constants.libTypeIds.SAQ },
        { label: "LAQ", value: constants.libTypeIds.LAQ },
      ]);
    }
  }, [
    Alert.oldTestConfig.isOldTestConfig,
    Alert.oldTestConfig.oldTestData.testType,
  ]);

  useEffect(() => {
    MockTestHks.setSelectedTestType(
      Alert.oldTestConfig.isOldTestConfig
        ? MockTestHks.testOptions.filter((type) =>
            Alert.oldTestConfig.oldTestData.autoEvaluate === 1 &&
            Alert.oldTestConfig.oldTestData.libTypeId ===
              constants.libTypeIds.DESCRIPTIVE
              ? type.id === 10 && type
              : Alert.oldTestConfig.oldTestData.videoQn === 1 &&
                Alert.oldTestConfig.oldTestData.libTypeId ===
                  constants.libTypeIds.DESCRIPTIVE
              ? type.id === 11 && type
              : type.value === Alert.oldTestConfig.oldTestData.libTypeId && type
          )
        : ""
    );

    MockTestPageCxt.setTestData(
      Alert.oldTestConfig.isOldTestConfig
        ? Alert.oldTestConfig.oldTestData
        : {
            testDes: "",
            id: nanoid(),
            testName: "",
            isOld: false,
            key: nanoid(),
            weightage: 10,
            status: "new",
            testDuration: "",
            testStatus: "new",
            testDurationNum: "",
            customInstruction: "",
            testDurationUnit: "min/s",
            testMode: constants.MODE.TEST_MODE,
            ListView: constants.SHOW_LIST_VIEW.NO,
            testType: MockTestHks.selectedTestType,
            viewTest: constants.DISPLAY_TEST_RESULT.ALL,
            sections: [
              {
                id: 1,
                secName: "",
                key: nanoid(),
                status: "new",
                testStatus: "new",
                secData: [
                  {
                    id: 2,
                    key: nanoid(),
                    status: "new",
                    testStatus: "new",
                  },
                ],
              },
            ],
          }
    );
  }, []);

  useEffect(() => {
    if (Alert.oldTestConfig.isOldTestConfig) {
      let newDetails = JSON.parse(
          JSON.stringify(Alert.oldTestConfig.oldTestData)
        ),
        count = 0,
        regexForNum = /^[0-9]\d*$/;

      for (let sec in newDetails.sections) {
        for (let secData in newDetails.sections[sec].secData) {
          if (
            regexForNum.test(
              newDetails.sections[sec].secData[secData].fixedQnCount
            )
          )
            count += newDetails.sections[sec].secData[secData].fixedQnCount;

          if (newDetails.sections[sec].secData[secData].randomQnCount)
            count += newDetails.sections[sec].secData[secData].randomQnCount;
        }
      }

      MockTestPageCxt.setQnsCount(count);
    } else return;
  }, [Alert.oldTestConfig]);

  return (
    Object.keys(MockTestPageCxt.testData)?.length !== 0 && (
      <div>
        {!MockTestPageCxt.isNext && (
          <div className="mockTestCreateMainContainer">
            <div className="mockTestCreateForm">
              <div className="mockTestCreateFormTypeSection">
                <label
                  htmlFor="mockTestCreateType"
                  className="mockTestCreateTypeLabel"
                >
                  Mock Test Type
                </label>
                <Select
                  className="mockTestCreateType"
                  options={MockTestHks.testOptions}
                  onChange={MockTestHks.testTypeLoad}
                  placeholder="Select Mock Test Type"
                  value={MockTestHks.selectedTestType}
                  styles={MockTestHks.customStylesForTests}
                  isDisabled={Alert.oldTestConfig.isOldTestConfig}
                  components={{
                    IndicatorSeparator: () => null,
                  }}
                />
              </div>
              <div className="mockTestCreateNameSection">
                <label
                  htmlFor="mockTestCreateName"
                  className="mockTestCreateNameLabel"
                >
                  Mock Test Name
                </label>
                <input
                  type="text"
                  name="testName"
                  maxLength="100"
                  id="mockTestCreateName"
                  className="mockTestCreateName"
                  placeholder="Enter new test name"
                  value={MockTestPageCxt.testData.testName}
                  onChange={MockTestHks.handleTestDetails}
                  disabled={
                    MockTestPageCxt.testData.published === 0 ||
                    MockTestPageCxt.isNewTest
                      ? false
                      : true
                  }
                />
              </div>
              <div className="mockTestCreateDesSection">
                <label
                  htmlFor="mockTestCreateDes"
                  className="mockTestCreateDesLabel"
                >
                  Description{" "}
                  <span className="mockTestCreateDesLabelOptionalText">
                    (Optional)
                  </span>
                </label>
                <textarea
                  name="testDes"
                  maxLength="300"
                  id="mockTestCreateDes"
                  placeholder="Description"
                  onChange={MockTestHks.handleTestDetails}
                  className="mockTestCreateDes"
                  disabled={
                    MockTestPageCxt.testData.published === 0 ||
                    MockTestPageCxt.isNewTest
                      ? false
                      : true
                  }
                  value={
                    MockTestPageCxt.testData.testDes === null ||
                    MockTestPageCxt.testData.testDes === "null" ||
                    MockTestPageCxt.testData.testDes === undefined
                      ? ""
                      : MockTestPageCxt.testData.testDes
                  }
                ></textarea>
              </div>
              {(MockTestPageCxt.testData.videoQn ||
                MockTestPageCxt.testData.testType?.id === 11) && (
                <div className="mockTestCreateAISection">
                  <label className="mockTestCreateAILabel">AI Mode</label>
                  <Form.Check
                    reverse
                    type="switch"
                    name="aiMode"
                    checked={MockTestPageCxt.isChecked}
                    onChange={MockTestHks.handleTestDetails}
                  />
                </div>
              )}
              <div className="mockTestCreateTagsSection">
                <Tag />
              </div>
              {MockTestPageCxt.testData.testType?.value !==
                constants.libTypeIds.CODING_CHALLENGE && (
                <div className="mockTestCreateDurationSection">
                  <label
                    htmlFor="mockTestCreateDuration"
                    className="mockTestCreateDurationLabel"
                  >
                    Duration{" "}
                  </label>
                  <div>
                    <input
                      type="text"
                      maxLength="100"
                      placeholder="Duration"
                      name="testDurationNum"
                      id="mockTestCreateDuration"
                      className="mockTestCreateDurationNum"
                      onChange={MockTestHks.handleTestDetails}
                      value={MockTestPageCxt.testData.testDurationNum}
                      disabled={
                        MockTestPageCxt.testData.published === 0 ||
                        MockTestPageCxt.isNewTest
                          ? false
                          : true
                      }
                    />
                    <select
                      placeholder="Duration"
                      name="testDurationUnit"
                      className="mockTestCreateDurationUnit"
                      onChange={MockTestHks.handleTestDetails}
                      value={MockTestPageCxt.testData.testDurationUnit}
                      disabled={
                        MockTestPageCxt.testData.published === 0 ||
                        MockTestPageCxt.isNewTest
                          ? false
                          : true
                      }
                    >
                      <option>min/s</option>
                      <option>hour/s</option>
                      <option>day/s</option>
                    </select>
                  </div>
                </div>
              )}

              <div className="mockTestCreateInstructionSection">
                <label
                  htmlFor="mockTestCreateInstructionLabel"
                  className="mockTestCreateInstructionLabel"
                >
                  Instructions
                </label>
                <div>
                  <Editor
                    value={MockTestPageCxt.testData.customInstruction}
                    id="mockTestCreateInstructionLabel"
                    tinymceScriptSrc="/tinymce/tinymce.min.js"
                    onInit={(evt, editor) =>
                      (MockTestHks.editorRef.current = editor)
                    }
                    onEditorChange={(event) =>
                      MockTestHks.handleTestDetails(event, "customInstruction")
                    }
                    disabled={
                      MockTestPageCxt.testData.published === 0 ||
                      MockTestPageCxt.isNewTest
                        ? false
                        : true
                    }
                    init={{
                      readonly: true,
                      menubar: false,
                      min_height: 150,
                      branding: false,
                      statusbar: false,
                      elementpath: false,
                      selector: "textarea",
                      allow_html_in_named_anchor: true,
                      plugins: [
                        "link",
                        "help",
                        "code",
                        "lists",
                        "image",
                        "table",
                        "media",
                        "anchor",
                        "editor",
                        "advlist",
                        "preview",
                        "charmap",
                        "wordcount",
                        "fullscreen",
                        "autoresize",
                        "visualblocks",
                        "searchreplace",
                        "insertdatetime",
                      ],
                      toolbar:
                        "undo redo | blocks | " +
                        "bold italic underline forecolor | link | alignleft aligncenter " +
                        "alignright alignjustify | bullist numlist outdent indent | " +
                        "removeformat | image | table tabledelete | tableProps tablerowProps tablecellProps | tableinsertrowbefore tableinsertrowafter tabledeleterow | tableinsertcolbefore tableinsertcolafter tabledeletecol | help |",
                      content_style:
                        "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
                    }}
                  />
                </div>
              </div>
              <div className="mockTestCreateFooter">
                <Button
                  size={"sm"}
                  onClick={MockTestHks.handleCancelTest}
                  hierarchy={{
                    buttonText: "Cancel",
                    type: "secondaryGrey",
                  }}
                />
                <Button
                  size={"sm"}
                  onClick={MockTestHks.handleClickNext}
                  hierarchy={{
                    type: "primary",
                    buttonText: "Next",
                  }}
                />
              </div>
            </div>
          </div>
        )}
        {MockTestPageCxt.isNext && <MockTestConfig />}
        <Modal
          centered
          show={MockTestHks.isChangeType.isChange}
          onHide={MockTestHks.handleConfirmChangeType}
          style={{
            fontSize: "14px",
            fontWeight: "700",
            lineHeight: "16px",
            fontFamily: "Inter",
            fontstyle: "normal",
          }}
        >
          <Modal.Body style={{ height: "50px" }}>
            Do you want to change this type.If you change the type ,you will
            lose you configuration ?{" "}
          </Modal.Body>
          <Modal.Footer style={{ margin: "10px", padding: "0px" }}>
            <Button
              size="sm"
              onClick={() => MockTestHks.handleConfirmChangeType(true)}
              hierarchy={{
                type: "primary",
                buttonText: "Yes",
              }}
            />
            <Button
              size="sm"
              onClick={() => MockTestHks.handleConfirmChangeType(false)}
              hierarchy={{
                buttonText: "No",
                type: "secondaryGrey",
              }}
            />
          </Modal.Footer>
        </Modal>
      </div>
    )
  );
}
