import { useContext } from "react";
import constants from "../constants";
import { useLocation } from "react-router-dom";
import AlertContext from "../context/AlertContext";

export default function HeaderHooks() {
  const Alert = useContext(AlertContext);

  const { pathname } = useLocation();

  const hideCompanyName = () => {
    return (
      (pathname === "/coding" &&
        !Alert.codeDetails.hasOwnProperty("lccid") &&
        (Alert.status === constants.PROGRESS_STATUS.EVALUATED ||
          Alert.status === constants.PROGRESS_STATUS.TO_BE_EVALUATED)) ||
      Alert.isAdmin
    );
  };

  return {
    hideCompanyName,
  };
}
