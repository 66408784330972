import { nanoid } from "nanoid";
import { useContext } from "react";
import constants from "../../constants";
import { getDataFromStorage } from "../../util";
import useAxiosPrivate from "../useAxiosPrivate";
import AlertContext from "../../context/AlertContext";
import useCommonFunctionHooks from "../useCommonFunctionHooks";

export default function CodingTestHooks() {
  const Alert = useContext(AlertContext);

  const axios = useAxiosPrivate();

  const { getCatchBlockDetails, getHeaderDetailsForAxios } =
    useCommonFunctionHooks();

  const getTestApi = (data) => {
    if (data.single) {
      return "node/learner/cc/add";
    } else {
      return "node/learner/codingassignment/add";
    }
  };

  const getTestPayload = (data) => {
    let payload = {
      learnerid: getDataFromStorage("learnerid"),
    };

    if (data.single) {
      payload.secid = data.secid;
      payload.questionid = data.questionid;
    } else {
      payload.topicid = data.topicid;
      payload.lmtid = Alert.testData.lmtid;
      payload.lmpid = Alert.testData.lmpid;
      payload.testconfigid = data.testconfigid;
      payload.mockuse = Alert.testData.mockuse;
      payload.lpid = sessionStorage.getItem("lpId");
      payload.lpconfigid = Alert.testData.lpconfigid;
      payload.completedqncount = data.completedcount;
      payload.mocklp = localStorage.getItem("mockLp");
    }

    return JSON.stringify(payload);
  };

  const takeTest = (data) => {
    Alert.setIsLoaded(true);
    Alert.setButtonDisable(true);
    axios
      .post(getTestApi(data), getTestPayload(data), getHeaderDetailsForAxios())
      .then((res) => {
        if (res.data.resultCode !== constants.RESULT_STATUS.SUCCESS) {
          Alert.setShowNotify({
            show: true,
            title: "Info",
            msg: res.data.msg,
          });
          Alert.setResultCode(res.data.resultCode);
        } else {
          let totalCount = 0;

          let response = res.data.data,
            testCases =
              response.testcases !== null ? JSON.parse(response.testcases) : [];

          testCases = testCases.filter((example) => example.isexample === 1);

          for (let j = 0; j < testCases.length; j++) {
            if (j === 0) testCases[j].isActive = true;
            testCases[j].id = nanoid();
          }

          response.testcases = JSON.stringify(testCases);

          if (
            data.status === constants.QUESTION_STATUS.ASSIGNED ||
            data.status === constants.QUESTION_STATUS.RESUBMIT
          ) {
            for (let k = 0; k < response.templatecode.length; k++) {
              response.templatecode[k].userCode = response.templatecode[k].code;
            }
          }

          response.isChangeCode = false;

          if (!data.hasOwnProperty("lccid")) {
            for (let i = 0; i < Alert.testData.data.topics.length; i++) {
              totalCount += Alert.testData.data.topics[i].totalcount;
            }

            response.topics = data;
            response.secData = data;
            response.lpId = Alert.lpId;
            response.status = data.status;
            response.secData.totalCount = totalCount;
            response.testname = Alert.testData.testname;
            response.lpConfigId = Alert.testData.lpconfigid;
            response.secData.testId = Alert.testData.testid;
            response.secData.testMode = Alert.testData.testmode;
            response.secData.proctored = Alert.testData.proctored;
            response.secData.completedcount = data.completedcount;
          }

          if (
            Alert.testData.testmode === constants.MODE.TEST_MODE &&
            Alert.testData.proctored === constants.PROCTORING_MODE.TAB_CAM
          ) {
            Alert.setIsTakeTest(true);
          }
          console.log("Alert.isTakeTest::::",Alert.isTakeTest)
          console.log("Alert.testData:::",Alert.testData)
          Alert.assignQuestionToLearner(response);
        }
      })
      .catch((error) => {
        if (document.fullscreenElement) {
          document.exitFullscreen();
        }

        getCatchBlockDetails(error);
      })
      .finally(() => {
        Alert.setIsLoaded(false);
        Alert.setButtonDisable(false);
      });
  };

  return { takeTest };
}
