import { Box, Slider, styled } from "@mui/material";
const PrettoSlider = styled(Slider)({
  color: "#ff5722",
  height: 8,
  "& .MuiSlider-thumb": {
    height: 16,
    width: 16,
    backgroundColor: "#fff",
    border: "2px solid currentColor",
    "&:hover": {
      boxShadow: "0px 0px 0px 8px rgba(255, 87, 34, 0.16)",
    },
  },
  "& .MuiSlider-valueLabel": {
    // backgroundColor: "black", 
    // color: "white", 
    // borderRadius: "4px",
    // fontSize: "12px", 
    padding:"8px 12px 8px 12px",
    borderRadius:"8px",
    backgroundColor: "#101828"



  },
  "& .MuiSlider-track": {
    height: 8,
  },
  "& .MuiSlider-rail": {
    height: 8,
    opacity: 0.5,
    backgroundColor: "#bfbfbf",
  },
});

function SalarySlider({ salryValue, handleSalaryChange }) {
  return (
    <Box sx={{ width: 180 }}>
      <PrettoSlider
        valueLabelDisplay="auto"
        value={salryValue}
        step={100000}
        min={0}
        max={5000000}
        onChange={handleSalaryChange}
        aria-label="Salary slider"
      />
    </Box>
  );
}

export default SalarySlider;
