import React, {
  memo,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { useNavigate } from "react-router-dom";
import {
  SandpackProvider,
  SandpackLayout,
  SandpackCodeEditor,
  SandpackPreview,
  SandpackThemeProvider,
  FileTabs,
  SandpackStack,
  useSandpackConsole,
  // SandpackConsole,
  useSandpack /* SandpackFileExplorer */,
} from "@codesandbox/sandpack-react";
import SplitPane from "react-split-pane";
import Pane from "react-split-pane/lib/Pane";
import Scrollbars from "react-custom-scrollbars-2";
import { SandpackFileExplorer } from "sandpack-file-explorer";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Typography from "antd/lib/typography/Typography";
import { sandpackDark } from "@codesandbox/sandpack-themes";
import "./SandpackEditor.scss";
import AlertContext from "../../context/AlertContext";
import SandpackEditorSplitContext from "./SandpackEditorSplitContext";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import { getDataFromStorage, getPreSignedUrl } from "../../util";
// import useSandpackEditor from '../../hooks/useSandpackEditor';
import useAxiosPrivate from "../../Hooks/useAxiosPrivate";
import constants from "../../constants";
import Loading from "../Notification-Loading/Loading/Loading";
// import { Console, Hook, Unhook } from "console-feed";
// import SandpackConsole from "./SandpackConsole";
import useUploadLargeFileToS3 from "../../Hooks/useUploadLargeFileToS3";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const SandpackEditorTools = (props) => {
  const {
    handleProblemDragFinished,
    problemWindowHeight,
    setProblemWindowHeight,
    outputWindowHeight,
    setOutputWindowHeight,
    handleDragFinished,
  } = SandpackEditorSplitContext();
  const { isAdmin, sandpackFiles, setSandpackFiles, openSandpackEditor } =
    useContext(AlertContext);
  const { uploadFileToS3, getS3ObjectByFolder } = useUploadLargeFileToS3();
  const { sandpack } = useSandpack();
  // const { logs, reset } = useSandpackConsole(sandpack);
  const [logs, setLogs] = useState([]);
  const [value, setValue] = useState(0);
  const [filesLoaded, setFilesLoaded] = useState(false);
  const [previewLoaded, setPreviewLoaded] = useState(false);
  const [refreshKey, setRefreshKey] = useState(0);
  const [previewUrl, setPreviewUrl] = useState("");
  const [consoleMessages, setConsoleMessages] = useState([]);
  const preValue = useRef(0);
  const previewRef = useRef(null);

  useEffect(() => {
    // if (previewRef.current) {
    const iframe = previewRef.current;
    // const captureLogs = () => {
    function handleMessage(event) {
      console.log("event.data", event.data);
      if (event.data.message) {
        console.log("message", event.data.message);
        setConsoleMessages((prevMessages) => [
          ...prevMessages,
          event.data.message,
        ]);
      }
    }
    if (window.addEventListener) {
      window.addEventListener("message", handleMessage);
    } else {
      window.attachEvent("onmessage", handleMessage);
    }

    // Send a message to the iframe requesting it to send logs (optional)
    // iframe.contentWindow.postMessage({ type: "getLogs" }, "*");
    // };

    // captureLogs();
    // }
  }, [previewUrl]);
  let adminId = getDataFromStorage("learnerid");
  const refreshIframe = () => {
    // setConsoleMessages([]);
    setRefreshKey((prevKey) => prevKey + 1);
    for (let file in sandpack.files) {
      uploadFileToS3(
        sandpack.files[file].code,
        isAdmin && props.isqueReport
          ? `${process.env.REACT_APP_WEBSITE_HOST}/library/${props.libDetails.id}${file}`
          : isAdmin
          ? `${process.env.REACT_APP_WEBSITE_HOST}/admin/${adminId}${file}`
          : `${process.env.REACT_APP_WEBSITE_HOST}/${
              props.libDetails.id
            }/${getDataFromStorage("learnerid")}${file}`,
        file.split(".")[1],
        "uploadAssignment"
      );
    }
    setSandpackFiles(sandpack.files);
    setPreviewUrl(
      isAdmin && props.isqueReport
        ? `https://gradiouslmsapp.s3.ap-south-1.amazonaws.com/${process.env.REACT_APP_WEBSITE_HOST}/library/${props.libDetails.id}`
        : isAdmin
        ? `https://gradiouslmsapp.s3.ap-south-1.amazonaws.com/${process.env.REACT_APP_WEBSITE_HOST}/admin/${adminId}/index.html`
        : `https://gradiouslmsapp.s3.ap-south-1.amazonaws.com/${
            process.env.REACT_APP_WEBSITE_HOST
          }/${props.libDetails.id}/${getDataFromStorage(
            "learnerid"
          )}/index.html`
    );
  };

  // Use a ref to track whether getSandpackFiles has been called
  const getSandpackFilesCalled = useRef(false);

  const handleChange = (event, newValue) => {
    event.preventDefault();
    preValue.current = newValue;
    setValue(newValue);
  };

  const getSandpackFiles = useCallback(async () => {
    if (
      (isAdmin && props.isqueReport) ||
      (!isAdmin &&
        props.isAddSubmission &&
        props.status === constants.PROGRESS_STATUS.UNLOCKED)
    ) {
      const filePath = `${process.env.REACT_APP_WEBSITE_HOST}/library/${props.libDetails.id}`;
      let fileData = await getS3ObjectByFolder(filePath);
      sandpack.addFile(fileData);
      // setSandpackFiles(fileData)
      setFilesLoaded(true);
    } else if (props.status !== constants.PROGRESS_STATUS.UNLOCKED) {
      const filePath = `${process.env.REACT_APP_WEBSITE_HOST}/${
        props.libDetails.id
      }/${
        isAdmin ? props.libDetails.learnerid : getDataFromStorage("learnerid")
      }`;

      let fileData = await getS3ObjectByFolder(filePath);
      console.log("inside", fileData);
      sandpack.addFile(fileData);
      // setSandpackFiles(fileData)
      setFilesLoaded(true);
    } else {
      return;
    }
  }, [
    props.isqueReport,
    props.libDetails,
    isAdmin,
    props.isAddSubmission,
    props.status,
  ]);

  useEffect(() => {
    const savedSize = localStorage.getItem("splitPos");
    const savedWidthSize = localStorage.getItem("splitWidthPos");

    if (savedSize) {
      const [, heightPercentage] = savedSize.split(","); // Extract the height percentage value
      const parsedHeight = parseFloat(heightPercentage.replace("%", "")); // Convert the height value to a float

      //setOutputWindowHeight(parsedHeight);
      setOutputWindowHeight(8.4);
    }

    if (savedWidthSize) {
      const [widthPercentage] = savedWidthSize.split(",");
      const parsedWidth = parseFloat(widthPercentage.replace("%", ""));

      setProblemWindowHeight(parsedWidth);
    }
    const editorHeightdiv = document.getElementById("sandpackCodeEditor");
    if (editorHeightdiv) {
      editorHeightdiv.style.height = `90vh`;
    }
    console.log("props.libDetails", props.libDetails);
  }, []);

  useEffect(() => {
    if (previewLoaded) {
      setTimeout(() => {
        setPreviewLoaded(false);
      }, 1000);
    }
  }, [previewLoaded]);

  useEffect(() => {
    if (!getSandpackFilesCalled.current) {
      if (props.libDetails.id) {
        getSandpackFiles();
      }
      getSandpackFilesCalled.current = true;
    }
  }, [getSandpackFiles]);

  useEffect(() => {
    setSandpackFiles(sandpack.files);
  }, [sandpack, setSandpackFiles]);

  console.log("consoleMessages", consoleMessages);

  // const handleOpenInNewTab = () => {
  //   let adminId = getDataFromStorage("learnerid");
  //   let url = `https://gradiouslmsapp.s3.ap-south-1.amazonaws.com/${process.env.REACT_APP_WEBSITE_HOST}/admin/${adminId}/index.html`;
  //   window.open(url, "_blank").focus();
  // };

  return (
    <SandpackLayout>
      <SandpackThemeProvider theme={sandpackDark}>
        <div className="sandpackEditor">
          <SplitPane
            size={100}
            minSize={30}
            split="vertical"
            primary="first"
            className="fileExplorerDiv"
            onChange={handleProblemDragFinished}
          >
            <Pane
              minSize="20%"
              maxSize="30%"
              className="fileExplorerpane"
              paneStyle={{ backgroundColor: "red" }}
              initialSize={`${problemWindowHeight}%`}
              iniinitialSize="30%"
            >
              <Scrollbars autoHide>
                <CustomFileExplorer
                  sandpack={sandpack}
                  setSandpackFiles={setSandpackFiles}
                  sandpackFiles={sandpackFiles}
                  isopenEditor={openSandpackEditor}
                  setOpenSandpackEditor={props.setOpenSandpackEditor}
                  filesLoaded={filesLoaded}
                  setFilesLoaded={setFilesLoaded}
                />
              </Scrollbars>
            </Pane>
            <SplitPane
              split="horizontal"
              onChange={handleDragFinished}
              primary="second"
            >
              <Pane minSize="2%" maxSize="90%" onChange={handleDragFinished}>
                <>
                  <SandpackCodeEditor
                    showTabs
                    showRunButton={false}
                    closableTabs
                    showInlineErrors
                    wrapContent={true}
                    editorHeight={`100vh`}
                    showLineNumbers={true}
                    id="sandpackCodeEditor"
                  />
                  <div
                    className="sandpackRunSec"
                    onClick={() => {
                      setPreviewLoaded(!previewLoaded);
                      refreshIframe();
                    }}
                  >
                    <svg
                      id="sandpackRunIcon"
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                    >
                      <path
                        d="M4.16675 2.5L15.8334 10L4.16675 17.5V2.5Z"
                        stroke="#F96343"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                    <button id="sandpackRunText">Run</button>
                  </div>

                  {/* {isAdmin && !props.isqueReport ? (
                    <div className="openinNewTab" onClick={handleOpenInNewTab}>
                      <svg
                        className="openlinkinnewicon"
                        xmlns="http://www.w3.org/2000/svg"
                        x="0px"
                        y="0px"
                        width="50"
                        height="50"
                        viewBox="0 0 24 24"
                      >
                        <path
                          fill="#ffffff"
                          d="M19,21H5c-1.1,0-2-0.9-2-2V5c0-1.1,0.9-2,2-2h7v2H5v14h14v-7h2v7C21,20.1,20.1,21,19,21z"
                        ></path>
                        <path
                          fill="#ffffff"
                          d="M21 10L19 10 19 5 14 5 14 3 21 3z"
                        ></path>
                        <path
                          fill="#ffffff"
                          d="M6.7 8.5H22.3V10.5H6.7z"
                          transform="rotate(-45.001 14.5 9.5)"
                        ></path>
                      </svg>
                    </div>
                  ) : null} */}
                </>
              </Pane>
              <Pane
                id="outputParentWindowContainer"
                minSize={"5%"}
                maxSize={"95%"}
                initialSize={`${outputWindowHeight}%`}
              >
                <div className="outputandpreview">
                  <Box sx={{ bgcolor: "background.paper" }}>
                    <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                      <Tabs
                        value={value}
                        onChange={handleChange}
                        aria-label="basic tabs example"
                        indicatorColor="secondary"
                      >
                        <Tab label="Output" {...a11yProps(0)} />

                        <Tab label="Console" {...a11yProps(1)} />
                      </Tabs>
                    </Box>
                    <CustomTabPanel value={value} index={0}>
                      {previewLoaded ? (
                        <div
                          style={{
                            position: "relative",
                            transform: "translate(-70px,70px)",
                          }}
                        >
                          <Loading />
                        </div>
                      ) : (
                        <iframe
                          ref={previewRef}
                          title="un"
                          key={refreshKey}
                          src={previewUrl}
                          width="100%"
                          height="100%"
                        />
                      )}
                    </CustomTabPanel>
                    <CustomTabPanel value={value} index={1}>
                      <div className="sandpackConsoleSec">
                        <button
                          className="sandpackConsoleClearBtn"
                          title="Clear"
                          onClick={() => setConsoleMessages([])}
                        >
                          Clear
                        </button>
                        {/* <SandpackConsole console={logs} />
                        <Unhook logs={logs} /> */}
                        {consoleMessages.map((message, index) => (
                          <div key={index}>{JSON.stringify(message)}</div>
                        ))}
                      </div>
                    </CustomTabPanel>
                  </Box>
                </div>
              </Pane>
            </SplitPane>
          </SplitPane>
        </div>
      </SandpackThemeProvider>
    </SandpackLayout>
  );
};

function CustomFileExplorer(props) {
  const {
    isFullScreensp,
    setIsFullScreensp,
    setIsAddSubmission,
    setIsEditSubmission,
    openSandpackEditor,
    setOpenSandpackEditor,
  } = useContext(AlertContext);
  const navigate = useNavigate();
  var [hasred, sethadred] = useState(false);

  // const handleFileUpload = (event) => {
  // const file = event.target.files[0];
  // const reader = new FileReader();
  // const url = URL.createObjectURL(file);
  // reader.onload = (evt) => {
  // const dataURL = evt.target.result;
  // let fileJson = { [file.name]: { code: dataURL } };
  // props.sandpack.addFile(fileJson);
  // props.sandpack.setActiveFile(`/${file.name}`);
  // props.sandpack.visibleFiles.push(`/${file.name}`);
  // setAddedFiles([...addedFiles, file.name]);
  // sethadred(false);
  // };
  // reader.readAsDataURL(file);
  // event.target.value = "";
  // };

  useEffect(() => {
    if (!hasred) {
      setTimeout(() => {
        sethadred(true);
      }, 1000);
    }
  }, [hasred]);

  const handleBack = () => {
    if (isFullScreensp) {
      document.exitFullscreen();
      setIsFullScreensp(false);
    }
    setIsEditSubmission(false);
    setIsAddSubmission(false);
    if (!openSandpackEditor) {
      navigate("/assignment");
    } else {
      setOpenSandpackEditor(false);
    }
  };

  return (
    <div className="fileexplorercontainer" style={{ background: "#151515" }}>
      <div className="backfromsandpack" title="back">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="18"
          height="18"
          viewBox="0 0 18 18"
          fill="none"
          onClick={handleBack}
        >
          <path
            d="M11.25 13.5L6.75 9L11.25 4.5"
            stroke="#EAECF0"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </div>

      <div
        className="fileExplorercustomsandpack"
        style={{ background: "#151515" }}
      >
        {hasred ? (
          <SandpackFileExplorer />
        ) : (
          <div className="loadinfileexp">
            <Loading />
          </div>
        )}
      </div>
    </div>
  );
}

const SandpackEditor = memo(function SandpackEditor(props) {
  const {
    setIsFullScreensp,
    isFullScreensp,
    isLoaded,
    openSandpackEditor,
    setOpenSandpackEditor,
  } = useContext(AlertContext);

  useEffect(() => {
    const handleFullScreenChange = () => {
      setIsFullScreensp(
        document.fullscreenElement ||
          document.webkitFullscreenElement ||
          document.mozFullScreenElement ||
          document.msFullscreenElement
      );
    };

    document.addEventListener("fullscreenchange", handleFullScreenChange);
    document.addEventListener("webkitfullscreenchange", handleFullScreenChange);
    document.addEventListener("mozfullscreenchange", handleFullScreenChange);
    document.addEventListener("MSFullscreenChange", handleFullScreenChange);

    return () => {
      document.removeEventListener("fullscreenchange", handleFullScreenChange);
      document.removeEventListener(
        "webkitfullscreenchange",
        handleFullScreenChange
      );
      document.removeEventListener(
        "mozfullscreenchange",
        handleFullScreenChange
      );
      document.removeEventListener(
        "MSFullscreenChange",
        handleFullScreenChange
      );
    };
  }, [setIsFullScreensp]);

  return (
    <div
      className={
        isFullScreensp ? "sandpackEditorContainer1" : "sandpackEditorContainer2"
      }
    >
      {isLoaded && <Loading />}
      <SandpackProvider
        template={props.selectedTemplate?.value?.toLowerCase()}
        options={{
          /* bundlerURL: "https://leap-dev.gradious.com", */
          autorun: false,
          autoReload: false,
          showConsole: true,
        }}
      >
        <SandpackEditorTools
          isqueReport={props.isqueReport}
          libDetails={props.libDetails}
          template={props.selectedTemplate}
          isAddSubmission={props.isAddSubmission}
          status={props.status}
          learnerId={props.learnerId}
          openSandpackEditor={openSandpackEditor}
          setOpenSandpackEditor={setOpenSandpackEditor}
        />
      </SandpackProvider>
    </div>
  );
});

export const TemplateSelect = (props) => {
  const {
    setIsLoaded,
    setIsUnAuth,
    setMsg,
    setShow,
    setTitle,
    setSandpackFiles,
  } = useContext(AlertContext);
  const axios = useAxiosPrivate();
  const [templates, setTemplates] = useState([]);

  useEffect(() => {
    const getTemplates = async () => {
      try {
        let response = await axios.get("node/admin/templates", {
          headers: {
            "Content-type": "application/json",
          },
        });

        if (response.data.resultCode === 1000) {
          let responseData = JSON.parse(JSON.stringify(response.data.data));
          for (let temp in responseData) {
            responseData[temp].value = responseData[temp].name;
            delete responseData[temp].name;
          }
          setTemplates(responseData);
        } else {
          setShow(true);
          setTitle("Error !");
          setMsg("Something went wrong. Please try again later.");
        }
      } catch (err) {
        console.log(err);
        setShow(true);
        setTitle("Warning !");
        if (err.message.includes("403")) {
          setIsUnAuth(true);
          setMsg("You have been logged-out due to inactivity. Login again..");
        } else setMsg("Something went wrong. Please try again later.");
      } finally {
        setIsLoaded(false);
      }
    };
    getTemplates();
    return () => getTemplates();
  }, []);

  const handleChangeTemplate = (selectedTemplate) => {
    let templateFiles = {
      1: {
        "/styles.css": {
          code: "body {\n font-family: sans-serif;\n -webkit-font-smoothing: auto;\n -moz-font-smoothing: auto;\n -moz-osx-font-smoothing: grayscale;\n font-smoothing: auto;\n text-rendering: optimizeLegibility;\n font-smooth: always;\n -webkit-tap-highlight-color: transparent;\n -webkit-touch-callout: none;\n}\n\nh1 {\n font-size: 1.5rem;\n}",
        },
        "/index.js": {
          code: 'import "./styles.css";\n\ndocument.getElementById("app").innerHTML = `\n<h1>Hello world</h1>\n`;\n console.log = function(message) {parent.postMessage({"message":message},"*");}console.error = console.debug = console.info = console.log;',
        },
        "/index.html": {
          code: '<!DOCTYPE html>\n<html>\n\n<head>\n <title>Parcel Sandbox</title>\n <meta charset="UTF-8" />\n</head>\n\n<body>\n <div id="app"></div>\n\n <script src="index.js">\n </script>\n</body>\n\n</html>',
        },
        "/package.json": {
          code: '{\n "dependencies": {},\n "main": "/index.js",\n "devDependencies": {}\n}',
        },
      },
      2: {
        "/styles.css": {
          code: "body {\n font-family: sans-serif;\n -webkit-font-smoothing: auto;\n -moz-font-smoothing: auto;\n -moz-osx-font-smoothing: grayscale;\n font-smoothing: auto;\n text-rendering: optimizeLegibility;\n font-smooth: always;\n -webkit-tap-highlight-color: transparent;\n -webkit-touch-callout: none;\n}\n\nh1 {\n font-size: 1.5rem;\n}",
        },
        "/index.html": {
          code: '<!DOCTYPE html>\n<html>\n\n<head>\n <title>Parcel Sandbox</title>\n <meta charset="UTF-8" />\n <link rel="stylesheet" href="/styles.css" />\n</head>\n\n<body>\n <h1>Hello world</h1>\n</body>\n\n</html>',
        },
        "/index.js": {
          code: `console.log = function(message) {
  parent.postMessage({'message':message},"*");
  }
  console.error = console.debug = console.info = console.log;`,
        },
        "/package.json": {
          code: '{\n "dependencies": {},\n "main": "/index.html",\n "devDependencies": {}\n}',
        },
      },
    };
    let templatData = templates.filter(
      (template) =>
        template.value.toLowerCase() ===
        selectedTemplate.target.value.toLowerCase()
    );
    props.setSeletedTemplate(templatData[0]);
    setSandpackFiles(templateFiles[templatData[0].id]);
  };

  return (
    <div className="sandpackEditorTemplateSelectSec">
      <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
        <InputLabel id="sandpackEditorTemplateSelectLabel">Template</InputLabel>
        <Select
          labelId="sandpackEditorTemplateSelectLabel"
          id="sandpackEditorTemplateSelect"
          value={props.selectedTemplate?.value}
          label="Template"
          autoWidth
          onChange={handleChangeTemplate}
        >
          {templates.length > 0 &&
            templates?.map((template) => (
              <MenuItem key={template.id} value={template.value}>
                {template.value}
              </MenuItem>
            ))}
        </Select>
      </FormControl>
    </div>
  );
};
export default SandpackEditor;
