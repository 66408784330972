import constants from "../../../constants";
import MenuBarFooter from "./MenuBarFooter";
import MenuSvgIcon from "../../../Svg/MenuSvgIcon";
import { getDataFromStorage } from "../../../util";
import ChevronUpSvg from "../../../Svg/ChevronUpSvg";
import React, { useContext, useEffect } from "react";
import { Outlet, useLocation } from "react-router-dom";
import ChevronDownSvg from "../../../Svg/ChevronDownSvg";
import AlertContext from "../../../context/AlertContext";
import CompanyLogo from "../../CommonComponents/CompanyLogo";
import useAxiosPrivate from "../../../Hooks/useAxiosPrivate";
import MenuBarHooks from "../../../Hooks/MenuBar/MenuBarHooks";
import Loading from "../../Notification-Loading/Loading/Loading";
import { Sidebar, Menu, MenuItem, SubMenu } from "react-pro-sidebar";
import useCommonFunctionHooks from "../../../Hooks/useCommonFunctionHooks";

export default function MenuBar() {
  const Alert = useContext(AlertContext);

  const axios = useAxiosPrivate();
  const MenuBarHks = MenuBarHooks();
  const useCommonFunctionHks = useCommonFunctionHooks();

  const { pathname } = useLocation();

  useEffect(() => {
    if (pathname === "/main") Alert.navigate("/main/dashboard");
  }, [pathname, Alert]);

  useEffect(() => {
    if (MenuBarHks.activeBatchRef.current) {
      MenuBarHks.activeBatchRef.current.scrollIntoView({
        block: "center",
        behavior: "smooth",
      });
    }
  }, [MenuBarHks.activeBatchRef.current]);

  useEffect(() => {
    if (pathname === "/main/dashboard") {
      Alert.setIsLoaded(true);

      axios
        .get(
          "node/learner/batch/" + getDataFromStorage("learnerid"),
          useCommonFunctionHks.getHeaderDetailsForAxios()
        )
        .then((res) => {
          if (res.data.resultCode !== constants.RESULT_STATUS.SUCCESS) {
            Alert.setShowNotify({
              show: true,
              title: "Error",
              msg: res.data.msg,
            });
          } else {
            Alert.setUserBatch(res.data.data);

            if (res.data.data.length === 1) {
              if (res.data.data[0].type === constants.BATCH_TYPE.TESTING) {
                let courseNav =
                  typeof res.data.data[0].courses == "string"
                    ? JSON.parse(res.data.data[0].courses)
                    : res.data.data[0].courses;

                if (courseNav.length > 1) {
                  MenuBarHks.handleSetLocalStorage(res.data.data[0]);
                  MenuBarHks.handleDashBoard();
                } else {
                  MenuBarHks.handleSetLocalStorage(res.data.data[0]);
                  sessionStorage.setItem("lpId", courseNav[0].lpId);
                  Alert.navigate("/home");
                }
              } else {
                MenuBarHks.handleSetLocalStorage(res.data.data[0]);
                MenuBarHks.handleDashBoard();
              }
            } else if (res.data.data.length === 0) {
              Alert.setShowNotify({
                show: true,
                title: "Warning",
                primaryButtonFunction: useCommonFunctionHks.handleUnAuthAlert,
                msg: "You don't belong to any batch. Please contact Gradious Team.",
              });
            } else {
              if (localStorage.getItem("batchid") != null) {
                let batch = res.data.data.filter(
                  (data) =>
                    data.id === parseInt(localStorage.getItem("batchid"))
                );

                if (batch && batch.length === 1) {
                  MenuBarHks.handleSetLocalStorage(batch[0]);

                  if (
                    res.data.data.length === 1 &&
                    batch[0].type === constants.BATCH_TYPE.TESTING
                  ) {
                    Alert.navigate("/home");
                  } else {
                    Alert.navigate("/main/dashboard");
                    MenuBarHks.handleDashBoard();
                  }
                }
              } else {
                MenuBarHks.handleSetLocalStorage(res.data.data[0]);
                MenuBarHks.handleDashBoard();
              }
            }
          }
        })
        .catch((error) => {
          useCommonFunctionHks.getCatchBlockDetails(error);
        })
        .finally(() => Alert.setIsLoaded(false));
    }
  }, [pathname]);

  return (
    <div className="menuBarMainContainer">
      {Alert.isLoaded && <Loading />}
      <Sidebar className="menuBarMainSideBar">
        <div className="menuBarMainInnerDiv">{<CompanyLogo />}</div>
        <Menu
          transitionDuration={1000}
          className="menuBarMainMenu"
          renderExpandIcon={({ open }) => (
            <span>
              {open ? (
                <ChevronUpSvg from="menu" />
              ) : (
                <ChevronDownSvg from="menu" />
              )}
            </span>
          )}
        >
          <SubMenu
            defaultOpen
            label="Batches"
            className="menuBarSubMenu"
            active={pathname.includes("dashboard")}
            onClick={() => Alert.navigate("/main/dashboard")}
            icon={<MenuSvgIcon menuId={constants.MENU_ICON.BATCHES} />}
          >
            {Alert.userBatch.length > 0 &&
              Alert.userBatch.map((batch) => (
                <MenuItem
                  key={batch.id}
                  className="menuBarSubMenuList"
                  onClick={() => MenuBarHks.handleBatch(batch)}
                  active={
                    batch.id === parseInt(localStorage.getItem("batchid"))
                  }
                  ref={
                    batch.id === parseInt(localStorage.getItem("batchid"))
                      ? MenuBarHks.activeBatchRef
                      : null
                  }
                >
                  {batch.name}
                </MenuItem>
              ))}
          </SubMenu>
          {getDataFromStorage("role") !== constants.Roles.parent && (
            <MenuItem
              className="menuBarMainMenuList"
              active={pathname.includes("cc")}
              onClick={() => Alert.navigate("main/cc")}
              prefix={
                <MenuSvgIcon menuId={constants.MENU_ICON.CODING_CHALLENGE} />
              }
            >
              Coding Challenges
            </MenuItem>
          )}
          <MenuItem
            className="menuBarMainMenuList"
            active={pathname.includes("jobs")}
            onClick={() => Alert.navigate("main/jobs")}
            prefix={<MenuSvgIcon menuId={constants.MENU_ICON.JOBS} />}
          >
            Jobs & Internships
          </MenuItem>
          {/* {getDataFromStorage("role") !== constants.Roles.parent && <MenuItem
            className="menuBarMainMenuList"
            active={pathname.includes("discussion")}
            onClick={() => Alert.navigate("main/discussion")}
            prefix={<MenuSvgIcon menuId={constants.MENU_ICON.DISCUSSION} />}
          >
            Discussions
          </MenuItem>} */}
          <MenuItem
            className="menuBarMainMenuList"
            active={pathname.includes("hackathons")}
            onClick={() => Alert.navigate("main/hackathons")}
            prefix={<MenuSvgIcon menuId={constants.MENU_ICON.HACKATHON} />}
          >
            Hackathons
          </MenuItem>
          {getDataFromStorage("role") !== constants.Roles.parent && (
            <MenuItem
              className="menuBarMainMenuList"
              active={pathname.includes("mock")}
              onClick={() => {Alert.navigate("main/mock/library");Alert.testPath.current="mocktest"}}
              prefix={<MenuSvgIcon menuId={constants.MENU_ICON.MOCK} />}
            >
              Mock Tests
            </MenuItem>
          )}
          <MenuItem
            prefix={<MenuSvgIcon />}
            className="menuBarMainMenuList"
            active={pathname.includes("interview")}
            onClick={() => {Alert.navigate("main/interview/library");Alert.testPath.current="ainterview"}}
          >
            AI Interview
          </MenuItem>
        </Menu>
        <MenuBarFooter />
      </Sidebar>

      <main className="menuBarBody">
        <Outlet />
      </main>
    </div>
  );
}
