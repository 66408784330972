import React from "react";
import IconButton from "./IconButton";
import PropTypes from "prop-types";

const Modal = ({
  isOpen,
  onClose,
  children,
  showCloseIcon,
  component,
  closeIcon = (
    <svg
      width={component === "leaderboard" ? "20" : "12"}
      height={component === "leaderboard" ? "20" : "12"}
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9 3L3 9M3 3L9 9"
        stroke="#101828"
        strokeWwidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  title,
}) => {
  if (!isOpen) return null;
  const onClickXMark = (event) => {
    if (onClose) onClose(event);
  };

  return (
    <div className="modalContainer" onClick={onClickXMark}>
      <div
        className={
          component === "leaderboard"
            ? "modalContent_leaderboard"
            : "modalContent"
        }
        onClick={(event) => event.stopPropagation()}
      >
        {(title || showCloseIcon) && (
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            {title && <div className="modalTitle">{title}</div>}
            {showCloseIcon && (
              <div className="modalCloseIcon">
                <IconButton
                  onClick={onClickXMark}
                  size="sm"
                  hierarchy="tertiaryGrey"
                >
                  {closeIcon}
                </IconButton>
              </div>
            )}
          </div>
        )}
        {children}
      </div>
    </div>
  );
};

export default Modal;

Modal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
  showCloseIcon: PropTypes.bool,
};

Modal.defaultProps = {
  isOpen: false,
  children: null,
  showCloseIcon: true,
};
