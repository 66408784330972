import Button from "../../Button";
import Avatar from "@mui/material/Avatar";
import constants from "../../../constants";
import { useLocation } from "react-router-dom";
import { getDataFromStorage } from "../../../util";
import TestSvgIcon from "../../../Svg/TestSvgIcon";
import React, { useContext, useEffect } from "react";
import AlertContext from "../../../context/AlertContext";
import useAxiosPrivate from "../../../Hooks/useAxiosPrivate";
import useCommonFunctionHooks from "../../../Hooks/useCommonFunctionHooks";
import { MockTestPageContext } from "../../../context/MockTestPageContext";
import MockTestLibraryHooks from "../../../Hooks/Mock/Student/MockTestLibraryHooks";

export default function MockTestLibrary() {
  const Alert = useContext(AlertContext);
  const MockTestPageCxt = useContext(MockTestPageContext);
  
  const axios = useAxiosPrivate();
  const MockTestLibraryHks = MockTestLibraryHooks();
  const useCommonFunctionHks = useCommonFunctionHooks();
  
  const { pathname } = useLocation();

  useEffect(() => {
    MockTestLibraryHks.handleSearch();
  }, [MockTestPageCxt.searchText]);

  useEffect(() => {
    Alert.setIsLoaded(true);
    axios
      .post(
        "node/learner/mock/lib",
        JSON.stringify({
          learnerid: getDataFromStorage("learnerid"),
          batchid: localStorage.getItem("batchid"),
        }),
        useCommonFunctionHks.getHeaderDetailsForAxios()
      )
      .then((res) => {
        if (res.data.resultCode !== constants.RESULT_STATUS.SUCCESS) {
          Alert.setShowNotify({
            show: true,
            title: "Info",
            msg: res.data.message,
          });
        } else {
          if(pathname === "/main/interview/library"){
            const videoQuestions = res.data.data.tests?.filter(data => (data.videoqn === 1 && data.aiMode === 1 ));
            MockTestPageCxt.setMockTest(videoQuestions);
            MockTestPageCxt.setSearchMockTest(videoQuestions);
          } else {
            if (res.data.data.hasOwnProperty("paths")) {
              MockTestPageCxt.setMockTest(
                res.data.data.tests.concat(res.data.data.paths)
              );
              MockTestPageCxt.setSearchMockTest(
                res.data.data.tests.concat(res.data.data.paths)
              );
            } else {
              MockTestPageCxt.setMockTest(res.data.data.tests);
              MockTestPageCxt.setSearchMockTest(res.data.data.tests);
            }
          }
        }
      })
      .catch((error) => {
        useCommonFunctionHks.getCatchBlockDetails(error);
      })
      .finally(() => {
        Alert.setIsLoaded(false);
      });
  }, [pathname, MockTestPageCxt.dataRerender]);

  useEffect(()=>{
    if(pathname === "/main/interview/library") Alert.testPath.current="ainterview";
    if(pathname === "/main/mock/library") Alert.testPath.current="mocktest";
  },[])

  return (
    <div className="mockTestLibraryMainContainer">
      {MockTestPageCxt.searchMockTest.map((test) => (
        <div key={test.id} className="mockTestLibraryGrid">
          <div className="mockTestLibraryGridHeader">
            <div className="mockTestLibraryGridHeaderTitle">{test.name}</div>
            {!test.hasOwnProperty("toc") || test.thumbnail === null ? (
              <TestSvgIcon
                videoQn={test.videoqn}
                autoEval={test.autoeval}
                libType={
                  test.thumbnail !== null
                    ? test.libtype
                    : constants.libTypeIds.LEARNINGPATH
                }
              />
            ) : (
              <Avatar
                src={test.thumbnail}
                style={{ width: "28px", height: "30px", padding: 0 }}
              />
            )}
          </div>
          <div className="mockTestLibraryGridSubHeader">
            <div className="mockTestLibraryGridSubHeaderTestTitle">
              {MockTestLibraryHks.getTestType(test)}
              <div className="mockTestLibraryGridSubHeaderTestQuestionCount">
                ({test.questionscount} Questions)
              </div>
            </div>
            <div className="mockTestLibraryGridSubHeaderTestDuration">
              {MockTestLibraryHks.getTime(test.testduration)}
            </div>
          </div>
          <div className="mockTestLibraryGridTestDescription">
            {test.description}
          </div>
          <div className="mockTestLibraryGridTestTagsDiv">
            {test.hasOwnProperty("tags") &&
              test.tags !== "" &&
              test.tags !== null &&
              test.tags !== "null" && (
                <div className="mockTestLibraryGridTestTags">{test.tags}</div>
              )}
          </div>
          <div className="mockTestLibraryGridFooter">
            <Button
              size="sm"
              onClick={() => MockTestLibraryHks.handleTest(test)}
              hierarchy={{
                type: "secondaryColor",
                buttonText:
                  test.status === constants.PROGRESS_STATUS.IN_PROGRESS &&
                  test.libtype === constants.libTypeIds.CODING_CHALLENGE
                    ? "Resume test"
                    : "Take test",
              }}
            />
          </div>
        </div>
      ))}
    </div>
  );
}
