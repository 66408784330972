import constants from "../../constants";
import CompanyLogo from "./CompanyLogo";
import React, { useContext } from "react";
import ProfileAvatar from "./ProfileAvatar";
import { useLocation } from "react-router-dom";
import { getDataFromStorage } from "../../util";
import HeaderHooks from "../../Hooks/HeaderHooks";
import Overlay from "../CommonComponents/Overlay";
import ReportHooks from "../../Hooks/ReportHooks";
import AlertContext from "../../context/AlertContext";
import CodingHeader from "../Student/CodingPage/CodingHeader";
import HeaderRightNavSvgIcon from "../../Svg/HeaderRightNavSvgIcon";
import StudentTestPageProvider from "../../context/StudentTestPageContext";
import CodingPageLearnerSideBreadCrumbs from "../../Hooks/CodingPage/CodingPageLearnerSideBreadCrumbs";

export default function Header(Props) {
  const Alert = useContext(AlertContext);

  const { hideCompanyName } = HeaderHooks();
  const { capitalizeFirstLetterOfEachWord } = ReportHooks();

  const { pathname } = useLocation();

  return (
    <div
      className={
        pathname === "/registration"
          ? "headerDivForRegistration"
          : pathname === "/sandpack"
          ? "headerDivSandPack"
          : Props.component === "leftNav" || pathname === "/ainterview"
          ? "headerDivLeftNav"
          : "headerDiv"
      }
    >
      <div className="headerDivLogo">
        {Props.component === "leftNav" && !Props.collapsed ? (
          ""
        ) : (
          <div className="headerDivLogoInnerDiv">
            <Overlay
              component={<CompanyLogo hideCompanyName={hideCompanyName()} />}
              title={
                Alert.isAdmin && (
                  <div>
                    Batch Name :{" "}
                    {capitalizeFirstLetterOfEachWord(
                      localStorage.getItem("reportLpName")
                    )}
                    <div>
                      LearningPath Name :{" "}
                      {capitalizeFirstLetterOfEachWord(
                        localStorage.getItem("reportBatchName")
                      )}
                    </div>
                  </div>
                )
              }
            />
            {hideCompanyName() && <CodingPageLearnerSideBreadCrumbs />}
          </div>
        )}
      </div>

      {pathname === "/mcq" ||
      pathname === "/coding" ||
      pathname === "/ainterview" ||
      pathname === "/assignment" ? (
        <StudentTestPageProvider>
          <CodingHeader />
        </StudentTestPageProvider>
      ) : (
        ((!pathname.includes("profile") && pathname !== "/sandpack") ||
          localStorage.getItem("batchType") ===
            constants.BATCH_TYPE.TESTING) && (
          <div className="headerDivBadges">
            {getDataFromStorage("role") === constants.Roles.learner &&
              parseInt(localStorage.getItem("batchType")) !==
                constants.BATCH_TYPE.TESTING && <HeaderRightNavSvgIcon />}
            <ProfileAvatar />
          </div>
        )
      )}
    </div>
  );
}
