import React from "react";

export default function InterviewHooks() {
  let data = {
    resultCode: 1000,
    data: {
      testid: 1464,
      videoqn: 1,
      deducted: true,
      duration: 60000,
      listview: 0,
      answer: [],
      sections: [
        {
          qnconfig: [
            {
              marks: 1,
              qncount: 1,
              topicid: 1,
              fixedqnid: "284,287,288,290,292",
              libtypeid: 3,
              qnduration: 60,
              targetscore: 1,
              complexityid: 1,
              fixedqncount: 5,
              testconfigid: 4015,
            },
          ],
          sectionid: 1479,
          sectionname: "HTML Video Assessment",
          questions: [
            {
              id: 290,
              name: "Explain <form> tag and its important attributes",
              links: [],
              topicid: 1,
              datatype: 2,
              libtypeid: 6,
              description: null,
              complexityid: 1,
              targetscore: 1,
              qnduration: 60000,
              testconfigid: 4015,
              isFixedQn: true,
            },
            {
              id: 287,
              name: "What are block-level elements and inline elements?",
              links: [],
              topicid: 1,
              datatype: 2,
              libtypeid: 6,
              description: null,
              complexityid: 1,
              targetscore: 1,
              qnduration: 60,
              testconfigid: 4015,
              isFixedQn: true,
            },
            {
              id: 292,
              name: "What are the HTML5 features you know?",
              links: [],
              topicid: 1,
              datatype: 2,
              libtypeid: 6,
              description: null,
              complexityid: 1,
              targetscore: 1,
              qnduration: 60,
              testconfigid: 4015,
              isFixedQn: true,
            },
            {
              id: 284,
              name: "Explain Div and Span",
              links: [],
              topicid: 1,
              datatype: 2,
              libtypeid: 6,
              description: null,
              complexityid: 1,
              targetscore: 1,
              qnduration: 60,
              testconfigid: 4015,
              isFixedQn: true,
            },
            {
              id: 288,
              name: "Why do we need to use <p> when we have <br>?",
              links: [],
              topicid: 1,
              datatype: 2,
              libtypeid: 6,
              description: null,
              complexityid: 1,
              targetscore: 1,
              qnduration: 60,
              testconfigid: 4015,
              isFixedQn: true,
            },
            {
              id: 293,
              name: "What is an iframe?",
              links: [],
              topicid: 1,
              datatype: 2,
              libtypeid: 6,
              description: null,
              complexityid: 1,
              targetscore: 1,
              qnduration: 60,
              testconfigid: 4015,
              isFixedQn: false,
            },
          ],
        },
      ],
      testmode: 2,
      testname: "HTML Video Assessment",
      lmtid: 287,
      lmpid: 343,
    },
  };

  return { data };
}
