import constants from "../../../constants";
import { useParams } from "react-router-dom";
import Search from "../../CommonComponents/Search";
import AlertContext from "../../../context/AlertContext";
import IconButton from "../../CommonComponents/IconButton";
import useAxiosPrivate from "../../../Hooks/useAxiosPrivate";
import React, {
  Fragment,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import CodingViewHooks from "../../../Hooks/CodingPage/CodingViewHooks";
import Loading from "../../Notification-Loading/Loading/Loading";
import { getDataFromStorage, MANAGEMENTROLES } from "../../../util";
import Table from "../../CommonComponents/Table";
import Button from "../../Button";
import CcSecQnsUtils from "./CcSecQnsUtils";
import ccSecQnsUtils from "./ccSecQnsUtils.json";
import Tooltip from "../../CommonComponents/Tooltip";
import { useDebouncedValue } from "../../../Hooks/useDebounceValue";

const CcSecQuestions = () => {
  const {
    setIsLoaded,
    isLoaded,
    setShowNotify,
    setTestData,
    setCodingTestData,
    setTopic,
    setShowInstruction,
    navigate,
    setStatus,
  } = useContext(AlertContext);

  const { secid } = useParams();
  const axios = useAxiosPrivate();
  const { viewCode } = CodingViewHooks();

  const tableRef = useRef(null);

  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [hasMore, setHasMore] = useState(true);
  const [searchValue, setSearchValue] = useState("");
  const [showFilter, setShowFilter] = useState(false);
  const [secQuestions, setSecQuestions] = useState([]);
  const [qnFilterCount, setQnFilterCount] = useState({});
  const [complexityId, setComplexityId] = useState(null);
  // const [debouncedSearchValue, setDebouncedSearchValue] = useState("");
  const [openedRowId, setOpenedRowId] = useState(null);
  const [qnsFilterValue, setQnsFilterValue] = useState({
    qns: true,
    attem: false,
    solv: false,
    first: false,
  });
  const [activeCard, setActiveCard] = useState(null);

  const debouncedSearchValue = useDebouncedValue(searchValue, 2000);

  function getQueryString(obj) {
    const trueKey = Object.keys(obj).find((key) => obj[key] === true);
    return trueKey ? `${trueKey}=true` : "";
  }

  const calculatePageSize = () => {
    const viewportHeight = window.innerHeight;
    const itemHeight = 48;
    return Math.floor(viewportHeight / itemHeight);
  };

  useEffect(() => {
    const handleResize = () => {
      setPageSize(calculatePageSize());
    };

    handleResize();
    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    const fetchSecQuestions = async () => {
      // const pageSize = 10;

      if (!hasMore) return;

      setIsLoaded(true);

      try {
        const response = await axios.get(
          `node/learner/cc/section/${secid}/question?page=${page}&limit=${pageSize}&cid=${complexityId}&name=${debouncedSearchValue}&${getQueryString(
            qnsFilterValue
          )}`,
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );

        if (response.data.resultCode === constants.RESULT_STATUS.SUCCESS) {
          const data = response.data.data.questions || [];

          setSecQuestions((prev) => {
            const questions = page === 1 ? data : [...prev, ...data];
            return questions.map((item, index) => ({
              sno: index + 1,
              ...item,
            }));
          });
          setQnFilterCount({
            attemptCount: response.data.data.attemptCount ?? 0,
            totalCount: response.data.data.totalCount ?? 0,
            firstCount: response.data.data.firstCount ?? 0,
            solvedCount: {
              totalCount: response.data.data.solvedCount.reduce(
                (accumulator, currentValue) =>
                  accumulator + currentValue.count ?? 0,
                0
              ),
              categoryCount: response.data.data.solvedCount,
            },
          });

          if (data.length < pageSize) setHasMore(false);
        } else {
          setShowNotify({
            show: true,
            title: "Error!",
            msg: "Something went wrong. Please try again later or contact support.",
          });
        }
      } catch (error) {
        console.error("Error fetching questions:", error);
        setShowNotify({
          show: true,
          title: error.message.includes("403") ? "Warning!" : "Error!",
          msg: error.message.includes("403")
            ? "You have been logged-out due to inactivity. Login again."
            : "Something went wrong. Please try again later or contact support.",
          isUnAuth: error.message.includes("403"),
        });
        setHasMore(false);
      } finally {
        setIsLoaded(false);
      }
    };

    if (Number.isInteger(Number(secid))) fetchSecQuestions();
  }, [
    page,
    secid,
    axios,
    hasMore,
    complexityId,
    setIsLoaded,
    setShowNotify,
    debouncedSearchValue,
    qnsFilterValue,
    pageSize,
  ]);

  useEffect(() => {
    setPage(1);
    setHasMore(true);
    setSecQuestions([]);
  }, [complexityId, debouncedSearchValue, qnsFilterValue]);

  const handleScroll = () => {
    if (!tableRef.current || isLoaded || !hasMore) return;

    const { scrollTop, scrollHeight, clientHeight } = tableRef.current;

    if (scrollTop + clientHeight >= scrollHeight - 10) {
      setPage((prev) => prev + 1);
    }
  };

  const prepareTestData = (data, overrides = {}) => {
    return {
      ...data,
      single: true,
      instruction: null,
      testmode: constants.MODE.TEST_MODE,
      status: constants.QUESTION_STATUS.ASSIGNED,
      proctored: constants.PROCTORING_MODE.TAB_CAM,
      testtype: constants.libTypeIds.CODING_CHALLENGE,
      configdata: {
        languageid: data.languageid,
      },
      ...overrides,
    };
  };

  const takeTest = (event, data) => {
    event.preventDefault();
    const testData = prepareTestData(data);
    setTestData(testData);
    setCodingTestData(testData);
    setTopic({ id: testData.secid, name: testData.secname });
    setShowInstruction(true);
    setStatus(constants.QUESTION_STATUS.ASSIGNED);
    navigate("/instruction");
  };

  const viewTest = (data) => {
    const testData = prepareTestData(data, {
      qndata: [
        {
          status: constants.QUESTION_STATUS.SUBMITTED,
        },
      ],
    });

    setTestData(testData);
    setCodingTestData(testData);
    setTopic({ id: testData.secid, name: testData.secname });
    viewCode(testData);
  };

  const columns = [
    {
      key: "sno",
      title: "S.No",
      className: "serialNo",
    },
    {
      key: "title",
      title: "Question",
      className: "title",
    },
    {
      key: "complexityid",
      title: "Difficulty",
      className: "difficulty",
    },
  ];

  const renderCustomRow = (row, rowIndex, columns) => (
    <Fragment key={rowIndex}>
      <tr
        key={rowIndex}
        className={`tableBodyRow ${openedRowId === row.sno ? "opened" : ""}`}
        id={`${rowIndex % 2 === 0 ? "even" : "odd"}`}
      >
        <td className={`tableData sno serialNo`}>{row.sno}</td>
        <td className={`tableData title`}>
          <div className="ccSecQnsTableBodyTitleAndStatusSec">
            <p className="ccSecQnsTableBodyTitle">{row.title}</p>
            {row.lccid && (
              <Tooltip
                position="left"
                text={
                  row.actualscore === row.targetscore
                    ? "Solved"
                    : row.attempteddata?.length
                    ? "Attempted"
                    : ""
                }
              >
                <div
                  className={`ccSecQnsTableBodyStatus ${
                    row.actualscore === row.targetscore
                      ? "solved"
                      : row.attempteddata?.length
                      ? "attempted"
                      : ""
                  }`}
                >
                  <svg
                    width="12"
                    height="12"
                    viewBox="0 0 12 12"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M10 3L4.5 8.5L2 6"
                      stroke="#667085"
                      strokeWidth="1.6"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </div>
              </Tooltip>
            )}
          </div>
        </td>
        <td className={`tableData difficulty`}>
          <div className="ccSecQnsTableBodyDifficultySec">
            <p className={row?.cname?.toLowerCase()}>
              {row.complexityid === constants.complexityid.HARD
                ? "Hard"
                : row.cname}
            </p>
            <div
              className={`ccSecQnsTableBodyDifficultyUtils ${
                row.sno === openedRowId ? "opened" : ""
              }`}
            >
              <Button
                size="sm"
                hierarchy={{ type: "secondaryGrey", buttonText: "Solve" }}
                onClick={(event) => takeTest(event, row)}
              />
              {JSON.parse(row?.attempteddata ?? "[]").length ? (
                <IconButton
                  children={
                    <svg
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M5 7.5L10 12.5L15 7.5"
                        stroke="#667085"
                        strokeWidth="1.66667"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  }
                  size="sm"
                  hierarchy="tertiaryGrey"
                  onClick={() =>
                    setOpenedRowId(openedRowId === row.sno ? null : row.sno)
                  }
                />
              ) : null}
            </div>
          </div>
        </td>
      </tr>
      {openedRowId === row.sno && (
        <tr
          className={`tableBodyRow expandRow`}
          id={`${rowIndex % 2 === 0 ? "even" : "odd"}`}
        >
          <td className="tableData" colSpan={columns.length}>
            {JSON.parse(row?.attempteddata ?? "[]")?.length ? (
              <div
                className={`ccSecQnsTableBodyAttemptSec ${
                  row.sno === openedRowId ? "opened" : ""
                }`}
              >
                {JSON.parse(row?.attempteddata ?? "[]")?.map(
                  (attempt, index) => (
                    <div
                      className="ccSecQnsTableBodyAttempt"
                      key={index}
                      onClick={() =>
                        viewTest({ ...row, lccid: attempt.attempt_id })
                      }
                    >
                      <p className="ccSecQnsTableBodyAttemptCount">
                        Attempt {index + 1}
                      </p>
                      <p className="ccSecQnsTableBodyAttemptScoreSec">
                        Score :{" "}
                        <span className="ccSecQnsTableBodyAttemptScore">
                          {attempt.actualscore}/{row.targetscore}
                        </span>
                      </p>
                    </div>
                  )
                )}
              </div>
            ) : null}
          </td>
        </tr>
      )}
    </Fragment>
  );

  const onClickFilter = (value) => {
    setQnsFilterValue((prev) =>
      Object.keys(prev).reduce((acc, key) => {
        acc[key] = key === value;
        return acc;
      }, {})
    );
  };

  return (
    <div className="ccSecQnsContainer ccContainer">
      <div className="ccSecQnsContainerSec">
        <div className="ccSecQnsHeadUtilsContainer">
          <div className="ccSecQnsHeaderContainer">
            <div className="ccSecQnsHeaderLeftSec">
              {/* <div
                className="ccSecQnsHeader"
                onClick={() => Alert.navigate("/main/cc")}
              >
                Coding Challenges
              </div>
              <svg
                width="8"
                height="10"
                fill="none"
                viewBox="0 0 8 10"
                style={{ alignSelf: "center" }}
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill="#667085"
                  d="M0.24 0.24H3.12L7.72 4.78L3.12 9.32H0.24L4.86 4.78L0.24 0.24Z"
                />
              </svg> */}
              <div className="ccSecQnsHeaderSecName">
                {sessionStorage.getItem("ccSecName")}
              </div>
            </div>
            <div className="ccSecQnsHeaderRightSec">
              <div
                className="ccSecQnsHeaderFilter"
                onMouseOver={() => setShowFilter(true)}
                onMouseLeave={() => setShowFilter(false)}
              >
                <IconButton
                  hierarchy={showFilter ? "secondaryGrey" : "tertiaryGrey"}
                >
                  <svg
                    width="20"
                    height="20"
                    fill="none"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      stroke="#667085"
                      strokeWidth="1.66667"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M5 10H15M2.5 5H17.5M7.5 15H12.5"
                    />
                  </svg>
                </IconButton>
                {showFilter && (
                  <DifficultyFilter
                    setComplexityId={setComplexityId}
                    complexityId={complexityId}
                  />
                )}
              </div>
              <Search
                value={searchValue}
                onChange={(event) => setSearchValue(event.target.value)}
              />
            </div>
          </div>
          <div className="ccSecQnsUtilsContainer">
            {ccSecQnsUtils.map((item) => (
              <CcSecQnsUtils
                item={item}
                key={item.key}
                count={qnFilterCount[item.key]}
                onClick={onClickFilter}
                activeCard={activeCard}
                setActiveCard={setActiveCard}
              />
            ))}
          </div>
        </div>
        <div
          ref={tableRef}
          onScroll={handleScroll}
          className="ccSecQnsTableContainer"
        >
          <Table
            columns={columns}
            data={secQuestions}
            renderRow={renderCustomRow}
          />
          {/* {!hasMore && !Alert.isLoaded && <p>No more data</p>} */}
        </div>
      </div>
      {isLoaded && MANAGEMENTROLES.includes(getDataFromStorage("role")) && (
        <Loading />
      )}
    </div>
  );
};

export default CcSecQuestions;

const DifficultyFilter = ({ setComplexityId, complexityId }) => {
  const renderTickIcon = (isSelected) =>
    isSelected && (
      <svg
        className="inputDropdownMenuItemIcon tickIcon"
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M16.6668 5L7.50016 14.1667L3.3335 10"
          stroke="#F55533"
          strokeWidth="1.66667"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    );

  return (
    <ul className="difficultyFilterContainer">
      <li
        className={`difficultyItem all ${
          complexityId === null ? "selected" : ""
        }`}
        onClick={() => setComplexityId(null)}
      >
        All
        {renderTickIcon(complexityId === null)}
      </li>
      {Object.entries(constants.complexityid).map(([label, id]) => (
        <li
          key={id}
          className={`difficultyItem ${label.toLowerCase()} ${
            complexityId === id ? "selected" : ""
          }`}
          onClick={() => setComplexityId(id)}
        >
          {label.toLowerCase()}
          {renderTickIcon(complexityId === id)}
        </li>
      ))}
    </ul>
  );
};
