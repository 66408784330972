import React from "react";

export default function InfoSvg(Props) {
  return (
    <>
      {Props.component === "notify" ||
      Props.component === "rejectForAssignment" ? (
        <svg
          width="56"
          height="56"
          fill="none"
          viewBox="0 0 56 56"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect x="4" y="4" width="48" height="48" rx="24" fill="#E0F2FE" />
          <rect
            x="4"
            y="4"
            rx="24"
            width="48"
            height="48"
            strokeWidth="8"
            stroke="#EFF8FF"
          />
          <path
            strokeWidth="2"
            stroke="#1570EF"
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M28 32V28M28 24H28.01M38 28C38 33.5228 33.5228 38 28 38C22.4772 38 18 33.5228 18 28C18 22.4772 22.4772 18 28 18C33.5228 18 38 22.4772 38 28Z"
          />
        </svg>
      ) : (
        <svg
          width={Props.pinned? "16" :"20"}
          height={Props.pinned? "16" :"20"}
          fill="none"
          viewBox="0 0 20 20"
          onClick={Props.onClick}
          onMouseEnter={Props.onClick}
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clip-path="url(#clip0_1929_16929)">
            <path
              stroke={ Props.pinned ? "#F04438" :"#667085"}
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M9.99935 13.3332V9.99984M9.99935 6.6665H10.0077M18.3327 9.99984C18.3327 14.6022 14.6017 18.3332 9.99935 18.3332C5.39698 18.3332 1.66602 14.6022 1.66602 9.99984C1.66602 5.39746 5.39698 1.6665 9.99935 1.6665C14.6017 1.6665 18.3327 5.39746 18.3327 9.99984Z"
            />
          </g>
          <defs>
            <clipPath id="clip0_1929_16929">
              <rect width="20" height="20" fill="white" />
            </clipPath>
          </defs>
        </svg>
      )}
    </>
  );
}
