import React, { Fragment } from "react";
import constants from "../../../constants";

const CcSecQnsUtils = ({ item, count, onClick, activeCard, setActiveCard }) => {
  return (
    <div
      key={item.key}
      className={`ccSecQnsUtilContainer ${item.key} ${
        activeCard !== null && activeCard !== item.key ? "blur" : ""
      }`}
      style={item.style}
      // onMouseEnter={() => setActiveCard(item.key)}
      // onMouseLeave={()=>setActiveCard()}
      onClick={() => {
        onClick(item.filValue);
        setActiveCard(item.key);
      }}
    >
      <div className="ccSecQnsUtilTopSec">
        <p className="ccSecQnsUtilTitle">{item.title}</p>
        <div
          dangerouslySetInnerHTML={{
            __html: item.icon,
          }}
        />
      </div>
      <div className="ccSecQnsUtilBottomSec">
        <p className="ccSecQnsUtilCount">
          {typeof count === "object" ? count.totalCount : count}
        </p>
        {item.showCategoryCount ? (
          <Fragment>
            <Category cateCountData={count?.categoryCount} />
          </Fragment>
        ) : (
          <div className="categoryContainer"></div>
        )}
      </div>
    </div>
  );
};

export default CcSecQnsUtils;

const Category = ({ cateCountData = [] }) => {
  const getCategoryCount = (category) => {
    const data = cateCountData.filter(
      (item) =>
        item?.name?.toLowerCase() ===
        (category?.toLowerCase() === "hard"
          ? "difficult"
          : category?.toLowerCase())
    );
    if (data.length) return String(data[0].count ?? 0);
    return String(0);
  };

  return (
    <div className="categoryContainer">
      {Object.entries(constants.complexityid).map(([key, value], index) => (
        <Fragment key={index}>
          <div key={index} className={`categoryItem ${key.toLowerCase()}`}>
            {getCategoryCount(key)} <br />
            <span className="categoryItemName">{key.toLowerCase()}</span>
          </div>
          {index !== Object.entries(constants.complexityid).length - 1 && (
            <hr />
          )}
        </Fragment>
      ))}
    </div>
  );
};
