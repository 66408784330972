import React from "react";
import PropTypes from "prop-types";

const Table = ({ columns = [], data = [], onRowClick, renderRow }) => {
  const onClickRow = (row) => {
    if (onRowClick) onRowClick(row);
  };

  return (
    <div className="tableContainer">
      <table className="tableSec">
        <thead>
          <tr className="tableHeadRow">
            {columns.map((col, colIndex) => (
              <th
                key={colIndex}
                className={`tableHead ${col.key} ${col.className}`}
              >
                {col.title}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {data.map((row, rowIndex) => {
            if (renderRow) return renderRow(row, rowIndex, columns);
            return (
              <tr
                key={rowIndex}
                className="tableBodyRow"
                onClick={() => onClickRow(row)}
                tabIndex={0}
                onKeyDown={(e) => {
                  if (e.key === "Enter" || e.key === " ") {
                    onClickRow(row);
                  }
                }}
              >
                {columns.map((col, colIndex) => (
                  <td
                    key={colIndex}
                    className={`tableData ${col.key} ${col.className}`}
                  >
                    {col.render ? col.render(row[col.key], row) : row[col.key]}
                  </td>
                ))}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

Table.propTypes = {
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      render: PropTypes.func,
      className: PropTypes.string,
    })
  ).isRequired,
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default Table;
