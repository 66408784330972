import React, { useContext } from "react";
import AngleRightSvg from "../../Svg/AngleRightSvg";
import AlertContext from "../../context/AlertContext";
import StudentList from "../../Components/StudentList";
import MultiLevelDropDown from "../../Components/MultiLevelDropDown";

export default function CodingPageLearnerSideBreadCrumbs() {
  const Alert = useContext(AlertContext);

  const { module, isAdmin, testData } = useContext(AlertContext);

  return (
    <div className="codingPageLearnerSideBreadCrumbsMainContainer">
      {!Alert.testData.hasOwnProperty("lccid") && (
        <>
          <span className="codingPageLearnerSideBreadCrumbsSeparator">|</span>
          <div className="codingPageLearnerSideBreadCrumbsDiv">
            {!isAdmin && (
              <>
                <span className="codingPageLearnerSideBreadCrumbsDivModuleName">
                  {Object.keys(module).length !== 0
                    ? module.name
                    : testData.testname}
                </span>
                <AngleRightSvg component="codingPageLearnerSideBreadCrumbs" />
              </>
            )}
            <MultiLevelDropDown />
          </div>
        </>
      )}
      {isAdmin && (
        <>
          <span className="codingPageLearnerSideBreadCrumbsSeparatorForStudentList">
            |
          </span>
          <StudentList />
        </>
      )}
    </div>
  );
}
