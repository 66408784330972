import ReactQuill from "react-quill";
import constants from "../../../constants";
import BackSvgIcon from "../../../Svg/BackSvgIcon";
import AlertContext from "../../../context/AlertContext";
import React, { useContext, useEffect, useState } from "react";
import ComplexityLabels from "../../CommonComponents/ComplexityLabels";

export default function CodingProblem() {
  const Alert = useContext(AlertContext);

  const handleBack = () => {
    Alert.setCodeDetails({});
    Alert.setFromCodeEditor(true);

    if (document.fullscreenElement) {
      document.exitFullscreen();
    }

    if (Alert.isAdmin) {
      localStorage.removeItem("splitPos");
      localStorage.removeItem("splitWidthPos");
      Alert.setLpId(Alert.lpId);
      Alert.navigate(`batches/${sessionStorage.getItem("abatchid")}/report#`);
    } else if (Alert.testData.mockuse) {
      Alert.navigate("main/mock/results");
    } else if (Alert.testData.single) {
      Alert.navigate(`/main/cc/section/${Alert.testData.secid}`);
    } else {
      Alert.setLpData({});
      localStorage.removeItem("splitPos");
      localStorage.removeItem("splitWidthPos");
      Alert.navigate("/home");
    }
  };

  const [learnerTestCases, setLearnerTestCases] = useState([]);

  useEffect(() => {
    const testCases =
      Alert.codeDetails.testcases !== null
        ? JSON.parse(
            Alert.codeDetails.testcases !== null
              ? Alert.codeDetails.testcases
              : []
          ).filter((testcase) => testcase.isexample === 1)
        : [];

    setLearnerTestCases(testCases);
  }, [Alert.codeDetails]);

  return (
    <div className="codingPageProblemMainContainer">
      {(Alert.isAdmin ||
        (!Alert.isAdmin &&
          (Alert.status === constants.QUESTION_STATUS.SUBMITTED ||
            Alert.status === constants.QUESTION_STATUS.TO_BE_EVALUATED ||
            Alert.testData.testmode === constants.MODE.PRACTICE_MODE))) && (
        <BackSvgIcon component="codingProblem" onClick={handleBack} />
      )}
      <div className="codingPageProblemHeader">
        <div className="codingPageProblemQuestionDiv">
          <div className="codingPageProblemQuestionName">
            {Alert.codeDetails?.name}
          </div>
          <ComplexityLabels complexityid={Alert.codeDetails?.complexityid} />
        </div>
        <ReactQuill
          readOnly={true}
          value={Alert.codeDetails?.description}
          className="codingPageProblemReactQuill"
        />
      </div>
      <div className="codingPageProblemTestCasesDiv">
        <div className="codingPageProblemTestCasesDivHeader">
          Go through the following examples below to understand the problem
          better:
        </div>
        <div className="codingPageProblemTestCasesDivBody">
          {Alert.codeDetails.istestcases === constants.ISNOTESTCASES.FALSE && (
            <>
              {learnerTestCases.length !== 0 &&
                learnerTestCases.map((testcase, index) => (
                  <div
                    key={index}
                    className="codingPageProblemTestCasesDivBodyContainer"
                  >
                    <div className="codingPageProblemTestCasesDivBodyContainerExampleDiv">
                      {" "}
                      Example {index + 1} :
                    </div>
                    <div className="codingPageProblemTestCasesDivBodyContainerInputDiv">
                      <div className="codingPageProblemTestCasesDivBodyContainerInputTitleDiv">
                        {testcase?.input !== "" &&
                          testcase?.input !== null &&
                          testcase?.input !== undefined &&
                          testcase.input.split("\n").map((input, ind) => (
                            <div
                              className="codingPageProblemTestCasesDivBodyContainerInputTitle"
                              key={ind}
                            >
                              <p className="codingPageProblemTestCasesDivBodyContainerInputVariable">
                                {Alert.isInputVariable.current
                                  ? Alert.inputVariable[ind] + " : "
                                  : "Input" + (ind + 1) + " : "}
                              </p>
                              <p className="codingPageProblemTestCasesDivBodyContainerInputValue">
                                {input}
                              </p>
                            </div>
                          ))}
                      </div>
                    </div>
                    <div className="codingPageProblemTestCasesDivBodyContainerOutputVariable">
                      Output {index + 1} :
                    </div>
                    <div className="codingPageProblemTestCasesDivBodyContainerOutputValue">
                      {testcase?.output !== "" &&
                        testcase?.output !== null &&
                        testcase?.output !== undefined &&
                        testcase.output}
                    </div>
                  </div>
                ))}
            </>
          )}
        </div>
      </div>
    </div>
  );
}
