import React, { useContext } from "react";
import { useLocation } from "react-router-dom";
import AlertContext from "../../context/AlertContext";

export default function BatchDropDown(props) {
  const Alert = useContext(AlertContext);

  const { pathname } = useLocation();

  return (
    <div className="dashboard-batch-dropdown-div">
      <div className="naviagtorfortestscorestimespent">
        <div className="testscorestimespentcontainer">
          <span
            onClick={() => {
              props.setOption(1);
              Alert.navigate("main/dashboard");
            }}
            className={
              pathname === "/main/dashboard"
                ? "optionsfornavigatecurrent"
                : "optionsfornavigate"
            }
          >
            <span
              className={
                pathname === "/main/dashboard"
                  ? "optiontextfornavigatecurrent"
                  : "optiontextfornavigate"
              }
            >
              Overview
            </span>
          </span>
          <span
            onClick={() => {
              Alert.navigate("main/dashboard/testresults");
            }}
            className={
              pathname.includes("testresults")
                ? "optionsfornavigatecurrent"
                : "optionsfornavigate"
            }
          >
            <span
              className={
                pathname.includes("testresults")
                  ? "optiontextfornavigatecurrent"
                  : "optiontextfornavigate"
              }
            >
              Test Results
            </span>
          </span>
          <span
            onClick={() => {
              Alert.navigate("main/dashboard/timespent");
            }}
            className={
              pathname.includes("timespent")
                ? "optionsfornavigatecurrent"
                : "optionsfornavigate"
            }
          >
            <span
              className={
                pathname.includes("timespent")
                  ? "optiontextfornavigatecurrent"
                  : "optiontextfornavigate"
              }
            >
              Time Spent{" "}
            </span>
          </span>
        </div>
      </div>
    </div>
  );
}
