import React from "react";

const ModalBody = ({ header, content, footer }) => {
  return (
    <div className="modalBodyContainer">
      <div className="modalBodyContent">
        {header && <header className="modalBodyHeader">{header}</header>}
        {content && <main className="modalBodyMain">{content}</main>}
      </div>
      {footer && <footer className="modalBodyFooter">{footer}</footer>}
    </div>
  );
};

export default ModalBody;
