import React, { useContext, useEffect, useRef, useState } from "react";
// import Modal from "../../CommonComponents/Modal";
// import ModalBody from "../../CommonComponents/ModalBody";
import { Modal } from "@mui/material";
import IssueForm from "./IssueForm";
import Button from "../../Button";
import Table from "../../CommonComponents/Table";
import AlertContext from "../../../context/AlertContext";
import constants from "../../../constants";
import useAxiosPrivate from "../../../Hooks/useAxiosPrivate";
import useUploadLargeFileToS3 from "../../../Hooks/useUploadLargeFileToS3";
import Toast from "../../CommonComponents/Toast";
import IconButton from "../../CommonComponents/IconButton";

const GetIssueStatus = ({ value }) =>
  Object.entries(constants.ISSUE_STATUS).map(
    (data, index) =>
      data[1] === value && (
        <p key={index} className={data[0]?.toLowerCase()}>
          {data[0]?.toLowerCase()}
        </p>
      )
  );

const columns = [
    { key: "sno", title: "S.No", className: "serialNo" },
    { key: "typename", title: "Issue Type", className: "issueType" },
    { key: "bname", title: "Batch", className: "batch" },
    {
      key: "title",
      title: "Issue Title",
      className: "issueTitle",
    },
    {
      key: "description",
      title: "Description",
      className: "description",
    },
    {
      key: "status",
      title: "Status",
      className: "status",
      render: (value, row) => <GetIssueStatus value={value} />,
    },
  ],
  initialDetails = {
    title: "",
    type: null,
    bid: null,
    description: "",
    attachment: null,
  };

const RaisedIssueTable = () => {
  const { isLoaded, setShowNotify, setIsLoaded } = useContext(AlertContext);
  const axios = useAxiosPrivate();
  const { getS3FileAsFile } = useUploadLargeFileToS3();

  const [hasMore, setHasMore] = useState(false);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [issues, setIssues] = useState([]);
  const [showForm, setShowForm] = useState(false);
  const [details, setDetails] = useState(initialDetails);
  const [selectedBatch, setSelectedBatch] = useState({});
  const [selectedType, setSelectedType] = useState({});
  const [isSuccess, setIsSuccess] = useState(false);
  const tableRef = useRef(null);

  const isEdit = details?.id && Number.isInteger(Number(details?.id));

  const calculatePageSize = () => {
    const viewportHeight = window.innerHeight;
    const itemHeight = 48;
    return Math.floor(viewportHeight / itemHeight);
  };

  useEffect(() => {
    const handleResize = () => {
      setPageSize(calculatePageSize());
    };

    handleResize();
    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    const fetchRaisedIssues = async () => {
      // const pageSize = 10;
      if (!hasMore) return;
      try {
        setIsLoaded(true);
        const response = await axios.get(
          `node/learner/issue?page=${page}&limit=${pageSize}&isdoubt=0`,
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
        if (response.data.resultCode === constants.RESULT_STATUS.SUCCESS) {
          const data = response.data.data ?? [];
          setIssues([
            ...data.map((item, index) => ({
              sno: index + 1,
              type: item.category,
              title: item.summary,
              batch: item.batchname,
              ...item,
            })),
          ]);
          if (data.length < pageSize) setHasMore(false);
        } else {
          setShowNotify({
            show: true,
            title: "Error!",
            msg:
              response.data.msg ??
              "Something went wrong. Please try again later or contact support.",
          });
        }
      } catch (error) {
        setShowNotify({
          show: true,
          title: error.message.includes("403") ? "Warning!" : "Error!",
          msg: error.message.includes("403")
            ? "You have been logged-out due to inactivity. Login again."
            : error.message ??
              "Something went wrong. Please try again later or contact support.",
          isUnAuth: error.message.includes("403"),
        });
      } finally {
        setIsLoaded(false);
      }
    };
    if (!showForm) fetchRaisedIssues();
  }, [axios, setIsLoaded, setShowNotify, showForm, hasMore, page, pageSize]);

  useEffect(() => {
    setHasMore(true);
    setPage(1);
  }, [showForm]);

  const onClickAddBtn = () => {
    setShowForm(true);
    setDetails(initialDetails);
    setSelectedBatch({});
  };

  const onCloseForm = () => {
    setShowForm(false);
    setDetails(initialDetails);
    setSelectedBatch({});
    setSelectedType({});
  };

  const onRowClick = async (row) => {
    let attachmentPath = null;
    if (row.attachment) {
      attachmentPath = await getS3FileAsFile(
        row.attachment,
        process.env.REACT_APP_S3_WEBSITE_HOST_BUCKET_NAME
      );
    }
    setDetails({
      id: row.id,
      title: row.title,
      type: row.type,
      bid: row.bid,
      description: row.description,
      attachment: attachmentPath,
      status: row.status,
    });
    setSelectedBatch({
      label: row.bname,
      value: row.bname,
      id: row.bid,
    });
    setSelectedType({
      id: row.type,
      value: row.typename,
      label: row.typename,
    });
    setShowForm(true);
  };

  const handleScroll = () => {
    if (!tableRef.current || isLoaded || !hasMore) return;

    const { scrollTop, scrollHeight, clientHeight } = tableRef.current;

    if (scrollTop + clientHeight >= scrollHeight - 10) {
      setPage((prev) => prev + 1);
    }
  };

  return (
    <div className="raisedIssueContainer">
      <div className="raisedIssueHeaderSec">
        <div className="">
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M12 8V12M12 16H12.01M2 8.52274V15.4773C2 15.7218 2 15.8441 2.02763 15.9592C2.05213 16.0613 2.09253 16.1588 2.14736 16.2483C2.2092 16.3492 2.29568 16.4357 2.46863 16.6086L7.39137 21.5314C7.56432 21.7043 7.6508 21.7908 7.75172 21.8526C7.84119 21.9075 7.93873 21.9479 8.04077 21.9724C8.15586 22 8.27815 22 8.52274 22H15.4773C15.7218 22 15.8441 22 15.9592 21.9724C16.0613 21.9479 16.1588 21.9075 16.2483 21.8526C16.3492 21.7908 16.4357 21.7043 16.6086 21.5314L21.5314 16.6086C21.7043 16.4357 21.7908 16.3492 21.8526 16.2483C21.9075 16.1588 21.9479 16.0613 21.9724 15.9592C22 15.8441 22 15.7218 22 15.4773V8.52274C22 8.27815 22 8.15586 21.9724 8.04077C21.9479 7.93873 21.9075 7.84119 21.8526 7.75172C21.7908 7.6508 21.7043 7.56432 21.5314 7.39137L16.6086 2.46863C16.4357 2.29568 16.3492 2.2092 16.2483 2.14736C16.1588 2.09253 16.0613 2.05213 15.9592 2.02763C15.8441 2 15.7218 2 15.4773 2H8.52274C8.27815 2 8.15586 2 8.04077 2.02763C7.93873 2.05213 7.84119 2.09253 7.75172 2.14736C7.6508 2.2092 7.56432 2.29568 7.39137 2.46863L2.46863 7.39137C2.29568 7.56432 2.2092 7.6508 2.14736 7.75172C2.09253 7.84119 2.05213 7.93873 2.02763 8.04077C2 8.15586 2 8.27815 2 8.52274Z"
              stroke="#FF0000"
              strokeWidth="1.6"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
          <p className="raisedIssueHeader">Issues</p>
        </div>
        <Button
          size="sm"
          hierarchy={{ type: "secondaryGrey", buttonText: "Add Issue" }}
          icon={{
            type: "leading",
            icon: (
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M10.0001 4.16699V15.8337M4.16675 10.0003H15.8334"
                  stroke="#344054"
                  strokeWidth="1.67"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            ),
          }}
          onClick={onClickAddBtn}
        />
      </div>
      <Modal open={showForm} className="raisedIssueFormModalContainer">
        <div className="raisedIssueFormModal">
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <div className="modalTitle">
              {`${isEdit ? "Edit" : "New"} Issue`}{" "}
            </div>
            <div className="modalCloseIcon">
              <IconButton
                onClick={onCloseForm}
                size="sm"
                hierarchy="tertiaryGrey"
              >
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M15 5L5 15M5 5L15 15"
                    stroke="#667085"
                    strokeWidth="1.66667"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </IconButton>
            </div>
          </div>
          <IssueForm
            setShowForm={setShowForm}
            isEdit={isEdit}
            details={details}
            setDetails={setDetails}
            selectedBatch={selectedBatch}
            setSelectedBatch={setSelectedBatch}
            selectedType={selectedType}
            setSelectedType={setSelectedType}
            initialDetails={initialDetails}
            setIsSuccess={setIsSuccess}
          />
        </div>
      </Modal>
      {/* <Modal
        isOpen={showForm}
        showCloseIcon
        onClose={onCloseForm}
        title={`${isEdit ? "Edit" : "New"} Issue`}
        closeIcon={
          <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M15 5L5 15M5 5L15 15"
              stroke="#667085"
              strokeWidth="1.66667"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        }
      >
        <ModalBody
          content={
            <IssueForm
              setShowForm={setShowForm}
              isEdit={isEdit}
              details={details}
              setDetails={setDetails}
              selectedBatch={selectedBatch}
              setSelectedBatch={setSelectedBatch}
              selectedType={selectedType}
              setSelectedType={setSelectedType}
              initialDetails={initialDetails}
              setIsSuccess={setIsSuccess}
            />
          }
        />
      </Modal> */}
      <Toast
        open={isSuccess}
        icon={
          <svg
            width="46"
            height="46"
            viewBox="0 0 46 46"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect x="3" y="3" width="40" height="40" rx="20" fill="#D1FADF" />
            <rect
              x="3"
              y="3"
              width="40"
              height="40"
              rx="20"
              stroke="#ECFDF3"
              strokeWidth="6"
            />
            <g clip-path="url(#clip0_9645_95814)">
              <path
                d="M20.5001 23L22.1667 24.6667L25.9167 20.9167M19.1116 16.1822C19.7814 16.1288 20.4173 15.8654 20.9288 15.4295C22.1224 14.4124 23.8778 14.4124 25.0714 15.4295C25.5828 15.8654 26.2187 16.1288 26.8886 16.1822C28.4518 16.307 29.6931 17.5483 29.8178 19.1115C29.8713 19.7813 30.1347 20.4173 30.5706 20.9287C31.5877 22.1223 31.5877 23.8777 30.5706 25.0713C30.1347 25.5827 29.8713 26.2187 29.8178 26.8885C29.6931 28.4517 28.4518 29.693 26.8886 29.8178C26.2187 29.8712 25.5828 30.1346 25.0714 30.5705C23.8778 31.5876 22.1224 31.5876 20.9288 30.5705C20.4173 30.1346 19.7814 29.8712 19.1116 29.8178C17.5484 29.693 16.3071 28.4517 16.1823 26.8885C16.1289 26.2187 15.8655 25.5827 15.4296 25.0713C14.4125 23.8777 14.4125 22.1223 15.4296 20.9287C15.8655 20.4173 16.1289 19.7813 16.1823 19.1115C16.3071 17.5483 17.5484 16.307 19.1116 16.1822Z"
                stroke="#039855"
                strokeWidth="1.66667"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </g>
            <defs>
              <clipPath id="clip0_9645_95814">
                <rect
                  width="20"
                  height="20"
                  fill="white"
                  transform="translate(13 13)"
                />
              </clipPath>
            </defs>
          </svg>
        }
        autoHideDuration={10000}
        TransitionComponent={"Fade"}
        message={`Issue raised successfully`}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        closeIcon={
          <svg
            width="36"
            height="36"
            viewBox="0 0 36 36"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            onClick={() => setIsSuccess(false)}
          >
            <path
              d="M23 13L13 23M13 13L23 23"
              stroke="#667085"
              stroke-width="1.66667"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        }
      />
      <div
        ref={tableRef}
        onScroll={handleScroll}
        className="raisedIssueTableContainer"
      >
        <Table columns={columns} data={issues} onRowClick={onRowClick} />
      </div>
    </div>
  );
};

export default RaisedIssueTable;
