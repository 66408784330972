import React, { useState, useEffect, useContext } from "react";
import useAxiosPrivate from "../../../../Hooks/useAxiosPrivate";
import Form from "react-bootstrap/Form";
import { Editor } from "@tinymce/tinymce-react";
import AlertContext from "../../../../context/AlertContext";
import Radio from "../../../CommonComponents/Radio";
import Button from "../../../Button";
import UploadSvg from "../../../../Svg/UploadIconsvg";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { TextField } from "@mui/material";
import { getPreSignedUrl } from "../../../../util";
import constants from "../../../../constants";
import { emailBody } from "../../../../util";
import { Offcanvas } from "react-bootstrap";

function UserDetails(props) {
  const [isExistingUser, setIsExistingUser] = useState(false);
  const [userDetailByEmail, setUserDetailByEmail] = useState([]);
  const [addSingleUser, setAddSingleUser] = useState(true);
  // const [showUserFormPopup, setShowUserFormPopup] = useState(false);
  const [savebtnHide, setSavebtnHide] = useState(false);
  const { setIsLoaded, batchDetails, setBatchDetails, setShowNotify } =
    useContext(AlertContext);
  const axios = useAxiosPrivate();
  const [focusStates, setFocusStates] = useState(false);
  const [fileLoaded, setFileLoaded] = useState("");
  const [checkExistEmail, setCheckExistEmail] = useState({
    batchid: batchDetails.id,
    emailBody:
      "<p>We are glad to invite you to share the joy of learning and educating with Gradious.</p>",
    role: props.newUserInput.role,
  });

  const [userId, setUserId] = useState("");

  useEffect(() => {
    console.log("props.setNewUserInput.email", props.newUserInput?.email);
    console.log("props>>>>", props);
    setUserId(props.newUserInput?.id);
  }, []);

  const handleInputChange = (e, field) => {
    setSavebtnHide(true);
    if (field === "emailBody") {
      props.setNewUserInput({ ...props.newUserInput, [field]: e });
    } else if (field === "mobile") {
      props.setNewUserInput({ ...props.newUserInput, [field]: e });
      setUserDetailByEmail({ ...userDetailByEmail, [field]: e });
    } else {
      let target = e.target;
      let name = target.name;
      let value = target.value;

      props.setNewUserInput({ ...props.newUserInput, [name]: value });
      setUserDetailByEmail({ ...userDetailByEmail, [name]: value });
    }
  };

  const handleFileUpload = (e) => {
    e.preventDefault();
    setSavebtnHide(true);
    console.log("click");
  };

  const handleClosePopup = () => {
    props.setShowUserFormPopup(false);
    props.setNewUserInput({
      batchid: batchDetails.id,
      emailBody: emailBody,
      role: props.newUserInput.role,
    });
  };

  const handleInputEmailChange = (e) => {
    let target = e.target;
    let name = target.name;
    let value = target.value;
    setCheckExistEmail({ ...checkExistEmail, [name]: value });
    props.setNewUserInput({ ...props.newUserInput, [name]: value });
  };

  const handleCreateUser = (event) => {
    event.preventDefault();

    let role = document.uservalidateForm.role?.value;
    let emailid = document.uservalidateForm.email?.value?.trim();
    let lname = document.uservalidateForm.lastname?.value;
    let fname = document.uservalidateForm.firstname?.value;
    let college = document.uservalidateForm.college?.value;

    let newUserDetails = { ...props.newUserInput };
    console.log("newUserDetails------>", newUserDetails);
    if (role !== "") {
      if (fname !== "") {
        if (/^[A-Za-z. ]+$/.test(fname)) {
          if (/^[A-Za-z. ]+$/.test(lname) || lname === "") {
            if (emailid !== "") {
              if (/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(emailid)) {
                if (/^[A-Za-z. ]+$/.test(college) || college === "") {
                  setIsLoaded(true);
                  axios
                    .post(
                      "node/admin/batch/user/add",
                      JSON.stringify(newUserDetails),
                      {
                        headers: {
                          "Content-type": "application/json",
                        },
                      }
                    )
                    .then((res) => {
                      setIsLoaded(false);

                      if (res.data.resultCode === 1000) {
                        setShowNotify({
                          show: true,
                          title: "Info",
                          msg: res.data.data,
                        });
                        handleClosePopup();
                        // props.setAddUser(false);
                        props.refetch();
                      } else if (
                        res.data.resultCode === 2050 &&
                        res.data.msg.includes("Duplicate entry")
                      ) {
                        setShowNotify({
                          show: true,
                          title: "Warning !",
                          msg: "This email has already exists",
                        });
                      } else {
                        setIsLoaded(false);
                        setShowNotify({
                          show: true,
                          title: "Warning !",
                          msg: "Something went wrong. Please try again later.",
                        });
                      }
                    })
                    .catch((error) => {
                      setIsLoaded(false);
                      if (error.message.includes("403")) {
                        setShowNotify({
                          show: true,
                          title: "Warning !",
                          msg: "You have been logged-out due to inactivity. Login again.",
                          isUnAuth: true,
                        });
                      }
                    });
                } else {
                  setShowNotify({
                    show: true,
                    title: "Warning !",
                    msg: "You have entered invalid college name !",
                  });
                }
              } else {
                setShowNotify({
                  show: true,
                  title: "Warning !",
                  msg: "You have entered an invalid email !",
                });
              }
            } else {
              setShowNotify({
                show: true,
                title: "Warning !",
                msg: "Email can't be blank !",
              });
            }
          } else {
            setShowNotify({
              show: true,
              title: "Warning !",
              msg: "Invalid lastname !",
            });
          }
        } else {
          setShowNotify({
            show: true,
            title: "Warning !",
            msg: "Invalid firstname !",
          });
        }
      } else {
        setShowNotify({
          show: true,
          title: "Warning !",
          msg: "Please enter your firstname",
        });
      }
    } else {
      setShowNotify({
        show: true,
        title: "Warning !",
        msg: "Please select the role",
      });
    }
    // props.setAddUser(false);
  };

  const checkEmail = (event) => {
    event.preventDefault();

    setFocusStates({ ...focusStates, [event.target.name]: false });

    if (checkExistEmail.batchid && checkExistEmail.email) {
      setIsLoaded(true);
      axios
        .post("node/admin/user/get", JSON.stringify(checkExistEmail), {
          headers: {
            "Content-type": "application/json",
          },
        })
        .then((res) => {
          setIsLoaded(false);

          if (res.data.resultCode === 1000 && res.data.data !== null) {
            setIsExistingUser(true);

            if (res.data.data.batchid === null) {
              setSavebtnHide(true);
              console.log("res.data.data++++++", res.data.data);
              setUserDetailByEmail(res.data.data);
              let notifyDetais = { show: true, title: "Warning !" };
              if (res.data.data.role !== props.newUserInput.role) {
                notifyDetais.msg = `User is ${
                  res.data.data.role
                },You cannot add as a ${
                  props.newUserInput.role === constants.Roles.hr
                    ? "Client"
                    : props.newUserInput.role
                }`;
                setUserDetailByEmail([]);
                setSavebtnHide(false);
              } else if (
                !isNaN(parseInt(userId)) &&
                checkExistEmail !== props.newUserInput.email
              ) {
                notifyDetais.msg = "This Email Already Exists. You Can't Update";
                setUserDetailByEmail([]);
                setSavebtnHide(false);
                // props.setNewUserInput({
                //   ...props.newUserInput,
                //   email: emailLastEntered,
                // });
              } else if (
                isNaN(parseInt(userId)) &&
                checkExistEmail !== props.newUserInput.email
              ) {
                notifyDetais.msg =
                  "User belongs to some other batch. if you click on the save button user will be added to this batch.";
              }
              setShowNotify(notifyDetais);
            } else if (userId !== res.data.data.id) {
              if (res.data.data.role !== props.newUserInput.role) {
                setShowNotify({
                  show: true,
                  title: "Warning !",
                  msg: `User is ${res.data.data.role},You cannot add as a learner`,
                });
                setUserDetailByEmail([]);
                setSavebtnHide(false);
              } else {
                setSavebtnHide(false);
                setUserDetailByEmail(res.data.data);
                setShowNotify({
                  show: true,
                  title: "Warning !",
                  msg: "User Already exists in this batch",
                });
              }
            } else {
              setSavebtnHide(true);
            }
          } else if (
            res.data.resultCode === 1000 &&
            res.data.msg.includes("No data")
          ) {
            setSavebtnHide(true);
            setUserDetailByEmail([]);
            setIsExistingUser(false);
          } else {
            setIsLoaded(false);
            setShowNotify({
              show: true,
              title: "Warning !",
              msg: "Something went wrong. Please try again later.",
            });
          }
        })
        .catch((err) => {
          setIsLoaded(false);
          let notifyDetais = { show: true };
          if (err.message.includes("403")) {
            setShowNotify({
              ...notifyDetais,
              title: "Warning !",
              msg: "You have been logged-out due to inactivity. Login again.",
              isUnAuth: true,
            });
            return;
          }
          setShowNotify({
            ...notifyDetais,
            title: "Error !",
            msg: err.message,
          });
        });
    } else {
      setShowNotify({
        show: true,
        title: "Warning",
        msg: "Please enter email.",
      });
    }
  };

  const handleSave = () => {
    const form = new FormData();
    form.append("csv", batchDetails.csv);
    form.append("from", "uploadusersinsidebatch");
    form.append("batchid", batchDetails.id);
    if (props.newUserInput.hasOwnProperty("emailBody")) {
      form.append("emailBody", props.newUserInput.emailBody);
    } else {
      form.append("emailBody", batchDetails.emailBody);
    }

    // setOpenUploadUserModal(true);
    setIsLoaded(true);
    axios
      .post("node/admin/batch/userviacsv", form, {
        mode: "no-cors",
        headers: {
          "Content-type": "multipart/form-data",
        },
      })
      .then((response) => {
        setIsLoaded(false);
        if (response.data.resultCode === 1000) {
          setShowNotify({
            show: true,
            title: "Info",
            msg: response.data.msg,
          });
          // setUpdatedThumbnail(batchDetails.thumbnail);
          // setIsSuccess(true);
        } else if (response.data.resultCode === 2050) {
          setShowNotify({
            show: true,
            title: "Warning !",
            msg: response.data.msg,
          });
          // setIsSuccess(true);
        } else {
          setShowNotify({
            show: true,
            title: "Error !",
            msg: "Something went wrong. Please try again later.",
          });
        }

        // inputRef.current.value = "";

        if (response.data.hasOwnProperty("filedata")) {
          const url = window.URL.createObjectURL(
            new Blob([response.data.filedata])
          );
          const link = document.createElement("a");

          link.href = url;
          link.setAttribute(
            "download",
            `${new Date().toISOString().split("T")[0]}.csv`
          );
          document.body.appendChild(link);
          link.click();
        }
      })
      .catch((error) => {
        setIsLoaded(false);
        if (error.message.includes("403")) {
          setShowNotify({
            show: true,
            title: "Warning !",
            msg: "You have been logged-out due to inactivity. Login again.",
            isUnAuth: true,
          });
        }

        if (error.data.hasOwnProperty("filedata")) {
          const url = window.URL.createObjectURL(
            new Blob([error.data.filedata])
          );
          const link = document.createElement("a");

          link.href = url;
          link.setAttribute(
            "download",
            `${new Date().toISOString().split("T")[0]}.csv`
          );
          document.body.appendChild(link);
          link.click();
        }
        // inputRef.current.value = "";
      })
      .finally(() => {
        handleClosePopup();
        props.refetch();
      });
  };

  const handleUpdateUser = (event) => {
    event.preventDefault();
    let emailid = document.uservalidateForm.email?.value?.trim();
    let lname = document.uservalidateForm.lastname?.value;
    let fname = document.uservalidateForm.firstname?.value;
    let college = document.uservalidateForm.college?.value;
    if (fname !== "") {
      if (/^[A-Za-z. ]+$/.test(fname)) {
        if (/^[A-Za-z. ]+$/.test(lname) || lname === "") {
          if (emailid !== "") {
            if (/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(emailid)) {
              if (/^[A-Za-z. ]+$/.test(college) || college === "") {
                setIsLoaded(true);
                axios
                  .put(
                    "node/admin/batch/user",
                    JSON.stringify(props.newUserInput),
                    {
                      headers: {
                        "Content-type": "application/json",
                      },
                    }
                  )
                  .then((res) => {
                    setIsLoaded(false);

                    if (res.data.resultCode === 1000) {
                      setShowNotify({
                        show: true,
                        title: "Info",
                        msg: res.data.data,
                      });
                      props.setNewUserInput({});
                      handleClosePopup();
                      props.refetch();
                    } else if (
                      res.data.resultCode === 2050 &&
                      res.data.msg.includes("Duplicate entry")
                    ) {
                      setShowNotify({
                        show: true,
                        title: "Warning !",
                        msg: "This email has already exists",
                      });
                    } else {
                      setIsLoaded(false);
                      setShowNotify({
                        show: true,
                        title: "Error !",
                        msg: "Something Went Wrong",
                      });
                    }
                  })
                  .catch((error) => {
                    setIsLoaded(false);
                    if (error.message.includes("403")) {
                      setShowNotify({
                        show: true,
                        title: "Warning !",
                        msg: "You have been logged-out due to inactivity. Login again.",
                        isUnAuth: true,
                      });
                    }
                  });
              } else {
                setShowNotify({
                  show: true,
                  title: "Warning !",
                  msg: "You have entered invalid college name !",
                });
              }
            } else {
              setShowNotify({
                show: true,
                title: "Warning !",
                msg: "You have entered an invalid email !",
              });
            }
          } else {
            setShowNotify({
              show: true,
              title: "Warning !",
              msg: "Email can't be blank !",
            });
          }
        } else {
          setShowNotify({
            show: true,
            title: "Warning !",
            msg: "Invalid lastname",
          });
        }
      } else {
        setShowNotify({
          show: true,
          title: "Warning !",
          msg: "Invalid firstname",
        });
      }
    } else {
      setShowNotify({
        show: true,
        title: "Warning !",
        msg: "Please enter your firstname",
      });
    }
  };

  const handleUploadUserfile = (event, file) => {
    if (!file.size) return;

    let reader = new FileReader();

    reader.onload = async (e) => {
      if (!e.target.result.includes("data:text/csv")) {
        setShowNotify({
          show: true,
          title: "Warning !",
          msg: "Wrong file type - CSV only.",
        });
      } else {
        setBatchDetails({
          ...batchDetails,
          csv: file,
          from: "uploadusersinsidebatch",
        });

        setFileLoaded(file.name);
        setSavebtnHide(true);
      }
    };

    reader.readAsDataURL(file);
  };

  const handleFileDownload = async (e) => {
    e.preventDefault();
    let URL = "";
    console.log("download called");
    if (process.env.REACT_APP_DEPLOYMENT === "LOCAL")
      URL = "../../../assets/files/TEMPLATE_USER.csv";
    else URL = await getPreSignedUrl("Templates/TEMPLATE_USER.csv");
    window.location.href = URL;
  };

  const handleFieldFocus = (name) => {
    setFocusStates({ ...focusStates, [name]: true });
  };

  const handleFieldBlur = (name) => {
    setFocusStates({ ...focusStates, [name]: false });
  };

  return (
    <div>
      <Offcanvas
        placement="end"
        id="UserDetailsContainer"
        show={props.showUserFormPopup}
        onHide={handleClosePopup}
      >
        <div className="addUsersTitleSection">
          <p className="adduserTitle">
            {props.newUserInput.role === constants.Roles.learner
              ? isNaN(parseInt(userId))
                ? "Add user(s)"
                : "Edit user"
              : `Add ${
                  props.newUserInput.role === constants.Roles.hr
                    ? "Client"
                    : props.newUserInput.role
                }(s)`}
          </p>
        </div>
        {isNaN(parseInt(userId)) &&
          props.newUserInput.role === constants.Roles.learner && (
            <div className="selectTypeOfadd">
              <Radio
                checked={addSingleUser}
                value={0}
                size="md"
                text="Single User"
                disabled={false}
                onChange={() => {
                  setAddSingleUser(true);
                }}
              />
              <Radio
                checked={!addSingleUser}
                value={1}
                size="md"
                text="Multiple users"
                disabled={false}
                onChange={() => {
                  setAddSingleUser(false);
                }}
              />
            </div>
          )}
        <div className="scrollableUserDetailsSection">
          <form className="addUserform" name="uservalidateForm">
            <div className="userDetails">
              {addSingleUser ? (
                <div className="inputFieldUserContainer">
                  <div className="emailContainer">
                    <TextField
                      label="Email ID"
                      id="usersEmail"
                      className="userFormField"
                      name="email"
                      variant="outlined"
                      size="small"
                      value={props.newUserInput?.email}
                      onChange={handleInputEmailChange}
                      onBlur={checkEmail}
                      InputLabelProps={{
                        shrink:
                          focusStates["email"] ||
                          Boolean(props.newUserInput?.email),
                      }}
                      InputProps={{
                        onFocus: (event) => handleFieldFocus(event.target.name),
                      }}
                      fullWidth
                    />
                  </div>

                  <div className="nameContainer">
                    <TextField
                      label="First Name"
                      id="usersFirstname"
                      className="userFormField"
                      name="firstname"
                      variant="outlined"
                      size="small"
                      value={
                        isNaN(parseInt(userId)) && isExistingUser
                          ? userDetailByEmail?.firstname
                          : props.newUserInput?.firstname
                      }
                      onChange={(e) => handleInputChange(e)}
                      InputLabelProps={{
                        shrink:
                          focusStates["firstname"] ||
                          Boolean(
                            isNaN(parseInt(userId)) && isExistingUser
                              ? userDetailByEmail?.firstname
                              : props.newUserInput?.firstname
                          ),
                      }}
                      InputProps={{
                        onFocus: (event) => handleFieldFocus(event.target.name),
                        onBlur: (event) => handleFieldBlur(event.target.name),
                      }}
                    />
                    <TextField
                      label="Last Name"
                      id="usersLastname"
                      className="userFormField"
                      name="lastname"
                      variant="outlined"
                      size="small"
                      value={
                        isNaN(parseInt(userId)) && isExistingUser
                          ? userDetailByEmail?.lastname
                          : props.newUserInput?.lastname
                      }
                      onChange={(e) => handleInputChange(e, "lastname")}
                      InputLabelProps={{
                        shrink:
                          focusStates["lastname"] ||
                          Boolean(
                            isNaN(parseInt(userId)) && isExistingUser
                              ? userDetailByEmail?.lastname
                              : props.newUserInput?.lastname
                          ),
                      }}
                      InputProps={{
                        onFocus: (event) => handleFieldFocus(event.target.name),
                        onBlur: (event) => handleFieldBlur(event.target.name),
                      }}
                    />
                  </div>
                  <div className="phnContainer">
                    {/* <TextField
                      label="Phone Number"
                      id="usersPhone"
                      className="userFormField"
                      name="mobile"
                      variant="outlined"
                      size="small"
                      value={
                        isNaN(parseInt(userId)) && isExistingUser
                          ? userDetailByEmail?.mobile
                          : props.newUserInput?.mobile
                      }
                      onChange={handleInputChange}
                      InputLabelProps={{
                        shrink:
                          focusStates["mobile"] ||
                          Boolean(
                            isNaN(parseInt(userId)) && isExistingUser
                              ? userDetailByEmail?.mobile
                              : props.newUserInput?.mobile
                          ),
                      }}
                      InputProps={{
                        onFocus: (event) => handleFieldFocus(event.target.name),
                        onBlur: (event) => handleFieldBlur(event.target.name),
                      }}
                      fullWidth
                    /> */}
                    <PhoneInput
                      placeholder="Enter Number"
                      onlyCountries={["us", "in"]}
                      value={
                        isNaN(parseInt(userId)) && isExistingUser
                          ? userDetailByEmail?.mobile
                          : props.newUserInput?.mobile
                      }
                      onChange={(ph) => handleInputChange(ph, "mobile")}
                    />
                  </div>
                  {props.newUserInput.role === constants.Roles.learner ? (
                    <div className="branchndCollege">
                      <TextField
                        label="Branch"
                        id="usersBranch"
                        className="userFormField"
                        name="branch"
                        variant="outlined"
                        size="small"
                        value={
                          isNaN(parseInt(userId)) && isExistingUser
                            ? userDetailByEmail?.branch
                            : props.newUserInput?.branch
                        }
                        onChange={(e) => handleInputChange(e, "branch")}
                        InputLabelProps={{
                          shrink:
                            focusStates["branch"] ||
                            Boolean(
                              isNaN(parseInt(userId)) && isExistingUser
                                ? userDetailByEmail?.branch
                                : props.newUserInput?.branch
                            ),
                        }}
                        InputProps={{
                          onFocus: (event) =>
                            handleFieldFocus(event.target.name),
                          onBlur: (event) => handleFieldBlur(event.target.name),
                        }}
                      />
                      <TextField
                        label="College"
                        id="usersCollege"
                        className="userFormField"
                        name="college"
                        variant="outlined"
                        size="small"
                        value={
                          isNaN(parseInt(userId)) && isExistingUser
                            ? userDetailByEmail?.college
                            : props.newUserInput?.college
                        }
                        onChange={(e) => handleInputChange(e, "college")}
                        InputLabelProps={{
                          shrink:
                            focusStates["college"] ||
                            Boolean(
                              isNaN(parseInt(userId)) && isExistingUser
                                ? userDetailByEmail?.college
                                : props.newUserInput?.college
                            ),
                        }}
                        InputProps={{
                          onFocus: (event) =>
                            handleFieldFocus(event.target.name),
                          onBlur: (event) => handleFieldBlur(event.target.name),
                        }}
                      />
                    </div>
                  ) : null}
                </div>
              ) : (
                <div className="multiuploadbtnContainer">
                  {fileLoaded === "" ? (
                    <Button
                      size={"lg"}
                      onClick={handleFileUpload}
                      disable={false}
                      icon={{
                        type: "leading",
                        icon: <UploadSvg stroke="#ffffff" />,
                      }}
                      hierarchy={{
                        type: "primary",
                        buttonText: "Upload Student List",
                      }}
                    />
                  ) : (
                    <Button
                      size={"lg"}
                      onClick={handleFileUpload}
                      disable={false}
                      icon={{
                        type: "leading",
                        icon: <UploadSvg stroke="#ffffff" />,
                      }}
                      hierarchy={{
                        type: "secondaryColor",
                        buttonText: fileLoaded,
                      }}
                    />
                  )}

                  <input
                    type="file"
                    name="myfile"
                    className="uploadUserstFileInput"
                    style={{ width: "150px", height: "30px" }}
                    accept=".csv"
                    onChange={(event) =>
                      handleUploadUserfile(event, event.target.files[0])
                    }
                  />
                  <Button
                    size={"lg"}
                    onClick={handleFileDownload}
                    disable={false}
                    icon={{
                      type: "leading",
                      icon: (
                        <svg
                          width="20"
                          height="20"
                          viewBox="0 0 20 20"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M17.5 12.5V15.8333C17.5 16.2754 17.3244 16.6993 17.0118 17.0118C16.6993 17.3244 16.2754 17.5 15.8333 17.5H4.16667C3.72464 17.5 3.30072 17.3244 2.98816 17.0118C2.67559 16.6993 2.5 16.2754 2.5 15.8333V12.5M5.83333 8.33333L10 12.5M10 12.5L14.1667 8.33333M10 12.5V2.5"
                            stroke="#344054"
                            stroke-width="1.67"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </svg>
                      ),
                    }}
                    hierarchy={{
                      type: "secondaryGrey",
                      buttonText: "Download Template",
                    }}
                  />
                </div>
              )}

              {isNaN(parseInt(userId)) ? (
                <Form.Group className="mb-3 mt-3">
                  <Editor
                    tinymceScriptSrc="/tinymce/tinymce.min.js"
                    textareaName="emailBody"
                    value={props.newUserInput.emailBody}
                    onEditorChange={(event) =>
                      handleInputChange(event, "emailBody")
                    }
                    init={{
                      license_key: "gpl",
                      minHeight: 1200,
                      menubar: false,
                      branding: false,
                      statusbar: false,
                      elementpath: false,
                      selector: "textarea",
                      allow_html_in_named_anchor: true,
                      plugins: [
                        "code",
                        "help",
                        "lists",
                        "media",
                        "table",
                        "anchor",
                        "charmap",
                        "preview",
                        "advlist",
                        "wordcount",
                        "fullscreen",
                        "autoresize",
                        "visualblocks",
                        "searchreplace",
                        "insertdatetime",
                      ],
                      toolbar:
                        "undo redo | blocks | " +
                        "bold italic underline forecolor  | alignleft aligncenter " +
                        "alignright alignjustify | bullist numlist outdent indent | " +
                        "removeformat | help |",
                      content_style:
                        "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
                    }}
                  />
                </Form.Group>
              ) : null}
            </div>
            <div></div>
          </form>
        </div>
        <div className="buttonContainerUserdetails">
          {isNaN(parseInt(userId)) ? (
            <Button
              size={"sm"}
              onClick={addSingleUser ? handleCreateUser : handleSave}
              disable={!savebtnHide}
              hierarchy={{
                type: "primary",
                buttonText: "Save",
              }}
            />
          ) : (
            <Button
              size={"sm"}
              onClick={handleUpdateUser}
              disable={
                !props.newUserInput?.email ||
                !props.newUserInput?.firstname ||
                !savebtnHide
              }
              hierarchy={{
                type: "primary",
                buttonText: "Update",
              }}
            />
          )}
          <Button
            size={"sm"}
            onClick={handleClosePopup}
            disable={false}
            hierarchy={{
              type: "secondaryGrey",
              buttonText: "Cancel",
            }}
          />
        </div>
      </Offcanvas>
    </div>
  );
}

export default UserDetails;
