import JobDetails from "./JobDetails";
import SalarySlider from "./SalarySlider";
import constants from "../../../constants";
import { Offcanvas } from "react-bootstrap";
import AlertContext from "../../../context/AlertContext";
import useAxiosPrivate from "../../../Hooks/useAxiosPrivate";
import React, { useContext, useState, useEffect } from "react";

const FilterJobs = () => {
  const Alert = useContext(AlertContext);

  const {
    isAdmin,
    expandedJob,
    setIsLoaded,
    setInitialJobs,
    setExpandedJob,
    setFilteredJobs,
  } = useContext(AlertContext);

  const [error, setError] = useState(null);
  const [expValue, setExpValue] = useState([]);
  const [expRange, setExprange] = useState([]);
  const [jobTypes, setJobTypes] = useState([]);
  const [salryValue, setSalaryValue] = useState([0, 5000000]);
  const [selectedWorkModes, setSelectedWorkModes] = useState([]);
  const [selectedQualifications, setSelectedQualifications] = useState([]);

  const axios = useAxiosPrivate();

  let experience = [
    { value: 1, label: "0-3 years", range: [0, 3] },
    { value: 2, label: "3-5 years", range: [3, 5] },
    { value: 3, label: "5-7 years", range: [5, 7] },
    { value: 4, label: "7+ years", range: [7, 100] },
  ];

  const handleExperienceChange = (event, ind) => {
    let updatedExpValue;

    if (event.target.checked) {
      updatedExpValue = [...expValue, ind];
    } else {
      // If unchecked, remove the index from the expValue array

      updatedExpValue = expValue.filter((item) => item !== ind);
    }

    setExpValue(updatedExpValue);

    if (updatedExpValue.length > 0) {
      // Find the minimum and maximum ranges from the selected experiences

      const selectedRanges = updatedExpValue.map(
        (val) => experience.find((exp) => exp.value === val + 1).range
      );

      // Calculate min of the first values in the ranges and max of the second values

      const minRange = Math.min(...selectedRanges.map((range) => range[0]));
      const maxRange = Math.max(...selectedRanges.map((range) => range[1]));

      let result;

      if (updatedExpValue.includes(3)) {
        result = [minRange];
      } else {
        result = [minRange, maxRange];
      }

      setExprange(result);
    } else {
      setExprange([]);
    }
  };

  const handleSalaryChange = (event, newValue) => {
    setSalaryValue(newValue);
  };

  const handleWorkModeChange = (event, ind) => {
    if (event.target.checked) {
      setSelectedWorkModes([...selectedWorkModes, ind]);
    } else {
      setSelectedWorkModes(selectedWorkModes.filter((item) => item !== ind));
    }
  };

  const handleJobTypesChange = (event, ind) => {
    if (event.target.checked) {
      setJobTypes([...jobTypes, ind]);
    } else {
      setJobTypes(jobTypes.filter((item) => item !== ind));
    }
  };

  useEffect(() => {
    const fetchJobs = async () => {
      try {
        setIsLoaded(true);
        const searchParams = {
          role: "learner",
          roletype: jobTypes,
          minctc: salryValue[0],
          maxctc: salryValue[1],
          workmode: selectedWorkModes,
        };

        if (expRange.length > 0) {
          searchParams.minexp = expRange[0];
        }

        if (expRange.length > 1) {
          searchParams.maxexp = expRange[1];
        }

        const queryString = new URLSearchParams(searchParams).toString();

        const response = await axios.get(`node/learner/jobs?${queryString}`, {
          headers: {
            "Content-type": "application/json",
          },
        });

        setInitialJobs(response.data.data);
        setFilteredJobs(response.data.data);
      } catch (error) {
        setError(error);
      } finally {
        setIsLoaded(false);
      }
    };
    fetchJobs();
  }, [
    expRange,
    expValue,
    jobTypes,
    salryValue,
    selectedWorkModes,
    selectedQualifications,
  ]);

  return (
    <>
      {expandedJob && (
        <Offcanvas
          placement="end"
          show={expandedJob}
          id="jobDetailsContainer"
          onHide={() => setExpandedJob(null)}
        >
          {" "}
          <JobDetails
            expandedJob={expandedJob}
            setExpandedJob={setExpandedJob}
          />
        </Offcanvas>
      )}

      <div className={isAdmin ? "filter-container-admin" : "filter-container"}>
        {error && <p>Error fetching jobs: {error.message}</p>}
        <label className="filter-heading">Filters</label>
        <hr className="hrline"></hr>
        <div className="filtCont">
          <label className="styled-label">Type</label>
          {constants.roletype.map((i, ind) => (
            <div key={ind} className="filter-option">
              <input
                type="checkbox"
                className="custom-checkbox"
                checked={jobTypes.includes(i.value)}
                onChange={(event) => handleJobTypesChange(event, i.value)}
              />{" "}
              <label className="filter-label">{i.label}</label>
            </div>
          ))}
        </div>
        <hr className="hrline" />
        <div className="filtCont">
          <label className="styled-label">Work Mode</label>
          {constants.workmode.map((i, ind) => (
            <div key={ind} className="filter-option">
              <input
                type="checkbox"
                className="custom-checkbox"
                checked={selectedWorkModes.includes(i.value)}
                onChange={(event) => handleWorkModeChange(event, i.value)}
              />{" "}
              <label className="filter-label">{i.label}</label>
            </div>
          ))}
        </div>
        <hr className="hrline"></hr>
        <div className="filtCont">
          <label className="styled-label">Experience</label>
          {experience?.map((i, ind) => (
            <div key={ind} className="filter-option">
              <input
                type="checkbox"
                className="custom-checkbox"
                checked={expValue.includes(ind)}
                onChange={(event) => handleExperienceChange(event, ind)}
              />{" "}
              <label className="filter-label">{i.label}</label>
            </div>
          ))}
        </div>
        <hr className="hrline"></hr>
        <div style={{ display: " flex", flexDirection: "column", gap: "44px" }}>
          <label className="styled-label">Salary</label>
          <SalarySlider
            salryValue={salryValue}
            handleSalaryChange={handleSalaryChange}
          />
        </div>
        <div className="salaryRange">
          <p className="salaryText">0 LPA</p>
          <p className="salaryText">50 LPA</p>
        </div>
      </div>
    </>
  );
};

export default FilterJobs;
