import constants from "../../../constants";
import { useParams } from "react-router-dom";
import { getDataFromStorage } from "../../../util";
import TestSvgIcon from "../../../Svg/TestSvgIcon";
import React, { useContext, useEffect } from "react";
import Overlay from "../../CommonComponents/Overlay";
import AlertContext from "../../../context/AlertContext";
import useAxiosPrivate from "../../../Hooks/useAxiosPrivate";
import { UserReportContext } from "../../../context/UserReportContext";
import useCommonFunctionHooks from "../../../Hooks/useCommonFunctionHooks";
import UserTestResultReportTableHooks from "../../../Hooks/UserReport/UserTestResultReportTable/UserTestResultReportTableHooks";

export default function UserTestResultReportTable() {
  const Alert = useContext(AlertContext);
  const UserReportCxt = useContext(UserReportContext);

  const axios = useAxiosPrivate();
  const useCommonFunctionHks = useCommonFunctionHooks();
  const UserTestResultReportTableHks = UserTestResultReportTableHooks();

  const { id } = useParams();

  useEffect(() => {
    Alert.setIsLoaded(true);

    axios
      .get(
        `node/learner/${
          Alert.isAdmin || getDataFromStorage("role") === "hr"
            ? UserReportCxt.currentLearnerDetails.value
            : getDataFromStorage("learnerid")
        }/batch/${
          Alert.isAdmin || getDataFromStorage("role") === "hr"
            ? id
            : localStorage.getItem("batchid")
        }/testpoints`,
        useCommonFunctionHks.getHeaderDetailsForAxios()
      )
      .then((res) => {
        if (res.data.resultCode === constants.RESULT_STATUS.TECHNICAL_ERROR) {
          Alert.setShowNotify({
            show: true,
            title: "Info",
            msg: res.data.msg,
          });
        } else {
          if (
            Object.keys(res.data).length === 0 ||
            res.data.resultCode === constants.RESULT_STATUS.NO_DATA
          ) {
            UserReportCxt.setUserTestResultTableData([]);
            Alert.setShowNotify({
              show: true,
              title: "Info",
              msg: "No data to display",
            });
          } else {
            res.data.data.data.sort(
              (item1, item2) => item1.orderno - item2.orderno
            );

            UserTestResultReportTableHks.generateDataForUserTestResultReportTable(
              res.data.data.data
            );
          }
        }
      })
      .catch((error) => {
        useCommonFunctionHks.getCatchBlockDetails(error);
      })
      .finally(() => Alert.setIsLoaded(false));
  }, [
    localStorage.getItem("batchid"),
    UserReportCxt.currentLearnerDetails.value,
  ]);

  return (
    <div
      className={
        !Alert.isAdmin && getDataFromStorage("role") !== "hr"
          ? "userTestResultReportTableMainContainer"
          : "userTestResultReportTableMainContainerForAdmin"
      }
    >
      {UserReportCxt.userTestResultTableData.length === 0 && (
        <div className="userTestResultReportTableNoResponse">
          <h4>No data to show the test results</h4>
        </div>
      )}
      {UserReportCxt.userTestResultTableData.map((lp, index) => (
        <div
          key={index}
          className={
            !Alert.isAdmin && getDataFromStorage("role") !== "hr"
              ? "userTestResultReportTableContainer"
              : "userTestResultReportTableContainerForAdmin"
          }
        >
          <div className="userTestResultReportTableLearningPathName">
            {lp.lpName}
          </div>
          <div className="userTestResultReportTableTestMainHeader">
            <div className="userTestResultReportTableTestHeader">
              <div className="userTestResultReportTableTest">Test Name</div>
              <div className="userTestResultReportTableTotalPoints">
                Total Points
              </div>
              <div className="userTestResultReportTableMyScore">My Score</div>
            </div>
            <div className="userTestResultReportTablePointsScored">
              Points Scored
            </div>
          </div>
          {lp.modules.map((module, index) => (
            <div className="userTestResultReportTableTestBody">
              <div
                key={index}
                className="userTestResultReportTableTestBodyModuleName"
              >
                {module.mName}
              </div>

              {module.test.map((test, index) => (
                <div
                  key={index}
                  className="userTestResultReportTableTestBodyData"
                >
                  <div className="userTestResultReportTableTestBodyDataTestLabelDiv">
                    <div className="userTestResultReportTableTestBodyDataTestSvgIcon">
                      <TestSvgIcon
                        active={true}
                        disable={false}
                        libType={test.libtype}
                      />
                    </div>

                    <div className="userTestResultReportTableTestBodyDataTestName">
                      <Overlay
                        key={test.id}
                        title={test.name}
                        component={
                          <div className="userTestResultReportTableTestBodyDataTestNameLabel">
                            {test.name}
                          </div>
                        }
                      />
                      <div className="userTestResultReportTableTestBodyDataTestAssignedDate">
                        {test.assignedDate}
                      </div>
                    </div>
                    <div className="userTestResultReportTableTestBodyDataTestWeightage">
                      {test.weightage}
                    </div>
                    <div className="userTestResultReportTableTestBodyDataTestMyScore">
                      {test.score.split("/")[0] === "null"
                        ? "-"
                        : test.score.split("/")[0]}
                      /{test.score.split("/")[1]}
                    </div>
                    <div className="userTestResultReportTableTestBodyDataTestPointsEarned">
                      {test.pointsEarned}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          ))}
          <div className="userTestResultReportTableTestBodyDataTotalTestPointsEarned">
            <div className="userTestResultReportTableTestBodyDataTotalTestPointsEarnedLabel">
              Total
            </div>
            <div className="userTestResultReportTableTestBodyDataTotalTestPointsEarnedValue">
              {UserTestResultReportTableHks.calculateTotalEarnedPointsPerLearningpath(
                lp
              )}
            </div>
          </div>
        </div>
      ))}
    </div>
  );
}
