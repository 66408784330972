import constants from "../constants";
import { getDataFromStorage } from "../util";
import useAxiosPrivate from "./useAxiosPrivate";
import AlertContext from "../context/AlertContext";
import { useContext, useRef, useState } from "react";
import NotifyBody from "../Components/Notify/NotifyBody";
import CodingTestHooks from "./CodingPage/CodingTestHooks";
import useCommonFunctionHooks from "./useCommonFunctionHooks";
import StudentTestPageHooks from "./StudentTestPage/StudentTestPageHooks";

export default function InstructionPageHooks() {
  const Alert = useContext(AlertContext);

  const axios = useAxiosPrivate();
  const { takeTest } = CodingTestHooks();
  const { startTest } = StudentTestPageHooks();

  const { handleRegistration, getCatchBlockDetails, getHeaderDetailsForAxios } =
    useCommonFunctionHooks();

  const picRef = useRef(null);

  const [audioAvailable, setAudioAvailable] = useState(true);

  const videoConstraints = {
    width: 181,
    height: 101,
    facingMode: "user",
  };

  const getButtonText = () => {
    if (Alert.testData.ainterview) {
      return "Attend Interview";
    } else if (Alert.status === constants.PROGRESS_STATUS.UNLOCKED) {
      return "Take Test";
    } else if (Alert.status === constants.PROGRESS_STATUS.QN_CONTINUE) {
      return "Resume Test";
    } else {
      return "Retake Test";
    }
  };

  if (Alert.testData.videoqn === 1) {
    navigator.mediaDevices
      .getUserMedia({ audio: true })
      .catch(() => setAudioAvailable(false));
  }

  const countNoOfdisplays = async () => {
    let count = await window?.electronAPI?.sendScreenInfo();

    return { count };
  };

  const handlePic = () => {
    let payload = {
      batchid: localStorage.getItem("batchid"),
      learnerid: getDataFromStorage("learnerid"),
      image: picRef.current.getScreenshot().split(",")[1],
    };

    axios
      .post("node/learner/pic/verify", payload, getHeaderDetailsForAxios())
      .then((res) => {
        if (
          (res.data.Mobile_Found ||
            res.data.Multi_People_Found ||
            res.data.message === "Face not matched") &&
          res.data.resultCode === constants.RESULT_STATUS.SUCCESS &&
          res.data.resultCode === constants.RESULT_STATUS.TIMEOUT
        ) {
          if (res.data.Mobile_Found) {
          } else if (res.data.Multi_People_Found) {
          } else if (res.data.message) {
          }
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const checkRefreshTokenIsValid = async (payload) => {
    try {
      let res = await axios.post(
        "node/auth/refreshtoken/check",
        { ...payload, refreshToken: localStorage.getItem("refreshToken") },
        getHeaderDetailsForAxios()
      );

      if (res.data.resultCode === constants.RESULT_STATUS.SUCCESS) {
        if (Alert.testData.testtype === constants.libTypeIds.CODING_CHALLENGE) {
          return takeTest(Alert.codingTestData);
        } else {
          return startTest();
        }
      } else if (
        res.data.resultCode === constants.RESULT_STATUS.TECHNICAL_ERROR
      ) {
        Alert.setShowNotify({
          show: true,
          title: "Warning",
          msg: res.data.msg,
          isUnAuth: res.data.msg.includes("session is expiring") && true,
        });
      } else {
        Alert.setShowNotify({
          show: true,
          title: "Warning",
          msg: "Something went wrong. Please try again.",
        });
      }
    } catch (error) {
      getCatchBlockDetails(error);
    } finally {
      Alert.setIsLoaded(false);
    }
  };

  const handleCheck = async () => {
    Alert.setIsLoaded(true);
    Alert.setButtonDisable(true);

    // handlePic();

    if (!document.fullscreenElement) {
      document.documentElement.requestFullscreen();
    }

    let count = await countNoOfdisplays();

    if (
      Alert.lpData.registered === 0 &&
      Alert.testData.testmode === constants.MODE.TEST_MODE &&
      Alert.testData.proctored === constants.PROCTORING_MODE.TAB_CAM
    ) {
      Alert.setIsLoaded(false);
      Alert.setButtonDisable(false);
      Alert.setShowNotify({
        show: true,
        size: "lg",
        primaryButton: "Continue",
        secondaryButton: "Cancel",
        primaryButtonFunction: handleRegistration,
        title: "First time taking a proctored test.",
        msg: <NotifyBody component="instructionPageNotifyMsgForRegistration" />,
      });
    } else if (
      !Alert.camAvailable &&
      (Alert.testData.videoqn === 1 ||
        Alert.testData.proctored === constants.PROCTORING_MODE.TAB_CAM)
    ) {
      Alert.setIsLoaded(false);
      Alert.setButtonDisable(false);
      Alert.setShowNotify({
        show: true,
        titleSvg: "warning",
        msg: <NotifyBody component="instructionPageNotifyMsgForCameraAccess" />,
      });
    } else if (!audioAvailable && Alert.testData.videoqn === 1) {
      Alert.setButtonDisable(false);
      Alert.setShowNotify({
        show: true,
        titleSvg: "warning",
        msg: <NotifyBody component="instructionPageNotifyMsgForAudioAccess" />,
      });
    } else if (
      Alert.testData.testtype === constants.libTypeIds.CODING_CHALLENGE
    ) {
      if (count?.count > 1) {
        Alert.setIsLoaded(false);
        Alert.setShowNotify({
          show: true,
          title: "Warning",
          msg: `You connected More than 1 displays,Please Ensure without external screen connection and start agian!`,
        });
      } else {
        takeTest(Alert.codingTestData);
      }
    } else {
      Alert.setButtonDisable(false);

      let payload = {
        libtypeid: Alert.testData.testtype,
      };

      if (Alert.testData.testtype === constants.libTypeIds.CODING_CHALLENGE) {
        payload.testconfigid = Alert.codingTestData.testconfigid;
      } else {
        payload.testId = Alert.testData.testid;
      }

      if (count?.count > 1) {
        Alert.setIsLoaded(false);
        Alert.setShowNotify({
          show: true,
          title: "Warning",
          msg: `You connected More than 1 displays,Please Ensure without external screen connection and start agian!`,
        });
      } else {
        return checkRefreshTokenIsValid(payload);
      }
    }
  };

  return { picRef, handleCheck, videoConstraints, getButtonText };
}
