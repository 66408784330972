// Tooltip.js
import React, { useState } from "react";
import PropTypes from "prop-types";

const Tooltip = ({ text, children, position, theme }) => {
  const [visible, setVisible] = useState(false);

  const showTooltip = () => setVisible(true);
  const hideTooltip = () => setVisible(false);

  return (
    <div
      className={`tooltipContainer`}
      onMouseEnter={showTooltip}
      onMouseLeave={hideTooltip}
      aria-describedby={visible ? "tooltip" : undefined}
    >
      {children}
      {visible && (
        <div className={`tooltipBox tooltip ${position} ${theme}`}>
          <div className={`tooltipArrow ${position} ${theme}`} />
          <div className="tooltipText">{text}</div>
        </div>
      )}
    </div>
  );
};

Tooltip.propTypes = {
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  children: PropTypes.node.isRequired,
  position: PropTypes.oneOf([
    "topLeft",
    "top",
    "topCenter",
    "topRight",
    "right",
    "bottom",
    "bottomLeft",
    "bottomCenter",
    "bottomRight",
    "left",
  ]),
  theme: PropTypes.oneOf(["light", "dark"]),
};

Tooltip.defaultProps = {
  position: "topCenter",
  theme: "dark",
};

export default Tooltip;
