import React, { useContext, useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import SettingsRightHeader from "./SettingsRightHeader";
import Button from "../../../Button";
import SkillBody from "./SkillBody";
import Collapse from "../../../CommonComponents/Collapse";
import useAxiosPrivate from "../../../../Hooks/useAxiosPrivate";
import AlertContext from "../../../../context/AlertContext";
import constants from "../../../../constants";
// import { TextField } from "@mui/material";
import _ from "lodash";
import { nanoid } from "nanoid";
import CreatableSelect from "react-select/creatable";

export const makeTestLists = (data) => {
  return _.map(data, (lp) => ({
    value: lp.lpid,
    label: lp.lpname,
    checked: false,
    disabled: false,
    isLp: true,
    children: _.orderBy(lp.modules, ["morderno"], "asc").map((item) => ({
      value: item.mid,
      label: item.mname,
      checked: false,
      disabled: false,
      isModule: true,
      children: _.orderBy(item.mconfig ?? [], ["orderno"], "asc").map(
        (config) => ({
          value: config.lpcid,
          label: config.name,
          checked: false,
          disabled: false,
          isTest: true,
        })
      ),
    })),
  }));
};

const SkillMapping = () => {
  const { id } = useParams();
  const axios = useAxiosPrivate();
  const { setIsLoaded, setShowNotify } = useContext(AlertContext);

  const [skills, setSkills] = useState([]);
  const [batchSkills, setBatchSkills] = useState([]);
  // const [focusStates, setFocusStates] = useState(() =>
  //   _.reduce(batchSkills, (acc, key) => ({ ...acc, [key.id]: false }), {})
  // );

  const [skillsCollapse, setSkillsCollapse] = useState(() =>
    _.reduce(batchSkills, (acc, key) => ({ ...acc, [key.id]: false }), {})
  );
  const [batchTests, setBatchTests] = useState([]);
  const [testOptions, setTestOptions] = useState({ 1: [], 2: [], 3: [] });
  const [payload, setPayload] = useState([]);
  const allTests = useRef([]);

  useEffect(() => {
    const fetchSkills = async () => {
      try {
        const response = await axios.get("node/admin/skill", {
          headers: {
            "Content-Type": "application/json",
          },
        });
        if (response.data.resultCode === constants.RESULT_STATUS.SUCCESS) {
          const data = _.map(response.data.data ?? [], (item) => ({
            value: item.id,
            label: item.name,
          }));
          setSkills(data ?? []);
        } else {
          setShowNotify({
            show: true,
            title: "Error !",
            msg:
              response.data.msg ??
              "Something went wrong. Please try again later or contact gradious team.",
          });
        }
      } catch (error) {
        if (error.message.includes("403")) {
          setShowNotify({
            show: true,
            title: "Warning !",
            msg: "You have been logged-out due to inactivity. Login again.",
            isUnAuth: true,
          });
        } else
          setShowNotify({
            show: true,
            title: "Error !",
            msg: "Something went wrong. Please try again later or contact gradious team.",
          });
      } finally {
        setIsLoaded(false);
      }
    };
    fetchSkills();
  }, [axios, setIsLoaded, setShowNotify]);

  useEffect(() => {
    const fetchBatchAllTests = async () => {
      try {
        let response = await axios.get(`node/admin/batch/${id}/assignments`, {
          headers: {
            "Content-type": "application/json",
          },
        });
        // console.log("response of fetch batch tests", response);
        if (response.data.resultCode === constants.RESULT_STATUS.SUCCESS) {
          if (response.data.data) {
            let data = JSON.parse(
              typeof response.data.data === "string" ? response.data.data : "[]"
            );
            // let sortedData = _.orderBy(data, ["morderno"], "asc");
            const transformedData = makeTestLists(data);
            setBatchTests(transformedData);
            setTestOptions({
              1: transformedData,
              2: transformedData,
              3: transformedData,
            });
            allTests.current = transformedData;
          }
        } else if (
          response.data.resultCode === constants.RESULT_STATUS.TECHNICAL_ERROR
        ) {
          setShowNotify({
            show: true,
            title: "Error !",
            msg: response.data.msg,
          });
        } else {
          setShowNotify({
            show: true,
            title: "Error !",
            msg: "Something went wrong. Please try again later or contact gradious team.",
          });
        }
      } catch (error) {
        console.error("error while fetch batch tests", error);
        if (error.message.includes("403")) {
          setShowNotify({
            show: true,
            title: "Warning !",
            msg: "You have been logged-out due to inactivity. Login again.",
            isUnAuth: true,
          });
        } else
          setShowNotify({
            show: true,
            title: "Error !",
            msg: "Something went wrong. Please try again later or contact gradious team.",
          });
      } finally {
        setIsLoaded(false);
      }
    };
    fetchBatchAllTests();
  }, [id, axios, setIsLoaded, setShowNotify]);

  useEffect(() => {
    const fetchBatchSkills = async () => {
      setIsLoaded(true);
      try {
        let response = await axios.get(`node/admin/batch/${id}/skills`, {
          headers: {
            "Content-type": "application/json",
          },
        });
        // console.log("response of batch skill api", response);
        if (response.data.resultCode === constants.RESULT_STATUS.SUCCESS) {
          setBatchSkills(
            response.data.data.map((skill) => ({
              ...skill,
              bSkillId: skill.id,
            }))
          );
        } else if (
          response.data.resultCode === constants.RESULT_STATUS.TECHNICAL_ERROR
        ) {
          setShowNotify({
            show: true,
            title: "Error !",
            msg: response.data.msg,
          });
        } else {
          setShowNotify({
            show: true,
            title: "Error !",
            msg: "Something went wrong. Please try again later or contact gradious team.",
          });
        }
      } catch (error) {
        console.error("err while fetch batch skills", error);
        if (error.message.includes("403")) {
          setShowNotify({
            show: true,
            title: "Warning !",
            msg: "You have been logged-out due to inactivity. Login again.",
            isUnAuth: true,
          });
        } else
          setShowNotify({
            show: true,
            title: "Error !",
            msg: "Something went wrong. Please try again later or contact gradious team.",
          });
      } finally {
        setIsLoaded(false);
      }
    };
    fetchBatchSkills();
  }, [axios, setShowNotify, setIsLoaded, id]);

  const onAddSkill = () => {
    setBatchSkills((prev) => [
      ...prev,
      { id: nanoid(), skillname: null, skillid: null },
    ]);
  };

  const onSelectSkill = (value, bSkillId) => {
    // console.log("selected skill", value);
    const newDetails = JSON.parse(JSON.stringify(batchSkills));
    const index = newDetails.findIndex((item) => item.id === bSkillId);
    const item = newDetails[index];
    if (item) {
      item.skillname = value.label;
      item.skillid = value.value;
      newDetails[index] = item;
    }
    setBatchSkills(newDetails);
  };

  const onCreateSkill = (value, bSkillId) => {
    onSelectSkill({ label: value, value: null }, bSkillId);
  };

  const onBlurSkillField = async (skillId, bSkillId) => {
    setIsLoaded(true);
    try {
      const newDetails = JSON.parse(JSON.stringify(batchSkills));
      const index = newDetails.findIndex((item) => item.id === skillId);
      const item = newDetails[index];
      // console.log("onBlurSkillField", item, "bSkillId", bSkillId);
      let response;
      if (bSkillId && item && item.skillname && item.skillname !== "") {
        response = await axios.put(
          `node/admin/batch/${id}/skills/${bSkillId}`,
          item,
          {
            headers: {
              "Content-type": "application/json",
            },
          }
        );
      }
      if (!bSkillId && item && item.skillname && item.skillname !== "") {
        response = await axios.post(`node/admin/batch/${id}/skills`, item, {
          headers: {
            "Content-type": "application/json",
          },
        });
      }
      // console.log(":::response", response);
      if (
        response &&
        response.data.resultCode === constants.RESULT_STATUS.SUCCESS
      ) {
        if (
          response.data.data &&
          response.data.data.bSkillId &&
          response.data.data.skillId
        ) {
          item.id = response.data.data.bSkillId;
          item.skillid = response.data.data.skillId;
          item.bSkillId = response.data.data.bSkillId;
        }
        newDetails[index] = item;
        setBatchSkills(newDetails);
        return;
      }
      if (
        response &&
        response.data.resultCode === constants.RESULT_STATUS.TECHNICAL_ERROR
      ) {
        setShowNotify({
          show: true,
          title: "Error !",
          msg: response.data.msg,
        });
        return;
      }
      if (item && item.skillname && item.skillname !== "")
        setShowNotify({
          show: true,
          title: "Error !",
          msg: "Something went wrong. Please try again later or contact gradious team.",
        });
    } catch (error) {
      console.error("error while add or update batch skill", error);
      if (error.message.includes("403")) {
        setShowNotify({
          show: true,
          title: "Warning !",
          msg: "You have been logged-out due to inactivity. Login again.",
          isUnAuth: true,
        });
      } else
        setShowNotify({
          show: true,
          title: "Error !",
          msg: "Something went wrong. Please try again later or contact gradious team.",
        });
    } finally {
      setIsLoaded(false);
    }
    // setFocusStates((prev) => ({ ...prev, [skillId]: false }));
  };

  const onDeleteSkill = async (skillId, bSkillId) => {
    const newDetails = JSON.parse(JSON.stringify(batchSkills));
    const index = batchSkills.findIndex((item) => item.id === skillId);
    if (bSkillId) {
      try {
        setIsLoaded(true);
        let response = await axios.delete(
          `node/admin/batch/${id}/skills/${bSkillId}`
        );
        if (response.data.resultCode === constants.RESULT_STATUS.SUCCESS) {
          newDetails.splice(index, 1);
          setBatchSkills(newDetails);
        } else if (
          response.data.resultCode === constants.RESULT_STATUS.TECHNICAL_ERROR
        ) {
          setShowNotify({
            show: true,
            title: "Error !",
            msg: response.data.msg,
          });
        } else {
          setShowNotify({
            show: true,
            title: "Error !",
            msg: "Something went wrong. Please try again later or contact gradious team.",
          });
        }
      } catch (error) {
        console.error("error while delete batch skill", error);
        if (error.message.includes("403")) {
          setShowNotify({
            show: true,
            title: "Warning !",
            msg: "You have been logged-out due to inactivity. Login again.",
            isUnAuth: true,
          });
        } else
          setShowNotify({
            show: true,
            title: "Error !",
            msg: "Something went wrong. Please try again later or contact gradious team.",
          });
      } finally {
        setIsLoaded(false);
      }
    } else {
      newDetails.splice(index, 1);
      setBatchSkills(newDetails);
    }
  };

  return (
    <div className="skillMapContainer">
      <SettingsRightHeader
        title="Resume Skill Mapping"
        titleIcon={
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M9.75 20.75L11.223 21.5683C11.5066 21.7259 11.6484 21.8047 11.7986 21.8355C11.9315 21.8629 12.0685 21.8629 12.2015 21.8355C12.3516 21.8047 12.4934 21.7259 12.777 21.5683L14.25 20.75M5.25 18.25L3.82297 17.4572C3.52346 17.2908 3.37368 17.2076 3.26463 17.0893C3.16816 16.9846 3.09515 16.8605 3.05048 16.7253C3 16.5725 3 16.4012 3 16.0586V14.5M3 9.5V7.94145C3 7.5988 3 7.42748 3.05048 7.27468C3.09515 7.13951 3.16816 7.01543 3.26463 6.91074C3.37368 6.7924 3.52345 6.7092 3.82297 6.5428L5.25 5.75M9.75 3.25L11.223 2.43168C11.5066 2.27412 11.6484 2.19535 11.7986 2.16446C11.9315 2.13713 12.0685 2.13713 12.2015 2.16446C12.3516 2.19535 12.4934 2.27412 12.777 2.43168L14.25 3.25M18.75 5.75L20.177 6.54279C20.4766 6.7092 20.6263 6.7924 20.7354 6.91073C20.8318 7.01542 20.9049 7.13951 20.9495 7.27468C21 7.42748 21 7.5988 21 7.94145V9.5M21 14.5V16.0586C21 16.4012 21 16.5725 20.9495 16.7253C20.9049 16.8605 20.8318 16.9846 20.7354 17.0893C20.6263 17.2076 20.4766 17.2908 20.177 17.4572L18.75 18.25M9.75 10.75L12 12M12 12L14.25 10.75M12 12V14.5M3 7L5.25 8.25M18.75 8.25L21 7M12 19.5V22"
              stroke="#344054"
              strokeWidth="1.6"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        }
        button={
          <Button
            size="sm"
            hierarchy={{ type: "secondaryGrey", buttonText: "Add Skill" }}
            icon={{
              type: "leading",
              icon: (
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M10.0001 4.16666V15.8333M4.16675 10H15.8334"
                    stroke="#344054"
                    strokeWidth="1.67"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              ),
            }}
            onClick={onAddSkill}
          />
        }
      />
      <div className="skillMapBodySec">
        {_.map(batchSkills, (skill, index) => (
          <Collapse
            key={index}
            title={
              <CreatableSelect
                id="skillName"
                placeholder="Select or create skill"
                value={{ value: skill.skillid, label: skill.skillname }}
                components={{ IndicatorSeparator: null }}
                className="skillNameField selectSkillField"
                options={skills}
                onChange={(value) => onSelectSkill(value, skill.id)}
                styles={{
                  control: (baseStyles, state) => ({
                    ...baseStyles,
                    borderColor: state.isFocused ? "#F9B9AC" : "#D0D5DD",
                    borderRadius: "8px",
                    boxShadow: state.isFocused
                      ? "0px 0px 0px 4px #f4ebff"
                      : "unset",
                    outline: "none",
                    "&:hover": {
                      borderColor: state.isFocused ? "#F9B9AC" : "#D0D5DD",
                    },
                  }),
                  option: (baseStyles, state) => ({
                    ...baseStyles,
                    color: "#101828",
                    fontSize: "16px",
                    lineHeight: "24px",
                    fontFamily: "Inter",
                    cursor: "pointer",
                    background: state.isSelected ? "#f9fafb" : "unset",
                    "&:hover": {
                      background: "#F9FAFB",
                    },
                  }),
                }}
                onKeyDown={(event) => {
                  if (event.key === "Enter")
                    onBlurSkillField(skill.id, skill.bSkillId);
                }}
                onBlur={() => onBlurSkillField(skill.id, skill.bSkillId)}
                onCreateOption={(value) => onCreateSkill(value, skill.id)}
              />
            }
            open={skillsCollapse[skill.id]}
            onToggle={() =>
              setSkillsCollapse((prev) => ({
                ...prev,
                [skill.id]: skill.bSkillId ? !prev[skill.id] : false,
              }))
            }
            isDeletable
            onDelete={(event) => {
              event.preventDefault();
              onDeleteSkill(skill.id, skill.bSkillId);
            }}
          >
            <SkillBody
              bSkillId={skill.bSkillId}
              open={skillsCollapse[skill.id]}
              setSkillsCollapse={setSkillsCollapse}
              batchTests={batchTests}
              setBatchTests={setBatchTests}
              testOptions={testOptions}
              setTestOptions={setTestOptions}
              allTests={allTests}
              payload={payload}
              setPayload={setPayload}
            />
          </Collapse>
        ))}
      </div>
    </div>
  );
};

export default SkillMapping;
