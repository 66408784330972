import { useContext } from "react";
import { useParams } from "react-router-dom";
import { getDataFromStorage } from "../../../util";
import AlertContext from "../../../context/AlertContext";
import { UserReportContext } from "../../../context/UserReportContext";

export default function UserTimeSpentReportChartHooks() {
  const Alert = useContext(AlertContext);
  const UserReportCxt = useContext(UserReportContext);

  const { id } = useParams();  

  const params = {
    learnerId:
      Alert.isAdmin || getDataFromStorage("role") === "hr"
        ? UserReportCxt.currentLearnerDetails.value
        : getDataFromStorage("learnerid"),
    batchId:
      Alert.isAdmin || getDataFromStorage("role") === "hr"
        ? id
        : localStorage.getItem("batchid"),
  };

  const convertMinutesToHours = (minutes) => minutes;

  const getAverageTimeSpent = () => {
    const timeSpentByDay = UserReportCxt.userTimeSpentDetails.reduce(
      (acc, curr) => {
        const day = curr.day;

        if (!acc[day]) {
          acc[day] = 0;
        }
        acc[day] += curr.timespent;

        return acc;
      },
      {}
    );

    const totalDays = Object.keys(timeSpentByDay).length;
    const totalTime = Object.values(timeSpentByDay).reduce(
      (acc, curr) => acc + curr,
      0
    );

    const averageTime = totalTime / totalDays;

    if (averageTime >= 60) {
      const hours = Math.floor(averageTime / 60);
      const minutes = Math.round(averageTime % 60);

      return `${hours} hrs ${minutes} mins`;
    } else {
      return `${
        !isNaN(Math.ceil(averageTime)) ? Math.ceil(averageTime) : 0
      } mins`;
    }
  };

  const prepareChartData = (data) => {
    const dates = generateCurrentMonthDates();

    UserReportCxt.setLabels(dates);

    const material = dates.map((day) => {
      const items = data.filter(
        (item) => item.day === day && item.itemtype === 1
      );

      const totalTimeSpent = items.reduce(
        (acc, curr) => acc + curr.timespent,
        0
      );

      return convertMinutesToHours(totalTimeSpent);
    });

    const assignment = dates.map((day) => {
      const items = data.filter(
        (item) => item.day === day && item.itemtype === 2
      );

      const totalTimeSpent = items.reduce(
        (acc, curr) => acc + curr.timespent,
        0
      );

      return convertMinutesToHours(totalTimeSpent);
    });

    const assessment = dates.map((day) => {
      const items = data.filter(
        (item) => item.day === day && item.itemtype === 3
      );

      const totalTimeSpent = items.reduce(
        (acc, curr) => acc + curr.timespent,
        0
      );

      return convertMinutesToHours(totalTimeSpent);
    });

    UserReportCxt.setMaterial(material);
    UserReportCxt.setAssignment(assignment);
    UserReportCxt.setAssessment(assessment);
  };

  const generateUserTimeSpentModuleData = (data) => {
    let moduleData = [];

    for (let i = 0; i < data.length; i++) {
      let learningPathData = {
        children: [],
        checked: false,
        disabled: false,
        label: data[i].lpname,
        value: {
          lpId: data[i].lpid,
        },
      };

      let test = data[i].modules;

      for (let j = 0; j < test.length; j++) {
        learningPathData.children.push({
          checked: false,
          disabled: false,
          label: test[j].mname,
          value: {
            lpId: data[i].lpid,
            moduleId: data[i].modules[j].mid,
          },
        });
      }

      moduleData.push(learningPathData);
    }

    UserReportCxt.setUserTimeSpentModuleOption(moduleData);
  };

  const generateCurrentMonthDates = () => {
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    const currentMonth = currentDate.getMonth();

    const lastDay = new Date(currentYear, currentMonth + 1, 0).getDate();
    const dates = [];

    for (let day = 1; day <= lastDay; day++) {
      dates.push(day);
    }

    return dates;
  };

  const getTotalTimeSpent = (type) => {
    const totalTimeSpent = type.reduce((acc, curr) => acc + curr, 0);

    const days = Math.floor(totalTimeSpent / 1440); // 1440 minutes in a day
    const hours = Math.floor((totalTimeSpent % 1440) / 60);
    const mins = totalTimeSpent % 60;

    let result = "";

    if (days > 0) {
      result += `${days}d `;
    }

    if (hours > 0) {
      result += `${hours}h `;
    }

    if (mins > 0 || (days === 0 && hours === 0)) {
      result += `${mins}m`;
    }

    return result.trim(); // Return as HTML string
  };

  const handleUserTimeSpentModuleData = (selectedItems, selectedNodes) => {
    let selectedLpIds = new Set();
    let selectedModuleIds = new Set();

    selectedNodes.forEach((node) => {
      if (!node._parent) {
        selectedLpIds.add(node.value.lpId);

        const modules =
          UserReportCxt.userTimeSpentModuleOption.find(
            (lp) => lp.value.lpId === node.value.lpId
          )?.children || [];

        modules.forEach((module) =>
          selectedModuleIds.add(module.value.moduleId)
        );
      } else {
        selectedLpIds.add(node.value.lpId);
        selectedModuleIds.add(node.value.moduleId);
      }
    });

    UserReportCxt.setUserTimeSpentModuleData({
      lpIds: Array.from(selectedLpIds).join(","),
      moduleIds: Array.from(selectedModuleIds).join(","),
    });
  };

  const data = {
    labels: UserReportCxt.labels,
    datasets: [
      {
        name: "Material",
        borderRadius: 10,
        maxBarThickness: 12,
        backgroundColor: "#6CE9A6",
        data: UserReportCxt.material,
        label: `Material ${getTotalTimeSpent(
          UserReportCxt.material
        ).toString()}`,
      },
      {
        borderRadius: 10,
        name: "Assignment",
        maxBarThickness: 12,
        backgroundColor: "#344BFD",
        data: UserReportCxt.assignment,
        label: `Assignment ${getTotalTimeSpent(
          UserReportCxt.assignment
        ).toString()}`,
      },
      {
        borderRadius: 10,
        name: "Assessment",
        maxBarThickness: 12,
        backgroundColor: "#EE902C",
        data: UserReportCxt.assessment,
        label: `Assessment ${getTotalTimeSpent(
          UserReportCxt.assessment
        ).toString()}`,
      },
    ],
  };

  const options = {
    barPercentage: 0.8,
    categoryPercentage: 0.6,
    plugins: {
      legend: {
        position: "bottom",
        labels: {
          padding: 24,
          boxWidth: 10,
          color: "#767676",
          usePointStyle: true,
          pointStyle: "circle",
          font: {
            size: 14,
            weight: "bold",
            family: "Inter",
          },
        },
      },
      tooltip: {
        callbacks: {
          title: (tooltipItems) => {
            return `Day: ${tooltipItems[0].label}`;
          },
          label: (tooltipItem) => {
            const label = tooltipItem.dataset.name || "";
            const value =
              tooltipItem.raw > 60
                ? Math.floor(tooltipItem.raw / 60) +
                  " hrs " +
                  Math.round(tooltipItem.raw % 60) +
                  " mins"
                : tooltipItem.raw + " mins";

            return `${label}: ${value}`;
          },
        },
      },
    },
    responsive: true,
    scales: {
      x: {
        stacked: true,
        title: {
          display: true,
          color: "#767676",
        },
        grid: {
          display: false,
        },
      },
      y: {
        stacked: true,
        title: {
          display: true,
          color: "#767676",
        },
        ticks: {
          stepSize: Math.ceil(
            Math.max(
              ...UserReportCxt.material,
              ...UserReportCxt.assignment,
              ...UserReportCxt.assessment
            ) <= 30
              ? 2
              : Math.max(
                  ...UserReportCxt.material,
                  ...UserReportCxt.assignment,
                  ...UserReportCxt.assessment
                ) <= 60
              ? 15
              : 30
          ),
          callback: (value) => {
            if (value >= 60) {
              const hours = Math.floor(value / 60);
              const minutes = value % 60;

              return `${hours} h ${minutes ? minutes + " m" : ""}`;
            } else {
              return value + " m";
            }
          },
          max:
            Math.max(
              Math.max(
                ...UserReportCxt.material,
                ...UserReportCxt.assignment,
                ...UserReportCxt.assessment
              ),
              60
            ) + 10,
        },
        grid: {
          lineWidth: 0.5,
          color: "#B1B1B1",
        },
        border: {
          display: false,
        },
        min: 0,
      },
    },
  };

  return {
    data,
    params,
    options,
    prepareChartData,
    getAverageTimeSpent,
    handleUserTimeSpentModuleData,
    generateUserTimeSpentModuleData,
  };
}
