import React, { useContext, useEffect } from "react";
import CreatableSelect from "react-select/creatable";
import AlertContext from "../../context/AlertContext";
import useAxiosPrivate from "../../Hooks/useAxiosPrivate";
import MockTestHooks from "../../Hooks/Mock/Admin/MockTestHooks";
import useCommonFunctionHooks from "../../Hooks/useCommonFunctionHooks";

export default function Tag() {
  const Alert = useContext(AlertContext);

  const axios = useAxiosPrivate();
  const MockTestHks = MockTestHooks();
  const useCommonFunctionHks = useCommonFunctionHooks();

  let oldTags =
      Alert.lpData2.tag ||
      Alert.batchDetails.tags ||
      Alert.oldTestConfig.oldTestData.tags,
    edit =
      Alert.isOldLpDetail ||
      Alert.oldTestConfig.isOldTestConfig ||
      Alert.batchDetails.hasOwnProperty("tags");

  const customStylesForTests = {
    control: (base) => ({
      ...base,
      height: "38px",
      cursor: "pointer",
      paddingLeft: "7px",
      backgroundColor: "#ffffff",
    }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        margin: "0px",
        height: "30px",
        fontSize: "12px",
        textAlign: "left",
        fontWeight: "600",
        lineHeight: "15px",
        fontFamily: "Inter",
        color: isSelected ? "#000000" : undefined,
        backgroundColor: isFocused ? "#e9ecef" : undefined,
      };
    },
    singleValue: (styles, { data }) => ({
      ...styles,
      fontSize: "12px",
      fontWeight: "600",
      lineHeight: "20px",
      fontFamily: "Inter",
      fontStyle: "normal",
      borderRadius: "20px",
    }),
    placeholder(base, state) {
      return {
        ...base,
        fontSize: "14px",
        color: "#BDC1C6",
        fontWeight: "400",
        lineHeight: "20px",
        fontFamily: "Inter",
        fontStyle: "normal",
      };
    },
    menuList: (base) => ({
      ...base,
      height: "135px",
    }),
  };

  const handleCreateTag = (inputValue) => {
    if (
      inputValue.trim().length === 0 ||
      Alert.tagList.filter((option) => option.label === inputValue).length > 0
    ) {
    } else {
      const newOption = {
        label: inputValue,
        value:
          Alert.tagList.length > 0
            ? -(Alert.tagList.length + 1)
            : Alert.tagList.length - 1,
      };

      Alert.setTagList((prev) => [...prev, newOption]);
      Alert.setSelectedTag((prev) => [...prev, newOption]);
    }
  };

  const handleChange = (newValue) => {
    if (Alert.oldTestConfig.isOldTestConfig) {
      MockTestHks.handleTestDetails("", "tags");
    }

    Alert.setSelectedTag(newValue);
  };

  useEffect(() => {
    Alert.setIsLoaded(true);
    axios
      .get(
        "node/admin/tag/get",
        useCommonFunctionHks.getHeaderDetailsForAxios()
      )
      .then((res) => {
        let tag = res.data.map((tag) => {
          return {
            value: tag.id,
            label: tag.name,
          };
        });

        if (
          edit &&
          oldTags !== "" &&
          oldTags !== null &&
          oldTags !== undefined &&
          oldTags !== "undefined"
        ) {
          Alert.setSelectedTag(
            tag.filter((tag) =>
              oldTags
                .split(",")
                .map((id) => parseInt(id))
                .includes(tag.value)
            )
          );
        } else {
          Alert.setSelectedTag([]);
        }

        Alert.setTagList(tag);
      })
      .catch((error) => {
        useCommonFunctionHks.getCatchBlockDetails(error);
      })
      .finally(() => Alert.setIsLoaded(false));
  }, []);

  return (
    <>
      <label
        htmlFor="mockTestCreateFormTags"
        className="mockTestCreateTagsLabel"
      >
        Tags
      </label>
      <CreatableSelect
        isMulti
        required
        isClearable
        options={Alert.tagList}
        placeholder="Select Tags"
        value={Alert.selectedTag}
        name="mockTestCreateFormTags"
        styles={customStylesForTests}
        onCreateOption={handleCreateTag}
        className="mockTestCreateFormTags"
        components={{ IndicatorSeparator: () => null }}
        onChange={(newValue) => {
          handleChange(newValue);
        }}
      />
    </>
  );
}
