import Button from "../Button";
import InfoSvg from "../../Svg/InfoSvg";
import Modal from "react-bootstrap/Modal";
import React, { useContext, useEffect } from "react";
import AlertContext from "../../context/AlertContext";
import ErrorNoticeSvgIcon from "../../Svg/ErrorNoticeSvgIcon";
import CompleteSvgIcon from "../CommonComponents/CompleteSvgIcon";
import WarningNoticeSvgIcon from "../../Svg/WarningNoticeSvgIcon";
import useCommonFunctionHooks from "../../Hooks/useCommonFunctionHooks";

export default function Notify() {
  const { buttonDisable, showNotify, qnTrack, VideoSaved, setButtonDisable } =
    useContext(AlertContext);

  const { handleClose, handleUnAuthAlert } = useCommonFunctionHooks();

  if (qnTrack.current === VideoSaved) setButtonDisable(false);

  useEffect(() => {}, [buttonDisable]);

  if (!showNotify.show) {
    return null;
  }

  return (
    <Modal
      centered
      onHide={handleClose}
      show={showNotify.show}
      size={showNotify.size}
      style={{ zIndex: 9999 }}
      fullscreen={showNotify.fullscreen}
      aria-labelledby="contained-modal-title-vcenter"
      autoFocus={!showNotify.autoFocus ? showNotify.autoFocus : true}
      backdrop={showNotify.backdrop ? showNotify.backdrop : "static"}
      className={
        showNotify.position === "top"
          ? "modalMainContainerForProctor"
          : showNotify.position === "bottom"
          ? "modalMainContainerForProctorBottom"
          : showNotify.fullscreen
          ? "modalMainContainerFullScreen"
          : "modalMainContainer"
      }
    >
      <Modal.Header
        closeButton={showNotify.showClose}
        className={
          showNotify.hasOwnProperty("title")
            ? "modalHeader"
            : showNotify.component === "leaderBoard"
            ? "modalHeaderForLeaderBoard"
            : "modalHeaderWithTitleSvgOnly"
        }
      >
        <Modal.Title className={"modalTitle"}>
          <div
            className={
              showNotify.title && showNotify.titleSvg && "modalHeaderTitleSvg"
            }
          >
            {showNotify.titleSvg === "success" ? (
              <CompleteSvgIcon />
            ) : showNotify.titleSvg === "warning" ? (
              <WarningNoticeSvgIcon />
            ) : showNotify.titleSvg === "error" ? (
              <ErrorNoticeSvgIcon />
            ) : (
              showNotify.titleSvg === "info" && <InfoSvg component="notify" />
            )}
          </div>
          {showNotify.title}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="modalBody">{showNotify.msg}</Modal.Body>
      {!showNotify.hideButton && (
        <Modal.Footer className={`modalFooter ${showNotify.buttonStyle}`}>
          {showNotify.hasOwnProperty("secondaryButton") && (
            <Button
              size={"sm"}
              onClick={
                showNotify.hasOwnProperty("secondaryButtonFunction")
                  ? showNotify.secondaryButtonFunction
                  : handleClose
              }
              hierarchy={{
                type: "secondaryGrey",
                buttonText: showNotify.secondaryButton,
              }}
            />
          )}
          <Button
            size={"sm"}
            disable={buttonDisable}
            hierarchy={{
              type: "primary",
              buttonText: showNotify.hasOwnProperty("primaryButton")
                ? showNotify.primaryButton
                : "Ok",
            }}
            onClick={
              showNotify.hasOwnProperty("primaryButtonFunction")
                ? showNotify.primaryButtonFunction
                : showNotify.isUnAuth
                ? handleUnAuthAlert
                : handleClose
            }
          />
        </Modal.Footer>
      )}
    </Modal>
  );
}
