import React, { useContext } from "react";
import { Outlet, useLocation } from "react-router-dom";
import SearchSvgIcon from "../../../Svg/SearchSvgIcon";
import AlertContext from "../../../context/AlertContext";
import Loading from "../../Notification-Loading/Loading/Loading";
import { MockTestPageContext } from "../../../context/MockTestPageContext";

export default function MockTestPage() {
  const Alert = useContext(AlertContext);
  const MockTestPageCxt = useContext(MockTestPageContext);

  const { pathname } = useLocation();

  return (
    <div
      className={
        Alert.isAdmin
          ? "mockTestMainContainerForAdmin"
          : "mockTestMainContainer"
      }
    >
      {Alert.isAdmin && Alert.isLoaded && <Loading />}
      {!Alert.isAdmin && (
        <>
          <div className="mockTestLabel">{(pathname === "/main/interview/library" ||  pathname === "/main/interview/results") ? "AI Interview": "Mock Test"}</div>
          <div className="mockTestNavBar">
            <div className="mockTestNavBarPath">
              <div
                onClick={() => {
                  MockTestPageCxt.setMockTest([]);
                  MockTestPageCxt.setSearchText("");
                  MockTestPageCxt.setSearchMockTest([]);
                  (pathname === "/main/interview/library" ||  pathname === "/main/interview/results") ? Alert.navigate("main/interview/library") : Alert.navigate("main/mock/library");
                  MockTestPageCxt.setDataRerender(!MockTestPageCxt.dataRerender);
                }}
                className={`mockTestNavBarLabel${
                  pathname.includes("/library") ? "Active" : ""
                }`}
              >
                Library
              </div>
              <div
                onClick={() => {
                  MockTestPageCxt.setMockTest([]);
                  MockTestPageCxt.setSearchText("");
                  MockTestPageCxt.setSearchMockTest([]);
                  (pathname === "/main/interview/library" || pathname === "/main/interview/results") ? Alert.navigate("main/interview/results") : Alert.navigate("main/mock/results");
                  MockTestPageCxt.setDataRerender(!MockTestPageCxt.dataRerender);
                }}
                className={`mockTestNavBarLabel${
                  pathname.includes("/results") ? "Active" : ""
                }`}
              >
                Completed Tests
              </div>
            </div>
            <div className="mocktestSearchBox">
              <SearchSvgIcon />
              <input
                type="text"
                name="search"
                placeholder="Search"
                className="mockTestLibrarySearch"
                value={MockTestPageCxt.searchText}
                onChange={(e) => {
                  MockTestPageCxt.setSearchText(e.target.value);
                }}
              />
            </div>
          </div>
          <hr />
        </>
      )}
      <Outlet />
    </div>
  );
}
