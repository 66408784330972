import WarningMsg from "./WarningMsg";
import constants from "../../../../constants";
import RightMain from "../RightMain/RightMain";
import React, { useContext, useEffect } from "react";
import BackSvgIcon from "../../../../Svg/BackSvgIcon";
import TestSvgIcon from "../../../../Svg/TestSvgIcon";
import Header from "../../../CommonComponents/Header";
import ContentPage from "../../ContentPage/ContentPage";
import Overlay from "../../../CommonComponents/Overlay";
import TestDueHooks from "../../../../Hooks/TestDueHooks";
import LeftNavSvgIcon from "../../../../Svg/LeftNavSvgIcon";
import WarningSvgIcon from "../../../../Svg/WarningSvgIcon";
import AlertContext from "../../../../context/AlertContext";
import LeftNavBarHooks from "../../../../Hooks/LeftNavBarHooks";
import Progressbar from "../../../CommonComponents/Progressbar";
import CompanyLogo from "../../../CommonComponents/CompanyLogo";
import Loading from "../../../Notification-Loading/Loading/Loading";
import { Sidebar, Menu, MenuItem, SubMenu } from "react-pro-sidebar";
import ModuleProgressSvgIcon from "../../../../Svg/ModuleProgressSvgIcon";

export default function LeftNavBar() {
  const Alert = useContext(AlertContext);

  const LeftNavBarHks = LeftNavBarHooks();
  const { currentDueLabel } = TestDueHooks();

  useEffect(() => {
    if (LeftNavBarHks.activeTestRef.current) {
      LeftNavBarHks.activeTestRef.current.scrollIntoView({
        block: "center",
        behavior: "smooth",
      });
    }
  }, [LeftNavBarHks.activeTestRef.current]);

  useEffect(() => {
    if (Alert.updateLeftNav || Object.keys(Alert.lpData).length === 0) {
      LeftNavBarHks.handleGetDataForHome();
    }
  }, [Alert.updateLeftNav]);

  return (
    <>
      {Alert.isLoaded && <Loading />}
      {Object.keys(Alert.lpData).length !== 0 && (
        <div className="testPageMain">
          <Sidebar
            className="leftNavMainContainer"
            collapsed={LeftNavBarHks.collapsed}
          >
            <div className="leftNavheaderDiv">
              <header className="leftNavheader">
                <CompanyLogo component={"leftNav"} />
              </header>
              <div className="leftNavLearningPath">
                {localStorage.getItem("showBack") && (
                  <BackSvgIcon onClick={LeftNavBarHks.gotToDashboard} />
                )}
                <span className="leftNavLearningPathName">
                  {Alert.lpData.lpname}
                </span>
                <div className="leftNavLearningPathInfo">
                  {parseInt(localStorage.getItem("batchType")) ===
                  constants.BATCH_TYPE.TESTING ? (
                    <span className="leftNavLearningPathModule">
                      {Alert.lpData.modules.length} Sections
                    </span>
                  ) : (
                    <span className="leftNavLearningPathModule">
                      {Alert.lpData.modules.length} modules •{" "}
                      {Alert.lpData.totalItems} chapters
                    </span>
                  )}
                  <span className="leftNavLearningPathPercentage">
                    {LeftNavBarHks.calculatePercentage(
                      Alert.lpData.completedItems,
                      Alert.lpData.totalItems
                    )}
                    %
                  </span>
                </div>
                <Progressbar
                  bgColor="#475467"
                  component="leftNav"
                  percentage={LeftNavBarHks.calculatePercentage(
                    Alert.lpData.completedItems,
                    Alert.lpData.totalItems
                  )}
                />
              </div>
            </div>
            <Menu className="leftNavMenu">
              {Alert.lpData.modules.map((moduleData) => (
                <SubMenu
                  key={moduleData.modid}
                  label={moduleData.modname}
                  className="leftNavLearningPathModuleName"
                  open={
                    Alert.moduleActive && moduleData.modid === Alert.module.id
                  }
                  onClick={() => {
                    LeftNavBarHks.openModule(moduleData);
                  }}
                  disabled={
                    moduleData.data.length === 0 ||
                    moduleData.data[0].status ===
                      constants.PROGRESS_STATUS.LOCKED
                  }
                  icon={
                    <ModuleProgressSvgIcon
                      currentModuleStatus={LeftNavBarHks.calculatePercentage(
                        moduleData.completedItems,
                        moduleData.totalItems
                      )}
                    />
                  }
                >
                  {moduleData.data.map((test) => (
                    <Overlay
                      key={test.testid !== null ? test.testid : test.libid}
                      title={
                        currentDueLabel(Alert.testData.duedate) ===
                          "Due Today" ||
                        test.status === constants.PROGRESS_STATUS.LOCKED ||
                        (Alert.testActive &&
                          (test.testid === Alert.testId ||
                            test.libid === Alert.testId))
                          ? ""
                          : test.testname !== null
                          ? test.testname
                          : test.libname
                      }
                      component={
                        <MenuItem
                          className="leftNavLearningPathTestName"
                          disabled={
                            test.status === constants.PROGRESS_STATUS.LOCKED
                          }
                          ref={
                            Alert.testActive &&
                            (test.testid === Alert.testId ||
                              test.libid === Alert.testId)
                              ? LeftNavBarHks.activeTestRef
                              : null
                          }
                          onClick={() => {
                            LeftNavBarHks.openTest(test);
                          }}
                          active={
                            Alert.testActive &&
                            (test.testid === Alert.testId ||
                              test.libid === Alert.testId)
                          }
                          icon={
                            <TestSvgIcon
                              videoQn={test.videoqn}
                              autoEval={test.autoeval}
                              disabled={
                                test.status === constants.PROGRESS_STATUS.LOCKED
                              }
                              active={
                                Alert.testActive &&
                                (test.testid === Alert.testId ||
                                  test.libid === Alert.testId)
                              }
                              libType={
                                test.testtype !== null
                                  ? test.testtype
                                  : test.libtype
                              }
                            />
                          }
                        >
                          {test.testname !== null
                            ? test.testname
                            : test.libname}
                          {((currentDueLabel(test.duedate) === "Due Today" &&
                            test.status !==
                              constants.PROGRESS_STATUS.EVALUATED) ||
                            (((test.status !==
                              constants.PROGRESS_STATUS.UNLOCKED &&
                              test.status !==
                                constants.PROGRESS_STATUS.EVALUATED &&
                              test.testtype !==
                                constants.libTypeIds.CODING_CHALLENGE &&
                              test.libtype !==
                                constants.libTypeIds.ASSIGNMENT) ||
                              (test.status ===
                                constants.PROGRESS_STATUS.IN_PROGRESS &&
                                test.testtype !==
                                  constants.libTypeIds.CODING_CHALLENGE &&
                                test.libtype !==
                                  constants.libTypeIds.ASSIGNMENT)) &&
                              currentDueLabel(test.duedate) !== "Past Due")) &&
                            test.status !==
                              constants.PROGRESS_STATUS.TO_BE_EVALUATED &&
                            test.status !==
                              constants.PROGRESS_STATUS.PARTIAL_EVALUATED &&
                            test.status !==
                              constants.PROGRESS_STATUS.LOCKED && (
                              <Overlay
                                title={
                                  <WarningMsg
                                    status={test.status}
                                    due={currentDueLabel(test.duedate)}
                                  />
                                }
                                component={
                                  <WarningSvgIcon
                                    status={test.status}
                                    due={currentDueLabel(test.duedate)}
                                  />
                                }
                              />
                            )}
                        </MenuItem>
                      }
                    />
                  ))}
                </SubMenu>
              ))}
            </Menu>
          </Sidebar>
          <LeftNavSvgIcon
            collapsed={LeftNavBarHks.collapsed}
            onClick={() => LeftNavBarHks.setCollapsed(!LeftNavBarHks.collapsed)}
          />

          <main
            className={
              !LeftNavBarHks.collapsed
                ? "rightNavMainContainer"
                : "rightNavMainContainerCollapsed"
            }
          >
            <Header component="leftNav" collapsed={LeftNavBarHks.collapsed} />
            {Alert.showMain &&
              (Alert.testData.libtype !== null &&
              Object.keys(Alert.contentData).length !== 0 &&
              Alert.testData.libtype === constants.libTypeIds.CONTENT ? (
                <ContentPage />
              ) : (
                Alert.testData.libtype !== constants.libTypeIds.CONTENT && (
                  <RightMain />
                )
              ))}
          </main>
        </div>
      )}
    </>
  );
}
