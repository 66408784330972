import { Snackbar } from "@mui/material";
import React from "react";

const Toast = ({ open, onClose, icon, message, closeIcon, ...props }) => {
  if (!open) return null;
  return (
    <Snackbar
      className="toastContainer"
      open={open}
      onClose={onClose}
      {...props}
    >
      <div className="toastContentContainer">
        {icon && icon}
        {message && <p className="toastContent">{message}</p>}
        {closeIcon && closeIcon}
      </div>
    </Snackbar>
  );
};

export default Toast;
