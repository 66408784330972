import { nanoid } from "nanoid";
import constants from "../../../constants";
import { useContext, useRef, useState } from "react";
import AlertContext from "../../../context/AlertContext";
import { MockTestPageContext } from "../../../context/MockTestPageContext";

export default function MockTestHooks() {
  const Alert = useContext(AlertContext);
  const MockTestPageCxt = useContext(MockTestPageContext);

  const editorRef = useRef(null);

  const [selectedTestType, setSelectedTestType] = useState({});

  const [testOptions, setTestOptions] = useState([
    {
      value: 2,
      label: "MCQ Test",
    },
    {
      value: 3,
      label: "Descriptive Test",
    },
    {
      id: 10,
      value: 3,
      label: "Coding Snippet Test",
    },
    {
      id: 11,
      value: 3,
      label: "Video Assessment Test",
    },
    {
      value: 5,
      label: "Coding Test",
    },
  ]);

  const [isChangeType, setIsChangeType] = useState({
    type: "",
    isChange: false,
  });

  const customStylesForTests = {
    control: (base) => ({
      ...base,
      height: "38px",
      cursor: "pointer",
      paddingLeft: "7px",
      backgroundColor: "#ffffff",
    }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        margin: "0px",
        height: "30px",
        fontSize: "12px",
        textAlign: "left",
        fontWeight: "600",
        lineHeight: "15px",
        fontFamily: "Inter",
        color: isSelected ? "#000000" : undefined,
        backgroundColor: isFocused ? "#e9ecef" : undefined,
      };
    },
    singleValue: (styles, { data }) => ({
      ...styles,
      fontSize: "12px",
      fontWeight: "600",
      lineHeight: "20px",
      fontFamily: "Inter",
      fontStyle: "normal",
      borderRadius: "20px",
    }),
    placeholder(base, state) {
      return {
        ...base,
        fontSize: "14px",
        color: "#BDC1C6",
        fontWeight: "400",
        lineHeight: "20px",
        fontFamily: "Inter",
        fontStyle: "normal",
      };
    },
    menuList: (base) => ({
      ...base,
      height: "135px",
    }),
  };

  const testTypeLoad = (e) => {
    MockTestPageCxt.testData.testType = e;
    setSelectedTestType(e);
  };

  const handleConfirmChangeType = (isTrue) => {
    if (isTrue) {
      MockTestPageCxt.setIsBackDetails({ isBack: false, testType: "" });
      MockTestPageCxt.setTestData({
        testDes: "",
        id: nanoid(),
        testName: "",
        key: nanoid(),
        status: "old",
        testDuration: "",
        testDurationNum: "",
        testDurationUnit: "day/s",
        testType: isChangeType.type,
        sections: [
          {
            id: 1,
            secName: "",
            key: nanoid(),
            status: "old",
            secData: [
              {
                id: 2,
                key: nanoid(),
                status: "old",
              },
            ],
          },
        ],
      });
    } else {
      MockTestPageCxt.testData.testType =
        MockTestPageCxt.isBackDetails.testType;
      MockTestPageCxt.setIsBackDetails({ isBack: true, testType: "" });
    }

    setIsChangeType({ isChange: false, type: "" });
  };

  const handleTestDetails = (event, field) => {
    let newDetails = { ...MockTestPageCxt.testData };

    if (field === "testType") {
      if (MockTestPageCxt.isBackDetails.isBack) {
        if (event.label === MockTestPageCxt.isBackDetails.testType.label)
          MockTestPageCxt.setIsBackDetails({ isBack: true, testType: "" });
        else {
          setIsChangeType({ isChange: true, type: event });
        }
      }

      newDetails[field] = event;
      newDetails.testTypeId = event.value;
    } else if (field === "customInstruction") {
      newDetails[field] = event;
    } else if (field !== "tags") {
      if (
        event.target.name === "weightage" ||
        event.target.name === "testDurationNum"
      ) {
        if (event.target.value !== "") {
          let regexForNum = /^[1-9]\d*$/;

          if (regexForNum.test(event.target.value)) {
            newDetails[event.target.name] = Number(event.target.value);
          } else {
            Alert.setShowNotify({
              show: true,
              title: "Warning",
              msg: "Please enter valid number",
            });
            newDetails[event.target.name] = "";
          }
        }
      }

      newDetails[event.target.name] = event.target.value;
    }

    if (event.target?.name === "aiMode") {
      MockTestPageCxt.setIsChecked((prev) => !prev);
    }

    if (Alert.oldTestConfig.isOldTestConfig) newDetails.type = "update";
    MockTestPageCxt.setTestData(newDetails);
  };

  const handleClickNext = () => {
    if (
      MockTestPageCxt.testData.testType === "" ||
      Object.keys(MockTestPageCxt.testData.testType).length === 0
    ) {
      Alert.setShowNotify({
        show: true,
        title: "Warning",
        msg: "Please select mock test type.",
      });
    } else if (MockTestPageCxt.testData.testName === "") {
      Alert.setShowNotify({
        show: true,
        title: "Warning",
        msg: "Please enter mock test name.",
      });
    } else if (
      MockTestPageCxt.testData.testType.value !==
      constants.libTypeIds.CODING_CHALLENGE
    ) {
      if (MockTestPageCxt.testData.testDurationNum === "") {
        Alert.setShowNotify({
          show: true,
          title: "Warning",
          msg: "Please enter duration.",
        });
      } else {
        if (
          !MockTestPageCxt.isBackDetails.isBack &&
          !Alert.oldTestConfig.isOldTestConfig
        ) {
          MockTestPageCxt.testData.sections[0].secData[0].complexity = "";
          MockTestPageCxt.testData.sections[0].secData[0].topic = "";
          MockTestPageCxt.testData.sections[0].secData[0].marks = "";

          if (
            !selectedTestType?.id === 11 &&
            MockTestPageCxt.testData.testType.value ===
              constants.libTypeIds.DESCRIPTIVE
          )
            MockTestPageCxt.testData.sections[0].secData[0].desType = "";
          else if (
            selectedTestType?.id === 11 &&
            MockTestPageCxt.testData.testType.value ===
              constants.libTypeIds.DESCRIPTIVE
          ) {
            MockTestPageCxt.testData.sections[0].secData[0].durationNum = "";
            MockTestPageCxt.testData.sections[0].secData[0].durationUnit =
              "min/s";
          }
          MockTestPageCxt.testData.sections[0].secData[0].randomQnCount = "";
          MockTestPageCxt.testData.sections[0].secData[0].fixedQnCount = "";
        }

        MockTestPageCxt.testData.testDuration =
          MockTestPageCxt.testData.testDurationNum +
          (MockTestPageCxt.testData.testDurationUnit === "hour/s"
            ? "h"
            : MockTestPageCxt.testData.testDurationUnit === "day/s"
            ? "d"
            : "m");
        MockTestPageCxt.setIsNext(true);
      }
    } else {
      if (
        !MockTestPageCxt.isBackDetails.isBack &&
        !Alert.oldTestConfig.isOldTestConfig
      ) {
        MockTestPageCxt.testData.sections[0].secData[0].topic = "";
        MockTestPageCxt.testData.sections[0].secData[0].marks = "";
        MockTestPageCxt.testData.sections[0].secData[0].languages = [];
        MockTestPageCxt.testData.sections[0].secData[0].complexity = "";
        MockTestPageCxt.testData.sections[0].secData[0].durationNum = "";
        MockTestPageCxt.testData.sections[0].secData[0].fixedQnCount = "";
        MockTestPageCxt.testData.sections[0].secData[0].randomQnCount = "";
        MockTestPageCxt.testData.sections[0].secData[0].durationUnit = "min/s";
      }
      MockTestPageCxt.testData.testDuration =
        (MockTestPageCxt.testData.testDurationNum === ""
          ? 0
          : MockTestPageCxt.testData.testDurationNum) +
        (MockTestPageCxt.testData.testDurationUnit === "hour/s"
          ? "h"
          : MockTestPageCxt.testData.testDurationUnit === "day/s"
          ? "d"
          : "m");
      MockTestPageCxt.setIsNext(true);
    }

    MockTestPageCxt.testData.viewTest = constants.DISPLAY_TEST_RESULT.ALL;
    MockTestPageCxt.testData.autoEvaluate =
      selectedTestType?.id === 10 || selectedTestType[0]?.id === 10;
    MockTestPageCxt.testData.videoQn =
      selectedTestType?.id === 11 || selectedTestType[0]?.id === 11;
  };

  const handleCancelTest = () => {
    MockTestPageCxt.setIsNext(false);

    if (!Alert.oldTestConfig.isOldTestConfig) {
      MockTestPageCxt.setIsNewTest(false);
      MockTestPageCxt.setIsAddTest(false);
    } else if (
      Alert.isFromBatch &&
      Alert.isOldLpDetail &&
      Alert.oldTestConfig.isOldTestConfig
    )
      Alert.navigate("/mock/test");
    else if (Alert.oldTestConfig.isOldTestConfig && Alert.fromMenu) {
      MockTestPageCxt.setIsAddTest(false);
      Alert.setFromMenuToEditTest(true);
      Alert.setOldTestConfig({
        oldTestData: {},
        isOldTestConfig: false,
      });
    } else Alert.navigate("/learningpath");
  };

  return {
    editorRef,
    testOptions,
    testTypeLoad,
    isChangeType,
    setTestOptions,
    handleClickNext,
    setIsChangeType,
    selectedTestType,
    handleCancelTest,
    handleTestDetails,
    setSelectedTestType,
    customStylesForTests,
    handleConfirmChangeType,
  };
}
