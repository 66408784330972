let libTypeIds = {
  MCQ: 2,
  SAQ: 6,
  LAQ: 7,
  TEST: 0,
  CONTENT: 1,
  MCQ_MULTI: 9,
  ASSIGNMENT: 4,
  MCQ_SINGLE: 8,
  DESCRIPTIVE: 3,
  LEARNINGPATH: 10,
  CODING_CHALLENGE: 5,
};

let datatypes = {
  NUMERIC: 1,
  ALPHA_NUMERIC: 2,
};

const PROGRESS_STATUS = {
  LOCKED: 0,
  UNLOCKED: 1,
  EVALUATED: 2,
  REATTEMPT: 3,
  IN_PROGRESS: 6,
  QN_CONTINUE: 7,
  TO_BE_EVALUATED: 4,
  PARTIAL_EVALUATED: 5,
};

const QUESTION_STATUS = {
  ASSIGNED: 1,
  RESUBMIT: 3,
  CONTINUE: 7,
  SUBMITTED: 2,
  CANCELLED: 4,
  IN_PROGRESS: 6,
  TO_BE_EVALUATED: 5,
};

const MODE = {
  SELF_MODE: 3,
  TEST_MODE: 2,
  PRACTICE_MODE: 1,
};

const PROCTORING_MODE = {
  TAB: 1,
  NONE: 0,
  TAB_CAM: 2,
};

const LINK_TYPE = {
  EMBEDED_LINK: 3,
  CONTENT_FILE_LINK: 1,
  ASSIGNMENT_FILE_LINK: 2,
};

const getLangName = {
  C: "C",
  Cpp: "C++",
  Java: "Java",
  Python: "Python",
  Javascript: "Javascript",
};

const complexityid = {
  EASY: 1,
  HARD: 3,
  MEDIUM: 2,
};

const LANG_ID = {
  C: 3,
  Cpp: 4,
  Java: 1,
  Python: 5,
  Javascript: 2,
};

const DISPLAY_TEST_RESULT = {
  ALL: 3,
  NONE: 0,
  SCORE: 2,
  SUBMISSION: 1,
};

const SHOW_LIST_VIEW = {
  NO: 0,
  YES: 1,
};

const ATTENDANCE_STATUS = {
  ABSENT: 2,
  PRESENT: 1,
  NO_RESPONSE: 0,
  PARTIALLY_PRESENT: 3,
};

const SESSION_STATUS = {
  ATTENDANCE_INITIATED: 1,
  ATTENDANCE_NOT_RECORDED: 0,
};

const SESSION_MODE = {
  ONLINE_SESSION: 0,
  OFFLINE_SESSION: 1,
};

const Roles = {
  hr: "hr",
  admin: "admin",
  parent: "parent",
  learner: "learner",
  trainer: "trainer",
};

const FACE_POSITION = {
  UP: 2,
  DOWN: 3,
  LEFT: 4,
  RIGHT: 5,
  FORWARD: 1,
};

const RESULT_STATUS = {
  SUCCESS: 1000,
  NO_DATA: 2003,
  TIMEOUT: 3000,
  NETWORK_ISSUE: 4000,
  TECHNICAL_ERROR: 2050,
  FAILURE_ATTEMPT: 2000,
  REPLACE_QUESTION: 2001,
  INCORRECT_ANSWER: 2002,
  TESTCASES_FAILED: 2004,
  NO_QNS_AVAILABLE: 3001,
  ALL_TESTCASES_FAILED: 2005,
  PARTIAL_TESTCASES_FAILED: 2006,
};

const ISNOTESTCASES = {
  TRUE: 1,
  FALSE: 0,
};

const BATCH_TYPE = {
  SELF: 3,
  TESTING: 2,
  LEARNING: 1,
};

const BOARD_STATUS = {
  DRAFT: 1,
  DELETED: 4,
  ARCHIVED: 3,
  PUBLISHED: 2,
};

const BOARD_USERS = {
  ACTIVE: 1,
  INACTIVE: 0,
};

const BOARD_WIDGETS = {
  SCORES: 5,
  ATTENDANCE: 1,
  PERCENTILE: 3,
  LEADERBOARD: 2,
  ASSIGNMENT_STATUS: 4,
};

const MENU_ICON = {
  JOBS: 3,
  MOCK: 6,
  ISSUE: 9,
  DOUBTS: 7,
  BATCHES: 1,
  LOGOUT: 11,
  PROFILE: 10,
  HACKATHON: 5,
  DISCUSSION: 4,
  NOTIFICATION: 8,
  CODING_CHALLENGE: 2,
};

const BOARD_WIDGET_DELETE = {
  NO: 1,
  YES: 0,
};

const BOARD_WIDGET_COLUMNS_HIDE = {
  NO: 1,
  YES: 0,
};

const DATE_RANGE = {
  CUSTOM: 8,
  THIS_WEEK: 1,
  LAST_WEEK: 3,
  THIS_MONTH: 2,
  LAST_MONTH: 5,
  LAST_2_WEEK: 4,
  LAST_3_MONTHS: 6,
  FROM_STARTING: 7,
};

const PERCENTAGE_DURATION = {
  BOTH: 3,
  GIVEN_PERIOD: 2,
  BATCH_START_DATE: 1,
};

const LEADERBOARD_COVERAGE = {
  COLLEGE: 2,
  COMPLETE_BATCH: 1,
};

const ORIENTATION = {
  PORTRAIT: 1,
  LANDSCAPE: 2,
};

const TEST_ACTIONS = {
  WARN: 1,
  REMOVE_FROM_TEST: 2,
  REMOVE_FROM_BATCH: 3,
};

const ACCOUNT_TYPE = {
  LMS: 1,
  CLIENT_EVAL: 2,
};

const PROFILE_ATTRIBUTES = {
  TAGS: 6,
  CODING_SCORE: 9,
  GRAMMAR_SCORE: 8,
  SKILL_FEEDBACK: 4,
  APTITUDE_SCORE: 7,
  ASSIGNMENT_SCORE: 1,
  ASSESSMENT_SCORE: 2,
  OVERALL_FEEDBACK: 5,
  ASSIGNMENT_COMPLETED: 3,
};

const HACKATHON_MODE = {
  ONLINE: 1,
  OFFLINE: 2,
};

const HACKATHON_ENTRY = {
  PAID: 1,
  FREE: 2,
};

const locations = [
  { label: "Gurugram", value: 10 },
  { label: "Mumbai", value: 9 },
  { label: "Pune", value: 8 },
  { label: "Delhi", value: 4 },
  { label: "Noida", value: 7 },
  { label: "Chennai", value: 5 },
  { label: "Kolkata", value: 6 },
  { label: "Hyderabad", value: 1 },
  { label: "Bengaluru", value: 3 },
  { label: "Visakhapatnam", value: 2 },
];

let jobtype = {
  Contract: 2,
  PartTime: 3,
  Permanent: 1,
};


let workmode = [
  { value: 2, label: "Remote" },
  { value: 3, label: "In Office" },
  { value: 1, label: "Hybrid" },
];

let roletype = [
  { value: 1, label: "Jobs" },
  { value: 0, label: "Internships" },
];

let experience = [
  { value: 4, label: "7+ years" },
  { value: 1, label: "0-3 years" },
  { value: 2, label: "3-5 years" },
  { value: 3, label: "5-7 years" },
];

const qualifications = [
  { value: 3, label: "MCA" },
  { value: 5, label: "MBA" },
  { value: 2, label: "B.Sc" },
  { value: 1, label: "B.Tech" },
  { value: 4, label: "M.Tech" },
];

const ISSUE_STATUS = {
  PENDING: 1,
  RESOLVED: 2,
};

const TOPICS = {
  TREE: 17,
  QUEUE: 16,
  GRAPH: 19,
  ARRAYS: 4,
  STACK: 15,
  SERIES: 3,
  STRINGS: 5,
  SORTING: 6,
  HASHING: 13,
  PATTERNS: 2,
  SEARCHING: 7,
  RECURSION: 8,
  ITERATIONS: 1,
  LINKED_LIST: 14,
  TWO_POINTERS: 10,
  PREFIX_ARRAY: 12,
  BACKTRACKING: 18,
  SLIDING_WINDOW: 11,
  TIME_COMPLEXITY: 9,
  GREEDY_ALGORITHM: 20,
  DYNAMIC_PROGRAMMING: 21,
};

module.exports = {
  MODE,
  Roles,
  TOPICS,
  LANG_ID,
  LINK_TYPE,
  datatypes,
  MENU_ICON,
  BATCH_TYPE,
  DATE_RANGE,
  libTypeIds,
  BOARD_USERS,
  getLangName,
  ORIENTATION,
  TEST_ACTIONS,
  complexityid,
  BOARD_STATUS,
  SESSION_MODE,
  ACCOUNT_TYPE,
  FACE_POSITION,
  ISNOTESTCASES,
  BOARD_WIDGETS,
  RESULT_STATUS,
  SHOW_LIST_VIEW,
  SESSION_STATUS,
  PROGRESS_STATUS,
  QUESTION_STATUS,
  PROCTORING_MODE,
  ATTENDANCE_STATUS,
  PROFILE_ATTRIBUTES,
  PERCENTAGE_DURATION,
  DISPLAY_TEST_RESULT,
  BOARD_WIDGET_DELETE,
  LEADERBOARD_COVERAGE,
  BOARD_WIDGET_COLUMNS_HIDE,
  HACKATHON_MODE,
  HACKATHON_ENTRY,
  locations,
  jobtype,
  workmode,
  qualifications,
  experience,
  roletype,
  ISSUE_STATUS,
};
