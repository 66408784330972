import React from "react";

const ToggleButton = ({
  size,
  text,
  supportingText,
  pressed,
  theme = "Dark",
  disabled = false,
  onChange,
  name,
}) => {
  const handleOnClick = (event) => {
    event.preventDefault();
    event.stopPropagation();
    if (onChange) {
      onChange(disabled ? pressed : !pressed);
    }
  };

  return (
    <div
      className={`toggleBtnContainer ${disabled ? "disabled" : ""}`}
      onClick={handleOnClick}
    >
      <button
        name={name}
        className={`toggleBtn${
          pressed || pressed === 1 ? " toggled" : ""
        } ${theme?.toLowerCase()}${size === "md" ? " medium" : " small"}`}
        disabled={disabled}
      >
        <div className="toggleBtnThumb"></div>
      </button>
      <div
        className={`toggleBtnLabelSection ${
          size === "md" ? "medium" : "small"
        }`}
      >
        <div className="toggleBtnLabel">{text}</div>
        <div className="toggleBtnSupportingText">{supportingText}</div>
      </div>
    </div>
  );
};

export default ToggleButton;
