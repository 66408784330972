import "./LoginPage.scss";
import axios from "../../api/axios";
import jwt_decode from "jwt-decode";
import constants from "../../constants.js";
import useAuth from "../../Hooks/useAuth.js";
import { useLocation } from "react-router-dom";
import { getDataFromStorage } from "../../util";
import AlertContext from "../../context/AlertContext.js";
import React, { useContext, useEffect, useRef } from "react";
import Loading from "../Notification-Loading/Loading/Loading";

const LoginPage = () => {
  const Alert = useContext(AlertContext);

  let role = getDataFromStorage("role");

  const { setAuth } = useAuth();

  let location = useLocation();

  const from = useRef(location?.state?.from?.pathname || "/");

  useEffect(() => {
    if (!localStorage.tokenKey) {
      /* global google*/

      google.accounts.id.initialize({
        callback: handleCredentialResponse,
        client_id: process.env.REACT_APP_GOOGLE_CLIENT_ID,
      });

      google.accounts.id.renderButton(
        document.getElementById("signInBtn"),
        { theme: "outline", size: "large" } // customization attributes
      );

      google.accounts.id.prompt(); // also display the One Tap dialog

      async function handleCredentialResponse(response) {
        Alert.setIsLoaded(true);

        try {
          const validateResponse = await axios.post(
            "node/auth/validate",
            {
              jwtcredential: response.credential,
              refreshToken: localStorage.getItem("refreshToken"),
            },
            {
              headers: {
                Authorization: "abdjcdifouvn",
                "Content-type": "application/json",
              },
            }
          );

          let token = validateResponse.data.accessToken;
          let refreshToken = validateResponse.data.refreshToken;

          localStorage.setItem("picture", validateResponse.data.picture);
          Alert.setUserProfilePic(validateResponse.data.picture);

          if (token && refreshToken) {
            setAuth({ token, refreshToken });
            localStorage.setItem("tokenKey", token);
            localStorage.setItem("refreshToken", refreshToken);

            //decoding the jwt access token comming from server

            let result = jwt_decode(token);

            if (result.role === constants.Roles.learner) {
              Alert.setIsAdmin(false);
              from.current = "/main/dashboard";
              Alert.navigate("/main/dashboard", { replace: true });
            } else if (
              result.role === constants.Roles.admin ||
              result.role === constants.Roles.trainer
            ) {
              Alert.setIsAdmin(true);
              from.current = "/batches";
              Alert.navigate("/batches", { replace: true });
            } else if (result.role === constants.Roles.hr) {
              Alert.setIsAdmin(false);
              from.current = "/batches";
              Alert.navigate("/batches", { replace: true });
            } else if (result.role === constants.Roles.parent) {
              from.current = "/main/dashboard";
              Alert.navigate("/main/dashboard", { replace: true });
            } else {
              Alert.setShowNotify({
                show: true,
                title: "Warning",
                msg: "Email does not exist",
              });
              Alert.navigate("/");
            }
          } else {
            Alert.setShowNotify({
              show: true,
              title: "Warning",
              msg: validateResponse.data.message,
            });
          }
        } catch (err) {
          Alert.setShowNotify({
            show: true,
            title: "Warning",
            msg: "The email id does not exit. Please contact our gradious team",
          });
        } finally {
          Alert.setIsLoaded(false);
        }
      }
    } else if (localStorage.getItem("tokenKey")) {
      if (role === constants.Roles.learner || role === constants.Roles.parent) {
        Alert.setIsAdmin(false);
        Alert.navigate("/main/dashboard", { replace: true });
      } else if (
        role === constants.Roles.admin ||
        role === constants.Roles.trainer
      ) {
        Alert.setIsAdmin(true);
        Alert.navigate("/batches", { replace: true });
      } else if (role === constants.Roles.hr) {
        Alert.setIsAdmin(false);
        Alert.navigate("/dashboards", { replace: true });
      }
    }
  }, []);

  return (
    <>
      <div className="loginpg"></div>
      <form className="loginForm">
        <div className="image">
          <img
            alt="Gradious"
            className="logo1"
            style={{ width: "200px" }}
            src="https://gradious.com/wp-content/uploads/2021/09/Final-Logo-2.svg"
          />
        </div>
        <div className="tabs">Sign In</div>
        <div className="signInForm">
          <button id="signInBtn"> SignIn with google </button>
        </div>
      </form>
      {Alert.isLoaded && <Loading />}
    </>
  );
};

export default LoginPage;
