import { nanoid } from "nanoid";
import NavDropdown from "react-bootstrap/NavDropdown";
import AlertContext from "../../../context/AlertContext.js";
import React, { useContext, useEffect } from "react";
import moment from "moment";
import { Link, Outlet, useLocation, useParams } from "react-router-dom";
import useAxiosPrivate from "../../../Hooks/useAxiosPrivate.js";
import constants from "../../../constants.js";
import { getDataFromStorage, emailBody } from "../../../util.js";

const Batch = () => {
  const {
    lpId,
    setLpId,
    setReportSortState,
    setHiddenColumnsInTable,
    setSearchText,
    setReportTopics,
    setReportStatus,
    setTypesAndModules,
    viewLearnerData,
    selectedColumns,
    viewColumns,
    setIsAddLpModule,
    setLpData2,
    setIsReportClick,
    setIsLoaded,
    setIsFromBatch,
    setIsOldLpDetail,
    setModuleExpandKey,
    batchDetails,
    setBatchDetails,
    navigate,
    setShowNotify,
  } = useContext(AlertContext);
  const axios = useAxiosPrivate();
  const { pathname } = useLocation();
  const { id } = useParams();

  useEffect(() => {
    const fetchBatchData = async () => {
      try {
        // setIsLoaded(true);
        let response = await axios.get(`node/admin/batch/${id}`, {
          headers: {
            "Content-type": "application/json",
          },
        });
        if (response.data.resultCode === constants.RESULT_STATUS.SUCCESS) {
          let batchDetail = JSON.parse(response.data.data[0].data);
          batchDetail.emailBody = emailBody;
          if (batchDetail.learningpath !== null) {
            batchDetail.learningpath = batchDetail.learningpath.filter(
              (lp) => lp.lpid !== null
            );
            for (let i = 0; i < batchDetail.learningpath.length; i++) {
              batchDetail.learningpath[i].coursename =
                batchDetail.learningpath[i].lpname;
            }
          }
          sessionStorage.setItem("abatchid", id);

          setBatchDetails(batchDetail);
          // setIsLoaded(false);
        } else {
          setShowNotify({
            show: true,
            title: "Error !",
            msg: "Something went wrong. Please try again later.",
          });
        }
      } catch (error) {
        if (error.message.includes("403")) {
          setShowNotify({
            show: true,
            title: "Warning !",
            msg: "You have been logged-out due to inactivity. Login again.",
            isUnAuth: true,
          });
        } else
          setShowNotify({
            show: true,
            title: "Error !",
            msg: "Something went wrong. Please try again later.",
            isUnAuth: true,
          });
      } finally {
        // setIsLoaded(false);
      }
    };
    fetchBatchData();
  }, [id, axios, setShowNotify, setBatchDetails, setIsLoaded]);

  const handleGetLpApi = async (lpid, lpname) => {
    try {
      if (!lpid) {
        setShowNotify({
          show: true,
          title: "Warning !",
          msg: "There is no course assigned to this batch.",
        });
        return;
      }

      setIsLoaded(true);
      let res = await axios.get("node/admin/learningpath/" + lpid, {
        headers: {
          "Content-type": "application/json",
        },
      });

      if (res.data.resultCode === 1000) {
        if (res.data.data === null) {
          setLpData2({
            lpDes: "",
            lpId: lpid,
            id: nanoid(),
            moduleId: "",
            libTypeId: 2,
            lpName: lpname,
            isAddTest: false,
            modules: [
              {
                id: 1,
                key: nanoid(),
                status: "new",
                moduleName: "",
                moduleData: [],
              },
            ],
          });
          setIsAddLpModule(true);
          setIsOldLpDetail(true);
          setIsFromBatch(true);
        } else {
          let newDetails = JSON.parse(JSON.stringify(res.data.data[0])),
            uniqueId = 1;

          newDetails.modules.sort((a, b) => a.moduleorderno - b.moduleorderno);

          newDetails.batchName = batchDetails.name;
          newDetails.batchId = batchDetails.id;

          for (let i = 0; i < newDetails.modules.length; i++) {
            newDetails.modules[i].id = uniqueId;
            newDetails.modules[i].key = nanoid();
            newDetails.modules[i].isAddTest = false;
            newDetails.modules[i].status = "old";
            uniqueId++;

            for (let j = 0; j < newDetails.modules[i].moduleData.length; j++) {
              newDetails.modules[i].moduleData[j].moduleDataId = uniqueId;
              newDetails.modules[i].moduleData[j].key = nanoid();
              newDetails.modules[i].moduleData[j].status = "old";
              newDetails.modules[i].moduleData[j].testStatus = "old";
              newDetails.modules[i].moduleData[j].title =
                newDetails.modules[i].moduleData[j].testName;
              newDetails.modules[i].moduleData[j].dueDate = moment(
                newDetails.modules[i].moduleData[j].dueDate
              ).format("DD/MM/YY HH:mm a");
              delete newDetails.modules[i].moduleData[j].testName;
              uniqueId++;
            }
          }
          setIsAddLpModule(true);
          setLpData2(newDetails);
          setModuleExpandKey([newDetails.modules[0].key]);
          setIsFromBatch(true);
          setIsOldLpDetail(true);
        }

        navigate(`/batches/${id}/courses`);
      } else {
        setShowNotify({
          show: true,
          title: "Error !",
          msg: "Something went wrong. Please try again later.",
        });
      }
    } catch (error) {
      if (error.message.includes("403")) {
        setShowNotify({
          show: true,
          title: "Warning !",
          msg: "You have been logged-out due to inactivity. Login again.",
          isUnAuth: true,
        });
      } else {
        setShowNotify({
          show: true,
          title: "Error !",
          msg: "Something went wrong. Please try again later.",
        });
      }
    } finally {
      setIsLoaded(false);
    }
  };

  const handleGetCourseUsersDetail = () => {
    navigate(`/batches/${id}/users`);
  };

  const handleGetCourseAttendanceDetail = () => {
    setIsLoaded(true);
    navigate(`/batches/${id}/event`);
  };

  const handleReport = (lp) => {
    localStorage.setItem("reportBatchName", batchDetails.name);
    setIsReportClick(true);
    if (lpId !== lp.lpid) {
      setReportSortState([]);
      setLpId(lp.lpid);
      setSearchText("");
      setHiddenColumnsInTable([]);
      setReportTopics([]);
      setReportStatus([
        {
          label: "Yet To Start",
          value: constants.PROGRESS_STATUS.LOCKED,
          checked: false,
          disabled: false,
        },
        {
          label: "Submitted",
          value: constants.PROGRESS_STATUS.TO_BE_EVALUATED,
          checked: false,
          disabled: false,
        },
        {
          label: "Yet To Submit",
          value: constants.PROGRESS_STATUS.UNLOCKED,
          checked: false,
          disabled: false,
        },
        {
          label: "Resubmission Granted",
          value: constants.PROGRESS_STATUS.REATTEMPT,
          checked: false,
          disabled: false,
        },
        {
          label: "Graded",
          value: constants.PROGRESS_STATUS.EVALUATED,
          checked: false,
          disabled: false,
        },
      ]);
      setTypesAndModules([
        {
          label: "Active",
          value: "active",
          checked: false,
          disabled: false,
        },
        {
          label: "Assignment",
          value: "assi",
          checked: false,
          disabled: false,
        },
        {
          label: "Coding",
          value: "ccq",
          checked: false,
          disabled: false,
        },
        {
          label: "Coding Totals",
          value: "cct",
          checked: false,
          disabled: false,
        },
        {
          label: "Confidence",
          value: "confidence",
          checked: false,
          disabled: false,
        },
        {
          label: "Description",
          value: "des",
          checked: false,
          disabled: false,
        },
        {
          label: "Inactive",
          value: "inactive",
          checked: false,
          disabled: false,
        },
        {
          label: "MCQ",
          value: "mcq",
          checked: false,
          disabled: false,
        },
        {
          label: "Module Totals",
          value: "moduletotals",
          checked: false,
          disabled: false,
        },
        {
          label: "Performance",
          value: "performance",
          checked: false,
          disabled: false,
        },
        {
          label: "Practice",
          value: "practice",
          checked: false,
          disabled: false,
        },
      ]);
      viewLearnerData.current = [];
      selectedColumns.current = [];
      viewColumns.current = [];
      sessionStorage.setItem("bdNestContainer", 0);
      sessionStorage.setItem("admin-reports-table-div", 0);
    }
    navigate(`/batches/${id}/report`);
  };

  return (
    <div className="bdBatchDetailSec">
      <div className="bdNavBarSec">
        <ul className="bdNavBar">
          {getDataFromStorage("role") !== constants.Roles.hr && (
            <>
              <li className="bdNav">
                {batchDetails?.learningpath?.length > 1 ? (
                  <NavDropdown
                    title="Courses"
                    menuVariant="light"
                    id="bdNavLink bdCourseNav"
                    className={`bdNavLink ${
                      pathname === `/batches/${id}/courses` ? "bdNavactive" : ""
                    }`}
                  >
                    {batchDetails?.learningpath?.map(
                      (lp) =>
                        lp.lpid &&
                        lp.lpname && (
                          <NavDropdown.Item
                            key={lp.lpid}
                            className="bdCourseLink"
                            onClick={() => handleGetLpApi(lp.lpid, lp.lpname)}
                          >
                            {lp.lpname}
                          </NavDropdown.Item>
                        )
                    )}
                  </NavDropdown>
                ) : (
                  <p
                    id="bdNavLink"
                    className={`bdNavLink bdNavP ${
                      pathname === `/batches/${id}/courses` ? "bdNavactive" : ""
                    }`}
                    onClick={() =>
                      handleGetLpApi(
                        batchDetails?.learningpath[0]?.lpid,
                        batchDetails.learningpath[0]?.lpname
                      )
                    }
                  >
                    Courses
                  </p>
                )}
              </li>
            </>
          )}

          {/* ----------------  User Page -------------- */}
          <li className="bdNav">
            {batchDetails?.learningpath?.length >= 1 &&
            batchDetails?.learningpath[0]?.lpid !== null ? (
              <p
                id="bdNavLink"
                to={`/batches`}
                className={`bdNavLink bdNavP ${
                  pathname === `/batches/${id}/users` ? "bdNavactive" : ""
                }`}
                onClick={() =>
                  handleGetCourseUsersDetail(batchDetails?.learningpath[0].lpid)
                }
              >
                Users
              </p>
            ) : (
              <p
                id="bdNavLink"
                onClick={() =>
                  handleGetCourseUsersDetail(batchDetails?.learningpath[0].lpid)
                }
                className={`bdNavLink bdNavP ${
                  pathname === `/batches/${id}/users` ? "bdNavactive" : ""
                }`}
              >
                Users
              </p>
            )}
          </li>
          {getDataFromStorage("role") !== constants.Roles.hr &&
            batchDetails?.batchtype === constants.BATCH_TYPE.LEARNING && (
              <li className="bdNav">
                <p
                  id="bdNavLink"
                  to={`/batches/attendance`}
                  className={`bdNavLink bdNavP ${
                    pathname === `/batches/${id}/event` ||
                    pathname === `/batches/${id}/event/add`
                      ? "bdNavactive"
                      : ""
                  }`}
                  onClick={() => handleGetCourseAttendanceDetail()}
                >
                  Event
                </p>
              </li>
            )}
          <li className="bdNav">
            {batchDetails?.learningpath?.length > 1 ? (
              <NavDropdown
                title="Report"
                menuVariant="light"
                id="bdNavLink bdCourseNav"
                className={`bdNavLink ${
                  pathname === `/batches/${id}/report` ? "bdNavactive" : ""
                }`}
              >
                {batchDetails.learningpath?.map(
                  (lp) =>
                    lp.lpid &&
                    lp.lpname && (
                      <NavDropdown.Item
                        key={lp.lpid}
                        className="bdCourseLink"
                        onClick={() => handleReport(lp)}
                      >
                        {lp.lpname}
                      </NavDropdown.Item>
                    )
                )}
              </NavDropdown>
            ) : batchDetails?.learningpath?.length === 1 &&
              batchDetails?.learningpath[0]?.lpid !== null ? (
              <p
                id="bdNavLink"
                className={`bdNavLink bdNavP ${
                  pathname === `/batches/${id}/report` ? "bdNavactive" : ""
                }`}
                onClick={() => handleReport(batchDetails?.learningpath[0])}
              >
                Report
              </p>
            ) : (
              <p
                id="bdNavLink"
                onClick={() =>
                  setShowNotify({
                    show: true,
                    msg: "There is no course assigned to this batch",
                    title: "Warning !",
                  })
                }
                className={`bdNavLink bdNavP ${
                  pathname === `/batches/${id}/report` ? "bdNavactive" : ""
                }`}
              >
                Report
              </p>
            )}
          </li>
          {getDataFromStorage("role") !== constants.Roles.hr &&
            batchDetails?.batchtype === constants.BATCH_TYPE.LEARNING && (
              <li className="bdNav">
                <p
                  id="bdNavLink"
                  onClick={() => navigate(`/batches/${id}/issue`)}
                  className={`bdNavLink bdNavP ${
                    pathname === `/batches/${id}/issue` ? "bdNavactive" : ""
                  }`}
                >
                  Support Requests
                </p>
              </li>
            )}
          {getDataFromStorage("role") !== constants.Roles.hr && (
            <li className="bdNav">
              <Link
                id="bdNavLink"
                to={`/batches/${id}/settings/details`}
                className={`bdNavLink ${
                  pathname.includes(`/batches/${id}/settings`)
                    ? "bdNavactive"
                    : ""
                }`}
              >
                Settings
              </Link>
            </li>
          )}
          {/* {getDataFromStorage("role") !== constants.Roles.hr && ( */}
            <li className="bdNav">
              <Link
                id="bdNavLink"
                to={`/batches/${id}/timeline`}
                className={`bdNavLink ${
                  pathname.includes(`/batches/${id}/timeline`)
                    ? "bdNavactive"
                    : ""
                }`}
              >
                Calendar
              </Link>
            </li>
          {/* )} */}
        </ul>
      </div>
      <div
        style={
          pathname === `/batches/${id}/courses`
            ? {
                overflow: "auto",
                paddingTop: "18px",
                height: "calc(100vh - 25vh)",
              }
            : pathname === `/batches/${id}/users`
            ? {
                overflowY: "auto",
                height: "calc(100vh - 16.2vh)",
                paddingTop: "7px",
              }
            : pathname.includes("settings")
            ? {
                padding: 0,
                height: "calc(100vh - 16.2vh)",
              }
            : {
                overflow: "auto",
                paddingTop: "18px",
                height: "calc(100vh - 16.2vh)",
              }
        }
      >
        <Outlet />
      </div>
    </div>
  );
};

export default Batch;
