// import React, { useState, useEffect, useRef } from "react";
// import { Menu, MenuItem, Button, Typography } from "@mui/material";
// import { ArrowRight } from "@mui/icons-material";

// const MultiLevelDropdown = ({
//   placeholder = "Select",
//   items,
//   selectedOption,
//   onSelect,
// }) => {
//   const [anchorEl, setAnchorEl] = useState(null); // For the main menu
//   const [submenuAnchorEl, setSubmenuAnchorEl] = useState({}); // For submenus
//   //   const [selectedOption, setSelectedOption] = useState(""); // Track selected option
//   const dropdownRef = useRef(null); // Ref to track the dropdown

//   const handleOpenMenu = (event) => setAnchorEl(event.currentTarget);
//   const handleCloseMenu = () => {
//     setAnchorEl(null);
//     setSubmenuAnchorEl({});
//   };

//   const handleOpenSubmenu = (event, index) => {
//     setSubmenuAnchorEl((prev) => ({
//       ...prev,
//       [index]: event.currentTarget,
//     }));
//   };

//   const handleCloseSubmenu = (index) => {
//     setSubmenuAnchorEl((prev) => ({
//       ...prev,
//       [index]: null,
//     }));
//   };

//   const handleSelect = (item) => {
//     if (onSelect) onSelect(item); // Update selected option
//     handleCloseMenu(); // Close all menus
//   };

//   // Detect clicks outside the dropdown to close it
//   useEffect(() => {
//     const handleClickOutside = (event) => {
//       if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
//         handleCloseMenu();
//       }
//     };

//     document.addEventListener("mousedown", handleClickOutside);
//     return () => document.removeEventListener("mousedown", handleClickOutside);
//   }, []);

//   const renderSubmenu = (submenuItems, index) => (
//     <Menu
//       anchorEl={submenuAnchorEl[index]}
//       open={Boolean(submenuAnchorEl[index])}
//       onClose={() => handleCloseSubmenu(index)}
//       anchorOrigin={{
//         vertical: "top",
//         horizontal: "right",
//       }}
//       transformOrigin={{
//         vertical: "top",
//         horizontal: "left",
//       }}
//     >
//       {submenuItems.map((subItem, subIndex) => (
//         <MenuItem
//           key={subIndex}
//           onClick={() => {
//             if (subItem.children?.length === 0) handleSelect(subItem);
//           }}
//           onMouseEnter={(e) =>
//             subItem.children?.length > 0 &&
//             handleOpenSubmenu(e, `${index}-${subIndex}`)
//           }
//           onMouseLeave={(e) =>
//             setSubmenuAnchorEl((prev) => ({
//               ...prev,
//               [`${index}-${subIndex}`]: null,
//             }))
//           }
//         >
//           <Typography variant="body1" style={{ flexGrow: 1 }}>
//             {subItem.label}
//           </Typography>
//           {subItem.children?.length > 0 && <ArrowRight fontSize="small" />}
//           {subItem.children?.length > 0 &&
//             renderSubmenu(subItem.children, `${index}-${subIndex}`)}
//         </MenuItem>
//       ))}
//     </Menu>
//   );

//   return (
//     <div ref={dropdownRef} className="multiLevelSelect">
//       <Button
//         variant="outlined"
//         endIcon={
//           <svg
//             class="inputDropdownIcon chevronDown"
//             width="20"
//             height="20"
//             viewBox="0 0 20 20"
//             fill="none"
//             xmlns="http://www.w3.org/2000/svg"
//           >
//             <path
//               d="M5 7.5L10 12.5L15 7.5"
//               stroke="#667085"
//               stroke-width="1.66667"
//               stroke-linecap="round"
//               stroke-linejoin="round"
//             ></path>
//           </svg>
//         }
//         onClick={handleOpenMenu}
//       >
//         {selectedOption || placeholder}
//       </Button>
//       <Menu
//         anchorEl={anchorEl}
//         open={Boolean(anchorEl)}
//         onClose={handleCloseMenu} // Close the entire dropdown when clicking outside
//         anchorOrigin={{
//           vertical: "bottom",
//           horizontal: "left",
//         }}
//         transformOrigin={{
//           vertical: "top",
//           horizontal: "left",
//         }}
//         className="multiLevelSelectMenu"
//       >
//         {items.map((item, index) => (
//           <MenuItem
//             key={index}
//             onMouseEnter={(e) =>
//               item.children?.length > 0 &&
//               handleOpenSubmenu(e, index.toString())
//             }
//             onMouseLeave={(e) =>
//               setSubmenuAnchorEl((prev) => ({
//                 ...prev,
//                 [index]: null,
//               }))
//             }
//             onClick={() => {
//               if (item.children?.length === 0) handleSelect(item.label);
//             }}
//           >
//             <Typography variant="body1" style={{ flexGrow: 1 }}>
//               {item.label}
//             </Typography>
//             {item.children?.length > 0 && <ArrowRight fontSize="small" />}
//             {item.children?.length > 0 &&
//               renderSubmenu(item.children, index.toString())}
//           </MenuItem>
//         ))}
//       </Menu>
//     </div>
//   );
// };

// export default MultiLevelDropdown;

import React, { useEffect, useRef, useState } from "react";
import { Button } from "@mui/material";

const MultiLevelDropdown = ({
  items,
  placeholder = "Select",
  selectedOption,
  onSelect,
}) => {
  const [open, setOpen] = useState(false);
  const handleOpenMenu = () => {
    setOpen(true);
  };

  let ref = useRef();

  useEffect(() => {
    const handler = (event) => {
      if (open && ref.current && !ref.current.contains(event.target)) {
        setOpen(false);
      }
    };
    document.addEventListener("mousedown", handler);
    document.addEventListener("touchstart", handler);
    return () => {
      // Cleanup the event listener
      document.removeEventListener("mousedown", handler);
      document.removeEventListener("touchstart", handler);
    };
  }, [open]);
  return (
    <div className="multiLevelSelect" ref={ref}>
      <Button
        variant="outlined"
        endIcon={
          <svg
            class="inputDropdownIcon chevronDown"
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M5 7.5L10 12.5L15 7.5"
              stroke="#667085"
              stroke-width="1.66667"
              stroke-linecap="round"
              stroke-linejoin="round"
            ></path>
          </svg>
        }
        onClick={handleOpenMenu}
      >
        {selectedOption || placeholder}
      </Button>
      {open && (
        <Dropdown
          submenus={items}
          dropdown={open}
          depthLevel={0}
          onSelect={onSelect}
          setOpen={setOpen}
        />
      )}
    </div>
  );
};

export default MultiLevelDropdown;

export const MenuItems = ({ items, depthLevel, onSelect, setOpen }) => {
  const [dropdown, setDropdown] = useState(false);

  let ref = useRef();

  useEffect(() => {
    const handler = (event) => {
      if (dropdown && ref.current && !ref.current.contains(event.target)) {
        setDropdown(false);
      }
    };
    document.addEventListener("mousedown", handler);
    document.addEventListener("touchstart", handler);
    return () => {
      // Cleanup the event listener
      document.removeEventListener("mousedown", handler);
      document.removeEventListener("touchstart", handler);
    };
  }, [dropdown]);

  const onMouseEnter = () => {
    window.innerWidth > 960 && setDropdown(true);
  };

  const onMouseLeave = () => {
    window.innerWidth > 960 && setDropdown(false);
  };

  return (
    <li
      className="menu-items"
      ref={ref}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      {items.children?.length > 0 ? (
        <>
          <button
            type="button"
            aria-haspopup="menu"
            aria-expanded={dropdown ? "true" : "false"}
            onClick={() => setDropdown((prev) => !prev)}
          >
            {items.label}{" "}
            {depthLevel > 0 ? <span>&raquo;</span> : <span className="arrow" />}
          </button>
          <Dropdown
            depthLevel={depthLevel}
            submenus={items.children}
            dropdown={dropdown}
            onSelect={onSelect}
            setOpen={setOpen}
          />
        </>
      ) : (
        <button
          type="button"
          onClick={() => {
            setDropdown((prev) => !prev);
            setOpen(false);
            onSelect(items);
          }}
        >
          {items.label}
        </button>
      )}
    </li>
  );
};

const Dropdown = ({ submenus, dropdown, depthLevel, onSelect, setOpen }) => {
  depthLevel = depthLevel + 1;
  const dropdownClass = depthLevel > 1 ? "dropdown-submenu" : "";
  return (
    <ul className={`dropdown ${dropdownClass} ${dropdown ? "show" : ""}`}>
      {submenus.map((submenu, index) => (
        <MenuItems
          items={submenu}
          key={index}
          depthLevel={depthLevel}
          onSelect={onSelect}
          setOpen={setOpen}
        />
      ))}
    </ul>
  );
};
