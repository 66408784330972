import React from "react";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DesktopDatePicker } from "@mui/x-date-pickers";
import dayjs from "dayjs";

export default function Datepicker({
  value,
  onChange,
  format = "DD-MM-YYYY",
  ...props
}) {
  return (
    <div>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DesktopDatePicker
          {...props}
          format={format}
          value={dayjs(value)}
          onChange={onChange}
        />
      </LocalizationProvider>
    </div>
  );
}
