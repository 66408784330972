import { nanoid } from "nanoid";
import { useContext } from "react";
import AlertContext from "../../../../context/AlertContext";
import { MockTestPageContext } from "../../../../context/MockTestPageContext";

export default function MockLearningPathHooks() {
  const Alert = useContext(AlertContext);
  const MockTestPageCxt = useContext(MockTestPageContext);

  const MAX_IMAGE_SIZE = 150000;

  const handleLpDetails = (event) => {
    let newDetails = JSON.parse(JSON.stringify(MockTestPageCxt.newLpDetails));

    if (event.target.name === "lpIcon") {
      let reader = new FileReader();

      reader.onloadend = () => {
        if (reader.result.length > MAX_IMAGE_SIZE) {
          Alert.setShowNotify({
            show: true,
            title: "Warning",
            msg: "File size is too large.",
          });
          event.target.value = "";

          return;
        }

        newDetails[event.target.name] = reader.result;

        if (Alert.isOldLpDetail) newDetails.status = "update";

        MockTestPageCxt.setNewLpDetails(newDetails);
      };

      reader.readAsDataURL(event.target.files[0]);
    } else newDetails[event.target.name] = event.target.value;

    if (Alert.isOldLpDetail) newDetails.status = "update";

    MockTestPageCxt.setNewLpDetails(newDetails);
  };

  const handleClickNext = () => {
    if (MockTestPageCxt.newLpDetails.lpName === "") {
      Alert.setShowNotify({
        show: true,
        title: "Warning",
        msg: "Please enter the mock group test name",
      });
    } else Alert.setIsAddLpModule(true);

    if (Alert.isOldLpDetail) {
      MockTestPageCxt.newLpDetails.status = "update";
      Alert.setLpData2(MockTestPageCxt.newLpDetails);
    }
  };

  const handleCancel = () => {
    Alert.setLpData2({
      lpDes: "",
      lpName: "",
      lpIcon: "",
      moduleId: "",
      id: nanoid(),
      libTypeId: 2,
      lpContent: "",
      lpShortDes: "",
      isAddTest: false,
      modules: [
        {
          id: 1,
          key: nanoid(),
          status: "new",
          moduleName: "",
          moduleData: [],
        },
      ],
    });

    Alert.setIsAddLp(false);
    Alert.setIsOldLpDetail(false);
    Alert.navigate("mock/learningpath");
  };

  return {
    handleCancel,
    handleLpDetails,
    handleClickNext,
  };
}
