import constants from "../constants";
import { useContext, useState } from "react";
import AlertContext from "../context/AlertContext";
import CodingTestHooks from "./CodingPage/CodingTestHooks";
import CodingViewHooks from "./CodingPage/CodingViewHooks";

export default function CodingTableHooks() {
  const {
    setTopic,
    testData,
    setStatus,
    setCodingTestData,
    setShowInstruction,
  } = useContext(AlertContext);

  const { viewCode } = CodingViewHooks();
  const { takeTest } = CodingTestHooks();

  const [expanded, setExpanded] = useState(false);

  const handleChange = (test) => {
    if (handleExpand(test).expand) {
      setExpanded(!expanded);
      setTopic({ id: test.topicid, name: test.topicname });
    } else {
      setExpanded(false);
    }
  };

  const handleExpand = (test) => {
    if (
      !test.showIncompleteTest &&
      (((testData.viewtest === constants.DISPLAY_TEST_RESULT.SUBMISSION ||
        testData.viewtest === constants.DISPLAY_TEST_RESULT.ALL) &&
        test.completedcount === test.totalcount) ||
        ((test.showTakeTest || test.showRetakeTest || test.showResumeTest) &&
          (test.completedcount === 0 ||
            test.completedcount === test.totalcount)) ||
        ((test.showTakeTest || test.showRetakeTest || test.showResumeTest) &&
          (test.completedcount !== 0 ||
            test.completedcount !== test.totalcount) &&
          (testData.viewtest === constants.DISPLAY_TEST_RESULT.NONE ||
            testData.viewtest === constants.DISPLAY_TEST_RESULT.SCORE)))
    ) {
      return {
        expand: false,
        disable: false,
      };
    } else if (
      !test.showIncompleteTest &&
      (test.showTakeTest || test.showRetakeTest || test.showResumeTest) &&
      (test.completedcount !== 0 || test.completedcount !== test.totalcount)
    ) {
      return {
        expand: true,
        disable: false,
      };
    } else {
      return {
        expand: false,
        disable: true,
      };
    }
  };

  const testStatus = (test) => {
    if (test.showIncompleteTest) {
      return constants.PROGRESS_STATUS.IN_PROGRESS;
    } else if (test.showRetakeTest || test.showResumeTest) {
      return constants.PROGRESS_STATUS.QN_CONTINUE;
    } else if (
      test.completedcount >= 0 &&
      test.completedcount < test.totalcount
    ) {
      return constants.PROGRESS_STATUS.UNLOCKED;
    } else if (testData.viewtest === constants.DISPLAY_TEST_RESULT.SCORE) {
      return constants.PROGRESS_STATUS.REATTEMPT;
    } else {
      return constants.PROGRESS_STATUS.EVALUATED;
    }
  };

  const currentTestLabel = (test) => {
    if (test.showIncompleteTest) {
      return constants.PROGRESS_STATUS.IN_PROGRESS;
    } else if (test.showResumeTest) {
      return constants.PROGRESS_STATUS.QN_CONTINUE;
    } else if (test.showTakeTest) {
      return constants.PROGRESS_STATUS.UNLOCKED;
    } else if (test.showRetakeTest) {
      return constants.PROGRESS_STATUS.REATTEMPT;
    } else if (
      testData.viewtest === constants.DISPLAY_TEST_RESULT.ALL ||
      testData.viewtest === constants.DISPLAY_TEST_RESULT.SUBMISSION
    ) {
      return constants.PROGRESS_STATUS.EVALUATED;
    } else {
      return constants.PROGRESS_STATUS.LOCKED;
    }
  };

  const handleCodingTest = (test, status, question) => {
    if (
      (question === undefined &&
        (status === constants.PROGRESS_STATUS.UNLOCKED ||
          status === constants.PROGRESS_STATUS.QN_CONTINUE ||
          status === constants.PROGRESS_STATUS.REATTEMPT)) ||
      (!handleExpand(test).expand &&
        !handleExpand(test).disable &&
        status !== constants.PROGRESS_STATUS.EVALUATED &&
        testData.status !== constants.PROGRESS_STATUS.EVALUATED)
    ) {
      document.documentElement.requestFullscreen();
      test.status = currentTestLabel(test);
      setCodingTestData(test);
      setStatus(currentTestLabel(test));
      setTopic({ id: test.topicid, name: test.topicname });

      if (testData.testmode === constants.MODE.TEST_MODE) {
        setShowInstruction(true);
      } else {
        takeTest(test);
      }
    } else {
      viewCode(test, question !== undefined && "single", question);
    }
  };

  const testScore = (score) => {
    let marks = 0,
      actualScore = 0;

    for (let i = 0; i < score.length; i++) {
      marks += score[i].marks;
      actualScore += score[i].actualscore;
    }

    return actualScore + "/" + marks;
  };

  return {
    expanded,
    viewCode,
    testScore,
    testStatus,
    handleExpand,
    handleChange,
    currentTestLabel,
    handleCodingTest,
  };
}
