import AlertContext from "./AlertContext";
import { useLocation } from "react-router-dom";
import { createContext, useContext, useEffect, useState } from "react";

export const MockTestPageContext = createContext();

export default function MockTestPageProvider({ children }) {
  const Alert = useContext(AlertContext);

  const { pathname } = useLocation();

  const [focus, setFocus] = useState(null);
  const [topics, setTopics] = useState([]);
  const [modules, setModules] = useState([]);
  const [qnsCount, setQnsCount] = useState(0);
  const [isNext, setIsNext] = useState(false);
  const [testData, setTestData] = useState({});
  const [mockTest, setMockTest] = useState([]);
  const [languages, setLanguages] = useState([]);
  const [testConfig, setTestConfig] = useState([]);
  const [complexity, setComplexity] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [isChecked, setIsChecked] = useState(false);
  const [isNewTest, setIsNewTest] = useState(false);
  const [selectedQns, setSelectedQns] = useState([]);
  const [showQnList, setShowQnList] = useState(false);
  const [dataRerender, setDataRerender] = useState(false);
  const [searchMockTest, setSearchMockTest] = useState([]);
  const [newLpDetails, setNewLpDetails] = useState(Alert.lpData2);
  const [descriptiveOptions, setDescriptiveOptions] = useState([]);
  const [insufficientQuestions, setInsufficientQuestions] = useState({});
  const [isSufficientQuestions, setIsSufficientQuestions] = useState(true);

  const [isAddTest, setIsAddTest] = useState(
    Alert.oldTestConfig.isOldTestConfig
  );

  const [isBackDetails, setIsBackDetails] = useState({
    testType: "",
    isBack: false,
  });

  const [listQnData, setListQnData] = useState({
    data: [],
    sectionId: "",
    secDataId: "",
  });

  const [expandedRecords, setExpandedRecords] = useState(
    modules?.length > 0 ? modules[0] : []
  );

  useEffect(() => {
    setSearchMockTest([]);
    setMockTest([]);
  }, [pathname]);

  return (
    <MockTestPageContext.Provider
      value={{
        focus,
        isNext,
        topics,
        modules,
        setFocus,
        testData,
        qnsCount,
        mockTest,
        setTopics,
        isChecked, 
        setIsNext,
        isNewTest,
        isAddTest,
        languages,
        setModules,
        testConfig,
        searchText,
        listQnData,
        showQnList,
        complexity,
        setMockTest,
        selectedQns,
        setQnsCount,
        setTestData,
        setIsAddTest,
        setIsNewTest,
        newLpDetails,
        setLanguages,
        setIsChecked,
        setTestConfig,
        setComplexity,
        setShowQnList,
        setSearchText,
        isBackDetails,
        setListQnData,
        setSelectedQns,
        searchMockTest,
        expandedRecords,
        setNewLpDetails,
        setIsBackDetails,
        setSearchMockTest,
        setExpandedRecords,
        descriptiveOptions,
        setDescriptiveOptions,
        isSufficientQuestions,
        insufficientQuestions,
        setInsufficientQuestions,
        setIsSufficientQuestions,
        dataRerender,
        setDataRerender,
      }}
    >
      {children}
    </MockTestPageContext.Provider>
  );
}
