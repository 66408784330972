import { Box, Card, Typography } from '@material-ui/core';
import React, { useContext, useEffect, useState } from 'react';
import SemiCircleProgressBar from "react-progressbar-semicircle";
import LeetCodeProfile from "../../Svg/LeetCodeProfile/LeetcodeProfileSvg";
import StarSvg from "../../Svg/StarSvg";
import CloseSvgIcon from '../../Svg/CloseSvgIcon';
import LeetcodeIconSvg from '../../Svg/LeetCodeProfile/LeetcodeIconSvg';
import useCommonFunctionHooks from '../../Hooks/useCommonFunctionHooks';
import AlertContext from '../../context/AlertContext';
import useAxiosPrivate from '../../Hooks/useAxiosPrivate';
import constants from '../../constants';
import { getDataFromStorage } from '../../util';
import { UserReportContext } from '../../context/UserReportContext';
import ShowMoreSvg from '../../Svg/LeetCodeProfile/ShowMoreSvg';
import ShowLessSvg from '../../Svg/LeetCodeProfile/ShowLessSvg';

export default function LeetCode() {

    const axios = useAxiosPrivate();
    const Alert = useContext(AlertContext);
    const UserReportCxt = useContext(UserReportContext);
    const { getCatchBlockDetails, getHeaderDetailsForAxios } =useCommonFunctionHooks();
    const [showMore,setShowMore] = useState(false);

    useEffect(()=>{
        /* competitive api */        
        axios
        .get(
            `node/learner/competitive/${Alert.isAdmin ? UserReportCxt.currentLearnerDetails.value : getDataFromStorage("learnerid")}`, //4141
            getHeaderDetailsForAxios()
        )
        .then((res)=>{
            if (res.data.resultCode === constants.RESULT_STATUS.SUCCESS) {
                Alert.setCompetitiveData(res.data.data);
            } else {
                Alert.setCompetitiveData([]);
            }
        })
        .catch((err)=>{
            getCatchBlockDetails(err);
        })
        .finally(() => Alert.setIsLoaded(false));
    },[Alert.batchId, UserReportCxt.currentLearnerDetails.value])

    const semiCirclePercentage = (label) => {
        const difficultyData = Alert.competitiveData[0].questionCount.find(data => data[label]);
    
        if (difficultyData && difficultyData[label]) {
            const { count, totalCount } = difficultyData[label];
            const percentage = (count / totalCount) * 100;
            return percentage;
        }
    
        return 0;
    };

    const totalPercentage = () => {
        const easyData = Alert.competitiveData[0].questionCount.find(data => data["Easy"])?.Easy;
        const mediumData = Alert.competitiveData[0].questionCount.find(data => data["Medium"])?.Medium;
        const hardData = Alert.competitiveData[0].questionCount.find(data => data["Hard"])?.Hard;
    
        if (easyData && mediumData && hardData) {
            const totalSolved = easyData.count + mediumData.count + hardData.count;
            const totalQuestions = easyData.totalCount + mediumData.totalCount + hardData.totalCount;
    
            // Calculate overall percentage
            const percentage = (totalSolved / totalQuestions) * 100;
            return percentage === 0 ? 0 : Math.round(percentage); // Optional: to return percentage with 2 decimal places
        }
    
        return 0;
    };

    /* capitalize first letter */
  const capitalizeFirstLetter = (str) => {
    if(str!= undefined) return str[0].toUpperCase() + str.slice(1);
  }

  const sortBycompletedQn = (data) => {
    return data.sort((a,b)=>b.problemsSolved-a.problemsSolved);
  }

  const showMoreStats = () => {
    setShowMore(!showMore);
  }
    

  return (
    <React.Fragment>
        {Alert.competitiveData?.length>0 && <Box className='Leetcode_box'>
            <Box className='platform_name'><LeetcodeIconSvg /></Box>
            <Box className='Leetcode_parent_box'>
                <Box className='Leetcode_left_box'>
                    <Box className='profile_box'>
                        <Box className='profile'><LeetCodeProfile /></Box>
                        <Box className='rank_box'>
                            <Typography className='username'>{Alert.competitiveData[0].username}</Typography>
                            <Typography className='userrank'><StarSvg width={28} height={28}/> <Typography className='userrank_rank'>{Alert.competitiveData[0].overallRanking}</Typography></Typography>
                        </Box>
                    </Box>
                    <Box className='skill_box'>
                        {Object.entries(Alert.competitiveData[0].skillStats).map(([skill, stats]) => (
                            stats.length > 0 && <Box className={`skill_stats_box${skill === "intermediate" ? " intermediate":""}`} key={skill}>
                                <Typography className='skill_stats_header'>{capitalizeFirstLetter(skill)}</Typography>
                                 <Box className={`skill_stats_names${skill === "advanced" ? " advanced": skill === "intermediate" ? " intermediate" : " fundamental" }`}>
	                                     {stats.map(({ tagName, problemsSolved }, index) => (
                                    <Typography key={index} className='stats_name'>
                                        {tagName} <Typography className='stats_name_count'><CloseSvgIcon component={"leetcode"} />{problemsSolved}</Typography>
                                    </Typography>
                                    ))}
                                </Box>
                            </Box>
                        ))}
                    </Box>
                </Box>
                <Box className='Leetcode_right_box'>
                    <Box className='right_content_box'>
                        <Box className='circle_bar_box'>
                            <Box className='top_circle_bar'><SemiCircleProgressBar strokeWidth={12} percentage={semiCirclePercentage("Hard")} stroke={"#F97970"} diameter={289}/></Box>
                            <Box className='middle_circle_bar'><SemiCircleProgressBar strokeWidth={12} percentage={semiCirclePercentage("Medium")} stroke={"#FEC84B"}  diameter={247}/></Box>
                            <Box className='bottom_circle_bar'><SemiCircleProgressBar strokeWidth={12} percentage={semiCirclePercentage("Easy")} stroke={"#6CE9A6"}  diameter={207}/></Box>
                            <Box className='circle_bar_percentage'>{totalPercentage()}%</Box>
                        </Box>
                        <Box className='complexity_box'>
                        {Alert.competitiveData[0].questionCount.map((complexity, index) => {
                            const [key, value] = Object.entries(complexity)[0];
                            return (
                                <Box key={index} className={`complexity_type_box${key === "Medium" ? " medium":""}`}>
                                    <Typography className={`complexity_name${key === "Easy" ? " easy" : key === "Medium" ? " medium" : " hard"}`}>{key}</Typography>
                                    <Typography className='complexity_qn_count'>{value.count + "/" + value.totalCount}</Typography>
                                </Box>
                            );
                            })}
                        </Box>
                    </Box>
                </Box>
            </Box>
        </Box>}
        {(Alert.competitiveData?.length === 0 && Alert.isAdmin) && <Box className='admin_nodata_msg'>
          There is no data to show.
        </Box>}
    </React.Fragment>
  )
}
