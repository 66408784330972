import Button from "../../Button";
import constants from "../../../constants";
import Logo from "../../Admin/Header/Svg/Logo";
import { getDataFromStorage } from "../../../util";
import BackSvgIcon from "../../../Svg/BackSvgIcon";
import Profile from "../../../assets/images/profile.png";
import AlertContext from "../../../context/AlertContext";
import { useReactMediaRecorder } from "react-media-recorder";
import React, { useEffect, useContext, useRef } from "react";
import useAxiosPrivate from "../../../Hooks/useAxiosPrivate";
// import InterviewHooks from "../../../Hooks/AI/InterviewHooks";
import WarningSvg from "../../Notification-Loading/Notify/Svg/WarningSvg";
import useCommonFunctionHooks from "../../../Hooks/useCommonFunctionHooks";
import useUploadLargeFileToS3 from "../../../Hooks/useUploadLargeFileToS3";
import { StudentTestPageContext } from "../../../context/StudentTestPageContext";
import CodingHeaderLearnerSideHooks from "../../../Hooks/CodingPage/CodingHeaderLearnerSideHooks";
import Verifypic from "../../CommonComponents/Verifypic";

export default function Interview() {
  const Alert = useContext(AlertContext);
  const StudentTestPageCxt = useContext(StudentTestPageContext);

  const axios = useAxiosPrivate();
  const useCommonFunctionHks = useCommonFunctionHooks();
  const { uploadLargeFileToS3 } = useUploadLargeFileToS3();
  const { submitAnswers } = CodingHeaderLearnerSideHooks();

  const containerRef = useRef(null);

  useEffect(() => {
    const container = containerRef.current;
    if (container) {
      container.scrollTop = container.scrollHeight;
    }
  }, [containerRef]);

  let { startRecording, stopRecording, previewStream } = useReactMediaRecorder({
    video: true,
    onStop: (blobUrl, blob) => handleStopRecord(blobUrl, blob),
  });

  const handleStopRecord = async (blobUrl, blob) => {
    let data =
      Alert.comingObj.sections[0].questions[StudentTestPageCxt.currentQnIndex];

    let anstext = `VideoRecord/${Alert.comingObj.lmpid}/${Alert.comingObj.testid}/${getDataFromStorage(
      "learnerid"
    )}/${data.id}.mp4`;

    // let temp = JSON.parse(JSON.stringify(Alert.videoQn.current));

    // let currentIndex = temp.findIndex((det) => det.selected === true);
    // let temp2;

    // if (currentIndex !== -1) {
    //   temp[currentIndex].selected = false;
    //   temp[currentIndex].answer = anstext;

    //   // Make questionlit answerd true

    //   temp2 = JSON.parse(JSON.stringify(Alert.questionListObj.current));

    //   for (let det1 in temp2) {
    //     for (let det2 in temp2[det1].qnsObj) {
    //       if (temp2[det1].qnsObj[det2].qId === data.id) {
    //         temp2[det1].qnsObj[det2].isAnswered = true;
    //         temp2[det1].qnsObj[det2].transcript =
    //           StudentTestPageCxt.transcript.current;
    //       }
    //     }
    //   }
    // }

    // if (Alert.exitVdoSubmit.current) {
    //   Alert.videoQn.current = temp;
    //   Alert.questionListObj.current = temp2;
    // }

    // if (!Alert.exitVdoSubmit.current) {
    //   if (isNextClicked.current) {
    //     let nextIndex = (currentIndex + 1) % temp.length;

    //     if (nextIndex === 0 && currentIndex !== -1) {
    //       let unselectedIndex = temp.findIndex(
    //         (det) => det.selected !== true && det.selected !== false
    //       );

    //       if (unselectedIndex !== -1) {
    //         temp[unselectedIndex].selected = true;
    //         StudentTestPageCxt.videoQnNo.current =
    //           parseInt(unselectedIndex) + 1;

    //         for (let det1 in temp2) {
    //           for (let det2 in temp2[det1].qnsObj) {
    //             if (temp2[det1].qnsObj[det2].qId === temp[unselectedIndex].id) {
    //               StudentTestPageCxt.setVdoQnTimelimit(
    //                 temp2[det1].qnsObj[det2].qnduration * 60
    //               );
    //             }
    //           }
    //         }

    //         let count = temp.filter(
    //           (dat) => dat.selected === true || dat.selected === false
    //         ).length;

    //         if (count === temp.length) {
    //           let count = 0;
    //           let trueCount = 0;

    //           for (let det1 in temp2) {
    //             for (let det2 in temp2[det1].qnsObj) {
    //               count++;

    //               if (temp2[det1].qnsObj[det2].isAnswered) {
    //                 trueCount++;
    //               }
    //             }
    //           }

    //           let isShowSubmitBtn = count - trueCount;

    //           if (isShowSubmitBtn === 1) {
    //             StudentTestPageCxt.showSubmitBtn.current = true;
    //           } else {
    //             StudentTestPageCxt.setDisableNxtBtn(true);
    //           }
    //         }
    //       } else {
    //         let count = 0;
    //         let trueCount = 0;

    //         for (let det1 in temp2) {
    //           for (let det2 in temp2[det1].qnsObj) {
    //             count++;

    //             if (temp2[det1].qnsObj[det2].isAnswered) {
    //               trueCount++;
    //             }
    //           }
    //         }

    //         let isShowSubmitBtn = count - trueCount;

    //         if (isShowSubmitBtn === 1) {
    //           StudentTestPageCxt.showSubmitBtn.current = true;
    //         } else {
    //           StudentTestPageCxt.setDisableNxtBtn(true);
    //         }
    //       }
    //     } else {
    //       if (temp[nextIndex].selected === undefined) {
    //         temp[nextIndex].selected = true;
    //         StudentTestPageCxt.videoQnNo.current = parseInt(nextIndex) + 1;

    //         for (let det1 in temp2) {
    //           for (let det2 in temp2[det1].qnsObj) {
    //             if (temp2[det1].qnsObj[det2].qId === temp[nextIndex].id) {
    //               StudentTestPageCxt.setVdoQnTimelimit(
    //                 temp2[det1].qnsObj[det2].qnduration * 60
    //               );
    //             }
    //           }
    //         }

    //         let count = temp.filter(
    //           (dat) => dat.selected === true || dat.selected === false
    //         ).length;

    //         if (count === temp.length) {
    //           let count = 0;
    //           let trueCount = 0;

    //           for (let det1 in temp2) {
    //             for (let det2 in temp2[det1].qnsObj) {
    //               count++;

    //               if (temp2[det1].qnsObj[det2].isAnswered) {
    //                 trueCount++;
    //               }
    //             }
    //           }

    //           let isShowSubmitBtn = count - trueCount;

    //           if (isShowSubmitBtn === 1) {
    //             StudentTestPageCxt.showSubmitBtn.current = true;
    //           } else {
    //             StudentTestPageCxt.setDisableNxtBtn(true);
    //           }
    //         }
    //       } else {
    //         if (temp[nextIndex].selected === true) {
    //           temp[nextIndex].selected = false;

    //           let count = 0;
    //           let trueCount = 0;

    //           for (let det1 in temp2) {
    //             for (let det2 in temp2[det1].qnsObj) {
    //               count++;

    //               if (temp2[det1].qnsObj[det2].isAnswered) {
    //                 trueCount++;
    //               }
    //             }
    //           }

    //           let isShowSubmitBtn = count - trueCount;

    //           if (isShowSubmitBtn === 1) {
    //             StudentTestPageCxt.showSubmitBtn.current = true;
    //           } else {
    //             StudentTestPageCxt.setDisableNxtBtn(true);
    //           }
    //         } else {
    //           let missingIndex = temp.findIndex(
    //             (det) => det.selected !== true && det.selected !== false
    //           );

    //           if (missingIndex !== -1) {
    //             temp[missingIndex].selected = true;
    //             StudentTestPageCxt.videoQnNo.current =
    //               parseInt(missingIndex) + 1;

    //             for (let det1 in temp2) {
    //               for (let det2 in temp2[det1].qnsObj) {
    //                 if (
    //                   temp2[det1].qnsObj[det2].qId === temp[missingIndex].id
    //                 ) {
    //                   StudentTestPageCxt.setVdoQnTimelimit(
    //                     temp2[det1].qnsObj[det2].qnduration * 60
    //                   );
    //                 }
    //               }
    //             }

    //             let isComplete = temp.filter(
    //               (dat) => dat.selected === true || dat.selected === false
    //             ).length;

    //             if (isComplete === temp.length) {
    //               let count = 0;
    //               let trueCount = 0;

    //               for (let det1 in temp2) {
    //                 for (let det2 in temp2[det1].qnsObj) {
    //                   count++;

    //                   if (temp2[det1].qnsObj[det2].isAnswered) {
    //                     trueCount++;
    //                   }
    //                 }
    //               }

    //               let isShowSubmitBtn = count - trueCount;

    //               if (isShowSubmitBtn === 1) {
    //                 StudentTestPageCxt.showSubmitBtn.current = true;
    //               } else {
    //                 StudentTestPageCxt.setDisableNxtBtn(true);
    //               }
    //             }
    //           } else {
    //             let count = 0;
    //             let trueCount = 0;

    //             for (let det1 in temp2) {
    //               for (let det2 in temp2[det1].qnsObj) {
    //                 count++;

    //                 if (temp2[det1].qnsObj[det2].isAnswered) {
    //                   trueCount++;
    //                 }
    //               }
    //             }

    //             let isShowSubmitBtn = count - trueCount;

    //             if (isShowSubmitBtn === 1) {
    //               StudentTestPageCxt.showSubmitBtn.current = true;
    //             } else {
    //               StudentTestPageCxt.setDisableNxtBtn(true);
    //             }
    //           }
    //         }
    //       }
    //     }

    //     Alert.videoQn.current = temp;
    //     Alert.questionListObj.current = temp2;
    //     Alert.setQueObj(temp);

    //     // if (isNextClicked.current) {
    //     //   Alert.qnTrack.current += 1;
    //     //   StudentTestPageCxt.setShowTimer(true);
    //     //   StudentTestPageCxt.setshowtransition(true);
    //     //   startRecording();
    //     // } else {
    //     //   if (StudentTestPageCxt.disableNxtBtn) {
    //     //     StudentTestPageCxt.setDisableNxtBtn(false);
    //     //   }

    //     //   StudentTestPageCxt.setVideoQuestionAttempt(true);
    //     //   Alert.setHideSubmitVideoQn(true);
    //     // }
    //   } else {
    //     StudentTestPageCxt.videoQnNo.current = 0;
    //     Alert.videoQn.current = temp;
    //     Alert.questionListObj.current = temp2;
    //     Alert.setQueObj(temp);

    //     StudentTestPageCxt.setVideoQuestionAttempt(true);
    //     Alert.setHideSubmitVideoQn(true);

    //     if (StudentTestPageCxt.disableNxtBtn) {
    //       StudentTestPageCxt.setDisableNxtBtn(false);
    //     }

    //     StudentTestPageCxt.setVideoQuestionAttempt(true);
    //     Alert.setHideSubmitVideoQn(true);
    //   }
    // }

    // await uploadLargeFileToS3(
    //   blob,
    //   `VideoRecord/${Alert.comingObj.testid}/${getDataFromStorage(
    //     "learnerid"
    //   )}/${data.id}.mp4`,
    //   blob.type
    // );

    uploadLargeFileToS3(blob, anstext, blob.type)
      .then((res) => {
        if (res === "Success") {
          Alert.setVideoSaved((prev) => prev + 1);
          saveCurrentAnswer(data, anstext);
        } else {
          // temp = JSON.parse(JSON.stringify(Alert.videoQn.current));
          // temp.forEach((data) => {
          //   if (data.id == data.id) {
          //     delete data.selected;
          //     delete data.answer;
          //   }
          // });
          // temp2 = JSON.parse(JSON.stringify(Alert.questionListObj.current));
          // for (let det1 in temp2) {
          //   for (let det2 in temp2[det1].qnsObj) {
          //     if (temp2[det1].qnsObj[det2].qId === data.id) {
          //       temp2[det1].qnsObj[det2].isAnswered = false;
          //     }
          //   }
          // }
          // Alert.videoQn.current = temp;
          // Alert.questionListObj.current = temp2;
          // Alert.setUpdatingStates(true);
        }
      })
      .catch((error) => {
        // Make questionlit answerd true
        // temp = JSON.parse(JSON.stringify(Alert.videoQn.current));
        // temp.forEach((data) => {
        //   if (data.id == data.id) {
        //     delete data.selected;
        //     delete data.answer;
        //   }
        // });
        // temp2 = JSON.parse(JSON.stringify(Alert.questionListObj.current));
        // for (let det1 in temp2) {
        //   for (let det2 in temp2[det1].qnsObj) {
        //     if (temp2[det1].qnsObj[det2].qId === data.id) {
        //       temp2[det1].qnsObj[det2].isAnswered = false;
        //     }
        //   }
        // }
        // Alert.videoQn.current = temp;
        // Alert.questionListObj.current = temp2;
        // Alert.setUpdatingStates(true);
      });
  };

  const saveCurrentAnswer = (data, anslink) => {

    // axios
    //   .post(
    //     "node/learner/question/saveans",
    //     JSON.stringify({
    //       mockuse: true,
    //       testid: Alert.comingObj.testid,
    //       timeleft: Alert.comingObj.duration,
    //       testmode: Alert.comingObj.testmode,
    //       libtype: constants.libTypeIds.DESCRIPTIVE,
    //       learnerid: getDataFromStorage("learnerid"),
    //       answers: [{ qnid: data.id, answer: anslink }],
    //     }),
    //     useCommonFunctionHks.getHeaderDetailsForAxios()
    //   )
    //   .then((res) => {
    //     console.log("res.data ",res.data);
        
    //     if (res.data.resultCode === constants.RESULT_STATUS.SUCCESS) {
    //       console.log(
    //         " test submit Alert.exitVdoSubmit.current ",
    //         Alert.exitVdoSubmit.current
    //       );
    //       console.log(
    //         " test submit StudentTestPageCxt.showSubmitBtn.current ",
    //         StudentTestPageCxt.showSubmitBtn.current
    //       );
    //       console.log(
    //         " test submit Alert.exitVdoSubmit.current ",
    //         Alert.exitVdoSubmit.current
    //       );
    //       console.log(
    //         " test submit Alert.timeVdoSubmit.current ",
    //         Alert.timeVdoSubmit.current
    //       );

          Alert.updateComingObj((prev) => ({
            ...prev,
            // answer: [...prev.answer, { qnid: data.id, answer: anslink }],
            answer: Array.isArray(prev.answer) 
                  ? [...prev.answer, { qnid: data.id, answer: anslink, transcript: StudentTestPageCxt.transcript.current }] 
                  : [{ qnid: data.id, answer: anslink, transcript: StudentTestPageCxt.transcript.current }], 
          }));

          // if (
          //   (!isNextClicked.current &&
          //     StudentTestPageCxt.showSubmitBtn.current) ||
          //   Alert.exitVdoSubmit.current
          // ) {
          //   Alert.exitVdoSubmit.current
          //     ? Alert.timeVdoSubmit.current
          //       ? submitAnswers(false, true)
          //       : submitAnswers(true, false)
          //     : submitAnswers();
          // }
      //   } else {
      //     Alert.updateComingObj((prev) => ({
      //       ...prev,
      //       // answer: [...prev.answer, { qnid: data.id, answer: anslink }], 
      //       answer: Array.isArray(prev.answer) 
      //             ? [...prev.answer, { qnid: data.id, answer: anslink }] 
      //             : [{ qnid: data.id, answer: anslink }], 
      //     }));
      //   }
      // })
      // .finally(() => Alert.setIsLoaded(false));
  };

  const playQns = (text) => {
    text.replace(/<[^>]*>/g, "");

    let speech = new SpeechSynthesisUtterance();

    const voices = window.speechSynthesis.getVoices();

    let indianFemaleVoice = voices.find(
      (voice) =>
        voice.lang === "en-IN" && voice.name?.toLowerCase().includes("female")
    );

    if (indianFemaleVoice) {
      speech.voice = indianFemaleVoice;
    } else {
      speech.voice = voices.find(
        (voice) => voice.name === "Google UK English Female"
      );
    }

    speech.text = text;

    speech.onstart = () => {
      StudentTestPageCxt.setIsSpeaking((prev) => ({
        ...prev,
        ...(StudentTestPageCxt.showButton.next ||
        StudentTestPageCxt.showButton.last
          ? { feedback: true }
          : { question: true }),
      }));
    };

    speech.onend = () => {
      StudentTestPageCxt.setIsSpeaking(StudentTestPageCxt.defaultIsSpeaking);
    };

    window.speechSynthesis.cancel();
    window.speechSynthesis.speak(speech);
  };

  const handleBack = () => {
    submitAnswers(false, false, "interview" );
    // if (document.fullscreenElement) {
    //   document.exitFullscreen();
    // }
    // Alert.navigate("main/interview/results");
  };

  const handleNext = () => {
    StudentTestPageCxt.transcript.current =
      StudentTestPageCxt.defaultTranscript;

    if (
      StudentTestPageCxt.currentQnIndex <
      Alert.comingObj.sections[0].questions.length - 1
    ) {
      StudentTestPageCxt.setCurrentQnIndex(
        StudentTestPageCxt.currentQnIndex + 1
      );
      StudentTestPageCxt.setShowButton(StudentTestPageCxt.defaultShowButton);
    }
  };

  const handleSubmit = (from) => {
    stopRecording();
    stopListening();

    if (
      StudentTestPageCxt.currentQnIndex <
      Alert.comingObj.sections[0].questions.length - 1
    ) {
      StudentTestPageCxt.setShowButton((prev) => ({
        ...prev,
        ...(from === "currentQnsAuto"
          ? { next: true, currentQnsAuto: true }
          : from === "testAuto"
          ? { last: true, testAuto: true }
          : { next: true }),
      }));
    } else {
      StudentTestPageCxt.setShowButton((prev) => ({
        ...prev,
        ...(from === "currentQnsAuto"
          ? { last: true, currentQnsAuto: true }
          : from === "testAuto"
          ? { last: true, testAuto: true }
          : { last: true }),
      }));
    }
  };

  useEffect(() => {
    startRecording();
    startListening();
    StudentTestPageCxt.setListening(true);
    // const currentQn = Alert.comingObj.sections[0].questions[StudentTestPageCxt.currentQnIndex];
    // currentQn.qnduration = Alert.comingObj.sections[0].qnconfig[0].qnduration;
    StudentTestPageCxt.setCurrentQnData(
      Alert.comingObj.sections[0].questions[StudentTestPageCxt.currentQnIndex]
    );
    playQns(
      Alert.comingObj.sections[0].questions[StudentTestPageCxt.currentQnIndex]
        .name
    );
  }, [StudentTestPageCxt.currentQnIndex]);

  useEffect(() => {
    if (StudentTestPageCxt.showButton.testAuto) {
      playQns(
        `You were unable to complete the test within the given time. We will submit your response. You have successfully completed the 
        AI interview. We will notify you of the results after evaluation.`
      );
    } else if (StudentTestPageCxt.showButton.currentQnsAuto) {
      if (StudentTestPageCxt.showButton.last) {
        playQns(
          `You were unable to answer this question within the given time. We will submit your response. You have successfully completed the 
          AI interview. We will notify you of the results after evaluation.`
        );
      } else {
        playQns(
          `You were unable to answer this question within the given time. We will submit your response for this question. You can proceed to 
        the next one.`
        );
      }
    } else if (StudentTestPageCxt.showButton.next) {
      playQns(
        "Thank you for your response, let's proceed to the next question"
      );
    } else if (StudentTestPageCxt.showButton.last) {
      playQns(
        `Thank you for your response! You have successfully completed the AI interview. We will notify you of the results after
      evaluation.`
      );
    }
  }, [StudentTestPageCxt.showButton]);

  const startListening = () => {
    if (StudentTestPageCxt.recognitionRef.current) {
      StudentTestPageCxt.recognitionRef.current.start();
      StudentTestPageCxt.setListening(true);
    }
  };

  const stopListening = () => {
    if (StudentTestPageCxt.recognitionRef.current) {
      StudentTestPageCxt.recognitionRef.current.stop();
      StudentTestPageCxt.setListening(false);
    }
  };

  useEffect(() => {
    const SpeechRecognition =
      window.SpeechRecognition || window.webkitSpeechRecognition;

    if (!SpeechRecognition) {
      console.error("SpeechRecognition API is not supported in this browser.");
      return;
    }

    const recognition = new SpeechRecognition();

    recognition.continuous = true;
    recognition.interimResults = true;
    recognition.lang = "en-US";

    const handleResult = (event) => {
      const transcriptArray = Array.from(event.results)
        .map((result) => result[0].transcript)
        .join("");

      StudentTestPageCxt.transcript.current = transcriptArray;
    };

    const handleEnd = () => {
      if (StudentTestPageCxt.listening) {
        console.log("Restarting recognition...");
        recognition.start();
      }
    };

    const handleError = (error) => {
      console.error("SpeechRecognition error:", error);
    };

    recognition.addEventListener("end", handleEnd);
    recognition.addEventListener("error", handleError);
    recognition.addEventListener("result", handleResult);

    /* new events */

    recognition.addEventListener("audioend", (e) => {
      console.log("Audio capturing ended", e);
    });

    recognition.addEventListener("audiostart", (e) => {
      console.log("Audio capturing started", e);
    });

    recognition.addEventListener("nomatch", (e) => {
      console.error("Speech not recognized", e);
    });

    recognition.addEventListener("soundend", (e) => {
      console.log("Sound has stopped being received", e);
    });

    recognition.addEventListener("soundstart", (e) => {
      console.log("Some sound is being received", e);
    });

    recognition.addEventListener("speechend", (e) => {
      console.log("Speech has stopped being detected", e);
    });

    recognition.addEventListener("speechstart", (e) => {
      console.log("Speech has been detected", e);
    })

    recognition.addEventListener("start", (e) => {
      console.log("Speech recognition service has started", e);
    });

    StudentTestPageCxt.recognitionRef.current = recognition;

    if (StudentTestPageCxt.listening) {
      recognition.start();
    }

    return () => {
      recognition.stop();
      recognition.removeEventListener("result", handleResult);
      recognition.removeEventListener("end", handleEnd);
      recognition.removeEventListener("error", handleError);
    };
  }, [StudentTestPageCxt.listening]);

  useEffect(() => {
    if (
      previewStream &&
      StudentTestPageCxt.videoRef.current &&
      StudentTestPageCxt.videoRef.current.srcObject?.active !==
        previewStream?.active
    ) {
      StudentTestPageCxt.videoRef.current.srcObject = previewStream;
    }
  }, [previewStream]);

  useEffect(() => {
    if (
      !StudentTestPageCxt.showButton.next &&
      !StudentTestPageCxt.showButton.last
    ) {
      const handleQnTimeLimit = setInterval(() => {
        if (StudentTestPageCxt.currentQnData.qnduration === 0) {
          handleSubmit("currentQnsAuto");
          clearInterval(handleQnTimeLimit);
        } else {
          StudentTestPageCxt.setCurrentQnData((prev) => ({
            ...prev,
            qnduration: prev.qnduration - 1,
          }));
        }
      }, 1000);

      return () => {
        clearInterval(handleQnTimeLimit);
      };
    }
  }, [
    StudentTestPageCxt.showButton,
    StudentTestPageCxt.currentQnData.qnduration,
  ]);

  useEffect(() => {
    const handleTestTimeLimit = setInterval(() => {
      if (Alert.comingObj.duration === 0) {
        handleSubmit("testAuto");
        submitAnswers(null, true, "interview", "actionType");
        clearInterval(handleTestTimeLimit);
      } else {
        Alert.updateComingObj((prev) => ({
          ...prev,
          duration: prev.duration - 1,
        }));
      }
    }, 1000);

    return () => {
      clearInterval(handleTestTimeLimit);
    };
  }, [Alert.comingObj.duration]);

  return (
    Object.keys(Alert.comingObj).length !== 0 && (
      <div className="interviewMainContainer">
        {/* <BackSvgIcon component="interview" onClick={handleBack} /> */}
        <div className="interviewTestName">{Alert.comingObj.testname}</div>
        <div className="interviewInnerMainContainer">
          <div className="interviewBody" ref={containerRef}>
            <div className="interviewBodyHeader">
              <div className="interviewBodyHeaderQuestionNo">
                Question {StudentTestPageCxt.currentQnIndex + 1}/
                {Alert.comingObj.sections[0].questions.length}
              </div>
              <div className="interviewBodyHeaderCurrentQnTime">
                Time Left :
                <span className="interviewBodyHeaderCurrentQnTimeCounter">
                  {useCommonFunctionHks.formatTime(
                    StudentTestPageCxt.currentQnData.qnduration
                  )}
                </span>
              </div>
            </div>
            <div className="interviewBodyMain">
              <div className="interviewBodyMainQuestionDiv">
                <div className="interviewBodyMainLogoDiv">
                  <Logo
                    component="interview"
                    className={`interviewBodyMainLogo ${
                      StudentTestPageCxt.isSpeaking.question ? "pulse" : ""
                    }`}
                  />
                </div>
                <div className="interviewBodyMainQuestion">
                  {StudentTestPageCxt.currentQnData.name}
                </div>
              </div>
              <div className="interviewBodyMainAnswerDiv">
                <img
                  alt={getDataFromStorage("name")}
                  className="interviewBodyMainUserImg"
                  src={localStorage.getItem("picture") || Profile}
                />
                <div className="interviewBodyMainAnswer">
                  {StudentTestPageCxt.transcript.current}
                </div>
              </div>
              {(StudentTestPageCxt.showButton.next ||
                StudentTestPageCxt.showButton.last) && (
                <div className="interviewBodyMainFeedbackDiv">
                  <div className="interviewBodyMainLogoDiv">
                    <Logo
                      component="interview"
                      className={`interviewBodyMainLogo ${
                        StudentTestPageCxt.isSpeaking.feedback ? "pulse" : ""
                      }`}
                    />
                  </div>
                  <div className="interviewBodyMainFeedback">
                    {StudentTestPageCxt.showButton.next
                      ? StudentTestPageCxt.showButton.currentQnsAuto
                        ? `You were unable to answer this question within the given time. We will submit your response for this question. 
                        You can proceed to the next one.`
                        : "Thank you for your response, let's proceed to the next question"
                      : StudentTestPageCxt.showButton.last &&
                        (StudentTestPageCxt.showButton.currentQnsAuto
                          ? `You were unable to answer this question within the given time. We will submit your response. You have 
                      successfully completed the AI interview. We will notify you of the results after evaluation.`
                          : StudentTestPageCxt.showButton.testAuto
                          ? `You were unable to complete the test within the given time. We will submit your response. You have successfully 
                          completed the AI interview. We will notify you of the results after evaluation.`
                          : `Thank you for your response! You have successfully completed the AI interview. We will notify you of the results 
                        after evaluation.`)}
                  </div>
                </div>
              )}
            </div>
            <div className="interviewBodyFooter">
              <Button
                size={"sm"}
                onClick={
                  StudentTestPageCxt.showButton.next
                    ? handleNext
                    : StudentTestPageCxt.showButton.last
                    ? handleBack
                    : handleSubmit
                }
                hierarchy={{
                  type: "primary",
                  buttonText: StudentTestPageCxt.showButton.next
                    ? "Next"
                    : StudentTestPageCxt.showButton.last
                    ? "Finish"
                    : "Submit",
                }}
              />
            </div>
          </div>
          <div className="interviewRightDiv">
            <video
              autoPlay
              ref={StudentTestPageCxt.videoRef}
              className={`interviewCam ${
                Alert.comingObj.deducted ? "warn" : ""
              }`}
            />
            {/* {Alert.comingObj.deducted && (
              <div className="interviewMultiFaceDeducted">
                <div className="interviewMultiFaceDeductedHeader">
                  <WarningSvg component="interview" />
                  Multiple Individuals Detected
                </div>
                <div className="interviewMultiFaceDeductedBody">
                  Multiple individuals detected. Please ensure you are alone to
                  continue the interview.
                </div>
              </div>
            )} */}

            {(Alert.verifyMsg.hasOwnProperty("resultCode") && Alert.verifyMsg.resultCode !== constants.RESULT_STATUS.SUCCESS && Alert.verifyMsg.resultCode !== constants.RESULT_STATUS.TIMEOUT)
              && (<div className="interviewMultiFaceDeducted">
              <div className="interviewMultiFaceDeductedHeader">
                <WarningSvg component="interview" />
                {Alert.verifyMsg.Multi_People_Found ? "Multiple Individuals Detected" : "Face Not Found"}
              </div>
              <div className="interviewMultiFaceDeductedBody">{Alert.verifyMsg.Multi_People_Found ? "Multiple individuals detected. Please ensure you are alone to continue the interview." : "Your face is not visible in the camera. Please ensure you are within the camera frame to continue the interview."}</div>
            </div>)}

          </div>
        </div>

        {Alert.isTakeTest && Alert.comingObj.videoqn === 1 && (
        <Verifypic
          isTakeTest={Alert.isTakeTest}
        />
      )}

      </div>
    )
  );
}
