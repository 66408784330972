import React, { useEffect, useRef } from "react";
import { Offcanvas, Image, Button } from "react-bootstrap";
import { Editor } from "@tinymce/tinymce-react";
import constants from "../../../constants";
import { faLink } from "@fortawesome/free-solid-svg-icons";
import PdfViewer from "../../CommonComponents/PdfViewer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import AssignmentPageHooks from "../../../Hooks/AssignmentPageHooks";
const SandpackAssignment = ({
  showAssignmentInstructions,
  setShowAssignmentInstructions,
  description,
  name,
}) => {
  const { referenceLinks } = AssignmentPageHooks();
  const imgExtensions = ["png", "jpeg", "jpg", "jfif", "gif"];
  const assignmentReferenceLinkRef = useRef([]);
  useEffect(() => {
    console.log("referenceLinks", referenceLinks);
  }, []);
  return (
    <Offcanvas
      placement="end"
      show={showAssignmentInstructions}
      onHide={() => setShowAssignmentInstructions(false)}
      id="instructionMain"
    >
      <div className="instructionMainContainer">
        <div className="header">
          <p className="assignmentname">{name}</p>
          <svg
            onClick={() => setShowAssignmentInstructions(false)}
            width="28"
            height="28"
            viewBox="0 0 28 28"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M19 9L9 19M9 9L19 19"
              stroke="#101010"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </div>
        <div className="body">
          <Editor
            tinymceScriptSrc="/tinymce/tinymce.min.js"
            disabled={true}
            toolbar={false}
            menubar={false}
            branding={false}
            statusbar={false}
            value={description}
            init={{
              resize: false,
              readonly: true,
              menubar: false,
              statusbar: false,
              license_key: "gpl",
              selector: "textarea",
              plugins: "autoresize",
            }}
          />
          {referenceLinks?.length > 0 && referenceLinks?.map((link, index) => (
            <div key={index}>
              <div onContextMenu={(event) => event.preventDefault()}>
                <div className="assignmentPageReferenceLink">
                  {link.extension === "pdf" &&
                  link.type !== constants.LINK_TYPE.EMBEDED_LINK ? (
                    <PdfViewer
                      path={link.path}
                      isDownloadable={link.download === 1}
                      name={link.name}
                    />
                  ) : imgExtensions.includes(link.extension) ? (
                    <div className="assignmentPageReferenceLinkImageDownloadDiv">
                      <img
                        alt=""
                        src={link.link}
                        className="assignmentPageReferenceLinkImage"
                        // onClick={() => handleRequestFullScreenMode(index)}
                        ref={(ref) =>
                          (assignmentReferenceLinkRef.current[index] = ref)
                        }
                      />
                      {/* {link.download === 1 && (
                        <DownloadSvgIcon
                          onClick={() =>
                            handleDownloadReferenceFileForLearner(link)
                          }
                        />
                      )} */}
                    </div>
                  ) : link.extension === "mp4" ? (
                    <video
                      controls
                      controlsList="nodownload"
                      src={`${link.link}#t=2.0`}
                      id="assignmentReferenceLinkPdf"
                      className="assignmentReferenceLinkVideo"
                      ref={(ref) =>
                        (assignmentReferenceLinkRef.current[index] = ref)
                      }
                    ></video>
                  ) : (
                    link.type === constants.LINK_TYPE.EMBEDED_LINK && (
                      <iframe
                        allowFullScreen
                        title={"PPTX-Viewer"}
                        controlsList="nodownload"
                        id="assignmentReferenceLinkPdf"
                        src={link.link + "#toolbar=0&view=FitH"}
                        style={{ height: "211px", width: "372px" }}
                        ref={(ref) =>
                          (assignmentReferenceLinkRef.current[index] = ref)
                        }
                      ></iframe>
                    )
                  )}
                  {link.type === constants.LINK_TYPE.EMBEDED_LINK && (
                    <FontAwesomeIcon
                      icon={faLink}
                      id="assignmentReferenceLinkEmbedLink"
                      className="assignmentReferenceLinkEmbedLink"
                    />
                  )}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </Offcanvas>
  );
};

export default SandpackAssignment;
