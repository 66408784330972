import constants from "../../constants";
import { useContext, useRef } from "react";
import { useLocation } from "react-router-dom";
import useAxiosPrivate from "../useAxiosPrivate";
import AlertContext from "../../context/AlertContext";
import { getMinutesGap, getDataFromStorage } from "../../util";
import useCommonFunctionHooks from "../useCommonFunctionHooks";

export default function MenuBarHooks() {
  const Alert = useContext(AlertContext);

  const axios = useAxiosPrivate();
  const useCommonFunctionHks = useCommonFunctionHooks();

  const { pathname } = useLocation();

  const activeBatchRef = useRef(null);

  const handleSetLocalStorage = (batch) => {
    localStorage.setItem("logo", batch.logo);
    localStorage.setItem("batchid", batch.id);
    localStorage.setItem("batchType", batch.type);
    localStorage.setItem("batchname", batch.name);
    localStorage.setItem("favicon", batch.favicon);
  };

  const handleLeaderBoard = () => {
    Alert.setLoadingLeaderboard(true);
    Alert.setLeaderBoardData([]);

    axios
      .get(
        `node/learner/${getDataFromStorage(
          "learnerid"
        )}/batch/${localStorage.getItem("batchid")}/leaderboard`,
        useCommonFunctionHks.getHeaderDetailsForAxios()
      )
      .then((res) => {
        if (res.data.resultCode === constants.RESULT_STATUS.TECHNICAL_ERROR) {
          Alert.setLoadingLeaderboard(false);
        } else {
          if (res.data.resultCode === constants.RESULT_STATUS.NO_DATA) {
            Alert.setLoadingLeaderboard(false);
          } else {
            Alert.setLeaderBoardData(res.data.data.data);
            Alert.setLoadingLeaderboard(false);
          }
        }
      })
      .catch((error) => {
        useCommonFunctionHks.getCatchBlockDetails(error);
        Alert.setLoadingLeaderboard(false);
      })
      .finally(() => Alert.setIsLoaded(false));
  };

  const handleBatch = (batch) => {
    handleSetLocalStorage(batch);

    if (!pathname.includes("testresults") && !pathname.includes("timespent")) {
      handleDashBoard();
      Alert.navigate("/main/dashboard");
    } else {
      Alert.navigate(pathname);
    }
  };

  const handleDashBoard = () => {
    if (
      parseInt(localStorage.getItem("batchType")) !==
      constants.BATCH_TYPE.TESTING
    ) {
      handleLeaderBoard();
    }

    Alert.setIsLoaded(true);
    axios
      .post(
        "node/learner/dashboard",
        JSON.stringify({
          batchId: localStorage.getItem("batchid"),
          learnerId: getDataFromStorage("learnerid"),
        }),
        useCommonFunctionHks.getHeaderDetailsForAxios()
      )
      .then((res) => {
        if (res.data.resultCode === constants.RESULT_STATUS.TECHNICAL_ERROR) {
          Alert.setShowNotify({
            show: true,
            title: "Info",
            msg: res.data.msg,
          });
        } else {
          if (res.data.resultCode === constants.RESULT_STATUS.NO_DATA) {
            Alert.setShowNotify({
              show: true,
              title: "Info",
              msg: "Learningpath is not published",
            });
          } else {
            Alert.setDashBoardDetails(res.data.data);

            if (
              res.data.data.lpData.length !== 0 ||
              res.data.data.batchDetails.length !== 0
            )
              localStorage.setItem("showBack", true);

            const givenTime = res.data.data.attendanceExpiry;
            const minutesGap = getMinutesGap(givenTime);

            let mss = minutesGap * 60 * 1000;

            if (Alert.showBlink && !res.data.data.markAttendance) {
              Alert.setShowBlink(res.data.data.markAttendance);
            }

            if (
              !Alert.showBlink &&
              res.data.data.markAttendance &&
              minutesGap >= 0
            ) {
              Alert.setShowBlink(res.data.data.markAttendance);
              setTimeout(() => {
                document
                  .getElementById("svg-element")
                  .classList.remove("blinking");
                Alert.setShowBlink(false);
              }, [mss]);
            }

            if (
              res.data.data.lpData.length === 1 &&
              res.data.data.batchDetails.length === 1 &&
              parseInt(localStorage.getItem("batchType")) ===
                constants.BATCH_TYPE.TESTING
            ) {
              sessionStorage.setItem("lpId", res.data.data.lpData[0].lpId);
              Alert.setLpId(res.data.data.lpData[0].lpId);
              Alert.navigate("/home");
            }
          }
        }
      })
      .catch((error) => {
        useCommonFunctionHks.getCatchBlockDetails(error);
      })
      .finally(() => Alert.setIsLoaded(false));
  };

  return {
    handleBatch,
    activeBatchRef,
    handleDashBoard,
    handleSetLocalStorage,
  };
}
