import React from "react";
import Button from "../../Button";
import ReactQuill from "react-quill";
import { getJobType, getLocation } from "../utils";

export default function JobDetails({ expandedJob, setExpandedJob }) {
  const handleClick = (link) => {
    window.open(link, "_blank", "noopener,noreferrer");
  };

  return (
    <div className="sidebar">
      <div className="backButton" onClick={() => setExpandedJob(null)}>
        <svg
          fill="none"
          viewBox="0 0 14 14"
          className="chevronIcon"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            stroke="#667085"
            strokeWidth="1.2"
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M8.75 10.5L5.25 7L8.75 3.5"
          />
        </svg>
        Back
      </div>

      <div className="jobContent">
        <div className="jobHeader">
          <div style={{ marginTop: "8px" }}>
            <h5 className="jobName">{expandedJob.name}</h5>
            <h6 className="jobCompany">{expandedJob.company}</h6>
          </div>
          <img
            alt={expandedJob.company}
            style={{ width: "50px", height: "50px" }}
            src={`https://${process.env.REACT_APP_S3_WEBSITE_HOST_BUCKET_NAME}.s3.ap-south-1.amazonaws.com/${expandedJob.logo}`}
          />
        </div>
        {expandedJob.skills !== "" && (
          <div style={{ marginTop: "8px" }}>
            <div className="skillsHeadingContainer">
              <div className="skillsHeading">Skills:</div>
              <div className="skillsContent">{expandedJob.skills}</div>
            </div>
          </div>
        )}
        {((expandedJob.maxexp !== 0 && expandedJob.type === 2) ||
          (expandedJob.maxexp !== 0 && expandedJob.type === 1)) && (
          <div style={{ marginTop: "12px" }}>
            <div className="jobInfo">
              <svg
                viewBox="0 0 18 16"
                className="briefcaseIcon"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M12 4.25C12 3.55252 12 3.20378 11.9233 2.91766C11.7153 2.1412 11.1088 1.53472 10.3323 1.32667C10.0462 1.25 9.69748 1.25 9 1.25C8.30252 1.25 7.95378 1.25 7.66766 1.32667C6.8912 1.53472 6.28472 2.1412 6.07667 2.91766C6 3.20378 6 3.55252 6 4.25M3.9 14.75H14.1C14.9401 14.75 15.3601 14.75 15.681 14.5865C15.9632 14.4427 16.1927 14.2132 16.3365 13.931C16.5 13.6101 16.5 13.1901 16.5 12.35V6.65C16.5 5.80992 16.5 5.38988 16.3365 5.06901C16.1927 4.78677 15.9632 4.5573 15.681 4.41349C15.3601 4.25 14.9401 4.25 14.1 4.25H3.9C3.05992 4.25 2.63988 4.25 2.31901 4.41349C2.03677 4.5573 1.8073 4.78677 1.66349 5.06901C1.5 5.38988 1.5 5.80992 1.5 6.65V12.35C1.5 13.1901 1.5 13.6101 1.66349 13.931C1.8073 14.2132 2.03677 14.4427 2.31901 14.5865C2.63988 14.75 3.05992 14.75 3.9 14.75Z" />
              </svg>
              <div className="experience">
                {expandedJob.type === 1
                  ? expandedJob.minexp + " - " + expandedJob.maxexp + " Years"
                  : expandedJob.duration + " Months"}
              </div>
            </div>
          </div>
        )}

        <div style={{ display: "flex", gap: "40px", marginTop: "12px" }}>
          {expandedJob.location !== "" && (
            <div className="jobInfo">
              <svg
                viewBox="0 0 14 18"
                className="locationIcon"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M7 9.75C8.24264 9.75 9.25 8.74264 9.25 7.5C9.25 6.25736 8.24264 5.25 7 5.25C5.75736 5.25 4.75 6.25736 4.75 7.5C4.75 8.74264 5.75736 9.75 7 9.75Z" />
                <path d="M7 16.5C10 13.5 13 10.8137 13 7.5C13 4.18629 10.3137 1.5 7 1.5C3.68629 1.5 1 4.18629 1 7.5C1 10.8137 4 13.5 7 16.5Z" />
              </svg>
              <div className="location">
                {getLocation(expandedJob.location)}
              </div>
            </div>
          )}

          <div className="location">
            <svg
              width="16"
              c
              height="16"
              fill="none"
              viewBox="0 0 16 16"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clip-path="url(#clip0_1348_2287)">
                <path
                  stroke="#344054"
                  d="M4 3C4 3 5.16176 3 6.33333 3M9.33333 13.6667L4.66667 9C4.66667 9 5.66667 9 6.33333 9C7 9 10 9.04119 10 6C10 2.95881 7 3 6.33333 3M12 3C12 3 8.28595 3 6.33333 3M4 5.66666H12"
                />
              </g>
              <defs>
                <clipPath id="clip0_1348_2287">
                  <rect
                    width="8"
                    fill="white"
                    height="10.6667"
                    transform="translate(4 2.66666)"
                  />
                </clipPath>
              </defs>
            </svg>

            {expandedJob.minctc !== 0 ? (
              <>
                {expandedJob.minctc + " "}
                <span>-</span>

                <svg
                  width="16"
                  height="16"
                  fill="none"
                  viewBox="0 0 16 16"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g clip-path="url(#clip0_1348_2287)">
                    <path
                      stroke="#344054"
                      d="M4 3C4 3 5.16176 3 6.33333 3M9.33333 13.6667L4.66667 9C4.66667 9 5.66667 9 6.33333 9C7 9 10 9.04119 10 6C10 2.95881 7 3 6.33333 3M12 3C12 3 8.28595 3 6.33333 3M4 5.66666H12"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_1348_2287">
                      <rect
                        width="8"
                        fill="white"
                        height="10.6667"
                        transform="translate(4 2.66666)"
                      />
                    </clipPath>
                  </defs>
                </svg>
                {expandedJob.maxctc}
              </>
            ) : (
              <span className="experience">
                {expandedJob.type !== 2 ? "Not disclosed" : "Unpaid"}
              </span>
            )}
          </div>
        </div>

        <div style={{ marginTop: "12px" }}>
          <div className="styledLine" />
        </div>

        {expandedJob.posteddate && (
          <div style={{ marginTop: "12px" }}>
            <div className="jobInfo">
              <p>
                <strong>Posted Date:</strong> {expandedJob.posteddate}
              </p>
            </div>
          </div>
        )}

        {expandedJob.ctc && (
          <div className="jobInfo">
            <p>
              <strong>CTC:</strong> {expandedJob.ctc}
            </p>
          </div>
        )}

        {expandedJob.workmode && (
          <div className="jobInfo">
            <p>
              <strong>Work Mode:</strong> {getJobType(expandedJob.workmode)}
            </p>
          </div>
        )}

        {expandedJob.description && (
          <div className="jobInfo">
            <p>
              <strong>Description:</strong>
              <ReactQuill
                readOnly={true}
                className="reactQuill"
                value={expandedJob.description.trim()}
              />
            </p>
          </div>
        )}

        {expandedJob.noofopenings && (
          <div className="jobInfo">
            <p>
              <strong>No of Openings:</strong> {expandedJob.noofopenings}
            </p>
          </div>
        )}
      </div>
      <div className="applyBtn">
        {expandedJob.link && (
          <Button
            size="sm"
            onClick={() => handleClick(expandedJob.link)}
            hierarchy={{
              type: "primary",
              buttonText: "Apply Now",
            }}
          />
        )}
      </div>
    </div>
  );
}
