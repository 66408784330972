import Button from "../../Button";
import { getLocation } from "../utils";
import React, { useContext } from "react";
import AlertContext from "../../../context/AlertContext";

export default function JobCard({ job }) {
  const Alert = useContext(AlertContext);

  const handleOnclick = () => {
    if (Alert.isAdmin) {
      Alert.setIsEditJob(true);
      Alert.setCurrentEditJob(job);
      Alert.setOpenJobForm(true);
      Alert.navigate("/addjob");
    } else {
      Alert.setExpandedJob(job);
    }
  };

  const handleClick = (link) => {
    window.open(link, "_blank", "noopener,noreferrer");
  };

  return (
    <div
      onClick={handleOnclick}
      className={Alert.isAdmin ? "job-card-admin" : "jobCardMainContainer"}
    >
      <div style={{ display: "flex", flexDirection: "column" }}>
        <div className="job-header">
          <div style={{ display: "flex", gap: "2px", flexDirection: "column" }}>
            <h5 className="job-name" title={job.name}>
              <span>{job.name + " "}</span>
              {!job.isjob && <span>{"(Internship)"}</span>}
            </h5>
            <h6 className="job-company">{job.company}</h6>
          </div>
          <img
            alt={job.company}
            style={{ width: "50px", height: "50px" }}
            src={`https://${process.env.REACT_APP_S3_WEBSITE_HOST_BUCKET_NAME}.s3.ap-south-1.amazonaws.com/${job.logo}`}
          />
        </div>

        {job.skills !== "" && (
          <div className="skills-heading-container">
            <div className="skills-heading">Skills:</div>
            <div className="skills-content">{job.skills}</div>
          </div>
        )}
      </div>
      <div className="jobCardBottom">
        <div className="jobCardBottomInfo">
          {((job.maxexp !== 0 && job.type === 2) ||
            (job.maxexp !== 0 && job.type === 1)) && (
            <>
              {job.isjob ? (
                <div className="job-info">
                  <svg
                    viewBox="0 0 18 16"
                    className="briefcase-icon"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M12 4.25C12 3.55252 12 3.20378 11.9233 2.91766C11.7153 2.1412 11.1088 1.53472 10.3323 1.32667C10.0462 1.25 9.69748 1.25 9 1.25C8.30252 1.25 7.95378 1.25 7.66766 1.32667C6.8912 1.53472 6.28472 2.1412 6.07667 2.91766C6 3.20378 6 3.55252 6 4.25M3.9 14.75H14.1C14.9401 14.75 15.3601 14.75 15.681 14.5865C15.9632 14.4427 16.1927 14.2132 16.3365 13.931C16.5 13.6101 16.5 13.1901 16.5 12.35V6.65C16.5 5.80992 16.5 5.38988 16.3365 5.06901C16.1927 4.78677 15.9632 4.5573 15.681 4.41349C15.3601 4.25 14.9401 4.25 14.1 4.25H3.9C3.05992 4.25 2.63988 4.25 2.31901 4.41349C2.03677 4.5573 1.8073 4.78677 1.66349 5.06901C1.5 5.38988 1.5 5.80992 1.5 6.65V12.35C1.5 13.1901 1.5 13.6101 1.66349 13.931C1.8073 14.2132 2.03677 14.4427 2.31901 14.5865C2.63988 14.75 3.05992 14.75 3.9 14.75Z" />
                  </svg>
                  <div className="experience">
                    {job.minexp + " - " + job.maxexp + " Years"}
                  </div>
                </div>
              ) : (
                <div className="job-info">
                  <svg
                    width="18"
                    height="18"
                    fill="none"
                    viewBox="0 0 18 18"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g clip-path="url(#clip0_1724_1668)">
                      <path
                        stroke="#667085"
                        stroke-width="1.6"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M9 4.5V9L12 10.5M16.5 9C16.5 13.1421 13.1421 16.5 9 16.5C4.85786 16.5 1.5 13.1421 1.5 9C1.5 4.85786 4.85786 1.5 9 1.5C13.1421 1.5 16.5 4.85786 16.5 9Z"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_1724_1668">
                        <rect width="18" height="18" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                  <div className="experience">{job.duration + " Months"}</div>
                </div>
              )}
            </>
          )}
          {job.location !== "" && job.location !== "undefined" && (
            <div className="job-info">
              <svg
                viewBox="0 0 14 18"
                className="location-icon"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M7 9.75C8.24264 9.75 9.25 8.74264 9.25 7.5C9.25 6.25736 8.24264 5.25 7 5.25C5.75736 5.25 4.75 6.25736 4.75 7.5C4.75 8.74264 5.75736 9.75 7 9.75Z" />
                <path d="M7 16.5C10 13.5 13 10.8137 13 7.5C13 4.18629 10.3137 1.5 7 1.5C3.68629 1.5 1 4.18629 1 7.5C1 10.8137 4 13.5 7 16.5Z" />
              </svg>
              <div className="location">{getLocation(job.location)}</div>
            </div>
          )}
          <div className="locationCtcContainer">
            {job.isjob ? (
              <div className="job-info">
                <svg
                  width="18"
                  height="18"
                  fill="none"
                  viewBox="0 0 18 18"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    stroke="#667085"
                    stroke-width="1.6"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M12 5.99999V3.37548C12 2.75168 12 2.43978 11.8686 2.2481C11.7538 2.08063 11.576 1.96687 11.3758 1.9328C11.1467 1.89382 10.8635 2.02452 10.2971 2.28593L3.64426 5.35649C3.13913 5.58963 2.88656 5.70619 2.70158 5.88698C2.53804 6.04681 2.41321 6.24191 2.33663 6.45737C2.25 6.70108 2.25 6.97925 2.25 7.53559V11.25M12.375 10.875H12.3825M2.25 8.39999L2.25 13.35C2.25 14.1901 2.25 14.6101 2.41349 14.931C2.5573 15.2132 2.78677 15.4427 3.06901 15.5865C3.38988 15.75 3.80992 15.75 4.65 15.75H13.35C14.1901 15.75 14.6101 15.75 14.931 15.5865C15.2132 15.4427 15.4427 15.2132 15.5865 14.931C15.75 14.6101 15.75 14.1901 15.75 13.35V8.39999C15.75 7.55992 15.75 7.13988 15.5865 6.81901C15.4427 6.53677 15.2132 6.30729 14.931 6.16348C14.6101 5.99999 14.1901 5.99999 13.35 5.99999L4.65 5.99999C3.80992 5.99999 3.38988 5.99999 3.06901 6.16348C2.78677 6.30729 2.5573 6.53676 2.41349 6.81901C2.25 7.13987 2.25 7.55991 2.25 8.39999ZM12.75 10.875C12.75 11.0821 12.5821 11.25 12.375 11.25C12.1679 11.25 12 11.0821 12 10.875C12 10.6679 12.1679 10.5 12.375 10.5C12.5821 10.5 12.75 10.6679 12.75 10.875Z"
                  />
                </svg>

                {job.minctc !== 0 ? (
                  <div className="location">
                    <svg
                      width="16"
                      height="16"
                      fill="none"
                      viewBox="0 0 16 16"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g clip-path="url(#clip0_1348_2287)">
                        <path
                          stroke="#344054"
                          d="M4 3C4 3 5.16176 3 6.33333 3M9.33333 13.6667L4.66667 9C4.66667 9 5.66667 9 6.33333 9C7 9 10 9.04119 10 6C10 2.95881 7 3 6.33333 3M12 3C12 3 8.28595 3 6.33333 3M4 5.66666H12"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_1348_2287">
                          <rect
                            width="8"
                            fill="white"
                            height="10.6667"
                            transform="translate(4 2.66666)"
                          />
                        </clipPath>
                      </defs>
                    </svg>

                    {job.minctc + " "}
                    <span>-</span>

                    <svg
                      width="16"
                      height="16"
                      fill="none"
                      viewBox="0 0 16 16"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g clip-path="url(#clip0_1348_2287)">
                        <path
                          stroke="#344054"
                          d="M4 3C4 3 5.16176 3 6.33333 3M9.33333 13.6667L4.66667 9C4.66667 9 5.66667 9 6.33333 9C7 9 10 9.04119 10 6C10 2.95881 7 3 6.33333 3M12 3C12 3 8.28595 3 6.33333 3M4 5.66666H12"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_1348_2287">
                          <rect
                            width="8"
                            fill="white"
                            height="10.6667"
                            transform="translate(4 2.66666)"
                          />
                        </clipPath>
                      </defs>
                    </svg>
                    {job.maxctc}
                  </div>
                ) : (
                  <div className="location">Not disclosed</div>
                )}
              </div>
            ) : (
              job.startdate !== "0000-00-00" && (
                <div className="job-info">
                  <svg
                    width="18"
                    height="18"
                    fill="none"
                    viewBox="0 0 18 18"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      stroke="#667085"
                      stroke-width="1.6"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M15.75 7.5H2.25M12 1.5V4.5M6 1.5V4.5M5.85 16.5H12.15C13.4101 16.5 14.0402 16.5 14.5215 16.2548C14.9448 16.039 15.289 15.6948 15.5048 15.2715C15.75 14.7902 15.75 14.1601 15.75 12.9V6.6C15.75 5.33988 15.75 4.70982 15.5048 4.22852C15.289 3.80516 14.9448 3.46095 14.5215 3.24524C14.0402 3 13.4101 3 12.15 3H5.85C4.58988 3 3.95982 3 3.47852 3.24524C3.05516 3.46095 2.71095 3.80516 2.49524 4.22852C2.25 4.70982 2.25 5.33988 2.25 6.6V12.9C2.25 14.1601 2.25 14.7902 2.49524 15.2715C2.71095 15.6948 3.05516 16.039 3.47852 16.2548C3.95982 16.5 4.58988 16.5 5.85 16.5Z"
                    />
                  </svg>

                  <div className="experience">{job.startdate}</div>
                </div>
              )
            )}
          </div>
        </div>
        {!Alert.isAdmin && (
          <Button
            size="sm"
            onClick={() => handleClick(job.link)}
            hierarchy={{
              type: "primary",
              buttonText: "Apply",
            }}
          />
        )}
      </div>
    </div>
  );
}
