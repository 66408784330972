import { nanoid } from "nanoid";
import constants from "../../../../constants";
import { useLocation } from "react-router-dom";
import useLogout from "../../../../Hooks/useLogout";
import { useContext, useRef, useState } from "react";
import { getDataFromStorage } from "../../../../util";
import AlertContext from "../../../../context/AlertContext";
import useAxiosPrivate from "../../../../Hooks/useAxiosPrivate";
import useSubmitAssignment from "../../../../Hooks/useSubmitAssignment";
export default function HeaderHooks() {
  const {
    lpId,
    setMsg,
    setShow,
    testData,
    setTitle,
    navigate,
    setLpData,
    setStatus,
    setNavhome,
    setIsUnAuth,
    setIsLoaded,
    contentData,
    learnerNotes,
    sandpackFiles,
    setLoginStatus,
    setContentData,
    setIsSubmitted,
    isFullScreensp,
    setIsFullScreensp,
    setIsAddSubmission,
    setIsEditSubmission,
    setNotificationPayload,
    assignQuestionToLearner,
    assignQnDetailsForLearner,
    stopProctoring
  } = useContext(AlertContext);

  const submitAssignment = useSubmitAssignment();
  const axios = useAxiosPrivate();

  const logout = useLogout();

  const location = useLocation();

  let qList;

  let tList = useRef([]);
  let details = useRef([]);
  let questionList = useRef([]);
  let selectedTopics = useRef([]);
  let selectedQuestions = useRef([]);

  const [notificationDetails, setNotificationDetails] = useState([]);

  const customStyle = {
    control: (provided) => ({
      ...provided,
      fontSize: "16px",
      outline: "none",
      minHeight: "0px",
      boxShadow: "none",
      fontWeight: "500",
      fontStyle: "Inter",
      lineHeight: "24px",
      border: "0px solid white",
      backgroundColor: "#0D0D3D",
    }),
    option: (styles, { isDisabled, isFocused }) => {
      return {
        ...styles,
        color: "#AEB0B2",
        paddingTop: "10px",
        cursor: isDisabled ? "not-allowed" : "default",
        backgroundColor: isFocused ? "white" : "#F2F3F5",
      };
    },
  };

  const topicLoad = (e, from) => {
    selectedTopics.current = e;

    if (
      (assignQnDetailsForLearner.status ===
        constants.QUESTION_STATUS.SUBMITTED ||
        assignQnDetailsForLearner.status ===
          constants.QUESTION_STATUS.TO_BE_EVALUATED) &&
      !assignQnDetailsForLearner.hasOwnProperty("firstname")
    ) {
      qList = testData.data.topics
        .filter((question) => question.topicid === e.value)[0]
        .qndata.map(
          (qndata, index) =>
            (qndata.status === constants.QUESTION_STATUS.SUBMITTED ||
              qndata.status === constants.QUESTION_STATUS.TO_BE_EVALUATED) && {
              qnNo: qndata.qnno,
              value: qndata.qnid,
              label: "Question " + (index + 1),
            }
        );
    } else {
      qList = {
        value: assignQnDetailsForLearner.qnid,
        label: "Question " + assignQnDetailsForLearner.qnno,
      };
    }

    questionList.current = qList;

    if (from === "useEffect") {
      questionLoad(qList[0], "topicLoad");
    } else {
      questionLoad(qList[0], "onChangeTopicLoad");
    }
  };

  const getLatestSessions = async (learnerid) => {
    let res = await axios.get("node/learner/activesession/" + learnerid, {
      headers: {
        "Content-type": "application/json",
      },
    });

    if (!res.data.data.hasOwnProperty("resolveddata")) {
      let sessionName = res.data.data.sessions[0].sessionName;
      let sessionDate = res.data.data.sessions[0].sessionDate;
      let sessionTime = res.data.data.sessions[0].sessionTime;
      let jwtToken = res.data.data.jwtToken;

      let notificationData = {
        data: {
          notification: {
            title: `Attendance:\n${sessionName}`,
            body: `Date: ${sessionDate}\nTime: ${sessionTime}\nPlease mark correctly as the coordinator will be checking it manually as well.`,
          },
          data: {
            jwtToken: jwtToken,
          },
        },
        open: true,
      };
      setNotificationPayload(notificationData);
    } else {
      setShow(true);
      setTitle("Info");
      setMsg(res.data.data.resolveddata);
    }
  };

  const questionLoad = (e, from) => {
    if (from === "topicLoad") {
      selectedQuestions.current = e;
    } else {
      selectedQuestions.current = e;
      let qnNo = e.qnNo;
      submittedQns(e.value, qnNo);
    }
  };

  const submittedQns = (qnid, qnno) => {
    setIsLoaded(true);
    axios
      .post(
        "node/learner/report/question",
        JSON.stringify({
          testqnno: qnno,
          questionid: qnid,
          lpid: assignQnDetailsForLearner.lpId,
          testid: assignQnDetailsForLearner.testid,
          learnerid: getDataFromStorage("learnerid"),
        }),
        {
          timeout: 60000,
          mode: "no-cors",
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((res) => {
        if (res.data.resultCode !== 1000) {
          setIsLoaded(false);
          setShow(true);
          setTitle("Info");
          setMsg(res.data.msg);
        } else {
          let response = res.data.data;
          let lpId = assignQnDetailsForLearner.lpId;
          let status = assignQnDetailsForLearner.status;
          let topics = JSON.parse(
            JSON.stringify(assignQnDetailsForLearner.topics)
          );
          let secData = JSON.parse(
            JSON.stringify(assignQnDetailsForLearner.secData)
          );
          let testName = assignQnDetailsForLearner.testname;
          let testCases =
            response.testcases !== null ? JSON.parse(response.testcases) : [];
          let lpConfigId = assignQnDetailsForLearner.lpConfigId;

          for (let testCase = 0; testCase < testCases.length; testCase++) {
            if (testCase === 0) testCases[testCase].isActive = true;
            testCases[testCase].isSystemTestCase = true;
            testCases[testCase].id = nanoid();
          }

          response.lpId = lpId;
          response.status = status;
          response.topics = topics;
          response.secData = secData;
          response.testname = testName;
          response.lpConfigId = lpConfigId;
          response.testcases = JSON.stringify(testCases);
          response.viewTest = assignQnDetailsForLearner.viewTest;
          assignQuestionToLearner(response);
          setIsLoaded(false);
        }
      })
      .catch((error) => {
        setIsLoaded(false);
        setShow(true);
        setTitle("Error");

        if (error.message === "timeout of 60000ms exceeded") {
          setMsg(
            "Something went wrong… program is taking too long to finish. Please, try again"
          );
        } else if (error.message.includes("403")) {
          setIsUnAuth(true);
          setMsg("You have been logged-out due to inactivity. Login again.");
        } else {
          setMsg(error.message);
        }
      });
  };

  const handleClearAll = () => {
    axios
      .post(
        "node/learner/notification/delete",
        JSON.stringify({ userid: getDataFromStorage("learnerid") }),
        {
          timeout: 60000,
          mode: "no-cors",
          headers: {
            "Content-type": "application/json",
          },
        }
      )
      .then((response) => {
        setNotificationDetails([]);
      })
      .catch((err) => {
        setIsLoaded(false);
        setShow(true);
        setTitle("Error");

        if (err.message === "timeout of 60000ms exceeded") {
          setMsg(
            "Something went wrong… program is taking too long to finish. Please, try again"
          );
        } else if (err.message.includes("403")) {
          setIsUnAuth(true);
          setMsg("You have been logged-out due to inactivity. Login again.");
        } else {
          setMsg(err.message);
        }
      });
  };

  const handleLogOut = async () => {
    setNavhome(false);
    // localStorage.clear();
    localStorage.removeItem("tokenKey");
    localStorage.removeItem("refreshToken");
    setLoginStatus(false);
    await logout();
    navigate("/", { state: { from: location }, replace: true });
    window.location.reload();
  };

  const handleSubmitAssignmentOnSandpack = async (isSave) => {
    console.log("window?.electronAPI------>>>",window?.electronAPI);
    if (window?.electronAPI) {
      window?.electronAPI?.closeAllExceptMain();
    }
    if (isSave) {
      stopProctoring.current.stop = true;
    }
    try {
      setIsLoaded(true);
      let response = await submitAssignment({
        lpId,
        libId: testData.libid,
        file: {
          name: `${getDataFromStorage("learnerid")}.json`,
          file: sandpackFiles,
          contentType: "json",
        },
        lpConfigId: testData.lpconfigid,
        learnerNotes: learnerNotes ? learnerNotes : "",
        template: contentData.template,
        isSave,
      });

      if (response.data.resultCode !== 1000) {
        setShow(true);
        setTitle("Error");
        setMsg(
          response.data === ""
            ? "Something went wrong. Please try again later"
            : response.data.message
        );
      } else {
        let newFiles = [],
          filePath = "";
        if (process.env.REACT_APP_DEPLOYMENT === "AWS")
          filePath = `SandpackFiles/library/submission/${
            testData.libid
          }/${getDataFromStorage("learnerid")}.json`;
        else
          filePath = `library/submission/${getDataFromStorage(
            "learnerid"
          )}/${getDataFromStorage("learnerid")}.json`;

        newFiles.push(filePath);

        let assignmentData = JSON.parse(JSON.stringify(contentData));
        assignmentData.commentsdata = [];
        assignmentData.anslinks = JSON.stringify(newFiles);
        assignmentData.answer = learnerNotes;
        assignmentData.submitteddate = new Date();
        setContentData(assignmentData);
        setStatus(
          isSave
            ? constants.PROGRESS_STATUS.TO_BE_EVALUATED
            : constants.PROGRESS_STATUS.IN_PROGRESS
        );
        setIsSubmitted(true);
        setIsEditSubmission(false);
        setIsAddSubmission(false);
        if (isFullScreensp) {
          document.exitFullscreen();
          setIsFullScreensp(false);
        }
        setLpData({});
        if (isSave) {
          navigate("/home");
        }
      }
    } catch (err) {
      setShow(true);
      setTitle("Warning");

      if (err.hasOwnProperty("message") && err.message.includes("403")) {
        setIsUnAuth(true);
        setMsg("You have been logged-out due to inactivity. Login again..");
      } else setMsg(err.message); //else setMsg("Something went wrong. Please try again later");
    } finally {
      setIsLoaded(false);
    }
  };

  return {
    tList,
    details,
    topicLoad,
    customStyle,
    questionList,
    questionLoad,
    handleLogOut,
    selectedTopics,
    handleClearAll,
    selectedQuestions,
    notificationDetails,
    setNotificationDetails,
    handleSubmitAssignmentOnSandpack,
    getLatestSessions,
  };
}
