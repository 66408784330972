import Button from "../Button";
import constants from "../../constants";
import HackathonCard from "./HackathonCard";
import AlertContext from "../../context/AlertContext";
import useAxiosPrivate from "../../Hooks/useAxiosPrivate";
import HackathonForm from "../Admin/Hackathons/HackathonForm";
import React, { useContext, useEffect, useState } from "react";
import { getDataFromStorage, MANAGEMENTROLES, USERSROLES } from "../../util";

const Hackathons = () => {
  const { setShowNotify, setIsLoaded } = useContext(AlertContext);

  const axios = useAxiosPrivate();

  const [hackathons, setHackathons] = useState([]);
  const [openForm, setOpenForm] = useState(false);
  const [editHackatonId, setEditHackathonId] = useState(null);

  useEffect(() => {
    const fetchHackathons = async () => {
      try {
        setIsLoaded(true);
        const response = await axios.get("node/learner/hackathon", {
          headers: {
            "Content-Type": "application/json",
          },
        });
        if (response.data.resultCode === constants.RESULT_STATUS.SUCCESS) {
          setHackathons(response.data.data ?? []);
        } else
          setShowNotify({
            show: true,
            title: "Error!",
            msg:
              response.data.msg ??
              "Something went wrong. Please try again later or contact support.",
          });
      } catch (error) {
        console.error("Error fetching hackathon:", error);
        setShowNotify({
          show: true,
          title: error.message.includes("403") ? "Warning!" : "Error!",
          msg: error.message.includes("403")
            ? "You have been logged-out due to inactivity. Login again."
            : error.message ??
              "Something went wrong. Please try again later or contact support.",
          isUnAuth: error.message.includes("403"),
        });
      } finally {
        setIsLoaded(false);
      }
    };
    if (!openForm) fetchHackathons();
  }, [axios, setShowNotify, setIsLoaded, openForm]);

  const onAdd = () => {
    setOpenForm(true);
    setEditHackathonId(null);
  };

  const onEditHackathon = (item) => {
    if (MANAGEMENTROLES.includes(getDataFromStorage("role"))) {
      setEditHackathonId(item.id);
      setOpenForm(true);
    } else {
      const anchor = document.createElement("a");
      anchor.href = item.link;
      anchor.target = "_blank";
      anchor.click();
    }
  };

  const onDeleteHackathon = async (hid) => {
    try {
      setOpenForm(false);
      setIsLoaded(true);
      const response = await axios.delete(`node/admin/hackathon/${hid}`, {
        headers: {
          "Content-Type": "application/json",
        },
      });
      if (response.data.resultCode === constants.RESULT_STATUS.SUCCESS) {
        setHackathons((pre) => pre.filter((item) => item.id !== hid));
      } else
        setShowNotify({
          show: true,
          title: "Error!",
          msg:
            response.data.msg ??
            "Something went wrong. Please try again later or contact support.",
        });
    } catch (error) {
      console.error("Error fetching hackathon:", error);
      setShowNotify({
        show: true,
        title: error.message.includes("403") ? "Warning!" : "Error!",
        msg: error.message.includes("403")
          ? "You have been logged-out due to inactivity. Login again."
          : error.message ??
            "Something went wrong. Please try again later or contact support.",
        isUnAuth: error.message.includes("403"),
      });
    } finally {
      setIsLoaded(false);
    }
  };

  return (
    <div
      className="hackathonContainer"
      style={
        USERSROLES.includes(getDataFromStorage("role"))
          ? {
              margin: "0 auto 0 auto",
              height: "calc(100vh - 40px)",
              maxWidth: "100%",
              padding: "0 80px",
            }
          : { margin: "101px auto 0 auto", height: "calc(100vh - 102px)" }
      }
    >
      <div className="hackathonSection">
        <div className="hackathonHeaderSec">
          <div className="hackathonHeader">Hackathons</div>
          {MANAGEMENTROLES.includes(getDataFromStorage("role")) && (
            <Button
              size="sm"
              hierarchy={{ type: "secondaryGrey", buttonText: "Hackathon" }}
              icon={{
                type: "leading",
                icon: (
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M10.0001 4.16699V15.8337M4.16675 10.0003H15.8334"
                      stroke="#344054"
                      strokeWidth="1.67"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                ),
              }}
              onClick={onAdd}
            />
          )}
          <HackathonForm
            openForm={openForm}
            setOpenForm={setOpenForm}
            isEdit={
              editHackatonId !== null &&
              Number.isInteger(Number(editHackatonId))
            }
            id={editHackatonId}
            setEditHackathonId={setEditHackathonId}
          />
        </div>

        {hackathons.length > 0 ? (
          <div
            className="hackathonCardsContainer"
            style={
              MANAGEMENTROLES.includes(getDataFromStorage("role"))
                ? { maxHeight: "calc(100vh - 158px)" }
                : {
                    maxHeight: "calc(100vh - 78px)",
                  }
            }
          >
            {hackathons.map((item) => (
              <HackathonCard
                key={item.id}
                item={item}
                role={getDataFromStorage("role")}
                onClick={onEditHackathon}
                onDelete={onDeleteHackathon}
              />
            ))}
          </div>
        ) : USERSROLES.includes(getDataFromStorage("role")) ? (
          <div className="no-hackathons">
            <p>
              No hackathons are currently available.
              <br />
              Check back later.
            </p>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default Hackathons;
