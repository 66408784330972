import Button from "../../Button";
import Avatar from "@mui/material/Avatar";
import constants from "../../../constants";
import { getDataFromStorage } from "../../../util";
import TestSvgIcon from "../../../Svg/TestSvgIcon";
import React, { useContext, useEffect } from "react";
import AlertContext from "../../../context/AlertContext";
import useAxiosPrivate from "../../../Hooks/useAxiosPrivate";
import StudentTestPageHooks from "../../../Hooks/StudentTestPage/StudentTestPageHooks";
import useCommonFunctionHooks from "../../../Hooks/useCommonFunctionHooks";
import { MockTestPageContext } from "../../../context/MockTestPageContext";
import MockTestLibraryHooks from "../../../Hooks/Mock/Student/MockTestLibraryHooks";
import { useLocation } from "react-router-dom";

export default function MockTestResults() {
  const Alert = useContext(AlertContext);
  const MockTestPageCxt = useContext(MockTestPageContext);
  const { pathname } = useLocation();

  const axios = useAxiosPrivate();
  const { viewTest } = StudentTestPageHooks();
  const { handleSearch, handleCodingInfo } = MockTestLibraryHooks();

  const { getCatchBlockDetails, getHeaderDetailsForAxios } =
    useCommonFunctionHooks();

  MockTestPageCxt.searchMockTest.sort(
    (date1, date2) =>
      new Date(date2.submitteddate) - new Date(date1.submitteddate)
  );

  const getSubmittedDate = (date) => {
    date = new Date(date);

    return (
      ((date.getDate() + 1).toString().length === 1
        ? "0" + date.getDate()
        : date.getDate()) +
      "/" +
      ((date.getMonth() + 1).toString().length === 1
        ? "0" + (date.getMonth() + 1)
        : date.getMonth() + 1) +
      "/" +
      date.getFullYear()
    );
  };

  const handleView = (test) => {
    if (test.hasOwnProperty("countitems")) {
      Alert.setLpId(test.id);
      localStorage.setItem("mockLp", true);
      sessionStorage.setItem("lpId", test.id);
      sessionStorage.setItem("lmpid", test.lmpid);
      Alert.navigate("home");
    } else {
      let testData = {
        mockuse: true,
        testid: test.id,
        lpconfigid: 1567,
        lmpid: test.lmpid,
        lmtid: test.lmtid,
        testname: test.name,
        testmode: test.mode,
        status: test.status,
        libtype: test.libtype,
        testtype: test.libtype,
        viewtest: test.viewtest,
        autoeval: test.autoeval === 1,
        proctored: constants.PROCTORING_MODE.TAB_CAM,

        configdata: {
          marks: test.marks,
          duration: test.testduration,
          questionscount: test.questionscount,
        },
      };

      Alert.setTestData(testData);
      Alert.setStatus(constants.PROGRESS_STATUS.EVALUATED);
      Alert.setLpId(parseInt(sessionStorage.getItem("lpId")));
      Alert.setIsTakeTest(false);
      if (test.libtype === constants.libTypeIds.CODING_CHALLENGE) {
        handleCodingInfo(testData);
      } else {
        viewTest();
      }
    }
  };

  useEffect(() => {
    handleSearch();
  }, [MockTestPageCxt.searchText]);

  useEffect(() => {
    Alert.setIsLoaded(true);
    axios
      .post(
        "node/learner/mock/completed",
        JSON.stringify({
          learnerid: getDataFromStorage("learnerid"),
        }),
        getHeaderDetailsForAxios()
      )
      .then((res) => {
        if (res.data.resultCode !== constants.RESULT_STATUS.SUCCESS) {
          Alert.setShowNotify({
            show: true,
            title: "Info",
            msg: res.data.message,
          });
        } else {
          
          if(pathname === "/main/interview/results"){
            const videoQuestions = res.data.data.tests?.filter(data => data.videoqn === 1 && data.withAI === 1);
            MockTestPageCxt.setMockTest(videoQuestions);
            MockTestPageCxt.setSearchMockTest(videoQuestions);
          } else {
            const woAIQuestions = res.data.data.tests?.filter(data => data.withAI === 0);

            if (woAIQuestions.hasOwnProperty("paths")) {
              MockTestPageCxt.setMockTest(
                woAIQuestions.concat(res.data.data.paths)
              );
              MockTestPageCxt.setSearchMockTest(
                woAIQuestions.concat(res.data.data.paths)
              );
            } else {
              MockTestPageCxt.setMockTest(woAIQuestions);
              MockTestPageCxt.setSearchMockTest(woAIQuestions);
            }

            // if (res.data.data.hasOwnProperty("paths")) {
            //   MockTestPageCxt.setMockTest(
            //     res.data.data.tests.concat(res.data.data.paths)
            //   );
            //   MockTestPageCxt.setSearchMockTest(
            //     res.data.data.tests.concat(res.data.data.paths)
            //   );
            // } else {
            //   MockTestPageCxt.setMockTest(res.data.data.tests);
            //   MockTestPageCxt.setSearchMockTest(res.data.data.tests);
            // }
          }
        }
      })
      .catch((error) => {
        getCatchBlockDetails(error);
      })
      .finally(() => {
        Alert.setIsLoaded(false);
      });
  }, [pathname, MockTestPageCxt.dataRerender]);  

  return (
    <div className="mockTestResultsMainContainer">
      {MockTestPageCxt.searchMockTest.map((test) => (
        <div key={test.id} className="mockTestResultsGrid">
          <div className="mockTestResultsGridHeader">
            <div className="mockTestResultsGridHeaderTitle">{test.name}</div>
            {!test.hasOwnProperty("toc") || test.thumbnail === null ? (
              <TestSvgIcon
                videoQn={test.videoqn}
                autoEval={test.autoeval}
                libType={
                  test.hasOwnProperty("libtype")
                    ? test.libtype
                    : constants.libTypeIds.LEARNINGPATH
                }
              />
            ) : (
              <Avatar
                src={test.thumbnail}
                style={{ width: "28px", height: "30px", padding: 0 }}
              />
            )}
          </div>
          <div className="mockTestResultsGridScoreDiv">
            Score
            <div className="mockTestResultsGridScore">
              {(test.libtype === constants.libTypeIds.DESCRIPTIVE &&
              (test.autoeval === 1 || test.videoqn === 1)
                ? "-"
                : test.actualscore) +
                "/" +
                test.targetscore}
            </div>
          </div>
          <div className="mockTestResultsGridCompletionDiv">
            Completed
            <div className="mockTestResultsGridCompletedDate">
              {getSubmittedDate(test.submitteddate)}
              <Button
                size={"sm"}
                onClick={() => handleView(test)}
                hierarchy={{
                  buttonText: "View",
                  type: "secondaryGrey",
                }}
              />
            </div>
          </div>
        </div>
      ))}
    </div>
  );
}
