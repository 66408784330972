import SideNav from "./SideNav";
import { Paper } from "@mui/material";
import User01 from "../../../Svg/User01";
import React, { useContext } from "react";
import constants from "../../../constants";
import TrendUp01 from "../../../Svg/TrendUp01";
import ChevronLeft from "../../../Svg/ChevronLeft";
import { getDataFromStorage } from "../../../util";
import TestSvgIcon from "../../../Svg/TestSvgIcon";
import { Outlet, useLocation } from "react-router-dom";
import AlertContext from "../../../context/AlertContext";
import CheckVerified01 from "../../../Svg/CheckVerified01";
import GraduationHat01 from "../../../Svg/GraduationHat01";
import { ProfileContext } from "../../../context/ProfileContext";
import Loading from "../../Notification-Loading/Loading/Loading";

const paperStyle = {
  width: "76%",
  display: "block",
  overflow: "auto",
  boxShadow: "none",
  borderRadius: "10px",
  margin: "4px 25px 0 0px",
  border: "1px solid #EAECF0",
};

const Profile = () => {
  const { isLoaded } = useContext(AlertContext);
  const { navigate, onSaveProfileDetails } = useContext(ProfileContext);

  const { pathname } = useLocation();

  const menuListItemsStudent = [
    {
      id: 1,
      path: "personalinfo",
      text: "Personal Info",
      active: pathname.includes("personalinfo"),
      icon: (
        <User01
          width="20"
          height="20"
          strokeWidth="1.6"
          stroke={pathname.includes("personalinfo") ? "#FCFCFD" : "#667085"}
        />
      ),
    },
    {
      id: 2,
      path: "parentinfo",
      text: "Parent Info",
      active: pathname.includes("parentinfo"),
      icon: (
        <User01
          width="20"
          height="20"
          strokeWidth="1.6"
          stroke={pathname.includes("parentinfo") ? "#FCFCFD" : "#667085"}
        />
      ),
    },
    {
      id: 3,
      text: "Education",
      path: "education",
      active: pathname.includes("education"),
      icon: (
        <GraduationHat01
          width="20"
          height="20"
          strokeWidth="1.6"
          stroke={pathname.includes("education") ? "#FCFCFD" : "#667085"}
        />
      ),
    },
    {
      id: 4,
      path: "academic",
      text: "Academic Work",
      active: pathname.includes("academic"),
      icon: (
        <TrendUp01
          width="20"
          height="20"
          strokeWidth="1.6"
          stroke={pathname.includes("academic") ? "#FCFCFD" : "#667085"}
        />
      ),
    },
    {
      id: 5,
      path: "socialprofile",
      text: "Social Profiles",
      active: pathname.includes("socialprofile"),
      icon: (
        <CheckVerified01
          width="20"
          height="20"
          strokeWidth="1.6"
          stroke={pathname.includes("socialprofile") ? "#FCFCFD" : "#667085"}
        />
      ),
    },
    {
      id: 7,
      path: "viewresume",
      text: "View Resume",
      active: pathname.includes("viewresume"),
      icon: (
        <TestSvgIcon
          width="20"
          libType={1}
          height="20"
          strokeWidth="1.6"
          component="resume"
          stroke={pathname.includes("viewresume") ? "#FFFFFF" : "#344054"}
        />
      ),
    },
  ];

  const menuListItemsAdmin = [
    {
      id: 1,
      path: "personalinfo",
      text: "Personal Info",
      active: pathname.includes("personalinfo"),
      icon: (
        <User01
          width="20"
          height="20"
          strokeWidth="1.6"
          stroke={pathname.includes("personalinfo") ? "#FCFCFD" : "#667085"}
        />
      ),
    },
    {
      id: 2,
      path: "socialprofile",
      text: "Social Profiles",
      active: pathname.includes("socialprofile"),
      icon: (
        <CheckVerified01
          width="20"
          height="20"
          strokeWidth="1.6"
          stroke={pathname.includes("socialprofile") ? "#FCFCFD" : "#667085"}
        />
      ),
    },
  ];

  return (
    <section
      className={
        getDataFromStorage("role") === constants.Roles.learner
          ? "profileContainerLearner"
          : "profileContainerAdmin"
      }
    >
      <div className="profileBackBtnSec">
        <div
          className="profileBackBtn"
          onClick={() => {
            navigate("/main/dashboard");
          }}
        >
          <ChevronLeft />
          <p className="profileBackBtnText">Back</p>
        </div>
      </div>
      <div className="profileInfoSection">
        <SideNav
          title="Profile"
          onMenuClick={onSaveProfileDetails}
          menuListItems={
            getDataFromStorage("role") === constants.Roles.admin ||
            getDataFromStorage("role") === constants.Roles.trainer
              ? menuListItemsAdmin
              : menuListItemsStudent
          }
        />
        <Paper sx={paperStyle} className="personalInfoSec">
          <Outlet />
        </Paper>
      </div>
      {isLoaded && <Loading />}
    </section>
  );
};

export default Profile;
