import Select from "react-select";
import MockTest from "./MockTest";
import Button from "../../../Button";
import constants from "../../../../constants";
import { useLocation } from "react-router-dom";
import DataTable from "react-data-table-component";
import React, { useContext, useEffect } from "react";
import AlertContext from "../../../../context/AlertContext";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Loading from "../../../Notification-Loading/Loading/Loading";
import { MockTestPageContext } from "../../../../context/MockTestPageContext";
import MockTestListHooks from "../../../../Hooks/Mock/Admin/MockTestListHooks";

export default function MockTestList() {
  const Alert = useContext(AlertContext);
  const MockTestPageCxt = useContext(MockTestPageContext);

  const {
    columns,
    filteredData,
    topicOptions,
    customStyles,
    handleBackBtn,
    contentColumn,
    getMockTestList,
    handleSearchTitle,
    handleTopicSelect,
    handleAddTopicToLp,
    handleCreateTestBtn,
    handleGetTestDetails,
    selectedTopicsForValue,
    handleSelectTopicAddToLp,
  } = MockTestListHooks();

  const { pathname } = useLocation();

  useEffect(() => {
    if (pathname === "/mock/test") Alert.setFromMenu(true);
  }, [pathname]);

  useEffect(() => {
    Alert.setBatchDetails = { learningpath: [] };

    if (!MockTestPageCxt.isAddTest) {
      let libTypeId = Alert.oldTestConfig.isOldTestConfig
        ? Alert.oldTestConfig.oldTestData.libTypeId
        : undefined;

      getMockTestList(libTypeId);
    }
  }, [
    Alert.fromMenu,
    Alert.lpData2.libTypeId,
    Alert.fromMenuToEditTest,
    MockTestPageCxt.isAddTest,
    Alert.oldTestConfig.isOldTestConfig,
    Alert.oldTestConfig.oldTestData.libTypeId,
  ]);

  return (
    <div className="mockTestListMainContainer">
      {!MockTestPageCxt.isAddTest && (
        <>
          <div className="mockTestListHeader">
            <h2 className="mockTestListHeaderLabel">Mock Test List</h2>
            <div className="mockTestListHeaderRightSideDiv">
              <FontAwesomeIcon
                icon={faSearch}
                className="mockTestListHeaderRightSearchIcon"
              />
              <input
                type="text"
                name="search"
                placeholder="Search"
                onChange={handleSearchTitle}
                value={MockTestPageCxt.searchText}
                className="mockTestListHeaderSearchBox"
              />
              <Select
                isMulti
                isSearchable={true}
                isClearable={false}
                options={topicOptions}
                placeholder="Choose topic"
                onChange={handleTopicSelect}
                value={selectedTopicsForValue}
                className="mockTestListHeaderTopicSelect"
              />
              <Button
                size={"sm"}
                onClick={handleCreateTestBtn}
                hierarchy={{
                  type: "primary",
                  buttonText: "Create Mock Test",
                }}
              />
            </div>
          </div>
          <DataTable
            data={filteredData}
            customStyles={customStyles}
            className="mockTestListTable"
            selectableRows={!Alert.fromMenu}
            onSelectedRowsChange={handleSelectTopicAddToLp}
            onRowClicked={(data) => handleGetTestDetails(data)}
            columns={
              Alert.lpData2.libTypeId === constants.libTypeIds.CONTENT ||
              Alert.lpData2.libTypeId === constants.libTypeIds.ASSIGNMENT
                ? contentColumn
                : columns
            }
          />
          {!Alert.fromMenu && (
            <div className="adminTestBtnSec">
              <Button
                size={"sm"}
                onClick={handleBackBtn}
                hierarchy={{
                  buttonText: "Back",
                  type: "secondaryGrey",
                }}
              />
              <Button
                size={"sm"}
                onClick={handleAddTopicToLp}
                hierarchy={{
                  type: "primary",
                  buttonText: "Add",
                }}
              />
            </div>
          )}
        </>
      )}
      {Alert.isLoaded && <Loading />}
      {MockTestPageCxt.isAddTest && <MockTest />}
    </div>
  );
}
