import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Pagination from "./Pagination";
import TestSvgIcon from "../../Svg/TestSvgIcon";
import Typography from "@mui/material/Typography";
import React, { useContext, useState } from "react";
import TestDueHooks from "../../Hooks/TestDueHooks";
import AlertContext from "../../context/AlertContext";
import constants from "../../constants";

export default function AssignmentDue(Props) {
  const { setTestData } = useContext(AlertContext);
  const { currentDueLabel } = TestDueHooks();

  // Pagination control

  const itemsPerPage = 3;
  const [currentPage, setCurrentPage] = useState(1);

  // Calculate the index range for the current page

  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;

  // Get the current page's events

  const currentEvents = Props.dueList?.slice(startIndex, endIndex);

  // Total number of pages

  const totalPages = Math.ceil(Props.dueList?.length / itemsPerPage);

  // Handle pagination click

  const handlePaginationClick = (pageIndex) => {
    setCurrentPage(pageIndex);
  };

  const showDateonly = (dateString) => {
    const date = new Date(dateString);
    const formattedDate = date.toLocaleDateString();

    return formattedDate;
  };

  return (
    <Box className={`assignment-due-box-div ${localStorage.getItem("batchType") == constants.BATCH_TYPE.SELF ?"singlebox":""}`}>
      <Typography className="assignment-due-box-header" gutterBottom>
        Assignments Due
      </Typography>
      {currentEvents?.length > 0 ? (
        <>
          <Box height={170}>
            {currentEvents?.map((data, index) => (
              <Grid
                container
                key={index}
                className="assignment-due-container-div"
              >
                <Box className="assignment-due-container-icon">
                  <TestSvgIcon
                    libType={data.libType}
                    component={"learnerDashBoard"}
                  />
                </Box>
                <Grid className="assignment-due-container-assignment-detail">
                  <Box className="assignment-due-container-assignment-detail-box">
                    <Typography className="assignment-due-container-assignment-name">
                      {data.name}
                    </Typography>
                    <Typography className="assignment-due-container-assignment-detail-date">
                      {data.lpName} . Assigned on{" "}
                      {showDateonly(data.assignedDate)}
                    </Typography>
                  </Box>
                </Grid>
                <Box className="assignment-due-container-assignment-status-box">
                  <div
                    className={
                      currentDueLabel(data.dueDate) === "Past Due"
                        ? "div-PastDue"
                        : currentDueLabel(data.dueDate) === "Due Today"
                        ? "div-DueToday"
                        : "div-NoDue"
                    }
                    onClick={() => {
                      setTestData({
                        testId: data.id,
                        pathName: "dashBoard",
                        moduleId: data.moduleId,
                      });
                      Props.naviageHomeFunction(data.lpId);
                    }}
                  >
                    {currentDueLabel(data.dueDate)}
                  </div>
                </Box>
              </Grid>
            ))}
          </Box>
          <Box className="assignment-due-pagination-box">
            <Pagination
              totalPages={totalPages}
              currentPage={currentPage}
              handlePaginationClick={handlePaginationClick}
            />
          </Box>
        </>
      ) : (
        <>
          <Box className="assignment-due-empty-container">
            <div>
              No Assignment due
              <div>You can focus on your learning path now</div>
            </div>
          </Box>
        </>
      )}
    </Box>
  );
}
