import Webcam from "react-webcam";
import constants from "../../constants";
import React, { useContext } from "react";
import HalfDonutChart from "../HalfDonutChart";
import RightSvgIcon from "../../Svg/RightSvgIcon";
import AlertContext from "../../context/AlertContext";
import CircularProgress from "@mui/material/CircularProgress";
import CodingPageSuccessNotify from "./CodingPageSuccessNotify";
import InstructionPageHooks from "../../Hooks/InstructionPageHooks";
import useCommonFunctionHooks from "../../Hooks/useCommonFunctionHooks";
import CodingPageTestCasesFailedNotify from "./CodingPageTestCasesFailedNotify";

export default function NotifyBody(Props) {
  const Alert = useContext(AlertContext);

  const InstructionPageHks = InstructionPageHooks();
  const { handleWebCamError } = useCommonFunctionHooks();

  return (
    <div className="notifyBodyMainContainer">
      {Props.component ===
      "codingPageNotifyMsgForInfiniteLoopWhileSubmitting" ? (
        <ul className="modalInstructionBodyunorderedListWithTestCasesFailed">
          <div>Something went wrong! program is taking too long to finish.</div>
          <div className="modalInstructionBodyTestCasesFailedTextList">
            Your code did not pass our test cases
          </div>
          <li className="modalInstructionBodyTestCasesFailedTextList">
            Try checking your code with all the test cases.
          </li>
          <li className="modalInstructionBodyTestCasesFailedTextList">
            Ensure you create new test cases and cover all the scenarios.
          </li>
          <li className="modalInstructionBodyTestCasesFailedTextList">
            Click on “Submit” if you want to still submit.
          </li>
          <li className="modalInstructionBodyTestCasesFailedTextList">
            Click on “Try Again” to continue with the current test.
          </li>
        </ul>
      ) : Props.component ===
        "mcqAndDescPageNotifyMsgForSuccessWhileSubmitting" ? (
        <div className={Props.component}>{Props.msg}</div>
      ) : Props.component === "codingPageNotifyMsgForSuccessWhileSubmitting" ? (
        <CodingPageSuccessNotify {...Props} />
      ) : Props.component ===
        "codingPageNotifyMsgForFailedTestCasesWhileSubmitting" ? (
        <CodingPageTestCasesFailedNotify {...Props} />
      ) : Props.component ===
        "codingPageNotifyMsgForFailedTestCasesStaticWhileSubmitting" ? (
        <div>
          {Props.isChart && (
            <div>
              <HalfDonutChart
                achievedPercentage={Props.achievedpercent}
                targetPercentage={Props.targetPercent}
              />
              <div className="after-submit-container">
                <div className="after-submit-header">Its Alright</div>
                <div className="after-submit-msg">
                  You Scored {Props.achievedpercent}%. The minimum criteria for
                  passing is {Props.targetPercent}%
                </div>
                {Props.explanation && (
                  <div className="after-submit-msg">
                    Explanation: {Props.explanation}
                  </div>
                )}
              </div>
            </div>
          )}
          <div>
            <ul className="modalInstructionBodyunorderedListWithTestCasesFailed">
              <div>
                Your code did not pass our testcases
                {Props.target && " and you scored less than the target score"}.
              </div>
              <div className="modalInstructionBodyTestCasesFailedTextList">
                As a next step
              </div>
              <li className="modalInstructionBodyTestCasesFailedTextList">
                Try checking your code with all the test cases.
              </li>
              <li className="modalInstructionBodyTestCasesFailedTextList">
                Ensure you create new test cases and cover all the scenarios.
              </li>
              {!Props.target && (
                <>
                  {!Props.isChart && (
                    <li className="modalInstructionBodyTestCasesFailedTextList">
                      Click on “Submit” if you want to still submit.
                    </li>
                  )}
                  <li className="modalInstructionBodyTestCasesFailedTextList">
                    Click on “Try Again” to continue with the current test.
                  </li>
                </>
              )}
            </ul>
          </div>
        </div>
      ) : Props.component ===
        "codingPageNotifyMsgForTimeLapsedsWhileSubmitting" ? (
        <ul>
          <li className="modalInstructionBodyTextList">
            You took too long for this{" "}
            {Alert.testData.testtype === constants.libTypeIds.CODING_CHALLENGE
              ? "coding challenge"
              : Alert.testData.testtype === constants.libTypeIds.MCQ
              ? "Multiple Choice Question"
              : "Descriptive Test"}
            . You were given a fixed time and it lapsed.
          </li>
          <li className="modalInstructionBodyTextList">
            You can close this challenge now, and you will be taken back to the
            course home page. You will be given another challenge of same
            difficulty.
          </li>
          <li className="modalInstructionBodyTextList">
            If you have tried all the test, you will need to wait for trainer to
            help.
          </li>
        </ul>
      ) : Props.component ===
        "codingPageNotifyMsgForTabSwitchesWhileSubmitting" ? (
        <ul>
          <li className="modalInstructionBodyTextList">
            We have detected you have been switching tabs. Your submission is
            flagged and has been auto-submitted.
          </li>
          <li className="modalInstructionBodyTextList">
            As you are taking a coding challenge, we will give you another
            attempt to clear this challenge, but the problem statement will be
            replaced with another problem of similar complexity.
          </li>
          <li className="modalInstructionBodyTextList">
            If you have exhausted maximum number of attempts you will loose
            chance to take up this challenge level.
          </li>
        </ul>
      ) : Props.component === "instructionPageNotifyMsgForRegistration" ? (
        <div>
          <ul>
            <li>
              Looks like this is the first time, you are taking a proctored test
              in the Gradious LEAP platform.
            </li>
            <br />
            <li>
              As a first step, we would need to capture a photo of you so that
              we know who is taking the test.
            </li>
            <br />
            <li>
              Note, tests in the Gradious LEAP platform are proctored. That
              means, we know when{" "}
            </li>
          </ul>
          <ol style={{ marginLeft: "30px" }}>
            <li>
              A candidate is switching windows. In fact, your test will be
              automatically invalidated if the system finds this happening.{" "}
            </li>
            <br />
            <li>
              Captures your photo, while you are taking the test. And checks for
              the candidate photo matching, multiple people in the frame, use of
              other electronic devices, and also candidates eye movements to
              detect any kind of cheating.
            </li>
          </ol>
          <ul>
            <li>Please click on continue to move forward and take the test.</li>
          </ul>
        </div>
      ) : Props.component === "instructionPageNotifyMsgForCameraAccess" ||
        Props.component === "instructionPageNotifyMsgForAudioAccess" ? (
        <div className={Props.component}>
          Your
          {Props.component === "instructionPageNotifyMsgForCameraAccess"
            ? " camera "
            : " microphone "}
          is not accessible. This test has to be taken in proctoring mode by
          enabling the{" "}
          {Props.component === "instructionPageNotifyMsgForCameraAccess"
            ? " camera."
            : " microphone."}
          <p>
            Please ensure your
            {Props.component === "instructionPageNotifyMsgForCameraAccess"
              ? " camera "
              : " microphone "}
            is accessible.
          </p>
        </div>
      ) : Props.component === "proctoringDataWarning" ? (
        <div className="proctoringDataWarning">
          <Webcam
            screenshotFormat="image/jpeg"
            className="instructionPageWebCam"
            onUserMediaError={handleWebCamError}
            videoConstraints={InstructionPageHks.videoConstraints}
          />
          {Props.data.Mobile_Found && <div>Mobile detected</div>}
          {Props.data.Multi_People_Found && <div>Multiple face detected</div>}
        </div>
      ) : Props.component === "videoTestLowNetwork" ? (
        <div>
          <div className="notify-div-box-circle">
            {Alert.buttonDisable ? (
              <>
                <CircularProgress
                  className="progresscircle-div-box-circle"
                  variant="determinate"
                  thickness={5}
                  value={
                    Alert.qnTrack.current > 0
                      ? Math.round(
                          (Alert.VideoSaved / Alert.qnTrack.current) * 100
                        )
                      : 0
                  }
                />
              </>
            ) : (
              <div className="right-icon-container">
                <RightSvgIcon practice={true} />
              </div>
            )}
          </div>
          <div>
            {Alert.buttonDisable ? (
              <>
                Video upload is in progress and the assignment was automatically
                submitted. Please ensure a stable network connection and do not
                close the app until the video is finished saving. You can track
                the status in the learning path
              </>
            ) : (
              <div className="after_success">Video uploaded successfully.</div>
            )}
          </div>
        </div>
      ) : (
        Props.component === "mockPage" && (
          <>
            <div>
              {Props.data.type} has been {Props.data.status} successfully.
            </div>
            <br />
            <b>Note : </b>
            <ul>
              <li>
                Click the <b>Publish</b> button to publish the{" "}
                {Props.data.type.toLowerCase()}.
              </li>
              <li>
                Click the <b>Back to Mock</b> button to go back to{" "}
                {Props.data.type === "Test" ? "mock " : "group "} testlist page.
              </li>
            </ul>
          </>
        )
      )}
    </div>
  );
}
