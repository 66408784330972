import React, { useContext } from "react";
import { getDataFromStorage } from "../util";
import SettingSvgIcon from "./SettingSvgIcon";
import { useLocation } from "react-router-dom";
import IconButton from "@mui/material/IconButton";
import AlertContext from "../context/AlertContext";
import NotificationSvgIcon from "./NotificationSvgIcon";
import NotificationHighlightSvgIcon from "./NotificationHighlightSvgIcon";

export default function HeaderRightNavSvgIcon() {
  const Alert = useContext(AlertContext);

  const { pathname } = useLocation();

  return (
    <div className="headerRightNavSvgIcon">
      {/* <SettingSvgIcon /> */}
    </div>
  );
}
