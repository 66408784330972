import React, {
  Fragment,
  // useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import moment from "moment";
import InputField from "../../CommonComponents/InputField";
import InputDropdown from "../../CommonComponents/InputDropdown";
import Textarea from "../../CommonComponents/Textarea";
import DragAndDropUpload from "../../CommonComponents/DragAndDropUpload";
import Button from "../../Button";
// import Container from "../../Admin/Batch/Report/DropdownContainer";
import AlertContext from "../../../context/AlertContext";
import useAxiosPrivate from "../../../Hooks/useAxiosPrivate";
import { getDataFromStorage, MANAGEMENTROLES } from "../../../util";
import useUploadLargeFileToS3 from "../../../Hooks/useUploadLargeFileToS3";
import constants from "../../../constants";
import { makeTestLists } from "../../Admin/Batch/Settings/SkillMapping";
import {
  findNameById,
  // markCheckedNodes,
} from "../../Admin/Batch/Settings/SkillBody";
import {
  supportMimeTypes,
  supportedFormat,
  fields,
  maxChar,
  validate,
} from "../Issue/IssueForm";
import MultiLevelDropdown from "../../CommonComponents/MultiLevelDropdown";

const DoubtForm = ({
  initialDetails,
  setShowForm,
  isEdit,
  details,
  setDetails,
  selectedBatch,
  setSelectedBatch,
  selectedType,
  setSelectedType,
  setIsSuccess,
}) => {
  const { setIsLoaded, setShowNotify } = useContext(AlertContext);
  const axios = useAxiosPrivate();
  const { uploadFileToS3 } = useUploadLargeFileToS3();

  const [titleCharCount, setTitleCharCount] = useState(0);
  const [batches, setBatches] = useState([]);
  // const [types, setTypes] = useState([]);
  const [modules, setModules] = useState([]);
  const [errors, setErrors] = useState({});
  const [isRemoved, setIsRemoved] = useState(false);
  const [selectModule, setSelectedModule] = useState("");

  const moduleRef = useRef(modules);
  // const ref = useRef(null);

  const isDisabled = !!MANAGEMENTROLES.includes(getDataFromStorage("role"));

  useEffect(() => {
    const fetchBatchesAndTypes = async () => {
      try {
        setIsLoaded(true);
        const response = await axios.get(
          `node/learner/batch/${getDataFromStorage("learnerid")}`,
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
        // const response2 = await axios.get(`node/learner/issue/type?isdoubt=1`, {
        //   headers: {
        //     "Content-Type": "application/json",
        //   },
        // });
        // if (response2.data.resultCode === constants.RESULT_STATUS.SUCCESS) {
        //   const data = response2.data.data ?? [];
        //   setTypes(
        //     data.map((item, index) => ({
        //       ...item,
        //       value: item.id,
        //       label: item.name,
        //     }))
        //   );
        //   setErrors({});
        // } else {
        //   setShowNotify({
        //     show: true,
        //     title: "Error!",
        //     msg:
        //       response.data.msg ??
        //       "Failed to fetch issue types. Please try again later or contact gradious team.",
        //   });
        // }
        if (response.data.resultCode === constants.RESULT_STATUS.SUCCESS) {
          const data = response.data.data ?? [];
          setBatches(
            data.map((item, index) => ({
              ...item,
              value: item.id,
              label: item.name,
            }))
          );
        } else {
          setShowNotify({
            show: true,
            title: "Error!",
            msg:
              response.data.msg ??
              "Failed to fetch batches. Please try again later or contact gradious team.",
          });
        }
      } catch (error) {
        setShowNotify({
          show: true,
          title: error.message.includes("403") ? "Warning!" : "Error!",
          msg: error.message.includes("403")
            ? "You have been logged-out due to inactivity. Login again."
            : error.message ??
              "Something went wrong. Please try again later or contact gradious team.",
          isUnAuth: error.message.includes("403"),
        });
      } finally {
        setIsLoaded(false);
      }
    };

    fetchBatchesAndTypes();
  }, [axios, setIsLoaded, setShowNotify]);

  useEffect(() => {
    const fetchModules = async () => {
      try {
        setIsLoaded(true);
        const response = await axios.get(
          `node/learner/${getDataFromStorage("learnerid")}/batch/${
            selectedBatch.id
          }/learningpath/modules`,
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
        if (response.data.resultCode === constants.RESULT_STATUS.SUCCESS) {
          let modules = makeTestLists(
            JSON.parse(response.data.data[0]?.data ?? "[]")
          );
          if (details.mid) {
            setSelectedModule(findNameById(modules, details.mid, false, true));
            // modules = markCheckedNodes(modules, [details.mid]);
          }
          setModules(modules);
          moduleRef.current = modules;
        } else {
          setShowNotify({
            show: true,
            title: "Error!",
            msg:
              response.data.msg ??
              `Failed to fetch modules. Please try again later or contact gradious team.`,
          });
        }
      } catch (error) {
        setShowNotify({
          show: true,
          title: error.message.includes("403") ? "Warning!" : "Error!",
          msg: error.message.includes("403")
            ? "You have been logged-out due to inactivity. Login again."
            : error.message ??
              "Something went wrong. Please try again later or contact gradious team.",
          isUnAuth: error.message.includes("403"),
        });
      } finally {
        setIsLoaded(false);
      }
    };
    if (
      Object.keys(selectedBatch).length &&
      Number.isInteger(Number(selectedBatch.id))
    )
      fetchModules();
  }, [selectedBatch, setIsLoaded, setShowNotify, axios]);

  const onChange = (name, value = "") => {
    if (name === "title") {
      setTitleCharCount(value.length);
      if (value.length === maxChar) return;
    }
    setErrors({ ...errors, [name]: validate(name, value) });
    setDetails({ ...details, [name]: value });
  };

  // const onSelectIssueType = (event) => {
  //   setSelectedType(event.target);
  //   setErrors({ ...errors, type: validate("type", event.target.id) });
  //   setDetails({
  //     ...details,
  //     type: event.target.id,
  //     typeName: event.target.value,
  //   });
  // };

  const onSelectBatch = (event) => {
    setSelectedBatch(event.target);
    setErrors({ ...errors, bid: validate("bid", event.target.id) });
    setDetails({ ...details, bid: event.target.id, bname: event.target.value });
  };

  const onSelectModule = (item) => {
    setSelectedModule(item.label);
    setDetails((prev) => ({ ...prev, mid: item?.value ?? null }));
  };

  // const onSelectModule = useCallback(
  //   (currentNode, selectedNodes) => {
  //     if (selectedNodes.length > 1 || selectedNodes[0]?._children?.length) {
  //       const updatedModule = markCheckedNodes(moduleRef.current, []);
  //       setModules(updatedModule);
  //       moduleRef.current = updatedModule;
  //       // setSelectedModule({});
  //       setShowNotify({
  //         show: true,
  //         title: "Warning !",
  //         msg: `Selection of multiple modules is not allowed.`,
  //       });
  //       setDetails((prev) => ({ ...prev, mid: null }));
  //       // return;
  //     } else {
  //       const updatedModule = markCheckedNodes(moduleRef.current, [
  //         selectedNodes[0]?.value,
  //       ]);
  //       setModules(updatedModule);
  //       moduleRef.current = updatedModule;
  //       setDetails((prev) => ({ ...prev, mid: selectedNodes[0]?.value }));
  //     }
  //     // setSelectedModule({
  //     //   id: selectedNodes[0]?.value,
  //     //   label: selectedNodes[0]?.label,
  //     // });
  //   },
  //   [setDetails, setShowNotify]
  // );

  const onDragOver = (event) => event.preventDefault();

  const onDrop = (event) => {
    const files = Array.from(event.dataTransfer.files);
    uploadFiles(files);
  };

  const uploadFiles = (files = []) => {
    if (files.length > 1) {
      setErrors({
        ...errors,
        attachment:
          "Multiple files are not supported in this format. Please upload a single ZIP file.",
      });
      return;
    }
    const isUploadableFormat = files.every((file) =>
      supportMimeTypes.includes(file.type)
    );
    if (!isUploadableFormat) {
      setErrors({
        ...errors,
        attachment: `Unsupported file type: ${
          files[0].type.split("/")[1]
        }. Allowed formats are: ${supportedFormat}`,
      });
      return;
    }
    setDetails({ ...details, attachment: files[0] });
  };

  const onUpload = (event) => {
    const files = Array.from(event.target.files);
    uploadFiles(files);
    event.target.value = "";
  };

  const onRemoveFile = () => {
    if (isEdit) setIsRemoved(true);
    setDetails({ ...details, attachment: null });
  };

  const onSumbitOrUpdate = async (event) => {
    event.preventDefault();
    let validationErrors = {};
    fields.forEach((name) => {
      const error = validate(name, details[name]);
      if (error && error.length > 0) {
        validationErrors[name] = error;
      }
    });
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }
    try {
      setIsLoaded(true);
      let attachment = details.attachment,
        path;
      if (attachment) {
        let imageName = attachment.name;
        path =
          !isEdit || (isEdit && isRemoved)
            ? `doubts/${imageName?.substring(
                0,
                imageName.lastIndexOf(".")
              )}_${moment().toISOString()}${imageName?.substring(
                imageName.lastIndexOf(".")
              )}`
            : `doubts/${imageName}`;
        if (!isEdit || (isEdit && isRemoved))
          await uploadFileToS3(
            attachment,
            path,
            imageName?.split(".").pop().toLowerCase(),
            "uploadAssignment"
          );
        // details.attachment = path;
        details.attachmentSize = `${Math.floor(
          details.attachment.size / 125000
        )}MB`;
        details.attachmentName = details.attachment.name;
      }
      const method = isEdit ? "put" : "post";
      const url = isEdit
        ? `node/learner/issue/${details.id}`
        : "node/learner/issue";
      if (isEdit) delete details.id;
      const response = await axios({
        method,
        url,
        data: { ...details, attachment: path, isdoubt: true },
        headers: {
          "Content-Type": "application/json",
        },
      });
      if (response.data.resultCode === constants.RESULT_STATUS.SUCCESS) {
        setIsSuccess(true);
      } else {
        setShowNotify({
          show: true,
          title: "Error!",
          msg:
            response.data.msg ??
            `Failed to ${
              isEdit ? "update" : "add"
            } issue. Please try again later or contact gradious team.`,
        });
      }
    } catch (error) {
      console.log("Error", error);
      setShowNotify({
        show: true,
        title: error.message.includes("403") ? "Warning!" : "Error!",
        msg: error.message.includes("403")
          ? "You have been logged-out due to inactivity. Login again."
          : error.message ??
            "Something went wrong. Please try again later or contact gradious team.",
        isUnAuth: error.message.includes("403"),
      });
    } finally {
      setIsLoaded(false);
      onCancel();
    }
  };

  const onCancel = (event) => {
    event?.preventDefault();
    setShowForm(false);
    setDetails(initialDetails);
    setIsSuccess(false);
    setSelectedBatch({});
    setSelectedType({});
    setErrors({});
  };

  return (
    <Fragment>
      <form className="issueFormContainer doubtFormContainer">
        <div className="issueFormFields">
          <InputField
            name="title"
            label="Title"
            placeholder="Give a title to your query"
            hintText={
              errors["title"] && errors["title"].length
                ? errors.title
                : `${titleCharCount}/80 characters`
            }
            value={details.title}
            onChange={(event) =>
              onChange(event.target.name, event.target.value)
            }
            disabled={isDisabled}
            destructive={errors["title"] && errors["title"].length}
          />
          <div className="issueFormSelectFieldSec">
            {/* <InputDropdown
              type="default"
              label="Type"
              placeholder="Select the query type"
              menuLists={types}
              onChange={onSelectIssueType}
              value={selectedType.value ?? ""}
              disabled={isDisabled}
              hintText={errors["type"]}
              destructive={!!errors["type"]}
            /> */}
            <InputDropdown
              label="Batch"
              placeholder="Select the batch"
              menuLists={batches ?? []}
              type="default"
              onChange={onSelectBatch}
              value={selectedBatch.value ?? ""}
              disabled={isDisabled}
              hintText={errors["bid"]}
              destructive={!!errors["bid"]}
            />
          </div>
          <div className="issueFormSelectModuleSec">
            <label className="issueFormSelectModuleLabel">Module</label>
            <MultiLevelDropdown
              placeholder="Select the Module in the learning path "
              items={modules}
              selectedOption={selectModule}
              onSelect={onSelectModule}
            />
            {/* <Container
              className="issueFormSelectModule"
              keepTreeOnSearch={true}
              keepOpenOnSelect={false}
              onChange={(currentNode, selectedNodes) =>
                onSelectModule(currentNode, selectedNodes)
              }
              data={modules}
              showPartiallySelected={true}
              texts={{
                placeholder: `Select the Module in the learning path`,
              }}
              disabled={isDisabled}
              closeOnOutsideClick={true}
              ref={ref}
            /> */}
          </div>
          <Textarea
            placeholder="Ask your doubt in detail, to get complete response"
            name="description"
            value={details.description}
            onChange={(event) =>
              onChange(event.target.name, event.target.value)
            }
            disabled={isDisabled}
            hintText={errors["description"]}
            destructive={!!errors["description"]}
          />
          <DragAndDropUpload
            onDragOver={onDragOver}
            onDrop={onDrop}
            file={details.attachment}
            isDeletable={!MANAGEMENTROLES.includes(getDataFromStorage("role"))}
            onRemoveFile={onRemoveFile}
            disabled={isDisabled}
            isDownloadable={
              !!MANAGEMENTROLES.includes(getDataFromStorage("role"))
            }
            isSuccessfullyUpload={
              !MANAGEMENTROLES.includes(getDataFromStorage("role"))
            }
            button={
              <Button
                size="sm"
                hierarchy={{ type: "secondaryGrey", buttonText: "Upload" }}
                icon={{
                  type: "leading",
                  icon: (
                    <svg
                      width="20"
                      height="21"
                      viewBox="0 0 20 21"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M17.5 13V16.3333C17.5 16.7754 17.3244 17.1993 17.0118 17.5118C16.6993 17.8244 16.2754 18 15.8333 18H4.16667C3.72464 18 3.30072 17.8244 2.98816 17.5118C2.67559 17.1993 2.5 16.7754 2.5 16.3333V13M14.1667 7.16667L10 3M10 3L5.83333 7.16667M10 3V13"
                        stroke="#344054"
                        strokeWidth="1.67"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  ),
                }}
              />
            }
            onUpload={onUpload}
            text="Drop your screenshot of the doubt here"
            supportFormat={supportedFormat}
            hintText={errors["attachment"]}
            destructive={!!errors["attachment"]}
          />
        </div>
        {!MANAGEMENTROLES.includes(getDataFromStorage("role")) && (
          <div className="issueFormBtnSec">
            <Button
              size="lg"
              hierarchy={{ type: "secondaryGrey", buttonText: "Cancel" }}
              onClick={onCancel}
            />
            <Button
              size="lg"
              hierarchy={{
                type: "primary",
                buttonText: isEdit ? "Update" : "Submit",
              }}
              onClick={onSumbitOrUpdate}
            />
          </div>
        )}
      </form>
      {/* ) : (
        <div className="issueSuccessContainer">
          <div className="issueSuccessIcon">
            <svg
              width="144"
              height="150"
              viewBox="0 0 144 150"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M132 69.2858V75.0358C131.993 88.5134 127.803 101.627 120.056 112.422C112.309 123.217 101.42 131.114 89.0121 134.935C76.6046 138.757 63.3435 138.298 51.2068 133.627C39.0701 128.956 28.7079 120.324 21.6658 109.017C14.6236 97.711 11.2787 84.3362 12.1301 70.8877C12.9814 57.4393 17.9832 44.6378 26.3897 34.3925C34.7961 24.1471 46.1567 17.0069 58.7771 14.0367C71.3975 11.0665 84.6016 12.4254 96.42 17.9108M132 25L72 87.5625L54 68.8125"
                stroke="#12B76A"
                strokeWidth="8"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
            <div className="issueSuccessText">
              New Doubt successfully {isEdit ? "updated" : "submitted"}
            </div>
          </div>
          <Button
            size="lg"
            hierarchy={{ type: "primary", buttonText: "Ok" }}
            onClick={onCancel}
          />
        </div>
      )} */}
    </Fragment>
  );
};

export default DoubtForm;
