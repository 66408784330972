import { nanoid } from "nanoid";
import Form from "react-bootstrap/Form";
import constants from "../../../constants";
import useAxiosPrivate from "../../useAxiosPrivate";
import React, { useContext, useState } from "react";
import AlertContext from "../../../context/AlertContext";
import useCommonFunctionHooks from "../../useCommonFunctionHooks";
import { MockTestPageContext } from "../../../context/MockTestPageContext";

export default function MockTestListHooks() {
  const Alert = useContext(AlertContext);
  const MockTestPageCxt = useContext(MockTestPageContext);

  const axios = useAxiosPrivate();

  const { handleClose, getCatchBlockDetails, getHeaderDetailsForAxios } =
    useCommonFunctionHooks();

  const [topics, setTopics] = useState([]);
  const [selectedTopics, setSelectedTopics] = useState([]);
  const [selectedTopicsForValue, setSelectedTopicsForValue] = useState([]);

  let topicData;

  const topicOptions = [{ value: [], label: "All" }, ...topics];

  const columns = [
    {
      width: "25%",
      name: "Title",
      sortable: true,
      selector: (row) => row.title,
    },
    {
      width: "35%",
      name: "Topics",
      sortable: true,
      selector: (row) =>
        row.topics.map((topic, index) => {
          if (index === row.topics.length - 1) {
            return topic.topicname;
          } else {
            return topic.topicname + ",";
          }
        }),
    },
    {
      width: "10%",
      sortable: true,
      name: "No of Qn",
      selector: (row) => row.qncount,
    },
    {
      width: "12%",
      sortable: true,
      name: "Duration (mins)",
      selector: (row) => row.duration,
    },
    {
      width: "18%",
      sortable: true,
      name: "Active",
      selector: (row) => (
        <Form.Check
          reverse
          type="switch"
          checked={row.mockuse === 2}
          onChange={() => handleActiveMockTest(row)}
        />
      ),
    },
  ];

  let contentColumn = [
    {
      width: "35%",
      name: "Title",
      sortable: true,
      selector: (row) => row.title,
    },
    {
      width: "20%",
      name: "Topics",
      sortable: true,
      selector: (row) =>
        row.topics.map((topic, index) => {
          if (index === row.topics.length - 1) {
            return topic.topicname;
          } else {
            return topic.topicname + ",";
          }
        }),
    },
    {
      width: "15%",
      sortable: true,
      name: "Createdby",
      selector: (row) => row.createdby,
    },
    {
      width: "20%",
      name: "Attachments",
      selector: (row) => (
        <ul>
          {row.topics.map((topic, index) =>
            topic.libattachments.map((attachment) => {
              let libAttachment =
                attachment.attachments !== null ? attachment.attachments : "";
              let index = libAttachment.lastIndexOf("/");

              return libAttachment !== "" ? (
                <li key={nanoid()}>
                  {attachment.type === constants.LINK_TYPE.EMBEDED_LINK ? (
                    <>
                      <a href={libAttachment} target="_blank" rel="noreferrer">
                        {libAttachment}
                      </a>
                      <br />
                    </>
                  ) : (
                    <>
                      <a
                        target="_blank"
                        rel="noreferrer"
                        href={process.env.REACT_APP_NODE_API + libAttachment}
                      >
                        {libAttachment.slice(index + 1)}
                      </a>
                      <br />
                    </>
                  )}
                </li>
              ) : (
                "-"
              );
            })
          )}
        </ul>
      ),
    },
  ];

  const customStyles = {
    headCells: {
      style: {
        fontSize: "12px",
        color: "#9AA0A6",
        marginTop: "10px",
        fontWeight: "500",
        lineHeight: "15px",
        position: "sticky",
        fontFamily: "Inter",
        fontStyle: "normal",
        background: "#FFFFFF",
        textTransform: "uppercase",
      },
    },
    cells: {
      style: {
        color: "#202124",
        fontSize: "14px",
        fontWeight: "400",
        lineHeight: "20px",
        fontStyle: "normal",
        fontFamily: "Inter",
        background: "#FFFFFF",
      },
    },
  };

  const handleSearchTitle = (e) => {
    MockTestPageCxt.setSearchText(e.target.value);
  };

  const handleSelectTopicAddToLp = (state) => {
    topicData = state.selectedRows.reverse();
  };

  const handleTopicSelect = (e) => {
    setSelectedTopics(Array.from(e, (e) => e.value));
    setSelectedTopicsForValue(e);
  };

  const handleBackBtn = () => {
    let newDetails = JSON.parse(JSON.stringify(Alert.lpData2));

    newDetails.isAddTest = false;
    Alert.handleNavigateToLpAfterAddTest(newDetails);
  };

  const filteredData = Alert.lists.filter((item) => {
    for (let i = 0; i < item.topics.length; i++) {
      const includesText = item.title
        .toLowerCase()
        .includes(MockTestPageCxt.searchText.toLowerCase());

      const includesTopic =
        selectedTopics.length === 0 ||
        selectedTopics[0].length === 0 ||
        selectedTopics.includes(item.topics[i].topicid);

      if (includesText && includesTopic === true) {
        return includesText && includesTopic;
      }
    }

    return "";
  });

  const handleCreateTestBtn = () => {
    if (Alert.lpData2.libTypeId === constants.libTypeIds.ASSIGNMENT) {
      MockTestPageCxt.setIsAddTest(false);
      Alert.setselectque({ published: 0 });
      Alert.setFromList({ isFromList: true, libType: Alert.lpData2.libTypeId });
      Alert.setTableShowStatus(Alert.lpData2.libTypeId);
      Alert.navigate("/library/assignment");
    } else if (Alert.lpData2.libTypeId === constants.libTypeIds.CONTENT) {
      MockTestPageCxt.setIsAddTest(false);
      Alert.setselectque({ published: 0 });
      Alert.setFromList({ isFromList: true, libType: Alert.lpData2.libTypeId });
      Alert.setTableShowStatus(Alert.lpData2.libTypeId);
      Alert.navigate("/library/contententry");
    } else {
      MockTestPageCxt.setIsNewTest(true);
      MockTestPageCxt.setIsAddTest(true);
      MockTestPageCxt.setIsChecked(false);
    }
  };

  const handleActiveMockTest = async (row) => {
    try {
      let response = await axios.put(
        `node/admin/test/settings/update/${row.id}`,
        JSON.stringify({ activateMock: row.mockuse === 1 ? 2 : 1 }),
        getHeaderDetailsForAxios()
      );

      if (response.data.resultCode !== constants.RESULT_STATUS.SUCCESS) {
        Alert.setShowNotify({
          show: true,
          title: "Warning",
          msg: "Something went wrong. Please try again later.",
        });
      } else {
        if (row.from === "test") {
          handleClose();
          MockTestPageCxt.setIsAddTest(false);
        } else {
          getMockTestList(undefined);
        }
      }
    } catch (error) {
      getCatchBlockDetails(error);
    } finally {
      Alert.setIsLoaded(false);
    }
  };

  const getMockTestList = (id) => {
    Alert.setIsLoaded(true);

    axios
      .post(
        "node/admin/mock/test",
        JSON.stringify({ libtypeid: id }),
        getHeaderDetailsForAxios()
      )
      .then((res) => {
        if (res.data.resultCode !== constants.RESULT_STATUS.SUCCESS) {
          Alert.setShowNotify({
            show: true,
            title: "Error",
            msg: res.data.message,
          });
        } else {
          let tests;

          if (
            id !== constants.libTypeIds.CONTENT &&
            id !== constants.libTypeIds.ASSIGNMENT
          ) {
            tests = JSON.parse(res.data.data.tests);
          } else {
            tests = JSON.parse(res.data.data.data);
          }

          if (tests !== null) {
            Alert.setLists(tests);

            let topics = [];

            for (let i = 0; i < tests.length; i++) {
              for (let j = 0; j < tests[i].topics.length; j++) {
                topics.push({
                  value: tests[i].topics[j].topicid,
                  label: tests[i].topics[j].topicname,
                });
                topics = [...new Map(topics.map((m) => [m.value, m])).values()];
              }
            }

            setTopics(topics);

            if (Alert.fromMenu && Alert.fromMenuToEditTest)
              MockTestPageCxt.setIsAddTest(false);
          } else {
            Alert.setLists([]);
            Alert.setShowNotify({
              show: true,
              title: "Warning",
              msg: "There is no data.",
            });
          }
        }
      })
      .catch((error) => {
        getCatchBlockDetails(error);
      })
      .finally(() => Alert.setIsLoaded(false));
  };

  const handleAddTopicToLp = () => {
    let newDetails = JSON.parse(JSON.stringify(Alert.lpData2)),
      uniqueId = 1,
      count = 0,
      found = 0;

    for (let i = 0; i < newDetails.modules.length; i++) {
      if (newDetails.modules[i].id === newDetails.moduleId) {
        for (let k = 0; k < topicData.length; k++) {
          for (let j = 0; j < newDetails.modules[i].moduleData.length; j++) {
            if (newDetails.modules[i].moduleData[j].hasOwnProperty("testId")) {
              if (
                newDetails.modules[i].moduleData[j].testId == null &&
                newDetails.modules[i].moduleData[j].libId == null
              ) {
                newDetails.modules[i].moduleData.splice(j, 1);
              } else {
                if (
                  newDetails.modules[i].moduleData[j].testId === topicData[k].id
                ) {
                  found = 1;
                  Alert.setShowNotify({
                    show: true,
                    title: "Warning",
                    msg: "The Test is already added to learningpath",
                  });

                  break;
                }
              }
            } else {
              if (newDetails.modules[i].moduleData[j].id === topicData[k].id) {
                found = 1;
                Alert.setShowNotify({
                  show: true,
                  title: "Warning",
                  msg: "The Test is already added to learningpath",
                });
                break;
              }
            }
          }

          if (found === 0) {
            topicData[k].published = 0;
            topicData[k].status = "new";
            topicData[k].isEdited = false;
            topicData[k].testStatus = "old";
            topicData[k].libTypeId = Alert.lpData2.libTypeId;
            newDetails.modules[i].moduleData.push(topicData[k]);
          } else break;
        }
      }

      newDetails.modules[i].id = uniqueId;
      uniqueId++;

      if (found === 0) {
        for (let j = 0; j < newDetails.modules[i].moduleData.length; j++) {
          newDetails.modules[i].moduleData[j].moduleDataId = uniqueId;
          newDetails.modules[i].moduleData[j].key = nanoid();
          uniqueId++;
        }
      }

      count++;
    }

    newDetails.isAddTest = false;

    if (Alert.isFromBatch || Alert.isOldLpDetail) newDetails.status = "update";

    if (count === newDetails.modules.length && found === 0)
      Alert.handleNavigateToLpAfterAddTest(newDetails);
  };

  const handleGetTestDetails = (data) => {
    if (Alert.fromMenu) {
      Alert.setIsLoaded(true);
      axios
        .get(
          process.env.REACT_APP_NODE_API + "node/admin/test/getbyid/" + data.id,
          getHeaderDetailsForAxios()
        )
        .then((response) => {
          if (response.data.resultCode === constants.RESULT_STATUS.SUCCESS) {
            let data = JSON.parse(response.data.data)[0],
              count = 0,
              uniqueId = 1;

            data.isOld = true;
            data.published = 0;
            data.status = "old";
            data.testStatus = "old";
            data.libTypeId = data.libType;
            data.testDurationUnit = "min/s";
            data.testDurationNum = data.testDuration;
            data.testDuration = data.testDuration + "m";
            data.testType = { label: data.libName, value: data.libType };

            for (let i = 0; i < data.sections.length; i++) {
              count++;
              data.sections[i].type = "old";
              data.sections[i].isOld = true;
              data.sections[i].id = uniqueId;
              data.sections[i].key = nanoid();
              data.sections[i].status = "old";
              data.sections[i].testStatus = "old";
              uniqueId++;

              for (let j = 0; j < data.sections[i].secData.length; j++) {
                data.sections[i].secData[j].type = "old";
                data.sections[i].secData[j].isOld = true;
                data.sections[i].secData[j].id = uniqueId;
                data.sections[i].secData[j].key = nanoid();
                data.sections[i].secData[j].status = "old";
                data.sections[i].secData[j].testStatus = "old";

                data.sections[i].secData[j].topic = {
                  label: data.sections[i].secData[j].topicName,
                  value: data.sections[i].secData[j].topicId,
                };
                data.sections[i].secData[j].desType = {
                  label: data.sections[i].secData[j].configLibName,
                  value: data.sections[i].secData[j].configLibType,
                };
                data.sections[i].secData[j].complexity = {
                  label: data.sections[i].secData[j].complexityName,
                  value: data.sections[i].secData[j].complexityId,
                };

                if (data.videoQn) {
                  data.sections[i].secData[j].durationUnit = "min/s";

                  data.sections[i].secData[j].durationNum =
                    data.sections[i].secData[j].duration /
                    (data.sections[i].secData[j].randomQnCount +
                      data.sections[i].secData[j].fixedQnCount);
                }

                if (data.libTypeId === constants.libTypeIds.CODING_CHALLENGE) {
                  let languages = [];

                  for (
                    let k = 0;
                    k < data.sections[i].secData[j].languages.length;
                    k++
                  ) {
                    languages.push({
                      key: nanoid(),
                      value: data.sections[i].secData[j].languages[k].id,
                      label: data.sections[i].secData[j].languages[k].name,
                    });
                  }

                  data.sections[i].secData[j].languages = languages;
                  data.sections[i].secData[j].durationUnit = "min/s";

                  data.sections[i].secData[j].durationNum =
                    data.sections[i].secData[j].duration /
                    (data.sections[i].secData[j].randomQnCount +
                      data.sections[i].secData[j].fixedQnCount);
                }

                uniqueId += 8;
              }

              if (count === data.sections.length) {
                Alert.setFromMenuToEditTest(false);
                Alert.setOldTestConfig({
                  moduleId: 1,
                  oldTestData: data,
                  isOldTestConfig: true,
                });
                MockTestPageCxt.setIsAddTest(true);
                MockTestPageCxt.setIsChecked(data.aiMode === 1);
              }
            }
          } else {
            Alert.setShowNotify({
              show: true,
              title: "Error",
              msg: "Something went wrong. Please try again later",
            });
          }
        })
        .catch((error) => {
          getCatchBlockDetails(error);
        })
        .finally(() => Alert.setIsLoaded(false));
    }
  };

  return {
    topics,
    columns,
    topicData,
    setTopics,
    filteredData,
    topicOptions,
    customStyles,
    contentColumn,
    handleBackBtn,
    selectedTopics,
    getMockTestList,
    handleTopicSelect,
    handleSearchTitle,
    setSelectedTopics,
    handleAddTopicToLp,
    handleCreateTestBtn,
    handleActiveMockTest,
    handleGetTestDetails,
    selectedTopicsForValue,
    handleSelectTopicAddToLp,
    setSelectedTopicsForValue,
  };
}
