import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import _ from "lodash";
import moment from "moment";
import constants from "../../../../constants";
import useAxiosPrivate from "../../../../Hooks/useAxiosPrivate";
import { Link, useParams } from "react-router-dom";
import Whatsapp from "../../../../Svg/Whatsapp";
import Checkbox from "../../../CommonComponents/Checkbox";
import Button from "../../../Button";
import AlertContext from "../../../../context/AlertContext";
import Loading from "../../../Notification-Loading/Loading/Loading";
import Modal from "../../../CommonComponents/Modal";
import ModalBody from "../../../CommonComponents/ModalBody";
import useUploadLargeFileToS3 from "../../../../Hooks/useUploadLargeFileToS3";
import IssueForm from "../../../Student/Issue/IssueForm";
import DoubtForm from "../../../Student/AskDoubts/DoubtForm";

const IssueTable = () => {
  const { id } = useParams();
  const { setShowNotify, setIsLoaded, isLoaded } = useContext(AlertContext);
  const axios = useAxiosPrivate();
  const { getS3FileAsFile } = useUploadLargeFileToS3();
  // const des =
  //   "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum";

  const [issueData, setIssueData] = useState([]);
  const [showForm, setShowForm] = useState(false);
  const [details, setDetails] = useState({});
  const [selectedBatch, setSelectedBatch] = useState({});
  const [selectedType, setSelectedType] = useState({});
  const [isDoubt, setIsDoubt] = useState(false);

  const resolvedIds = _.chain(issueData)
    .filter((issue) => issue.checked)
    .map((issue) => issue.id)
    .value();

  useEffect(() => {
    const getIssues = () => {
      setIsLoaded(true);
      axios
        .get(`node/admin/batch/${id}/issues`, {
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          console.log("response", response.data);
          if (response.data.resultCode === constants.RESULT_STATUS.SUCCESS) {
            setIssueData(
              response.data.data?.map((issue) => ({ ...issue, checked: false }))
            );
          }
        })
        .catch((error) => {
          console.error("error", error);
          // if (axios(error)) {
          //   console.log("Save duration request canceled");
          //   return;
          // }
          if (error.message.includes("403")) {
            setShowNotify({
              show: true,
              title: "Warning !",
              msg: "You have been logged-out due to inactivity. Login again.",
              isUnAuth: true,
            });
          } else {
            setShowNotify({
              show: true,
              title: "Error !",
              msg: "Something went wrong. Please try again later or contact gradious team",
            });
          }
        })
        .finally(() => {
          setIsLoaded(false);
        });
    };
    getIssues();
  }, [axios, id, setIsLoaded, setShowNotify]);

  const onRowClick = (issueId) => {
    let issues = JSON.parse(JSON.stringify(issueData));
    issues = _.map(issues, (issue) => {
      if (issue.id === issueId) issue.checked = !issue.checked;
      return issue;
    });
    setIssueData(issues);
  };

  const onClickIssue = async (event, row) => {
    event.preventDefault();
    event.stopPropagation();
    let attachmentPath = null;
    if (row.attachment) {
      attachmentPath = await getS3FileAsFile(
        row.attachment,
        process.env.REACT_APP_S3_WEBSITE_HOST_BUCKET_NAME
      );
    }
    setDetails({
      id: row.id,
      title: row.title,
      type: row.type,
      bid: row.bid,
      description: row.description,
      attachment: attachmentPath,
      mid: row.isdoubt ? row.mid : null,
    });
    setIsDoubt(row.isdoubt);
    setSelectedBatch({
      label: row.bname,
      value: row.bname,
      id: row.bid,
    });
    setSelectedType({
      id: row.type,
      value: row.typename,
      label: row.typename,
    });
    setShowForm(true);
  };

  const onMarkAsResolved = async (event) => {
    event?.preventDefault();
    console.log("resolvedIds", resolvedIds);
    if (resolvedIds.length) {
      try {
        setIsLoaded(true);
        const response = await axios.put(
          `node/admin/batch/${id}/issues`,
          resolvedIds,
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
        if (response.data.resultCode === constants.RESULT_STATUS.SUCCESS) {
          setIssueData((pre) => _.filter(pre, (issue) => !issue.checked));
          setShowNotify({
            show: true,
            msg: "Requests are resolved successfully.",
            title: "Info",
          });
        } else {
          setShowNotify({
            show: true,
            msg:
              response.data.msg ??
              "Something went wrong. Please try again later or contact gradious team",
            title: "Error !",
          });
        }
      } catch (error) {
        if (error.message.includes("403")) {
          setShowNotify({
            show: true,
            title: "Warning !",
            msg: "You have been logged-out due to inactivity. Login again.",
            isUnAuth: true,
          });
        } else {
          setShowNotify({
            show: true,
            title: "Error !",
            msg: "Something went wrong. Please try again later or contact gradious team",
          });
        }
      } finally {
        setIsLoaded(false);
      }
    }
  };

  const onCloseForm = () => {
    setShowForm(false);
    setDetails({});
    setSelectedBatch({});
    setSelectedType({});
  };

  return (
    <React.Fragment>
      <div className="issueUtils">
        <Button
          size="sm"
          hierarchy={{ type: "secondaryGrey", buttonText: "Mark As Resolved" }}
          onClick={onMarkAsResolved}
          disable={!resolvedIds.length}
        />
      </div>
      <Modal
        isOpen={showForm}
        showCloseIcon
        onClose={onCloseForm}
        title={isDoubt ? "Doubt" : "Issue"}
        closeIcon={
          <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M15 5L5 15M5 5L15 15"
              stroke="#667085"
              strokeWidth="1.66667"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        }
      >
        <ModalBody
          content={
            isDoubt ? (
              <DoubtForm
                setShowForm={setShowForm}
                isEdit={true}
                details={details}
                setDetails={setDetails}
                selectedBatch={selectedBatch}
                setSelectedBatch={setSelectedBatch}
                selectedType={selectedType}
                setSelectedType={setSelectedType}
                initialDetails={{}}
              />
            ) : (
              <IssueForm
                setShowForm={setShowForm}
                isEdit={true}
                details={details}
                setDetails={setDetails}
                selectedBatch={selectedBatch}
                setSelectedBatch={setSelectedBatch}
                selectedType={selectedType}
                setSelectedType={setSelectedType}
                initialDetails={{}}
              />
            )
          }
        />
      </Modal>
      <TableContainer className="issueTableContainer">
        <Table className="issueTable">
          <TableHead className="issueTableHead">
            <TableRow className="issueTableHRow">
              <TableCell className="issueTableHCell issueTableHCellCheckbox">
                <Checkbox
                  size="sm"
                  checked={
                    issueData.length
                      ? resolvedIds.length === issueData.length
                      : false
                  }
                  onChange={(value) =>
                    setIssueData((pre) =>
                      _.map(pre, (issue) => ({ ...issue, checked: value }))
                    )
                  }
                  disabled={!issueData.length}
                />
              </TableCell>
              <TableCell className="issueTableHCell issueTableHCellName ">
                Name
              </TableCell>
              <TableCell className="issueTableHCell issueTableHCellEmail">
                Email
              </TableCell>
              <TableCell className="issueTableHCell issueTableHCellMobile">
                mobile
              </TableCell>
              <TableCell className="issueTableHCell issueTableHCellSummary">
                Title
              </TableCell>
              <TableCell className="issueTableHCell issueTableHCellDes">
                Description
              </TableCell>
              <TableCell className="issueTableHCell issueTableHCellCDate">
                Created date
              </TableCell>
              {/* <TableCell className="issueTableHCell issueTableHCellJiraid">
                Jiraid
              </TableCell> */}
              {/* <TableCell className={classes.headerCol}>Options</TableCell> */}
            </TableRow>
          </TableHead>
          <TableBody>
            {issueData.length > 0 ? (
              _.map(issueData, (issue, index) => (
                <TableRow
                  className="issueTableBRow"
                  key={index}
                  onClick={() => onRowClick(issue.id)}
                >
                  <TableCell
                    className="issueTableBCell issueTableBCellCheckbox"
                    title={issue.name}
                  >
                    <Checkbox
                      size="sm"
                      onChange={(value) => onRowClick(issue.id)}
                      checked={issue.checked}
                    />
                  </TableCell>
                  <TableCell
                    className="issueTableBCell issueTableBCellName"
                    title={issue.name}
                  >
                    {issue.name}
                  </TableCell>

                  <TableCell
                    className="issueTableBCell issueTableBCellEmail"
                    title={issue.email}
                  >
                    {issue.email}
                  </TableCell>
                  <TableCell
                    className="issueTableBCell issueTableBCellMobile"
                    title={issue.mobile}
                  >
                    <div className="issueTableBCellMobileSec">
                      {issue.mobile && issue.mobile !== "" ? (
                        <>
                          <Whatsapp />
                          <Link
                            target="_blank"
                            to={`https://wa.me/${
                              issue.mobile.includes("+91")
                                ? issue.mobile
                                : "+91" + issue.mobile
                            }`}
                          >
                            {issue.mobile}
                          </Link>
                        </>
                      ) : (
                        "-"
                      )}
                    </div>
                  </TableCell>
                  <TableCell
                    className="issueTableBCell issueTableBCellSummary"
                    title={issue.title}
                  >
                    {issue.title}
                  </TableCell>
                  <TableCell
                    className="issueTableBCell issueTableBCellDes"
                    title={issue.description}
                  >
                    {issue.description?.substring(0, 100)}
                  </TableCell>
                  <TableCell className="issueTableBCell issueTableBCellCDate">
                    {moment(issue.createddate).format("DD/MM/YYYY - HH:MM:SS")}
                    <Button
                      size="sm"
                      hierarchy={{ type: "secondaryGrey", buttonText: "View" }}
                      onClick={(event) => onClickIssue(event, issue)}
                    />
                  </TableCell>

                  {/* <TableCell className="issueTableBCell issueTableBCellJiraid">
                    <Link
                      target="_blank"
                      to={`https://gradious.atlassian.net/jira/servicedesk/projects/LCS/queues/custom/1/${issue.jiraid}`}
                    >
                      {issue.jiraid}
                    </Link>
                  </TableCell> */}
                </TableRow>
              ))
            ) : (
              <TableRow className="issueTableBRow">
                <TableCell className="issueTableBCellNoData" colSpan={8}>
                  No support requests
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      {isLoaded && <Loading />}
    </React.Fragment>
  );
};

export default IssueTable;
