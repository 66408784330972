import React from "react";
import constants from "../../constants";
import CodingHeaderLearnerSideHooks from "../../Hooks/CodingPage/CodingHeaderLearnerSideHooks";
import HalfDonutChart from "../HalfDonutChart";

export default function CodingPageSuccessNotify(Props) {
  const testHeader = CodingHeaderLearnerSideHooks();

  return !Props.timedOut ? (
    <div>
      {Props.isChart && 
      <div>
        <HalfDonutChart achievedPercentage={Props.achievedpercent} targetPercentage={Props.targetPercent}/>
        <div className="after-submit-container">
          <div className="after-submit-header">Congratulations!!</div>
          <div className="after-submit-msg">You Scored {Props.achievedpercent}%</div>
          {Props.explanation && <div className="after-submit-msg">Explanation: {Props.explanation}</div>}
        </div>
      </div>}
      <div>
        <ul className="modalInstructionBodyunorderedListWithSuccess">
          <li className="modalInstructionBodyTextList">
            {testHeader.getSuccessMsg()}
          </li>
          <li className="modalInstructionBodyTextList">
            Your code has been submitted
            {Props.codeDetails.istestcases === constants.ISNOTESTCASES.FALSE
              ? " and evaluated."
              : " successfully."}
          </li>
          {Props.codeDetails.istestcases === constants.ISNOTESTCASES.FALSE && (
            <li className="modalInstructionBodyTextList">
              All the test cases have passed.
            </li>
          )}
        </ul>
      </div>
    </div>
  ) : (
    <div>
      {Props.isChart && 
      <div>
        <HalfDonutChart achievedPercentage={Props.achievedpercent} targetPercentage={Props.targetPercent}/>
        <div>
          <div className="after-submit-header">Congratulations!!</div>
          <div className="after-submit-msg">You Scored {Props.achievedpercent}%</div>
        </div>
      </div>
      }
      <div>
        <ul className="modalInstructionBodyunorderedListWithSuccess">
          <li className="modalInstructionBodyTextList">
            {testHeader.getSuccessMsg()}
          </li>
          <li className="modalInstructionBodyTextList">
            Your code has been submitted.
          </li>
        </ul>
      </div>
    </div>
  );
}
