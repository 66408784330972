import _ from "lodash";
import dayjs from "dayjs";
import Button from "../../../Button";
import { TextField } from "@mui/material";
import batchTypes from "./BatchTypes.json";
import BatchPathManager from "./BatchPaths";
import { useParams } from "react-router-dom";
import constants from "../../../../constants";
import Tag from "../../../CommonComponents/Tag";
import { getDataFromStorage } from "../../../../util";
import SettingsRightHeader from "./SettingsRightHeader";
import { DesktopDatePicker } from "@mui/x-date-pickers";
import Checkbox from "../../../CommonComponents/Checkbox";
import AlertContext from "../../../../context/AlertContext";
import ButtonGroup from "../../../CommonComponents/ButtonGroup";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import useAxiosPrivate from "../../../../Hooks/useAxiosPrivate";
import ToggleButton from "../../../CommonComponents/ToggleButton";
import ProfileUpload from "../../../Student/Profile/ProfileUpload";
import React, { useContext, useEffect, useRef, useState } from "react";
import useCommonFunctionHooks from "../../../../Hooks/useCommonFunctionHooks";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

const BatchDetails = () => {
  const Alert = useContext(AlertContext);

  const { id } = useParams();
  const axios = useAxiosPrivate();

  const { getHeaderDetailsForAxios, getCatchBlockDetails } =
    useCommonFunctionHooks();

  const [paths, setPaths] = useState([]);
  const [isChangePathName, setIsChangePathName] = useState(false);
  const [thumbnail, setThumbnail] = useState(Alert.batchDetails.thumbnail);

  const [newPath, setNewPath] = useState({
    lpId: null,
    lpName: "",
    courseName: "",
  });

  const [pathNames, setPathNames] = useState(() =>
    _.keyBy(Alert.batchDetails.learningpath, "lpid")
  );

  const [focusStates, setFocusStates] = useState(() =>
    ["name", "description", "stardate", "slackid", "roletitle"].reduce(
      (acc, key) => ({ ...acc, [key]: false }),
      {}
    )
  );

  const settings = useRef({
    ...Alert.batchDetails,
  });

  useEffect(() => {
    settings.current = JSON.parse(JSON.stringify(Alert.batchDetails));
  }, [Alert.batchDetails]);

  useEffect(() => {
    setPathNames(_.keyBy(Alert.batchDetails.learningpath, "lpid"));
  }, [Alert.batchDetails.learningpath]);

  useEffect(() => {
    const fetchPaths = async () => {
      try {
        Alert.setIsLoaded(true);

        let response = await axios.get(
          "node/admin/learningpath/get",
          getHeaderDetailsForAxios()
        );

        if (response.data.resultCode === constants.RESULT_STATUS.SUCCESS) {
          if (response.data.data !== null) {
            const res = _.filter(response.data.data, { archived: 0 });

            const newPaths = _.differenceBy(
              res,
              Alert.batchDetails.learningpath.map((lp) => ({ id: lp.lpid })),
              "id"
            );

            setPaths(
              _.map(newPaths, (path) => {
                return { label: path.name, value: path.id };
              })
            );
          } else {
            setPaths([]);
          }
        } else {
          Alert.setShowNotify({
            show: true,
            title: "Error !",
            msg: "Something went wrong. Please try again later or contact gradious team",
          });

          return;
        }
      } catch (error) {
        getCatchBlockDetails(error);
      } finally {
        Alert.setIsLoaded(false);
      }
    };

    fetchPaths();
  }, [
    id,
    axios,
    Alert.setIsLoaded,
    Alert.setShowNotify,
    Alert.batchDetails.learningpath,
  ]);

  useEffect(() => {
    const updateThumbnail = async () => {
      try {
        Alert.setIsLoaded(true);

        let response = await axios.post(
          "node/admin/batch/updatethumbnail",
          {
            batchid: id,
            newimage: thumbnail,
          },
          getHeaderDetailsForAxios()
        );

        if (response.data.resultCode === constants.RESULT_STATUS.SUCCESS) {
          Alert.setBatchDetails((prev) => ({ ...prev, thumbnail: thumbnail }));
        } else {
          Alert.setShowNotify({
            show: true,
            title: "Error !",
            msg: "Something went wrong. Please try again later or contact gradious team",
          });
        }
      } catch (error) {
        getCatchBlockDetails(error);
      } finally {
        Alert.setIsLoaded(false);
      }
    };

    if (thumbnail) updateThumbnail();
  }, [
    id,
    axios,
    thumbnail,
    Alert.setIsLoaded,
    Alert.setShowNotify,
    Alert.setBatchDetails,
  ]);

  const handleResponse = (title, msg) => {
    Alert.setShowNotify({
      msg,
      title,
      show: true,
    });
  };

  const updateSettings = async (field, value) => {
    Alert.setBatchDetails((pre) => ({ ...pre, [field]: value }));
    settings.current = { ...settings.current, [field]: value };

    if (field === "archived" || field === "batchtype" || field === "useorder")
      await handleSave();
  };

  const onChangeOldPathName = (value, lpId) => {
    setIsChangePathName(true);
    setPathNames((prev) => ({
      ...prev,
      [lpId]: { ...prev[lpId], coursename: value },
    }));
  };

  const updateOldPathName = async (event, lpId) => {
    event.preventDefault();

    try {
      Alert.setIsLoaded(true);

      let payload = {
        lpId,
        batchId: id,
        courseName: pathNames[lpId].coursename,
      };

      let response = await axios.post(
        "node/admin/batch/coursename/create",
        JSON.stringify(payload),
        getHeaderDetailsForAxios()
      );

      if (response.data.resultCode === constants.RESULT_STATUS.SUCCESS) {
        Alert.setShowNotify({
          show: true,
          title: "Info",
          msg: "Path name renamed successfully.",
        });

        let learningpaths = JSON.parse(
          JSON.stringify(Alert.batchDetails.learningpath)
        );

        learningpaths = _.map(learningpaths, (path) => {
          if (path.lpid === lpId) {
            path.coursename = payload.courseName;
            path.lpname = payload.courseName;
          }

          return path;
        });

        Alert.setBatchDetails((prev) => ({
          ...prev,
          learningpath: learningpaths,
        }));
        setIsChangePathName(false);
      } else if (
        response.data.resultCode === constants.RESULT_STATUS.TECHNICAL_ERROR &&
        response.data.msg.includes("Duplicate entry")
      ) {
        Alert.setShowNotify({
          show: true,
          title: "Warning !",
          msg: "This path name is already exists. Please give a different name.",
        });
      } else {
        Alert.setShowNotify({
          show: true,
          title: "Error !",
          msg: "Something went wrong. Please try again later or contact gradious team",
        });
      }
    } catch (error) {
      getCatchBlockDetails(error);
    } finally {
      Alert.setIsLoaded(false);
    }
  };

  const addNewPath = async (event) => {
    event.preventDefault();

    try {
      Alert.setIsLoaded(true);

      let payload = {
        ...newPath,
        batchId: id,
        batchName: Alert.batchDetails.name,
        batchType: Alert.batchDetails.batchtype,
      };

      if (newPath && newPath.lpId) {
        if (!newPath.courseName || newPath.courseName === "")
          payload.courseName = payload.lpName;

        let response = await axios.post(
          "node/admin/batch/learningpath/create",
          JSON.stringify(payload),
          getHeaderDetailsForAxios()
        );

        if (response.data.resultCode === constants.RESULT_STATUS.SUCCESS) {
          Alert.setShowNotify({
            show: true,
            title: "Info",
            msg: `A new path has been assigned to the ${Alert.batchDetails.name} batch. Please check course page for more.`,
          });

          let learningpaths = JSON.parse(
            JSON.stringify(Alert.batchDetails.learningpath)
          );

          learningpaths.push({
            lpname: payload.courseName,
            lpid: response.data.data.lpId,
            coursename: payload.courseName,
          });

          Alert.setBatchDetails((prev) => ({
            ...prev,
            learningpath: learningpaths,
          }));

          setNewPath({ courseName: "", lpId: null, lpName: "" });
        } else if (
          response.data.resultCode ===
            constants.RESULT_STATUS.TECHNICAL_ERROR &&
          response.data.msg.includes("Duplicate entry")
        ) {
          Alert.setShowNotify({
            show: true,
            title: "Warning !",
            msg: "This path name is already exists. Please give a different name.",
          });
        } else {
          Alert.setShowNotify({
            show: true,
            title: "Error !",
            msg: "Something went wrong. Please try again later or contact gradious team",
          });
        }
      } else
        Alert.setShowNotify({
          show: true,
          title: "Warning !",
          msg: "Please select a path to assign",
        });
    } catch (error) {
      getCatchBlockDetails(error);
    } finally {
      Alert.setIsLoaded(false);
    }
  };

  const handleSave = async (isNavigate) => {
    try {
      Alert.setIsLoaded(true);

      let payload = {
        tags: Alert.selectedTag,
        name: settings.current.name,
        type: settings.current.batchtype,
        slackid: settings.current.slackid,
        useorder: settings.current.useorder,
        archived: settings.current.archived,
        startdate: settings.current.startdate,
        description: settings.current.description,
        roletitle: settings.current.roletitle ?? "",
      };

      let response = await axios.put(
        `node/admin/batch/settings/${id}`,
        payload,
        getHeaderDetailsForAxios()
      );

      if (response.data.resultCode !== constants.RESULT_STATUS.SUCCESS) {
        Alert.setShowNotify({
          show: true,
          title: "Error !",
          msg: "An error occured while update batch settings.",
        });

        return;
      }

      if (
        response.data.resultCode === constants.RESULT_STATUS.SUCCESS &&
        isNavigate
      ) {
        Alert.navigate(`/batches/${id}/settings/skillmap`);
      }
    } catch (error) {
      getCatchBlockDetails(error);
    } finally {
      Alert.setIsLoaded(false);
    }
  };

  return (
    <div className="batchDetailsContainer">
      <SettingsRightHeader
        title="Batch Details"
        titleIcon={
          <svg
            width="24"
            height="24"
            fill="none"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              stroke="#344054"
              strokeWidth="1.6"
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M12 16V12M12 8H12.01M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12Z"
            />
          </svg>
        }
        button={
          <ToggleButton
            size="sm"
            text="Archive Batch"
            pressed={Alert.batchDetails?.archived}
            onChange={(value) => updateSettings("archived", value)}
          />
        }
      />
      <div className="batchDetailsBodySec">
        <div className="batchDetailsFormSec">
          <form className="batchDetailsForm">
            <div className="bdTopSec">
              <div
                className="bdTopLeftSec"
                style={
                  parseInt(getDataFromStorage("accountType")) ===
                  constants.ACCOUNT_TYPE.CLIENT_EVAL
                    ? { width: "80%" }
                    : {}
                }
              >
                <div className="bdNameAndTypeSec">
                  <TextField
                    name="name"
                    size="small"
                    id="bdBatchName"
                    label="Batch Name"
                    variant="outlined"
                    value={Alert.batchDetails?.name}
                    InputLabelProps={{
                      shrink:
                        focusStates["name"] ||
                        Boolean(Alert.batchDetails?.name),
                    }}
                    InputProps={{
                      maxLength: 100,
                      onFocus: (event) =>
                        setFocusStates({
                          ...focusStates,
                          [event.target.name]: true,
                        }),
                      onBlur: (event) =>
                        setFocusStates({
                          ...focusStates,
                          [event.target.name]: false,
                        }),
                    }}
                    onChange={(event) =>
                      Alert.setBatchDetails((prev) => ({
                        ...prev,
                        [event.target.name]: event.target.value,
                      }))
                    }
                  />
                  {parseInt(getDataFromStorage("accountType")) ===
                    constants.ACCOUNT_TYPE.LMS &&
                    getDataFromStorage("role") === constants.Roles.admin && (
                      <div className="bdTypeSec">
                        <label className="bdTypeLabel">Type</label>
                        <ButtonGroup
                          buttons={batchTypes}
                          value={parseInt(Alert.batchDetails?.batchtype)}
                          onClick={(value) =>
                            updateSettings("batchtype", parseInt(value))
                          }
                        />
                      </div>
                    )}
                </div>
                <div className="bdDescriptionSec">
                  <TextField
                    fullWidth
                    size="small"
                    multiline={true}
                    name="description"
                    id="bdDescription"
                    variant="outlined"
                    label="Description"
                    className="bdDescription"
                    placeholder="Description of batch"
                    value={Alert.batchDetails?.description}
                    InputLabelProps={{
                      shrink:
                        focusStates["description"] ||
                        Boolean(Alert.batchDetails?.description),
                    }}
                    InputProps={{
                      maxLength: 200,
                      onFocus: (event) =>
                        setFocusStates({
                          ...focusStates,
                          [event.target.name]: true,
                        }),
                      onBlur: (event) =>
                        setFocusStates({
                          ...focusStates,
                          [event.target.name]: false,
                        }),
                    }}
                    onChange={(event) =>
                      Alert.setBatchDetails((prev) => ({
                        ...prev,
                        [event.target.name]: event.target.value,
                      }))
                    }
                  />
                </div>
              </div>
              <div
                className="bdTopRightSec"
                style={
                  parseInt(getDataFromStorage("accountType")) ===
                  constants.ACCOUNT_TYPE.CLIENT_EVAL
                    ? { width: "20%" }
                    : {}
                }
              >
                <ProfileUpload
                  size={"480 * 270"}
                  profilePic={thumbnail}
                  setProfilePic={setThumbnail}
                  handleResponse={handleResponse}
                  buttonText="Upload a new Thumbnail"
                />
              </div>
            </div>
            {getDataFromStorage("role") === "admin" && (
              <div className="bdMandateOrderSec">
                <Checkbox
                  size="sm"
                  text="Mandate Order"
                  checked={Alert.batchDetails?.useorder}
                  onChange={(value) => updateSettings("useorder", value)}
                />
              </div>
            )}
            {getDataFromStorage("role") === "admin" && (
              <div className="bdMandateOrderSec">
                <Tag />
              </div>
            )}
            <div className="bdStartDateSec">
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DesktopDatePicker
                  id="bdStartDate"
                  name="startdate"
                  label="Start Date"
                  className="bdStartDate"
                  minDate={dayjs("1990-01-01")}
                  maxDate={dayjs("9999-12-31")}
                  value={
                    Alert.batchDetails?.startdate
                      ? dayjs(Alert.batchDetails.startdate)
                      : null
                  }
                  onChange={(event) =>
                    Alert.setBatchDetails((prev) => ({
                      ...prev,
                      [event.target.name]: event.target.value,
                    }))
                  }
                />
              </LocalizationProvider>
            </div>
            <div className="bdSlackNameSec">
              <TextField
                size="small"
                name="slackid"
                label="Slack ID"
                id="bdSlackName"
                variant="outlined"
                className="bdSlackName"
                placeholder="Enter Slack ID"
                value={Alert.batchDetails?.slackid}
                InputLabelProps={{
                  shrink:
                    focusStates["slackid"] ||
                    Boolean(Alert.batchDetails?.slackid),
                }}
                InputProps={{
                  maxLength: 50,
                  onFocus: (event) =>
                    setFocusStates({
                      ...focusStates,
                      [event.target.name]: true,
                    }),
                  onBlur: (event) =>
                    setFocusStates({
                      ...focusStates,
                      [event.target.name]: false,
                    }),
                }}
                onChange={(event) =>
                  updateSettings("slackid", event.target.value)
                }
              />
            </div>
            {parseInt(getDataFromStorage("accountType")) ===
              constants.ACCOUNT_TYPE.LMS && (
              <div className="bdRoleTitleSec">
                <TextField
                  size="small"
                  id="bdRoleTitle"
                  name="roletitle"
                  label="Role Title"
                  variant="outlined"
                  className="bdRoleTitle"
                  placeholder="Enter Role Title"
                  value={Alert.batchDetails?.roletitle}
                  InputLabelProps={{
                    shrink:
                      focusStates["roletitle"] ||
                      Boolean(Alert.batchDetails?.roletitle),
                  }}
                  InputProps={{
                    maxLength: 50,
                    onFocus: (event) =>
                      setFocusStates({
                        ...focusStates,
                        [event.target.name]: true,
                      }),
                    onBlur: (event) =>
                      setFocusStates({
                        ...focusStates,
                        [event.target.name]: false,
                      }),
                  }}
                  onChange={(event) =>
                    updateSettings("roletitle", event.target.value)
                  }
                />
              </div>
            )}
            <BatchPathManager
              paths={paths}
              newPath={newPath}
              pathNames={pathNames}
              setNewPath={setNewPath}
              addNewPath={addNewPath}
              batchDetails={Alert.batchDetails}
              isChangePathName={isChangePathName}
              updateOldPathName={updateOldPathName}
              onChangeOldPathName={onChangeOldPathName}
              setIsChangePathName={setIsChangePathName}
            />
          </form>
        </div>
        <div className="batchDetailsBtnSec">
          <Button
            size="sm"
            onClick={() => handleSave(true)}
            hierarchy={{ type: "primary", buttonText: "Save" }}
          />
        </div>
      </div>
    </div>
  );
};

export default BatchDetails;
