import React, { useContext } from "react";
import AlertContext from "../../../../context/AlertContext";

export default function Logo(Props) {
  const { navigate } = useContext(AlertContext);

  return Props.component === "interview" ? (
    <svg
      width="30"
      height="29"
      fill="none"
      viewBox="0 0 30 29"
      className={Props.className}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_dd_9310_27025)">
        <path
          fill="#F96343"
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M3.76794 8.03502C3.76794 12.484 3.80306 13.759 3.94671 14.5154C4.58646 17.8849 6.45889 20.6843 9.29314 22.5084C10.2187 23.1041 10.8765 23.4004 13.3713 24.3454L14.994 24.9601L16.9183 24.23C17.9765 23.8283 19.2947 23.267 19.8474 22.9825C23.0819 21.3178 25.3489 18.2246 26.0532 14.5154C26.1968 13.759 26.2319 12.484 26.2319 8.03502V2.49609H14.9999H3.76794V8.03502ZM19.7045 8.04801C19.744 10.7027 19.6261 11.604 19.1139 12.5642C17.5999 15.4023 13.7548 15.9054 11.5981 13.5477C10.9035 12.7884 10.5715 12.1251 10.3926 11.1394C9.96588 8.78831 11.7006 6.28463 14.086 5.80893C14.361 5.75401 15.73 5.71715 17.1281 5.72684L19.6701 5.74456L19.7045 8.04801ZM14.3714 8.56965C13.5232 8.87914 13.0858 9.49942 13.0719 10.4122C13.0553 11.5194 13.8483 12.3009 14.9879 12.3001C16.0297 12.2994 16.6709 11.7951 16.8989 10.7972C17.2297 9.34858 15.7677 8.06018 14.3714 8.56965ZM19.6611 16.4204C19.7818 16.6156 19.3814 17.6753 19.0234 18.1076C18.8265 18.3454 18.4358 18.6511 18.155 18.7868C17.6708 19.0211 17.4557 19.0337 13.9606 19.0337H10.2766L10.3504 18.6498C10.4733 18.0096 10.5957 17.7678 11.0533 17.2618C11.5336 16.7305 11.8023 16.5561 12.3397 16.4266C12.8525 16.3029 19.5848 16.2972 19.6611 16.4204Z"
        />
      </g>
      <defs>
        <filter
          x="0.767944"
          y="0.496094"
          width="28.464"
          height="28.4648"
          id="filter0_dd_9310_27025"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            type="matrix"
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          />
          <feOffset dy="1" />
          <feGaussianBlur stdDeviation="1" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.0627451 0 0 0 0 0.0941176 0 0 0 0 0.156863 0 0 0 0.06 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_9310_27025"
          />
          <feColorMatrix
            type="matrix"
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          />
          <feOffset dy="1" />
          <feGaussianBlur stdDeviation="1.5" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.0627451 0 0 0 0 0.0941176 0 0 0 0 0.156863 0 0 0 0.1 0"
          />
          <feBlend
            mode="normal"
            in2="effect1_dropShadow_9310_27025"
            result="effect2_dropShadow_9310_27025"
          />
          <feBlend
            mode="normal"
            result="shape"
            in="SourceGraphic"
            in2="effect2_dropShadow_9310_27025"
          />
        </filter>
      </defs>
    </svg>
  ) : (
    <svg
      fill="none"
      width="4.9vh"
      height="4.9vh"
      viewBox="0 0 35 35"
      xmlns="http://www.w3.org/2000/svg"
      onClick={() => {
        navigate("/batches");
        Props.setIsBatchDetails(false);
        Props.setBatchDetails([]);
      }}
    >
      <path
        fill="#F55533"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M35 0H0V15.4412C0 23.1899 4.78063 30.1364 12.0191 32.905L17.5 35L22.9809 32.905C30.2194 30.1364 35 23.1899 35 15.4412V0ZM24.7059 12.3529C24.7059 16.3319 21.4797 19.5588 17.5 19.5588C13.5203 19.5588 10.2941 16.3319 10.2941 12.3529C10.2941 8.37402 13.5203 5.14706 17.5 5.14706H24.7059V12.3529ZM17.5 15.4412C19.2056 15.4412 20.5882 14.0579 20.5882 12.3529C20.5882 10.648 19.2056 9.26471 17.5 9.26471C15.7944 9.26471 14.4118 10.648 14.4118 12.3529C14.4118 14.0579 15.7944 15.4412 17.5 15.4412ZM10.2941 25.7353C10.2941 23.4613 12.1377 21.6176 14.4118 21.6176H24.7059C24.7059 23.8916 22.8623 25.7353 20.5882 25.7353H10.2941Z"
      />
    </svg>
  );
}
