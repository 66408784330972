import { nanoid } from "nanoid";
import { useContext } from "react";
import constants from "../../constants";
import { getDataFromStorage } from "../../util";
import useAxiosPrivate from "../useAxiosPrivate";
import AlertContext from "../../context/AlertContext";
import useCommonFunctionHooks from "../useCommonFunctionHooks";

export default function CodingViewHooks() {
  const Alert = useContext(AlertContext);

  const axios = useAxiosPrivate();

  const { getCatchBlockDetails, getHeaderDetailsForAxios } =
    useCommonFunctionHooks();

  const getViewPayload = (test, type, question) => {
    let payload = {
      learnerid: getDataFromStorage("learnerid"),
    };
    if (test.single) {
      payload.lccid = test.lccid;
      payload.questionid = test.questionid;
    } else {
      payload.lpid = Alert.lpId;
      payload.lmtid = Alert.testData.lmtid;
      payload.lmpid = Alert.testData.lmpid;
      payload.testid = Alert.testData.testid;
      payload.mockuse = Alert.testData.mockuse;
      payload.testqnno =
        type === "single" ? question.qnno : test.qndata[0].qnno;
      payload.questionid =
        type === "single" ? question.qnid : test.qndata[0].qnid;
    }

    return JSON.stringify(payload);
  };

  const viewCode = (test, type, question) => {
    Alert.setIsTakeTest(false);
    Alert.setIsLoaded(true);

    for (let i = 0; i < test.qndata.length; i++) {
      if (
        test.qndata[i].status === constants.QUESTION_STATUS.SUBMITTED ||
        test.qndata[i].status === constants.QUESTION_STATUS.TO_BE_EVALUATED
      ) {
        axios
          .post(
            "node/learner/report/question",
            getViewPayload(test, type, question),
            getHeaderDetailsForAxios()
          )
          .then((res) => {
            Alert.setIsLoaded(false);

            if (res.data.resultCode !== constants.RESULT_STATUS.SUCCESS) {
              Alert.setShowNotify({
                show: true,
                title: "Info",
                msg: res.data.msg,
              });
            } else {
              let totalCount = 0,
                response = res.data.data,
                testCases =
                  response.testcases !== null
                    ? JSON.parse(response.testcases)
                    : [];

              testCases = testCases.filter(
                (example) => example.isexample === 1
              );

              Alert.setStatus(
                type === "single" ? question.status : test.qndata[0].status
              );

              for (let i = 0; i < testCases.length; i++) {
                if (i === 0) testCases[i].isActive = true;
                testCases[i].id = nanoid();
              }

              response.secData = module;
              response.lpId = Alert.lpId;
              response.testcases = JSON.stringify(testCases);
              response.secData.proctored = response.proctored;
              response.status =
                type === "single" ? question.status : test.qndata[0].status;

              if (!test.hasOwnProperty("lccid")) {
                for (let i = 0; i < Alert.testData.data.topics.length; i++) {
                  totalCount =
                    totalCount + Alert.testData.data.topics[i].totalcount;
                }

                Alert.setShowBreadCrumbsData({
                  topicName: test.topicname,
                  type: constants.libTypeIds.CODING_CHALLENGE,
                  questionNo:
                    type === "single" ? question.qnno : test.qndata[0].qnno,
                });

                response.topics = test;
                response.type = Alert.testData.testtype;
                response.secData.totalCount = totalCount;
                response.viewTest = Alert.testData.viewtest;
                response.testname = Alert.testData.testname;
                response.lpConfigId = Alert.testData.lpconfigid;
                response.secData.testId = Alert.testData.testid;
                response.secData.testMode = Alert.testData.testmode;
                response.breadCrumbsData = Alert.testData.data.topics;
                response.secData.completedcount = test.completedcount;
              }

              Alert.assignQuestionToLearner(response);
            }
          })
          .catch((error) => {
            getCatchBlockDetails(error);
          })
          .finally(() => Alert.setIsLoaded(false));
        break;
      }
    }
  };

  return { viewCode };
}
