import dayjs from "dayjs";
import Button from "../Button";
import LeetCode from "./LeetCode";
import Trainers from "./Trainers";
import Card from "@mui/material/Card";
import Timelinegraph from "./Timeline";
import Progressbar from "./Progressbar";
import Leaderboard from "./Leaderboard";
import InfoSvg from "../../Svg/InfoSvg";
import constants from "../../constants";
import Avatar from "@mui/material/Avatar";
import AssignmentDue from "./AssignmentDue";
import BatchDropDown from "./BatchDropDown";
import Divider from "@mui/material/Divider";
import UpcomingEvents from "./UpcomingEvents";
import { styled } from "@mui/material/styles";
import Collapse from "@mui/material/Collapse";
import TableWithTotal from "./TableWithTotal";
import { getDataFromStorage } from "../../util";
import CardHeader from "@mui/material/CardHeader";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import CardContent from "@mui/material/CardContent";
import AlertContext from "../../context/AlertContext";
import { Outlet, useLocation } from "react-router-dom";
import useAxiosPrivate from "../../Hooks/useAxiosPrivate";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import React, { useContext, useEffect, useState } from "react";
import useCommonFunctionHooks from "../../Hooks/useCommonFunctionHooks";

const ExpandMore = styled((props) => {
  const { expand, ...other } = props;

  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: expand ? "rotate(180deg)" : "rotate(0deg)",
  marginLeft: "auto",
  transition: theme.transitions.create("transform", {
    duration: theme.transitions.duration.shortest,
  }),
}));

export default function Dashboard() {
  const Alert = useContext(AlertContext);

  const axios = useAxiosPrivate();

  const { getCatchBlockDetails, getHeaderDetailsForAxios } =
    useCommonFunctionHooks();

  const { pathname } = useLocation();

  const [option, setOption] = useState(1);
  const [expanded, setExpanded] = useState([]);
  const [subExpanded, setSubExpanded] = useState([]);
  const [pinnedData, setPinnedData] = useState([]);
  const [showHeaderChapter, setShowHeaderChapter] = useState(true);
  const [showSubHeaderChapter, setShowSubheaderChapter] = useState(true);

  useEffect(() => {
    if (Alert.dashBoardDetails && Alert.dashBoardDetails.lpData) {
      setShowHeaderChapter(
        Array(Alert.dashBoardDetails.lpData.length).fill(true)
      );
    }

    if (Alert.dashBoardDetails && Alert.dashBoardDetails.lpData) {
      setExpanded(Array(Alert.dashBoardDetails.lpData.length).fill(false));
    }
  }, [Alert.dashBoardDetails]);

  useEffect(() => {
    const pinnedData = Alert.dashBoardDetails.taskDueLists?.find(
      (data) => data.pinnedDate === 1
    );
    setPinnedData(pinnedData);
  }, [Alert.dashBoardDetails]);

  const handleExpandClick = (index) => {
    if (Alert.dashBoardDetails && Alert.dashBoardDetails.lpData) {
      setSubExpanded(
        Array(Alert.dashBoardDetails.lpData[index].lpContent.length).fill(false)
      );
      setShowSubheaderChapter(
        Array(Alert.dashBoardDetails.lpData[index].lpContent.length).fill(true)
      );
    }

    setShowHeaderChapter((prevState) => {
      const newState = [...prevState];

      newState[index] = !prevState[index];

      return newState;
    });

    setExpanded((prevState) => {
      const newState = [...prevState];

      newState[index] = !prevState[index];

      return newState;
    });
  };

  const handleSubExpandClick = (index) => {
    setShowSubheaderChapter((prevState) => {
      const newState = [...prevState];

      newState[index] = !prevState[index];

      return newState;
    });

    setSubExpanded((prevState) => {
      const newState = [...prevState];

      newState[index] = !prevState[index];

      return newState;
    });
  };

  /* navigate to home page */

  const naviageHomeFunction = (lpId) => {
    Alert.setLpId(lpId);
    localStorage.removeItem("mockLp");
    sessionStorage.removeItem("lmpid");
    sessionStorage.setItem("lpId", lpId);
    Alert.navigate("home");
  };

  const completedPercenteageFuncton = (data) => {
    let finalPercentage;

    if (data.chapterCount !== 0) {
      finalPercentage = (data.completedCount / data.chapterCount) * 100;
    } else {
      finalPercentage = 0;
    }

    return parseFloat(finalPercentage.toFixed());
  };

  const handleLeaderboardDetail = (from) => {
    axios
      .get(
        `node/learner/${getDataFromStorage(
          "learnerid"
        )}/batch/${localStorage.getItem("batchid")}/testpoints`,
        getHeaderDetailsForAxios()
      )
      .then((res) => {
        if (res.data.resultCode === constants.RESULT_STATUS.TECHNICAL_ERROR) {
          Alert.setShowNotify({
            show: true,
            title: "Info",
            msg: res.data.msg,
          });
        } else {
          if (res.data.resultCode === constants.RESULT_STATUS.NO_DATA) {
            Alert.setShowNotify({
              show: true,
              title: "Info",
              msg: "No data to display",
            });
          } else if (typeof from != "object" && from?.includes("options")) {
            Alert.setLeaderBoardTestDetail(res.data.data);

            if (from === "optionsscore") {
              setOption(2);
            } else {
              setOption(3);
            }
          } else {
            Alert.setShowNotify({
              show: true,
              size: "xl",
              showClose: true,
              hideButton: true,
              component: "leaderBoard",
              msg: <TableWithTotal option={0} from="popup" />,
            });
          }
        }
      })
      .catch((error) => {
        getCatchBlockDetails(error);
      })
      .finally(() => Alert.setIsLoaded(false));
  };

  const formattedTime = (dueDate) => {
    const formattedTime = dayjs(dueDate).format("h:mm a");
    return formattedTime;
  };

  return (
    <div className="dashBoardMainContainer">
      <div className="Dashboard-div">
        {getDataFromStorage("role") == constants.Roles.parent && (
          <div className="parentGreetContainer">
            <div className="parentGreet">
              <span className="greet">{`Hello, ${getDataFromStorage(
                "name"
              )}’s parent!`}</span>
              <span className="instruct">
                You can track your child’s progress here...
              </span>
            </div>
          </div>
        )}
        {parseInt(localStorage.getItem("batchType")) !==
          constants.BATCH_TYPE.TESTING && (
          <div className="Dashboard-div-batch-change">
            <BatchDropDown
              option={option}
              setOption={setOption}
              handleLeaderboardDetail={handleLeaderboardDetail}
              batchdetails={Alert.dashBoardDetails.batchDetails}
            />
          </div>
        )}
        <Outlet />
        {pathname === "/main/dashboard" && (
          <div className="dashboard-content-div">
            {pinnedData && (
              <div className="dashboard_pinned_all_container">
                <div className="dashboard_pinned_container">
                  <div className="pinned_left_container">
                    <div className="pinned_name_assigned">
                      <span className="pinned_test_name">
                        {pinnedData.name}
                      </span>
                      <span className="pinned_label">Assigned Today</span>
                    </div>
                    <div className="pinned_info_aduedate">
                      <span className="pinned_infosvg">
                        <InfoSvg pinned={true} />
                      </span>
                      <span className="pinned_duetime">{`To be completed today by ${formattedTime(
                        pinnedData.dueDate
                      )}`}</span>
                    </div>
                  </div>
                  <div className="pinned_right_container">
                    <Button
                      size="sm"
                      hierarchy={{
                        type: "primary",
                        buttonText: "Complete",
                      }}
                      onClick={() => {
                        Alert.setTestData({
                          testId: pinnedData.id,
                          pathName: "dashBoard",
                          moduleId: pinnedData.moduleId,
                        });
                        naviageHomeFunction(pinnedData.lpId);
                      }}
                    />
                  </div>
                </div>{" "}
              </div>
            )}
            {Alert.dashBoardDetails.lpData?.map((data, index) => (
              <div className="dashboard-batchdetail-div">
                <Card
                  className={`dashboard-batchdetail-card ${
                    expanded ? "dashboard-batchdetail-card-expanded" : ""
                  }`}
                >
                  <CardHeader
                    className="dashboard-batchdetail-card-header"
                    avatar={
                      <Avatar
                        alt={"Icon"}
                        src={data.lpIcon}
                        style={{ width: "28px", height: "30px", padding: 0 }}
                      />
                    }
                    action={
                      <>
                        {data.chapterCount > 0 &&
                          getDataFromStorage("role") != "parent" && (
                            <Button
                              size="sm"
                              hierarchy={{
                                buttonText: "Resume",
                                type: pinnedData ? "secondaryGrey" : "primary",
                              }}
                              onClick={() => {
                                naviageHomeFunction(data.lpId);
                                localStorage.setItem("lpname", data.lpName);
                              }}
                            />
                          )}

                        <ExpandMore
                          aria-label="show more"
                          expand={expanded[index]}
                          aria-expanded={expanded[index]}
                          onClick={() => handleExpandClick(index)}
                          className="dashboard-batchdetail-card-header-expand-button"
                        >
                          <ExpandMoreIcon />
                        </ExpandMore>
                      </>
                    }
                    title={data.lpName}
                    titleTypographyProps={{
                      style: {
                        fontSize: "24px",
                        color: "#344054",
                        fontWeight: "600",
                        fontFamily: "Inter",
                      },
                    }}
                  />
                  {showHeaderChapter[index] && (
                    <CardContent className="dashboard-batchdetail-card-content">
                      <Typography className="dashboard-batchdetail-card-content-batchdetail">
                        <Typography
                          component="div"
                          className="dashboard-batchdetail-card-content-coursedetail"
                        >
                          {data.lpContent.length > 0
                            ? `${data.lpContent.length} modules . ${
                                data.lpContent.length > 0
                                  ? data.lpContent.reduce((total, item) => {
                                      return total + item.subtopics.length;
                                    }, 0)
                                  : 0
                              } chapters`
                            : `${data.moduleCount} modules . ${data.chapterCount} chapters`}
                        </Typography>
                        <Typography
                          component="div"
                          className="dashboard-batchdetail-card-content-percentage"
                        >
                          {completedPercenteageFuncton(data) + "%"}
                        </Typography>
                      </Typography>
                      <Progressbar
                        bgColor="#EAECF0"
                        barColor="#F55533"
                        percentage={completedPercenteageFuncton(data)}
                      />
                    </CardContent>
                  )}

                  <Collapse in={expanded[index]} timeout="auto" unmountOnExit>
                    <div className="dashboard-batchdetail-collapse-desc">
                      {data.lpDes}
                    </div>
                    {data.lpContent.length > 0 && (
                      <div className="dashboard-batchdetail-collapse-content">
                        <p>Content</p>
                      </div>
                    )}

                    {data.lpContent.length > 0 &&
                      data.lpContent.map((topic, index) => (
                        <div
                          key={index}
                          className="dashboard-batchdetail-collapse-div"
                        >
                          <CardContent
                            onClick={() => handleSubExpandClick(index)}
                            className="dashboard-batchdetail-collapse-lpname-div"
                          >
                            <div>
                              <Typography className="dashboard-batchdetail-collapse-lpname">
                                <div className="dashboard-batchdetail-index-div">
                                  <div className="dashboard-batchdetail-index">
                                    {index + 1}
                                  </div>
                                  <div>{topic.name}</div>
                                </div>
                              </Typography>
                              {showSubHeaderChapter[index] && (
                                <Typography className="dashboard-batchdetail-collapse-chapter-cout">
                                  {topic.subtopics.length} Chapters
                                </Typography>
                              )}
                            </div>
                            <IconButton
                              aria-label="show more"
                              aria-expanded={subExpanded[index]}
                              className="dashboard-batchdetail-collapse-expandicon"
                            >
                              <ExpandMoreIcon
                                className={`expand-icon ${
                                  subExpanded[index] ? "rotated" : ""
                                }`}
                              />
                            </IconButton>
                          </CardContent>
                          <Collapse
                            unmountOnExit
                            timeout="auto"
                            in={subExpanded[index]}
                          >
                            {topic.subtopics.map((subtopic, subIndex) => (
                              <CardContent className="dashboard-batchdetail-subcollapse-cardcontent">
                                <Typography
                                  key={subIndex}
                                  className="dashboard-batchdetail-subcollapse-subheader"
                                >
                                  {subtopic}
                                </Typography>
                                <Divider className="dashboard-batchdetail-subcollapse-divider" />
                              </CardContent>
                            ))}
                          </Collapse>
                        </div>
                      ))}
                  </Collapse>
                </Card>
              </div>
            ))}

            {parseInt(localStorage.getItem("batchType")) !==
              constants.BATCH_TYPE.TESTING && (
              <div>
                {Alert.dashBoardDetails.attendanceDetails?.sessionData !=
                  null &&
                  Alert.dashBoardDetails.attendanceDetails?.sessionData
                    ?.length > 0 && (
                    <div className="Dashboard-timeline-div">
                      <Timelinegraph
                        timelineData={Alert.dashBoardDetails.attendanceDetails}
                      />
                    </div>
                  )}

                <div className="event-asssignmentdue-leaderboard-container">
                  {parseInt(localStorage.getItem("batchType")) !==
                    constants.BATCH_TYPE.SELF && (
                    <UpcomingEvents
                      UpcomingEvents={Alert.dashBoardDetails.upComingEvents}
                    />
                  )}
                  <AssignmentDue
                    batchname={Alert.batchName}
                    naviageHomeFunction={naviageHomeFunction}
                    dueList={Alert.dashBoardDetails.taskDueLists}
                  />
                </div>

                {parseInt(localStorage.getItem("batchType")) !==
                  constants.BATCH_TYPE.SELF && (
                  <div className="dashboard-leaderboard-div">
                    <Leaderboard
                      isLoaded={Alert.isLoaded}
                      leaderBoardData={Alert.leaderBoardData}
                      loadingLeaderboard={Alert.loadingLeaderboard}
                      handleLeaderboardDetail={handleLeaderboardDetail}
                    />
                  </div>
                )}
                <div className="leetcode-container">
                  <LeetCode />
                </div>

                {Alert.dashBoardDetails.trainerList?.length > 0 && (
                  <div className="trainer-header">
                    <span>Trainers</span>
                  </div>
                )}
                <div className="Trainers-container">
                  <Trainers trainerData={Alert.dashBoardDetails.trainerList} />
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
}
