import React, { useContext } from "react";
import TopicSvgIcon from "../../../Svg/TopicSvgIcon";
import AlertContext from "../../../context/AlertContext";

const CcSection = ({ data }) => {
  const Alert = useContext(AlertContext);

  return (
    <div
      className="ccSecContainer"
      onClick={() => {
        Alert.navigate(`/main/cc/section/${data.id}`);
        sessionStorage.setItem("ccSecName", data.name);
      }}
    >
      <div className="ccSecContent">
        <TopicSvgIcon icon={data.id} />
        <div className="ccSecContentText">{data.name}</div>
      </div>
      <svg
        width="24"
        height="24"
        fill="none"
        viewBox="0 0 24 24"
        className="ccSecLeftChevron"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          strokeWidth="2"
          stroke="#667085"
          d="M9 18L15 12L9 6"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </div>
  );
};

export default CcSection;
