import Button from "../../../../Button";
import React, { useEffect, useContext } from "react";
import QuestionList from "../../../Tests/QuestionList";
import MockTestConfigTable from "./MockTestConfigTable";
import AlertContext from "../../../../../context/AlertContext";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import useAxiosPrivate from "../../../../../Hooks/useAxiosPrivate";
import MockTestHooks from "../../../../../Hooks/Mock/Admin/MockTestHooks";
import { MockTestPageContext } from "../../../../../context/MockTestPageContext";
import useCommonFunctionHooks from "../../../../../Hooks/useCommonFunctionHooks";
import MockTestConfigHooks from "../../../../../Hooks/Mock/Admin/MockTestConfig/MockTestConfigHooks";
import MockTestConfigTableHooks from "../../../../../Hooks/Mock/Admin/MockTestConfig/MockTestConfigTableHooks";
import MockTestConfigButtonHooks from "../../../../../Hooks/Mock/Admin/MockTestConfig/MockTestConfigButtonHooks";

export default function MockTestConfig() {
  const Alert = useContext(AlertContext);

  const {
    setFocus,
    testData,
    qnsCount,
    setTopics,
    isNewTest,
    listQnData,
    showQnList,
    selectedQns,
    setLanguages,
    setComplexity,
    setTestConfig,
    setSelectedQns,
  } = useContext(MockTestPageContext);

  const axios = useAxiosPrivate();

  const { handleCancelTest } = MockTestHooks();

  const {
    menuOpenState,
    multiSelectRefs,
    setMenuOpenState,
    handleCloseListPopup,
  } = MockTestConfigHooks();

  const {
    specificField,
    handleBackBtn,
    handleAddSection,
    handleAddQnsFromList,
  } = MockTestConfigTableHooks();

  const { handleSaveTest } = MockTestConfigButtonHooks();

  const { getCatchBlockDetails, getHeaderDetailsForAxios } =
    useCommonFunctionHooks();

  useEffect(() => {
    setTestConfig(testData.sections);
    /* Topic Api */

    Alert.setIsLoaded(true);
    axios
      .get(
        process.env.REACT_APP_NODE_API +
          `node/admin/topic/get/${testData.testType.value}`,
        getHeaderDetailsForAxios()
      )
      .then((response) => {
        let responseData = JSON.stringify(response);

        responseData = JSON.parse(responseData).data;

        let topicOptions = [];

        for (let i = 0; i < responseData.length; i++) {
          let temp = {
            value: responseData[i].id,
            label: responseData[i].name,
          };

          topicOptions.push(temp);
        }

        setTopics(topicOptions);
      })
      .catch((err) => {
        getCatchBlockDetails(err);
      })
      .finally(() => Alert.setIsLoaded(false));

    /* Complexity Api */

    axios
      .get("node/admin/complexity/get", getHeaderDetailsForAxios())
      .then((response) => {
        let responseData = JSON.stringify(response);

        responseData = JSON.parse(responseData).data;

        let complexityOptions = [];

        for (let i = 0; i < responseData.length; i++) {
          let temp = {
            value: responseData[i].id,
            label: responseData[i].name,
          };
          complexityOptions.push(temp);
        }

        setComplexity(complexityOptions);
      })
      .catch((err) => {
        getCatchBlockDetails(err);
      })
      .finally(() => Alert.setIsLoaded(false));

    /* Language Api */

    axios
      .get("node/admin/language/get", getHeaderDetailsForAxios())
      .then((response) => {
        let responseData = JSON.stringify(response);

        responseData = JSON.parse(responseData).data;

        let languageOptions = [];

        for (let i = 0; i < responseData.length; i++) {
          let temp = {
            value: responseData[i].id,
            label: responseData[i].name,
          };

          languageOptions.push(temp);
        }

        setLanguages(languageOptions);
      })
      .catch((err) => {
        getCatchBlockDetails(err);
      })
      .finally(() => Alert.setIsLoaded(false));

    setFocus(specificField !== 3 ? "1" : "2");
  }, []);

  useEffect(() => {
    // Handle document click to close the menu on outside click

    const handleClickOutside = (event) => {
      if (Object.values(menuOpenState).includes(true)) {
        const targetRef = multiSelectRefs.current.map((ref) => ref.current);

        if (!targetRef.some((ref) => ref && ref.contains(event.target))) {
          setMenuOpenState([]);
        }
      }
    };

    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [menuOpenState]);

  return (
    <>
      <div>
        <FontAwesomeIcon
          icon={faArrowLeft}
          onClick={handleBackBtn}
          className="adminTestBackBtn"
        />
        &nbsp;
        <span className="adminTestBack" onClick={handleBackBtn}>
          {testData.videoQn
            ? "Video Assessment Test"
            : testData.autoEvaluate
            ? "Coding Snippet Test"
            : testData.testType.label}
        </span>
      </div>
      <div className="adminTestQnCount adminTestGivenQnCount">
        No.of questions : {qnsCount}
      </div>
      <div className="adminTestConfigContainer">
        <div className="adminTestConfigSec">
          <MockTestConfigTable />
          {specificField !== 3 && (testData.published === 0 || isNewTest) ? (
            <p className="adminTestAddSectionBtn" onClick={handleAddSection}>
              + Add Section
            </p>
          ) : (
            <p className="adminTestAddSectionBtn"></p>
          )}
        </div>
        <div className="mockTestBtnDiv">
          <Button
            size="sm"
            onClick={handleCancelTest}
            hierarchy={{
              buttonText: "Cancel",
              type: "secondaryGrey",
            }}
          />
          {(testData.mockuse === 1 || isNewTest) && (
            <Button
              size="sm"
              onClick={handleSaveTest}
              hierarchy={{
                type: "primary",
                buttonText: isNewTest ? "Save" : "Update",
              }}
            />
          )}
        </div>
      </div>
      <QuestionList
        isNewTest={isNewTest}
        showQnList={showQnList}
        listQnData={listQnData}
        selectedQns={selectedQns}
        published={testData.published}
        setSelectedQns={setSelectedQns}
        handleCloseListPopup={handleCloseListPopup}
        handleAddQnsFromList={handleAddQnsFromList}
      />
    </>
  );
}
