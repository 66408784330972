import React, { useContext } from "react";
import constants from "../../../constants";
import Badge from "../../CommonComponents/Badge";
import TestSvgIcon from "../../../Svg/TestSvgIcon";
import TimeSvgIcon from "../../../Svg/TimeSvgIcon";
import TopicSvgIcon from "../../../Svg/TopicSvgIcon";
import AlertContext from "../../../context/AlertContext";
import MaxScoreSvgIcon from "../../../Svg/MaxScoreSvgIcon";
import QuestionInfoSvgIcon from "../../../Svg/QuestionInfoSvgIcon";

export default function TestDetail() {
  const Alert = useContext(AlertContext);

  const getTime = (seconds) => {
    const min = Math.floor(seconds / 60) % 60;
    const hrs = Math.floor(seconds / (60 * 60));

    return hrs !== 0
      ? " " + hrs + " hrs " + (min !== 0 ? min + " mins" : "")
      : " " + min + " mins";
  };

  const getLanguageName = (languageId) => {
    let langName = "";

    if (typeof languageId === "number") {
      languageId = JSON.stringify(languageId);
    }

    for (let i = 0; i < languageId.length; i++) {
      if (languageId.charAt(i) === "1") {
        langName += constants.getLangName.Java + ", ";
      }

      if (languageId.charAt(i) === "2") {
        langName += constants.getLangName.Javascript + ", ";
      }

      if (languageId.charAt(i) === "3") {
        langName += constants.getLangName.C + ", ";
      }

      if (languageId.charAt(i) === "4") {
        langName += constants.getLangName.Cpp + ", ";
      }

      if (languageId.charAt(i) === "5") {
        langName += constants.getLangName.Python + ", ";
      }
    }

    return langName.substring(0, langName.length - 2);
  };

  return (
    <div className="testDetailMainContainer">
      <div className="testDetail">
        {!Alert.testData.hasOwnProperty("lccid") &&
          Alert.testData.proctored !== constants.PROCTORING_MODE.NONE && (
            <Badge
              size="sm"
              color="error"
              icon={{ icon: <TimeSvgIcon />, position: "start" }}
              label={`Duration:${getTime(
                Alert.testData.testtype ===
                  constants.libTypeIds.CODING_CHALLENGE
                  ? Alert.codingTestData.configdata.duration
                  : Alert.testData.configdata.duration
              )}`}
            />
          )}
        <Badge
          size="sm"
          color="error"
          label={
            Alert.testData.testtype === constants.libTypeIds.CODING_CHALLENGE
              ? "Category: " + Alert.topic.name
              : "No. of Questions: " + Alert.testData.configdata.questionscount
          }
          icon={{
            position: "start",
            icon:
              Alert.testData.testtype ===
              constants.libTypeIds.CODING_CHALLENGE ? (
                <TopicSvgIcon />
              ) : (
                <QuestionInfoSvgIcon />
              ),
          }}
        />
        <Badge
          size="sm"
          color="error"
          label={
            Alert.testData.testtype === constants.libTypeIds.CODING_CHALLENGE
              ? "Coding Languages: " +
                getLanguageName(Alert.codingTestData.configdata.languageid)
              : "Maximum score: " + Alert.testData.configdata.marks
          }
          icon={{
            position: "start",
            icon:
              Alert.testData.testtype ===
              constants.libTypeIds.CODING_CHALLENGE ? (
                <TestSvgIcon
                  component={"testDetail"}
                  libType={Alert.testData.testtype}
                />
              ) : (
                <MaxScoreSvgIcon />
              ),
          }}
        />
      </div>
      <hr className="testDetailHorizontalLine" />
    </div>
  );
}
