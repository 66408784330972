import "./Header.scss";
import Select from "react-select";
import constants from "../../../constants";
import HeaderHooks from "./Hooks/HeaderHooks";
import { NavDropdown } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import Typography from "@mui/material/Typography";
import { getDataFromStorage } from "../../../util";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import GradiousLogoSvg from "./Svg/GradiousLogoSvg";
import React, { useEffect, useContext } from "react";
import TestDueHooks from "../../../Hooks/TestDueHooks";
import AlertContext from "../../../context/AlertContext";
import Notify from "../../Notification-Loading/Notify/Notify";
import useCommonFunctionHooks from "../../../Hooks/useCommonFunctionHooks";
import InstructionPageBody from "../InstructionPage/InstructionPageBody";
const { electronAPI } = window;
const Headers = (Props) => {
  const {
    msg,
    show,
    title,
    status,
    isAdmin,
    isUnAuth,
    testData,
    topicList,
    handleClose,
    setTopicList,
    isFullScreensp,
    handleUnAuthAlert,
    setIsFullScreensp,
    setExternalDevices,
    setShowNotify,
    setShowInstructions,
    assignQnDetailsForLearner,
    setOpenRecommendedList,
  } = useContext(AlertContext);

  const {
    tList,
    topicLoad,
    customStyle,
    questionList,
    questionLoad,
    handleLogOut,
    selectedTopics,
    selectedQuestions,
    getLatestSessions,
    handleSubmitAssignmentOnSandpack,
  } = HeaderHooks();

  const { currentDueLabel } = TestDueHooks();
  const { handleNavigate } = useCommonFunctionHooks();

  let isLearner = getDataFromStorage("role") === "learner" ? true : false;

  const { pathname } = useLocation();

  const userImage = (
    <img
      alt="userImage"
      className="logInProfile"
      src={localStorage.getItem("picture")}
    />
  );

  // const notificationReport = async (data, userid, notificationid) => {
  //   await handleMarkAllAsRead(notificationid, true);

  //   Props.viewUserSubmittedTestQnFromNotification(data.notificationData);

  //   document.querySelector(".notifications .container").style.visibility =
  //     "hidden";
  // };

  const handleScreenChange = (event, screenStatus) => {
    if (screenStatus.type === "added") {
      setExternalDevices(true);
      setShowNotify({
        show: true,
        title: "Warning",
        msg: "You are connecting to external display!",
      });
    } else if (screenStatus.type === "removed") {
      setExternalDevices(false);
      setShowNotify({
        show: true,
        title: "Warning",
        msg: "You are disconnecting from external display!",
      });
    }
  };

  useEffect(() => {
    electronAPI?.onScreenChanged(handleScreenChange);

    return () => {
      electronAPI?.offScreenChanged(handleScreenChange);
    };
  }, []);

  const toggleSandpackModeFull = () => {
    // Request full screen

    if (document.documentElement.requestFullscreen) {
      document.documentElement.requestFullscreen();
    } else if (document.documentElement.mozRequestFullScreen) {
      /* Firefox */

      document.documentElement.mozRequestFullScreen();
    } else if (document.documentElement.webkitRequestFullscreen) {
      /* Chrome, Safari and Opera */

      document.documentElement.webkitRequestFullscreen();
    } else if (document.documentElement.msRequestFullscreen) {
      /* IE/Edge */

      document.documentElement.msRequestFullscreen();
    }

    setIsFullScreensp(true);

    // isFullScreensp

    //   let header = document.getElementById('sandpackcont');
    //   console.log("heeaderHeight",header);
    //   if (header) {
    //     header.style.margin= '36px 0px 0';
    //     console.log("header.style.height",header.style.height);
    //     var headerStyle = window.getComputedStyle(header);
    //   var headerHeight = parseFloat(headerStyle.height);
    //  console.log("heeaderHeight",headerHeight);
    //   }
  };

  // var heeader = document.getElementById('appHeader'); // Replace 'your-header-id' with your actual header element's ID

  // if (heeader) {
  //   var heeaderStyle = window.getComputedStyle(heeader);
  //   var heeaderHeight = parseFloat(heeaderStyle.height);
  //  console.log("heeaderHeight",heeaderHeight);
  // }

  // const handleMarkAllAsRead = (notificationId, istrue) => {
  //   return new Promise((resolve, reject) => {
  //     axios
  //       .post(
  //         "node/learner/notification/read",
  //         istrue
  //           ? JSON.stringify({
  //               notificationid: notificationId,
  //               userid: getDataFromStorage("learnerid"),
  //             })
  //           : JSON.stringify({ userid: getDataFromStorage("learnerid") }),
  //         {
  //           timeout: 60000,
  //           headers: {
  //             "Content-type": "application/json",
  //           },
  //         }
  //       )
  //       .then((response) => {
  //         let jsonArr = [];

  //         for (let i = 0; i < details.current.length; i++) {
  //           if (notificationId === "") details.current[i].isread = 1;
  //           else {
  //             if (notificationId === details.current.notificationid) {
  //               details.current[i].isread = 1;
  //             }
  //           }

  //           let json = {
  //             id: nanoid(),
  //             message: (
  //               <>
  //                 <span
  //                   className="adminNotificationUnReadDot"
  //                   style={
  //                     details.current[i].isread === 1
  //                       ? { display: "none" }
  //                       : {
  //                           width: "5px",
  //                           float: "left",
  //                           height: "5px",
  //                           borderRadius: "50%",
  //                           background: "#0D6EFD",
  //                           margin: "15px 10px 0 0",
  //                         }
  //                   }
  //                 ></span>
  //                 <p
  //                   className="adminNotificationMsg"
  //                   onClick={() => {
  //                     notificationReport(
  //                       {
  //                         notificationData: JSON.parse(details.current[i].data),
  //                         tag: details.current[i].tag,
  //                       },
  //                       details.current[i].userid,
  //                       details.current[i].notificationid
  //                     );
  //                   }}
  //                 >
  //                   {details.current[i].message}
  //                 </p>
  //               </>
  //             ),

  //             // detailPage: "/events",

  //             receivedTime: moment(details.current[i].timestamp).fromNow(),
  //           };

  //           jsonArr.push(json);
  //         }
  //         setNotificationDetails(jsonArr.reverse());
  //         resolve("Success");
  //       })
  //       .catch((err) => {
  //         setIsLoaded(false);
  //         setShow(true);
  //         setTitle("Error");

  //         if (err.message === "timeout of 60000ms exceeded") {
  //           setMsg(
  //             "Something went wrong… program is taking too long to finish. Please, try again"
  //           );
  //         } else if (err.message.includes("403")) {
  //           setIsUnAuth(true);
  //           setMsg("You have been logged-out due to inactivity. Login again.");
  //         } else {
  //           setMsg(err.message);
  //         }

  //         reject("Failure");
  //       });
  //   });
  // };

  // useEffect(() => {
  //   setIsLoaded(true);
  //   axios
  //     .get("node/learner/notification/get/" + getDataFromStorage("learnerid"), {
  //       timeout: 60000,
  //       mode: "no-cors",
  //       headers: {
  //         "Content-type": "application/json",
  //       },
  //     })
  //     .then((response) => {
  //       setIsLoaded(false);

  //       if (response.data.resultCode === 1000) {
  //         let count = 0;
  //         let jsonArr = [];
  //         let res = response.data.msg;

  //         details.current = res;

  //         for (let i = 0; i < res.length; i++) {
  //           if (res[i].isread === 0) {
  //             count++;
  //           }

  //           let json = {
  //             id: nanoid(),
  //             message: (
  //               <>
  //                 <span
  //                   className="adminNotificationUnReadDot"
  //                   style={
  //                     res[i].isread === 1
  //                       ? { display: "none" }
  //                       : {
  //                           width: "5px",
  //                           float: "left",
  //                           height: "5px",
  //                           borderRadius: "50%",
  //                           background: "#0D6EFD",
  //                           margin: "15px 10px 0 0",
  //                         }
  //                   }
  //                 ></span>
  //                 <p
  //                   className="adminNotificationMsg"
  //                   onClick={() => {
  //                     notificationReport(
  //                       {
  //                         notificationData: JSON.parse(res[i].data),
  //                         tag: res[i].tag,
  //                       },
  //                       res[i].userid,
  //                       res[i].notificationid
  //                     );
  //                   }}
  //                 >
  //                   {res[i].message}
  //                 </p>
  //               </>
  //             ),

  //             // detailPage: "/events",

  //             receivedTime: moment(res[i].timestamp).fromNow(),
  //           };

  //           jsonArr.push(json);
  //         }

  //         setNotificationDetails(jsonArr.reverse());

  //         if (count > 0) {
  //           let countElmemt = document.querySelector(".count");

  //           if (countElmemt && countElmemt !== null) {
  //             countElmemt.innerHTML = count;
  //           }
  //         }
  //       } else {
  //         setShow(true);
  //         setMsg("Network error ");
  //         setTitle("Error");
  //       }
  //     })
  //     .catch((err) => {
  //       setIsLoaded(false);
  //       setShow(true);
  //       setTitle("Error");

  //       if (err.message === "timeout of 60000ms exceeded") {
  //         setMsg(
  //           "Something went wrong… program is taking too long to finish. Please, try again"
  //         );
  //       } else if (err.message.includes("403")) {
  //         setIsUnAuth(true);
  //         setMsg("You have been logged-out due to inactivity. Login again.");
  //       } else {
  //         setMsg(err.message);
  //       }
  //     });
  // }, []);

  useEffect(() => {
    if (pathname === "/coding") {
      if (
        (assignQnDetailsForLearner.status ===
          constants.QUESTION_STATUS.SUBMITTED ||
          assignQnDetailsForLearner.status ===
            constants.QUESTION_STATUS.TO_BE_EVALUATED) &&
        !assignQnDetailsForLearner.hasOwnProperty("firstname")
      ) {
        tList.current = testData.data.topics.map(
          (topic) =>
            topic.hasOwnProperty("qndata") && {
              value: topic.topicid,
              label: topic.topicname,
            }
        );
      } else {
        tList.current = [
          {
            value: assignQnDetailsForLearner.topics.topicid,
            label: assignQnDetailsForLearner.topics.topicname,
          },
        ];
      }

      setTopicList(tList.current);

      for (let i = 0; i < tList.current.length; i++) {
        if (
          tList.current[i].value === assignQnDetailsForLearner.topics.topicid
        ) {
          topicLoad(tList.current[i], "useEffect");
          break;
        }
      }
    }
  }, [pathname]);

  useEffect(() => {
    if (!isAdmin && pathname === "/sandpack") {
      setShowNotify({
        size: "lg",
        show: true,
        title: "Instruction",
        msg: <InstructionPageBody />,
      });
    }
  }, [isAdmin, pathname]);

  return (
    <>
      <header
        id="appHeader"
        className={pathname === "/sandpack" ? "headerforsp" : "Header"}
      >
        <div className="headerBody">
          <div
            className="headerLogoAndName"
            onClick={() => {
              if (!isLearner) {
                handleNavigate();
              }
            }}
          >
            <GradiousLogoSvg />
            <div className="headerLogoAndNameName">Gradious</div>
          </div>
          {pathname === "/coding" ? (
            <div className="headerBreadcrumbs">
              <Breadcrumbs
                separator="/"
                className="codingNav"
                aria-label="breadcrumb"
              >
                <Typography className="modulebc">
                  {assignQnDetailsForLearner.testname}
                </Typography>
                <Typography className="topicbc">
                  {assignQnDetailsForLearner.status ===
                    constants.QUESTION_STATUS.ASSIGNED ||
                  assignQnDetailsForLearner.hasOwnProperty("firstname") ? (
                    assignQnDetailsForLearner.topics.topicname
                  ) : testData.data.topics.filter((topic) =>
                      topic.hasOwnProperty("qndata")
                    ).length > 1 ? (
                    <div className="selectTopicHeader">
                      <Select
                        options={topicList}
                        isSearchable={false}
                        styles={customStyle}
                        onChange={topicLoad}
                        id="headerTopicSelect"
                        value={selectedTopics.current}
                        components={{
                          IndicatorSeparator: () => null,
                        }}
                      />
                    </div>
                  ) : (
                    assignQnDetailsForLearner.topics.topicname
                  )}
                </Typography>
                <Typography className="topicbc">
                  {assignQnDetailsForLearner.status ===
                    constants.QUESTION_STATUS.ASSIGNED ||
                  assignQnDetailsForLearner.status ===
                    constants.QUESTION_STATUS.RESUBMIT ||
                  assignQnDetailsForLearner.status ===
                    constants.QUESTION_STATUS.CONTINUE ? (
                    <h5 className="questionbc">
                      Question{" "}
                      {assignQnDetailsForLearner.secData.completedcount ===
                      assignQnDetailsForLearner.topics.totalcount
                        ? assignQnDetailsForLearner.secData.completedcount
                        : assignQnDetailsForLearner.secData.completedcount +
                          1 +
                          " "}
                      {"of "} {assignQnDetailsForLearner.topics.totalcount}
                    </h5>
                  ) : (assignQnDetailsForLearner.status ===
                      constants.QUESTION_STATUS.SUBMITTED ||
                      assignQnDetailsForLearner.status ===
                        constants.QUESTION_STATUS.TO_BE_EVALUATED) &&
                    assignQnDetailsForLearner.hasOwnProperty("firstname") ? (
                    <h5 className="questionbc">
                      Question {assignQnDetailsForLearner.qnno}
                    </h5>
                  ) : assignQnDetailsForLearner.status ===
                      constants.QUESTION_STATUS.SUBMITTED ||
                    assignQnDetailsForLearner.status ===
                      constants.QUESTION_STATUS.TO_BE_EVALUATED ? (
                    <div className="selectQuestionHeader">
                      <Select
                        isSearchable={false}
                        styles={customStyle}
                        onChange={questionLoad}
                        id="headerQuestionSelect"
                        options={questionList.current}
                        value={selectedQuestions.current}
                        components={{
                          IndicatorSeparator: () => null,
                        }}
                      />
                    </div>
                  ) : (
                    <h5 className="questionbc">
                      Question{" "}
                      {assignQnDetailsForLearner.secData.completedcount + 1} /{" "}
                      {testData.data.topics.map((topic) =>
                        topic.topicid === selectedTopics.current.value ||
                        topic.topicid === testData.data.topics[0].topicid
                          ? topic.totalcount
                          : null
                      )}
                    </h5>
                  )}
                </Typography>
              </Breadcrumbs>
            </div>
          ) : null}
          {pathname === "/sandpack" ? (
            <>
              <div className="containerforsandpackutils">
                <span
                  className="sandpacksubmit"
                  onClick={() => setShowInstructions(true)}
                >
                  View Assignment
                </span>
                {(currentDueLabel(testData.duedate) !== "Past Due" ||
                  localStorage.getItem("batchType") ==
                    constants.BATCH_TYPE.SELF) &&
                  status !== constants.PROGRESS_STATUS.EVALUATED &&
                  isLearner && (
                    <svg
                      width="20"
                      height="20"
                      fill="none"
                      onClick={() => handleSubmitAssignmentOnSandpack(false)}
                      className={
                        status === constants.PROGRESS_STATUS.EVALUATED
                          ? "disablesavesandpack"
                          : "savesandpack"
                      }
                    >
                      <path
                        stroke="#EAECF0"
                        className="savesp"
                        stroke-width="1.2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M14.1667 17.5V10.8333H5.83333V17.5M5.83333 2.5V6.66667H12.5M15.8333 17.5H4.16667C3.72464 17.5 3.30072 17.3244 2.98816 17.0118C2.67559 16.6993 2.5 16.2754 2.5 15.8333V4.16667C2.5 3.72464 2.67559 3.30072 2.98816 2.98816C3.30072 2.67559 3.72464 2.5 4.16667 2.5H13.3333L17.5 6.66667V15.8333C17.5 16.2754 17.3244 16.6993 17.0118 17.0118C16.6993 17.3244 16.2754 17.5 15.8333 17.5Z"
                      />
                    </svg>
                  )}
                {/* <button className="sandpacksubmit"
              onClick={()=>handleSubmitAssignmentOnSandpack(true)}> Submit
              </button> */}
                {(currentDueLabel(testData.duedate) !== "Past Due" ||
                  localStorage.getItem("batchType") ==
                    constants.BATCH_TYPE.SELF) &&
                  status !== constants.PROGRESS_STATUS.EVALUATED &&
                  isLearner && (
                    <span
                      className={
                        status === constants.PROGRESS_STATUS.EVALUATED
                          ? "disablesandpacksubmit"
                          : "sandpacksubmit"
                      }
                      onClick={() => handleSubmitAssignmentOnSandpack(true)}
                    >
                      Submit
                    </span>
                  )}

                {/* <svg fill="#000000" viewBox="0 0 1920 1920" xmlns="http://www.w3.org/2000/svg">
            <g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
            <g id="SVGRepo_iconCarrier"> <path d="M1158.513-.012v123.68h550.5L123.68 1708.878V1158.5H0V1920h761.5v-123.68H211.121l1585.21-1585.21v550.5h123.68V-.011z" fill-rule="evenodd">
              </path> </g></svg> */}
              </div>
            </>
          ) : null}

          {pathname !== "/sandpack" ? (
            <div className="headerNotificationAndProfilePic">
              {pathname === "/home" && (
                <i
                  class="fa fa-bell"
                  id="latestsessions_notifications"
                  onClick={() =>
                    getLatestSessions(getDataFromStorage("learnerid"))
                  }
                ></i>
              )}
              {/* <div className="adminNotificationSec">
              {Props.hideTimer.includes(pathname) ? null : (
                <Notifications
                  icon={bell}
                  data={notificationDetails}
                  header={{
                    title: "Notifications",
                    option: {
                      text: (
                        <div className="adminNotificationActionSec">
                          <p
                            onClick={handleClearAll}
                            className="adminNotificationClear"
                          >
                            Clear all
                          </p>
                          <p
                            className="adminNotificationMark"
                            onClick={() => handleMarkAllAsRead("", false)}
                          >
                            Mark all as read
                          </p>
                        </div>
                      ),
                    },
                  }}
                />
              )}
            </div>} */}

              <div>
                {Props.hideTimer.includes(pathname) ? null : (
                  <NavDropdown title={userImage}>
                    <li>
                      <img
                        alt="logInProfile1"
                        className="logInProfile1"
                        src={localStorage.getItem("picture")}
                      />
                    </li>
                    <li className="logoutUserName">
                      {getDataFromStorage("name")}
                    </li>
                    <hr
                      style={{ margin: "0", padding: "0", position: "static" }}
                    ></hr>
                    <li className="logout" onClick={handleLogOut}>
                      Logout
                    </li>
                  </NavDropdown>
                )}
              </div>
            </div>
          ) : null}
        </div>
      </header>
      <Notify
        show={show}
        message={msg}
        title={title}
        onHide={isUnAuth ? handleUnAuthAlert : handleClose}
      />
    </>
  );
};

export default Headers;
