import { nanoid } from "nanoid";
import { useContext } from "react";
import constants from "../../../../constants";
import useAxiosPrivate from "../../../useAxiosPrivate";
import useAddOrUpdateLp from "../../../useAddOrUpdateLp";
import AlertContext from "../../../../context/AlertContext";
import NotifyBody from "../../../../Components/Notify/NotifyBody";
import MockLearningPathListHooks from "./MockLearningPathListHooks";
import useCommonFunctionHooks from "../../../useCommonFunctionHooks";
import { MockTestPageContext } from "../../../../context/MockTestPageContext";

export default function MockLearningPathModuleHooks() {
  const Alert = useContext(AlertContext);
  const MockTestPageCxt = useContext(MockTestPageContext);

  const axios = useAxiosPrivate();
  const { handleAddOrUpdateLp } = useAddOrUpdateLp();
  const { handleActiveMockLearningPath } = MockLearningPathListHooks();

  const { handleClose, getCatchBlockDetails, getHeaderDetailsForAxios } =
    useCommonFunctionHooks();

  const handleAddModule = () => {
    let module = {
      id: "",
      dueDate: "",
      key: nanoid(),
      status: "new",
      moduleName: "",
      moduleData: [],
    };

    let newLpDetails = JSON.parse(JSON.stringify(Alert.lpData2));

    if (Alert.isOldLpDetail) newLpDetails.status = "update";

    let newDetails = [...MockTestPageCxt.modules],
      uniqueId = 1;
    newDetails.push(module);

    for (let i = 0; i < newDetails.length; i++) {
      newDetails[i].id = uniqueId;

      if (i === newDetails.length - 1) MockTestPageCxt.setFocus(uniqueId);
      uniqueId++;

      if (newDetails[i].hasOwnProperty("moduleData")) {
        for (let j = 0; j < newDetails[i].moduleData.length; j++) {
          newDetails[i].moduleData[j].moduleDataId = uniqueId;
          uniqueId++;
        }
      }
    }

    MockTestPageCxt.setModules(newDetails);
  };

  const handleBackBtn = () => {
    Alert.setIsAddLpModule(false);
    Alert.setLpData2({
      ...Alert.lpData2,
      id: nanoid(),
      moduleId: "",
      libTypeId: 2,
      isAddTest: false,
      lpDes: MockTestPageCxt.newLpDetails.lpDes,
      lpName: MockTestPageCxt.newLpDetails.lpName,
      lpIcon: MockTestPageCxt.newLpDetails.lpIcon,
      status: MockTestPageCxt.newLpDetails.status,
      iconData: MockTestPageCxt.newLpDetails.iconData,
      lpContent: MockTestPageCxt.newLpDetails.lpContent,
      lpShortDes: MockTestPageCxt.newLpDetails.lpShortDes,
      modules: JSON.parse(JSON.stringify(MockTestPageCxt.modules)),
    });

    MockTestPageCxt.setNewLpDetails({
      ...MockTestPageCxt.newLpDetails,
      id: nanoid(),
      moduleId: "",
      libTypeId: 2,
      isAddTest: false,
      modules: JSON.parse(JSON.stringify(MockTestPageCxt.modules)),
    });
  };

  const handleCancel = () => {
    Alert.setIsAddLpModule(false);

    if (!Alert.isFromBatch || !Alert.isOldLpDetail) {
      Alert.setIsAddLp(false);
      Alert.setIsOldLpDetail(false);
      Alert.setLpData2({
        lpDes: "",
        lpName: "",
        lpIcon: "",
        status: "",
        id: nanoid(),
        iconData: "",
        libTypeId: 2,
        moduleId: "",
        lpContent: "",
        lpShortDes: "",
        isAddTest: false,
        modules: [
          {
            id: 1,
            name: "",
            key: nanoid(),
            status: "new",
            moduleData: [],
          },
        ],
      });
    } else {
      let newDetails = JSON.parse(JSON.stringify(Alert.lpData2));

      newDetails.modules = MockTestPageCxt.modules;
      Alert.setLpData2(JSON.parse(JSON.stringify(newDetails)));
    }

    Alert.navigate("mock/learningpath");
  };

  const handleGetLibIdFromLp = (data) => {
    Alert.setOldTestConfig({
      mocklp: true,
      oldTestData: {},
      isOldTestConfig: false,
    });
    Alert.setFromMenu(false);
    Alert.setLpData2(data);
    Alert.navigate("library/testlist");
  };

  const handleAddModuleDetails = (moduleId, libTypeId) => {
    let newDetails = Alert.isOldLpDetail
      ? JSON.parse(JSON.stringify(Alert.lpData2))
      : JSON.parse(JSON.stringify(MockTestPageCxt.newLpDetails));

    newDetails.modules = MockTestPageCxt.modules;
    newDetails.moduleId = moduleId;
    newDetails.libTypeId = libTypeId;
    newDetails.isAddTest = true;
    handleGetLibIdFromLp(newDetails);
  };

  const handleSuccessResponse = () => {
    Alert.setOldTestConfig({
      mocklp: true,
      oldTestData: {},
      isOldTestConfig: false,
    });

    Alert.setLpData2(
      Alert.isOldLpDetail
        ? JSON.parse(JSON.stringify(Alert.lpData2))
        : {
            lpDes: "",
            lpName: "",
            lpIcon: "",
            id: nanoid(),
            moduleId: "",
            iconData: "",
            libTypeId: 2,
            lpContent: "",
            lpShortDes: "",
            isAddTest: false,
            modules: [
              {
                id: 1,
                key: nanoid(),
                status: "new",
                moduleName: "",
                moduleData: [],
              },
            ],
          }
    );
    Alert.setIsAddLpModule(false);
    Alert.setIsAddLp(false);
    handleClose();
  };

  const handleProceed = async () => {
    try {
      let res = await handleAddOrUpdateLp(
        MockTestPageCxt.newLpDetails,
        MockTestPageCxt.modules,
        MockTestPageCxt.setModules,
        true
      );

      if (res.title === "success2" || res.title === "success") {
        if (MockTestPageCxt.newLpDetails.mockuse === 1) {
          let row = {
            from: "learningpath",
            id: MockTestPageCxt.newLpDetails.lpId,
            mockuse: MockTestPageCxt.newLpDetails.mockuse,
          };

          Alert.setShowNotify({
            show: true,
            title: "Success",
            primaryButton: "Publish",
            secondaryButton: "Back to Mock",
            msg: (
              <NotifyBody
                component="mockPage"
                data={{ type: "Group test", status: "updated" }}
              />
            ),
            secondaryButtonFunction: handleSuccessResponse,
            primaryButtonFunction: () => handleActiveMockLearningPath(row),
          });
        } else {
          Alert.setShowNotify({
            show: true,
            title: "Success",
            msg: res.message,
            primaryButtonFunction: handleSuccessResponse,
          });
        }
      }
    } catch (err) {
      getCatchBlockDetails(err);
    }
  };

  const handleCreatedDeleteModule = (record) => {
    axios
      .post(
        "node/admin/lp/module/delete",
        JSON.stringify({ modid: record.moduleId }),
        getHeaderDetailsForAxios()
      )
      .then((res) => {
        if (res.data.resultCode === constants.RESULT_STATUS.SUCCESS) {
          Alert.setShowNotify({
            show: true,
            title: "Success",
            msg: res.data.data,
          });

          MockTestPageCxt.setModules((pre) =>
            pre.filter((module) => module.id !== record.id)
          );
        } else {
          Alert.setShowNotify({
            show: true,
            title: "Error",
            msg: "Something went wrong. Please try again later.",
          });
        }
      })
      .catch((err) => {
        getCatchBlockDetails(err);
      });
  };

  return {
    handleCancel,
    handleProceed,
    handleBackBtn,
    handleAddModule,
    handleAddModuleDetails,
    handleCreatedDeleteModule,
  };
}
