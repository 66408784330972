import React, { useEffect, useState, useContext } from "react";
import useAxiosPrivate from "../../../Hooks/useAxiosPrivate";
import AlertContext from "../../../context/AlertContext";
import CcSection from "./CcSection";
import constants from "../../../constants";
import Loading from "../../Notification-Loading/Loading/Loading";
import { getDataFromStorage, MANAGEMENTROLES } from "../../../util";

const CcHome = () => {
  const { setShowNotify, setIsLoaded, isLoaded } = useContext(AlertContext);
  const axios = useAxiosPrivate();
  const [ccSections, setCcSections] = useState([]);

  useEffect(() => {
    const fetchSections = async () => {
      setIsLoaded(true);
      try {
        let response = await axios.get("node/learner/cc/section", {
          headers: {
            "Content-Type": "application/json",
          },
        });
        if (response.data.resultCode === constants.RESULT_STATUS.SUCCESS) {
          setCcSections(response.data.data ?? []);
        } else {
          setShowNotify({
            show: true,
            title: "Error !",
            msg: "Something went wrong. Please try again later or contact gradious team.",
          });
        }
      } catch (error) {
        console.log(error);
        if (error.message.includes("403")) {
          setShowNotify({
            show: true,
            title: "Warning !",
            msg: "You have been logged-out due to inactivity. Login again.",
            isUnAuth: true,
          });
        } else {
          setShowNotify({
            show: true,
            title: "Error !",
            msg: error.message,
          });
        }
      } finally {
        setIsLoaded(false);
      }
    };
    fetchSections();
  }, [axios, setIsLoaded, setShowNotify]);

  return (
    <div className="ccContainer">
      <div className="ccSection">
        <div className="ccTitle">Coding Challenges</div>
        <div className="ccSectionsContainer">
          {ccSections.map((item) => (
            <CcSection data={item} key={item.id} />
          ))}
        </div>
      </div>
      {isLoaded && MANAGEMENTROLES.includes(getDataFromStorage("role")) && (
        <Loading />
      )}
    </div>
  );
};

export default CcHome;
