import React, { useState, useContext } from "react";
import Button from "../../Button";
import * as XLSX from "xlsx";
import UploadSvg from "../../../Svg/UploadIconsvg";
import DownloadSvg from "../../../Svg/Downloadsvg";
import useAxiosPrivate from "../../../Hooks/useAxiosPrivate";
import AlertContext from "../../../context/AlertContext";
import useCommonFunctionHooks from "../../../Hooks/useCommonFunctionHooks";
import { getPreSignedUrl } from "../../../util";

function UploadJobs() {
  const Alert = useContext(AlertContext);
  const [fileLoaded, setFileLoaded] = useState(null);
  const { handleClose } = useCommonFunctionHooks();
  const commonFunction = useCommonFunctionHooks();
  const axios = useAxiosPrivate();
  const [gcat, setGcat] = useState(1);
  const [isNotSupoortedFile, setIsNotSupportedFile] = useState(false);
  // function generateAndDownloadCSV(headers, firstColumnData, filename) {
  //   let csvContent = headers.join(",") + "\n";
  //   firstColumnData.forEach((item) => {
  //     csvContent += item + "\n";
  //   });
  //   const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
  //   const link = document.createElement("a");
  //   if (link.download !== undefined) {
  //     const url = URL.createObjectURL(blob);
  //     link.setAttribute("href", url);
  //     link.setAttribute("download", filename);
  //     link.style.visibility = "hidden";
  //     document.body.appendChild(link);
  //     link.click();
  //     document.body.removeChild(link);
  //   }
  // }

  function generateExcel() {
    const data = [
      {
        Designation: "Software Engineer",
        CompanyName: "TechCorp",
        CompanyLogo: "https://example.com/logo.png",
        Skills: "JavaScript, React, Node.js",
        minexp: 2,
        maxexp: 5,
        JobType: 1,
        minctc: 500000,
        maxctc: 1000000,
        WorkMode: 2,
        Link: "https://example.com/job/software-engineer",
        Description:
          "We are looking for a Software Engineer with 2-5 years of experience.",
        isjob: 0,
        paid: 1,
        PassingOutYear: "2025",
        startdate: "2025-01-15",
        duration: 6,
        location:2
      },
      {
        Designation: "Data Analyst",
        CompanyName: "DataCorp",
        CompanyLogo: "https://example.com/logo2.png",
        Skills: "SQL, Python, Power BI",
        minexp: 3,
        maxexp: 6,
        JobType: 2,
        minctc: 700000,
        maxctc: 1200000,
        WorkMode: 1,
        Link: "https://example.com/job/data-analyst",
        Description:
          "We are looking for a Data Analyst with 3-6 years of experience.",
        isjob: 1,
        paid: 0,
        PassingOutYear: "2024,2025",
        startdate: "2025-01-15",
        duration: 6,
        location:1
      },
    ];
    const worksheet = XLSX.utils.json_to_sheet(data);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Jobs");
    XLSX.writeFile(workbook, "jobs.xlsx");
  }

  const handleChangeOpt = (e) => {
    setGcat(!gcat);
    setFileLoaded(null);
  };

  const handleClearFile = () => {
    setFileLoaded(null);
    // setTcLoaded(null);
  };

  const handleLoadFile = (e) => {
    const file = e.target?.files[0];
    if (file) {
      setFileLoaded(file);
    }
    e.target.value = "";
  };

  const handleUploadTcFile = () => {
    console.log("!fileLoaded?.size", !fileLoaded?.size);
    if (!fileLoaded?.size) return;
    let reader = new FileReader();
    reader.onload = async (e) => {
      const form = new FormData();
      form.append("csv", fileLoaded);
      // form.append("batchid", id);
      axios
        .post(
          `node/admin/jobs/upload`,
          form,
          commonFunction.getHeaderDetailsForAxios("multipart")
        )
        .then((res) => {
          handleClose();
          if (res.data.resultCode === 1000) {
            Alert.setShow(true);
            Alert.setTitle("Success");
            Alert.setMsg(res.data.msg);
            // setTcLoaded(null);
          } else if (res.data.resultCode === 2050) {
            Alert.setShow(true);
            Alert.setTitle("Warning !");
            Alert.setMsg(res.data.msg);
          } else {
            Alert.setShow(true);
            Alert.setTitle("Error");
            Alert.setMsg("Something went wrong. Please try again later.");
          }
        })
        .catch((error) => {
          console.log("err in get timeline");
          if (error.message.includes("403")) {
            Alert.setShow(true);
            Alert.setIsUnAuth(true);
            Alert.setTitle("Error");
            Alert.setMsg(
              "You have been logged-out due to inactivity. Login again."
            );
          }
        });
    };

    reader.readAsDataURL(fileLoaded);
  };

  // const handleUploadFile = () => {
  //   if (!fileLoaded?.size) return;
  //   let reader = new FileReader();
  //   reader.onload = async (e) => {
  //     if (gcat && !e.target.result.includes("data:text/csv")) {
  //       // setShow(true);
  //       // setTitle("Warning !");
  //       // setMsg("Wrong file type - CSV only.");
  //       setIsNotSupportedFile(true);
  //     } else {
  //       const form = new FormData();
  //       form.append("csv", fileLoaded);
  //       form.append("batchid",Props.batchId)
  //       axios
  //         .post(
  //           gcat
  //             ? `node/admin/batch/profile/upload/gcat`
  //             : `node/admin/batch/profile/upload/feedback`,
  //           form,
  //           commonFunction.getHeaderDetailsForAxios("multipart")
  //         )
  //         .then((res) => {
  //           handleClose();
  //           if (res.data.resultCode === 1000) {
  //             setShow(true);
  //             setTitle("Success");
  //             setMsg(res.data.msg);
  //             setFileLoaded(null);
  //           } else if (res.data.resultCode === 2050) {
  //             setShow(true);
  //             setTitle("Warning !");
  //             setMsg(res.data.msg);
  //           } else {
  //             setShow(true);
  //             setTitle("Error");
  //             setMsg("Something went wrong. Please try again later.");
  //           }

  //           if (gcat && res.data?.hasOwnProperty("filedata")) {
  //             const url = window.URL.createObjectURL(
  //               new Blob([res.data.filedata])
  //             );
  //             const link = document.createElement("a");

  //             link.href = url;
  //             link.setAttribute(
  //               "download",
  //               `${new Date().toISOString().split("T")[0]}.csv`
  //             );
  //             document.body.appendChild(link);
  //             link.click();
  //           } else {
  //             if(res.data?.hasOwnProperty("filedata")){
  //               // Convert base64 back to binary data
  //               const byteCharacters = atob(res.data.filedata);
  //               const byteNumbers = new Array(byteCharacters.length).fill().map((_, i) => byteCharacters.charCodeAt(i));
  //               const byteArray = new Uint8Array(byteNumbers);

  //               // Create a Blob from the byte array
  //               const excelBlob = new Blob([byteArray], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });

  //               // Create a download link and trigger download
  //               const url = window.URL.createObjectURL(excelBlob);
  //               const link = document.createElement("a");
  //               link.href = url;
  //               link.setAttribute("download", `${new Date().toISOString().split("T")[0]}.xlsx`);
  //               document.body.appendChild(link);
  //               link.click();
  //               document.body.removeChild(link); // Clean up
  //             }
  //           }
  //         })
  //         .catch((error) => {
  //           console.log("err in upload file GCAT/FB");
  //           if (error.message.includes("403")) {
  //             setShow(true);
  //             setIsUnAuth(true);
  //             setTitle("Error");
  //             setMsg(
  //               "You have been logged-out due to inactivity. Login again."
  //             );
  //           }

  //           if (gcat && error.data?.hasOwnProperty("filedata")) {
  //             const url = window.URL.createObjectURL(
  //               new Blob([error.data.filedata])
  //             );
  //             const link = document.createElement("a");

  //             link.href = url;
  //             link.setAttribute(
  //               "download",
  //               `${new Date().toISOString().split("T")[0]}.csv`
  //             );
  //             document.body.appendChild(link);
  //             link.click();
  //           } else {
  //             if(error.data?.hasOwnProperty("filedata")){
  //                 // Convert base64 back to binary data
  //                 const byteCharacters = atob(error.data.filedata);
  //                 const byteNumbers = new Array(byteCharacters.length).fill().map((_, i) => byteCharacters.charCodeAt(i));
  //                 const byteArray = new Uint8Array(byteNumbers);

  //                 // Create a Blob from the byte array
  //                 const excelBlob = new Blob([byteArray], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });

  //                 // Create a download link and trigger download
  //                 const url = window.URL.createObjectURL(excelBlob);
  //                 const link = document.createElement("a");
  //                 link.href = url;
  //                 link.setAttribute("download", `${new Date().toISOString().split("T")[0]}.xlsx`);
  //                 document.body.appendChild(link);
  //                 link.click();
  //                 document.body.removeChild(link); // Clean up
  //             }
  //           }
  //         });
  //     }
  //   };

  //   reader.readAsDataURL(fileLoaded);
  // };

  const handleTemplateDownload = () => {
    generateExcel();
  };

  const downloadFile = async (libType) => {
    let URL = "";

    if (process.env.REACT_APP_DEPLOYMENT === "LOCAL")
      URL = "../../../assets/files/TEMPLATE_JOBS.xlsx";
    else {
      URL = await getPreSignedUrl("Templates/TEMPLATE_JOBS.xlsx");
    }

    window.location.href = URL;
  };

  return (
    <div className="uploadgcatfbcont">
      <div className="downloadTemplate">
        <div className="textForTemplateCont">
          <p className="btextfortemplate">Jobs Template</p>
          <p className="ntextfortemplate">
            We recommend you to use this template to upload the jobs.
          </p>
        </div>
        <Button
          disable={false}
          icon={{
            type: "leading",
            icon: <DownloadSvg stroke="#667085" />,
          }}
          size={"sm"}
          // onClick={handleTemplateDownload} //to be changed
          onClick={downloadFile} //to be changed
          hierarchy={{
            buttonText: "Download",
            type: "tertiaryGrey",
          }}
        />
      </div>
      <div>
        <div
          className={
            fileLoaded == null ? "upLoadTemplate" : "upLoadTemplateAfter"
          }
        >
          <div className="textForTemplateCont">
            <p className="btextfortemplate">{"Upload " + `Jobs`}</p>
            <p className="ntextfortemplate">Supported format: .xlsx</p>
            {isNotSupoortedFile && (
              <p className="etextfortemplate">
                The uploaded file is not supported.
              </p>
            )}
          </div>

          {fileLoaded == null && (
            <div className="upload-div">
              <Button
                disable={false}
                icon={{
                  type: "leading",
                  icon: <UploadSvg stroke="#344054" />,
                }}
                size={"sm"}
                onClick={handleLoadFile}
                hierarchy={{
                  buttonText: "Upload",
                  type: "secondaryGrey",
                }}
              />

              <input
                className="upload-div-input"
                type="file"
                accept=".xlsx"
                // hidden
                name="evaluation"
                onChange={handleLoadFile}
              />
            </div>
          )}
        </div>
        {fileLoaded != null && (
          <div className="afterfileloadedcont">
            {/* <div className="innercontafterfileloaded"> */}
            <p>{fileLoaded.name}</p>
            <svg
              onClick={handleClearFile}
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M12 4L4 12M4 4L12 12"
                stroke="#667085"
                stroke-width="1.33"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
            {/* </div> */}
          </div>
        )}
      </div>
      <div className="btnCont">
        <Button
          disable={false}
          size={"lg"}
          onClick={handleClose}
          hierarchy={{
            buttonText: "Cancel",
            type: "secondaryGrey",
          }}
        />
        <Button
          disable={fileLoaded == null}
          size={"lg"}
          onClick={handleUploadTcFile}
          hierarchy={{
            buttonText: "Submit",
            type: "primary",
          }}
        />
      </div>
    </div>
  );
}

export default UploadJobs;
