import React, { useContext } from "react";
import constants from "../../../constants";
import { useLocation } from "react-router-dom";
import useLogout from "../../../Hooks/useLogout";
import { getDataFromStorage } from "../../../util";
import MenuSvgIcon from "../../../Svg/MenuSvgIcon";
import ChevronUpSvg from "../../../Svg/ChevronUpSvg";
import AlertContext from "../../../context/AlertContext";
import ChevronDownSvg from "../../../Svg/ChevronDownSvg";
import { Menu, MenuItem, SubMenu } from "react-pro-sidebar";
import useAxiosPrivate from "../../../Hooks/useAxiosPrivate";

export default function MenuBarFooter() {
  const Alert = useContext(AlertContext);

  const logOut = useLogout();
  const axios = useAxiosPrivate();

  const { pathname } = useLocation();

  const getLatestSessions = async (learnerid, from) => {
    let cond = getDataFromStorage("role") === constants.Roles.learner;

    if (cond) {
      let res = await axios.get("node/learner/activesession/" + learnerid, {
        headers: {
          "Content-type": "application/json",
        },
      });

      if (from !== "root") {
        if (!res.data.data.hasOwnProperty("resolveddata")) {
          let jwtToken = res.data.data.jwtToken;
          let sessionName = res.data.data.sessions[0].sessionName;
          let sessionDate = res.data.data.sessions[0].sessionDate;
          let sessionTime = res.data.data.sessions[0].sessionTime;

          let notificationData = {
            open: true,
            data: {
              notification: {
                title: `Attendance:\n${sessionName}`,
                body: `Date: ${sessionDate}\nTime: ${sessionTime}\nPlease mark correctly as the coordinator will be checking it manually as well.`,
              },
              data: {
                jwtToken: jwtToken,
              },
            },
          };

          Alert.setNotificationPayload(notificationData);
        } else {
          Alert.setShowNotify({
            show: true,
            title: "Info",
            msg: res.data.data.resolveddata,
          });
        }
      } else {
        return !res.data.data.hasOwnProperty("resolveddata");
      }
    }
  };

  return (
    <Menu
      className="menuBarFooterMainContainer"
      renderExpandIcon={({ open }) => (
        <span>
          {open ? <ChevronDownSvg from="menu" /> : <ChevronUpSvg from="menu" />}
        </span>
      )}
    >
      {getDataFromStorage("role") !== constants.Roles.parent && (
        <MenuItem
          className="menuBarFooterMenuList"
          active={pathname.includes("doubts")}
          onClick={() => Alert.navigate("main/doubts")}
          prefix={<MenuSvgIcon menuId={constants.MENU_ICON.DOUBTS} />}
        >
          Ask Doubts
        </MenuItem>
      )}
      {Alert.showBlink && (
        <MenuItem
          className="menuBarFooterMenuList"
          onClick={() => getLatestSessions(getDataFromStorage("learnerid"))}
          prefix={<MenuSvgIcon menuId={constants.MENU_ICON.NOTIFICATION} />}
        >
          Attendance
        </MenuItem>
      )}
      <SubMenu
        className="menuBarFooterSubMenu"
        label={getDataFromStorage("name")}
        icon={
          <img
            className="menuBarFooterProfileImg"
            src={localStorage.getItem("picture")}
            alt={getDataFromStorage("name").substring(0, 1).toUpperCase()}
          />
        }
      >
        {getDataFromStorage("role") !== constants.Roles.parent && (
          <MenuItem
            className="menuBarFooterMenuList"
            active={pathname.includes("issue")}
            onClick={() => Alert.navigate("main/issue")}
            prefix={<MenuSvgIcon menuId={constants.MENU_ICON.ISSUE} />}
          >
            Raise an Issue
          </MenuItem>
        )}
        {getDataFromStorage("role") !== constants.Roles.hr &&
          getDataFromStorage("role") !== constants.Roles.parent &&
          parseInt(localStorage.getItem("batchType")) !==
            constants.BATCH_TYPE.TESTING && (
            <MenuItem
              className="menuBarFooterMenuList"
              onClick={() => Alert.navigate("/profile/personalinfo")}
              prefix={<MenuSvgIcon menuId={constants.MENU_ICON.PROFILE} />}
            >
              View Profile
            </MenuItem>
          )}
        <MenuItem
          onClick={logOut}
          className="menuBarFooterMenuList"
          prefix={<MenuSvgIcon menuId={constants.MENU_ICON.LOGOUT} />}
        >
          Logout
        </MenuItem>
      </SubMenu>
    </Menu>
  );
}
