import "./mainPage.css";
import JobCard from "./JobCard";
import Button from "../../Button";
import FilterJobs from "./FilterJobs";
import SearchJobs from "./SearchJobs";
import UploadJobs from "./UploadJobs";
import constants from "../../../constants";
import UploadSvg from "../../../Svg/UploadIconsvg";
import { getDataFromStorage } from "../../../util";
import AlertContext from "../../../context/AlertContext";
import React, { useContext, useEffect, useState } from "react";

export default function MainPage() {
  const Alert = useContext(AlertContext);

  const [role, setRole] = useState("learner");
  const [tcLoaded, setTcLoaded] = useState(null);

  // const handleUploadTcFile = () => {
  //   if (!tcLoaded?.size) return;
  //   let reader = new FileReader();
  //   reader.onload = async (e) => {
  //     const form = new FormData();
  //     form.append("csv", tcLoaded);
  //     // form.append("batchid", id);
  //     axios
  //       .post(
  //         `node/admin/jobs/upload`,
  //         form,
  //         commonFunction.getHeaderDetailsForAxios("multipart")
  //       )
  //       .then((res) => {
  //         if (res.data.resultCode === 1000) {
  //           Alert.setShow(true);
  //           Alert.setTitle("Success");
  //           Alert.setMsg(res.data.msg);
  //           setTcLoaded(null);
  //         } else if (res.data.resultCode === 2050) {
  //           Alert.setShow(true);
  //           Alert.setTitle("Warning !");
  //           Alert.setMsg(res.data.msg);
  //         } else {
  //           Alert.setShow(true);
  //           Alert.setTitle("Error");
  //           Alert.setMsg("Something went wrong. Please try again later.");
  //         }
  //       })
  //       .catch((error) => {
  //         if (error.message.includes("403")) {
  //           Alert.setShow(true);
  //           Alert.setIsUnAuth(true);
  //           Alert.setTitle("Error");
  //           Alert.setMsg(
  //             "You have been logged-out due to inactivity. Login again."
  //           );
  //         }
  //       });
  //   };

  //   reader.readAsDataURL(tcLoaded);
  // };

  // const handleUploadTcfile = (e) => {
  //   const file = e.target.files[0];
  //   if (file && file.name.endsWith(".xlsx")) {
  //     setTcLoaded(file);
  //   } else {
  //     Alert.setShow(true);
  //     Alert.setTitle("Error");
  //     Alert.setMsg("Please upload a valid .xlsx file.");
  //     e.target.value = "";
  //   }
  // };

  // const handleClearTcFile = () => {
  //   setTcLoaded(null);
  // };

  useEffect(() => {
    setRole(getDataFromStorage("role"));
    Alert.setCurrentEditJob({});
    Alert.setIsEditJob(false);
    Alert.setOpenJobForm(false);
  }, []);

  const handleUploadJobFile = (e) => {
    Alert.setShowNotify({
      show: true,
      size: "md",
      showClose: true,
      hideButton: true,
      title: "Jobs/Internships",
      msg: <UploadJobs tcLoaded={tcLoaded} setTcLoaded={setTcLoaded} />,
    });
  };
  return (
    <div
      className={
        !Alert.isAdmin ? "jobapplymainpagelearner" : "jobapplymainpageadmin"
      }
    >
      {!Alert.isAdmin && (
        <div className="headerJobs">
          <p className="headerTextForJobs">Jobs & Internships</p>
        </div>
      )}

      <div className={Alert.isAdmin ? "searchContAdmin" : "searchContLearner"}>
        <SearchJobs role={role} />
        <div style={{ display: "flex", gap: "12px" }}>
          {Alert.isAdmin && (
            <div>
              <Button
                size="lg"
                hierarchy={{
                  type: "secondaryGrey",
                  buttonText: "Add Job",
                }}
                icon={{
                  type: "leading",
                  icon: (
                    <svg
                      width="20"
                      height="20"
                      fill="none"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        stroke="#344054"
                        strokeWidth="1.67"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M10.0013 4.16699V15.8337M4.16797 10.0003H15.8346"
                      />
                    </svg>
                  ),
                }}
                onClick={() => {
                  Alert.setOpenJobForm(true);
                  Alert.navigate("/addjob");
                }}
              />
            </div>
          )}

          {(getDataFromStorage("role") === constants.Roles.admin ||
            getDataFromStorage("role") === constants.Roles.trainer) && (
            <>
              {tcLoaded == null && (
                <div className="upload-div">
                  <Button
                    size={"lg"}
                    disable={false}
                    onClick={handleUploadJobFile}
                    icon={{
                      type: "leading",
                      icon: <UploadSvg stroke="#344054" />,
                    }}
                    hierarchy={{
                      buttonText: "Upload",
                      type: "secondaryGrey",
                    }}
                  ></Button>
                </div>
              )}
            </>
          )}
        </div>
      </div>
      <div className={Alert.isAdmin ? "bottomContAdmin" : "bottomCont"}>
        <FilterJobs />
        <div
          className={
            Alert?.filteredJobs?.length > 0 ? "cardCont" : "noJobsFound"
          }
        >
          {Alert?.filteredJobs?.length > 0 ? (
            Alert.filteredJobs?.map((job) => <JobCard job={job} role={role} />)
          ) : (
            <span
              style={{
                color: "#667085",
                fontSize: "24px",
                textAlign: "center",
              }}
            >
              {" "}
              No Jobs/Internships Found{" "}
            </span>
          )}
        </div>
      </div>
    </div>
  );
}
