import Button from "../Button";
import Webcam from "react-webcam";
import React, { useContext } from "react";
import AlertContext from "../../context/AlertContext";
import Loading from "../Notification-Loading/Loading/Loading";
import RegistrationPageHooks from "../../Hooks/RegistrationPageHooks";
import useCommonFunctionHooks from "../../Hooks/useCommonFunctionHooks";

export default function RegistrationPage() {
  const Alert = useContext(AlertContext);

  const { handleWebCamError } = useCommonFunctionHooks();
  const { videoConstraints, takePicture, handleSkip } = RegistrationPageHooks();

  return (
    <div className="registrationPageMainContainer">
      {Alert.isLoaded && <Loading />}
      <div className="registrationPageContainer">
        <div className="registrationPageHeader">
          Register yourself before you start learning
          <p className="registrationPageSubHeader">
            As a first step, we need to capture a photo of you so that we know
            who the user is.
          </p>
        </div>
        <div className="registrationPageWebCamMainDiv">
          {Alert.camAvailable && (
            <div className="registrationPageWebCamDiv">
              <Webcam
                audio={false}
                ref={Alert.webCamRef}
                screenshotFormat="image/jpeg"
                className="registrationPageWebCam"
                videoConstraints={videoConstraints}
                onUserMediaError={handleWebCamError}
              />
              <Button
                size={"sm"}
                disable={!Alert.captureBtnHide}
                hierarchy={{
                  buttonText: "Capture",
                  type: "secondaryColor",
                }}
                onClick={(e) => {
                  e.preventDefault();
                  takePicture();
                }}
              />
            </div>
          )}
        </div>
        <div className="registrationPageInstruction">
          This will help us know who take the tests and do detect any
          malpractices like :
          <ul className="registrationPageInstructionUnorderedList">
            <li>
              A candidate is switching windows. In fact, your test will be
              automatically invalidated if the system finds this happening.
            </li>
            <li>
              Captures your photo, while you are taking the test. And checks for
              the candidate photo matching, multiple people in the frame, use of
              other electronic devices, and also candidates eye movements detect
              any kind of cheating.
            </li>
          </ul>
          <p>Please click on continue to start learning.</p>
        </div>
        <div className="registrationPageFooter">
          <Button
            size={"sm"}
            onClick={handleSkip}
            hierarchy={{
              type: "secondaryGrey",
              buttonText: "Skip for now",
            }}
          />
          <Button
            size={"sm"}
            disable={Alert.register.current !== 1 || !Alert.camAvailable}
            hierarchy={{
              type: "primary",
              buttonText: "Continue",
            }}
            onClick={() => {
              Alert.navigate("/home");
            }}
          />
        </div>
      </div>
    </div>
  );
}
