import constants from "../../constants";
import { useContext, useState } from "react";
import AlertContext from "../../context/AlertContext";
import { StudentTestPageContext } from "../../context/StudentTestPageContext";
import StudentTestPageQuestionUpdateHooks from "./StudentTestPageQuestionUpdateHooks";

export default function StudentTestBodyPageSingleViewHooks() {
  const Alert = useContext(AlertContext);
  const StudentTestPageCxt = useContext(StudentTestPageContext);

  const { questionUpdate } = StudentTestPageQuestionUpdateHooks();

  const [answers, setAnswers] = useState(Alert.answerRef.current);

  const handleCPC = (e) => {
    e.preventDefault();
  };

  const questionObjUpdate = (theObject) => {
    Alert.setQueObj(theObject);
  };

  const reviewLater = (from, current, obj) => {
    let tempPropObj = Alert.comingObj;
    let temp = obj;

    if (current === "review") {
      StudentTestPageCxt.setShowReview(false);
      tempPropObj.sections[Alert.secInd].questions[
        from === "single" ? Alert.queInd : obj
      ].visited =
        tempPropObj.sections[Alert.secInd].questions[
          from === "single" ? Alert.queInd : obj
        ].previsited;
      tempPropObj.sections[Alert.secInd].questions[
        from === "single" ? Alert.queInd : obj
      ].review = -1;
    } else {
      StudentTestPageCxt.setShowReview(true);
      tempPropObj.sections[Alert.secInd].questions[
        from === "single" ? Alert.queInd : obj
      ].visited = "review";
      tempPropObj.sections[Alert.secInd].questions[
        from === "single" ? Alert.queInd : obj
      ].review = 0;
    }

    if (from === "single") {
      questionUpdate(Alert.secInd, Alert.queInd, tempPropObj);
      Alert.setQueObj(temp);
      questionObjUpdate(Alert.queObj);
    }

    if (from === "multi") {
      StudentTestPageCxt.setSRender(obj);
      questionUpdate(Alert.secInd, Alert.queInd, tempPropObj);
      StudentTestPageCxt.setMulRevch(!StudentTestPageCxt.mulRevch);
    }

    Alert.updateComingObj(tempPropObj);
  };

  // const playQns = (text) => {
  //   let speech = new SpeechSynthesisUtterance();

  //   const voices = window.speechSynthesis.getVoices();

  //   let indianFemaleVoice = voices.find(
  //     (voice) =>
  //       voice.lang === "en-IN" && voice.name.toLowerCase().includes("female")
  //   );

  //   if (indianFemaleVoice) {
  //     speech.voice = indianFemaleVoice;
  //   } else {
  //     speech.voice = voices.find(
  //       (voice) => voice.name === "Google UK English Female"
  //     );
  //   }

  //   speech.text = text;
  //   window.speechSynthesis.speak(speech);
  // };

  const collectAnswers = (
    queid,
    ansid,
    libtypeid,
    secindex,
    queindex,
    datatype
  ) => {
    if (datatype === 1) {
      if (/^[\d.\s\n]*$/.test(ansid)) {
        StudentTestPageCxt.setErrorMessage(false);
      } else {
        StudentTestPageCxt.setErrorMessage(true);
        StudentTestPageCxt.setQuestionIdOffError(queid);
      }
    }

    let tempData = Alert.comingObj;

    /* need to see secindex */

    if (libtypeid === constants.libTypeIds.MCQ_SINGLE) {
      let temp = Alert.answerRef.current;

      temp = { ...temp, [queid]: ansid };
      setAnswers(temp);
      Alert.answerRef.current = temp;

      if (Alert.questionContainerSingle) {
        Alert.queObj.answered = 0;
        Alert.queObj.visited = "completed";
        Alert.queObj.previsited = "completed";
      } else {
        tempData.sections[Alert.secInd].questions[queindex].answered = 0;
        tempData.sections[Alert.secInd].questions[queindex].visited =
          "completed";
        tempData.sections[Alert.secInd].questions[queindex].previsited =
          "completed";
      }
    }

    if (libtypeid === constants.libTypeIds.MCQ_MULTI) {
      let temp = answers;
      let checkedAnswers = [];

      if (temp[queid] !== "") {
        let trimmedAnswer =
          temp[queid]?.length > 0
            ? temp[queid][0] === ","
              ? temp[queid].substring(1)
              : temp[queid]
            : "";

        temp[queid] = trimmedAnswer;
        checkedAnswers = temp[queid].split(",");

        const index = checkedAnswers.indexOf(ansid);

        if (index > -1) {
          // only splice array when item is found

          checkedAnswers.splice(index, 1); // 2nd parameter means remove one item only
        } else {
          checkedAnswers.push(ansid);
        }
      } else {
        checkedAnswers.push(ansid);
      }

      if (checkedAnswers.length === 0) {
        temp = { ...temp, [queid]: "" };
        setAnswers(temp);
        Alert.answerRef.current = temp;

        if (Alert.questionContainerSingle) {
          Alert.queObj.visited = "seen";
          Alert.queObj.previsited = "seen";
          Alert.queObj.answered = -1;
        } else {
          tempData.sections[Alert.secInd].questions[queindex].visited = "seen";
          tempData.sections[Alert.secInd].questions[queindex].previsited =
            "seen";
          Alert.queObj.answered = -1;
        }
      } else {
        temp = { ...temp, [queid]: checkedAnswers.toString() };
        setAnswers(temp);
        Alert.answerRef.current = temp;

        if (Alert.questionContainerSingle) {
          Alert.queObj.visited = "completed";
          Alert.queObj.previsited = "completed";
          Alert.queObj.answered = 0;
        } else {
          tempData.sections[Alert.secInd].questions[queindex].visited =
            "completed";
          tempData.sections[Alert.secInd].questions[queindex].previsited =
            "completed";
          tempData.sections[Alert.secInd].questions[queindex].answered = 0;
        }
      }
    }

    if (
      libtypeid === constants.libTypeIds.SAQ ||
      libtypeid === constants.libTypeIds.LAQ
    ) {
      let temp = answers;

      temp = { ...temp, [queid]: ansid };
      setAnswers(temp);
      Alert.answerRef.current = temp;
      
      if (ansid.length === 0) {
        if (Alert.questionContainerSingle) {
          Alert.queObj.visited = "not";
          Alert.queObj.previsited = "not";
          Alert.queObj.answered = -1;
        } else {
          tempData.sections[Alert.secInd].questions[queindex].visited = "not";
          tempData.sections[Alert.secInd].questions[queindex].previsited =
            "not";
          tempData.sections[Alert.secInd].questions[queindex].answered = -1;
        }
      } else {
        if (Alert.questionContainerSingle) {
          Alert.queObj.visited = "completed";
          Alert.queObj.previsited = "completed";
          Alert.queObj.answered = 0;
        } else {
          tempData.sections[Alert.secInd].questions[queindex].visited =
            "completed";
          tempData.sections[Alert.secInd].questions[queindex].previsited =
            "completed";
          tempData.sections[Alert.secInd].questions[queindex].answered = 0;
        }
      }

      Alert.updateComingObj(tempData);
    }
  };

  return {
    // playQns,
    answers,
    handleCPC,
    setAnswers,
    reviewLater,
    collectAnswers,
  };
}
