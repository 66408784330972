import React from "react";
import PropTypes from "prop-types";

export default function Radio({
  checked,
  value,
  size,
  text,
  supportingText,
  disabled,
  onChange,
}) {
  const handleRadioDivClick = () => {
    if (onChange) {
      const newValue = value !== undefined ? value : !checked;
      onChange(disabled ? checked : newValue);
    }
  };

  return (
    <div className="radioContainer" onClick={handleRadioDivClick}>
      <input
        value={value}
        className={size === "md" ? "radioInputMedium" : "radioInputSmall"}
        type="radio"
        disabled={disabled}
        checked={checked}
        onChange={(event) => {
          event.stopPropagation();
          handleRadioDivClick();
        }}
      />
      <div className={size === "md" ? "radioMedium" : "radioSmall"}>
        {(checked || checked === 1) &&
          (size === "md" ? (
            <svg
              id="radioIconMedium"
              width="8"
              height="8"
              viewBox="0 0 8 8"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle cx="4" cy="4" r="4" fill="#F55533" />
            </svg>
          ) : (
            <svg
              id="radioIconSmall"
              width="6"
              height="6"
              viewBox="0 0 6 6"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle cx="3" cy="3" r="3" fill="#F55533" />
            </svg>
          ))}
      </div>
      <div
        className={
          size === "md" ? "radioLabelSectionMedium" : "radioLabelSectionSmall"
        }
      >
        <span
          className={`${
            size === "md" ? "radioLabelMedium" : "radioLabelSmall"
          }`}
          id={supportingText ? "" : "radioLabel"}
        >
          {text}
        </span>
        {supportingText && (
          <span
            className={
              size === "md" ? "radioSupportTextMedium" : "radioSupportTextSmall"
            }
          >
            {supportingText}
          </span>
        )}
      </div>
    </div>
  );
}

Radio.propTypes = {
  size: PropTypes.oneOf(["md", "sm"]).isRequired,
  disabled: PropTypes.bool,
  checked: PropTypes.bool.isRequired,
  text: PropTypes.string.isRequired,
  supportingText: PropTypes.string,
  onChange: PropTypes.func.isRequired,
};

Radio.defaultProps = {
  disabled: false,
  supportingText: "",
};
