import React, { useCallback, useContext, useEffect, useRef } from 'react'
import AlertContext from '../../context/AlertContext';
import useCommonFunctionHooks from '../../Hooks/useCommonFunctionHooks';
import { getDataFromStorage } from '../../util';
import useAxiosPrivate from '../../Hooks/useAxiosPrivate';
import Webcam from "react-webcam";

const videoConstraints = {
    width: 1280,
    height: 720,
    facingMode: "user",
  };

export default function Verifypic(Props) {
    const axios = useAxiosPrivate();
    const Alert = useContext(AlertContext);
    const useCommonFunctionHks = useCommonFunctionHooks();

    const webcamRef = useRef(null);

      const capture = useCallback(
        (from) => {
          let pictureSrc = webcamRef.current.getScreenshot();
    
          if (from === "verifypic") {
            handleSavePicture(pictureSrc);
          }
        },
        [webcamRef]
      );

      useEffect(() => {
        let interval;
    
        const time = 30000;
    
        if (Props.isTakeTest) {
          interval = setInterval(() => capture("verifypic"), time);
        } else {
          clearInterval(interval);
        }
    
        return () => clearInterval(interval);
      }, [Props.isTakeTest, capture]);

      const handleWebCamError = () => {
        Alert.setCamAvailable(false);
      };

      const handleSavePicture = async (pic) => {
      
        let payload = {
            image: pic.split(",")[1],
            batchid: localStorage.getItem("batchid"),
            learnerid: getDataFromStorage("learnerid"),
        };
    
        await axios
            .post(
            "node/learner/pic/check",
            payload,
            useCommonFunctionHks.getHeaderDetailsForAxios()
            )
            .then((res) => {
                console.log("resssssssssss ", res);
                Alert.setVerifyMsg(res.data);
            // if (
            //     !Alert.stopProctoring.current.flag &&
            //     !Alert.stopProctoring.current.stop &&
            //     (res.data.Mobile_Found ||
            //     res.data.Multi_People_Found ||
            //     res.data.message === "Face not matched") &&
            //     res.data.resultCode !== constants.RESULT_STATUS.SUCCESS &&
            //     res.data.resultCode !== constants.RESULT_STATUS.TIMEOUT
            // ) {
            //     Alert.setShowNotify({
            //     size: "sm",
            //     show: true,
            //     timer: true,
            //     backdrop: true,
            //     position: "top",
            //     hideButton: true,
            //     title: "Warning",
            //     msg: (
            //         <NotifyBody component="proctoringDataWarning" data={res.data} />
            //     ),
            //     });
            // }
            })
            .catch((error) => {
            console.error("Error:", error);
            });
        };


  return (
    <div>
        <div className="camVideo">
            <Webcam
                width={300}
                height={300}
                audio={false}
                ref={webcamRef}
                screenshotFormat="image/jpeg"
                style={{ borderRadius: "30px" }}
                videoConstraints={videoConstraints}
                onUserMediaError={handleWebCamError}
            />
        </div>
    </div>
  )
}
