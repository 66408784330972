import constants from "../../constants";

export const getLocation = (location) => {
  let updtaedLocations = [];
  let locationsfromdb = String(location).split(",");

  for (let i = 0; i < locationsfromdb.length; i++) {
    let curLoc = constants.locations.filter(
      (item) => item.value == locationsfromdb[i]
    );

    updtaedLocations.push(curLoc[0]?.label);
  }

  return updtaedLocations.join(", ");
};

export const getJobType = (type) => {
  const found = constants.workmode.find((item) => item.value === type);

  return found ? found.label : null;
};
