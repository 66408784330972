import React, { useContext, useEffect, useRef, useState } from "react";
import CreatableSelect from "react-select/creatable";
import { FormControlLabel, Checkbox } from "@mui/material";
import { Editor } from "@tinymce/tinymce-react";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { TemplateSelect } from "../../utils/SandpackEditor";
import { Modal, Tab, Nav } from "react-bootstrap";
import DataTable from "react-data-table-component";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowUpFromBracket,
  faArrowLeft,
  faCircleInfo,
  faFileAlt,
  faTrash,
  faTrashCan,
} from "@fortawesome/free-solid-svg-icons";
import "./assignmentForm.scss";
import { useNavigate } from "react-router-dom";
import { nanoid } from "nanoid";
import { getDataFromStorage, getPreSignedUrl } from "../../../util";
import AlertContext from "../../../context/AlertContext";
import constants from "../../../constants";
import useUploadLargeFileToS3 from "../../../Hooks/useUploadLargeFileToS3";
import useAddOrUpdateLp from "../../../Hooks/useAddOrUpdateLp";
import useAxiosPrivate from "../../../Hooks/useAxiosPrivate";
import SandPackEditor from "../../Student/AssignmentPage/SandPackEditor";
import SandpackReactEditor from "../../Student/AssignmentPage/SandpackReactEditor";

const customStylesForTopic = {
  control: (base) => ({
    ...base,
    border: "1px solid #BDC1C6",
    height: "34px !important",
    width: "350px",
    marginTop: "10px",
  }),
  option: (styles, { isSelected }) => {
    return {
      ...styles,
      background: "white",
      ":hover": {
        backgroundColor: "#0d6efd",
        color: "white",
      },
      color: isSelected ? "#000000" : undefined,
      width: "340px",
      height: "36px",
      fontFamily: "Inter",
      fontWeight: "400",
      fontSize: "14px",
      lineHeight: "20px",
    };
  },
  placeholder: (styles) => ({
    ...styles,
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "14px",
    lineHeight: "20px",
  }),
  singleValue: (styles) => ({
    ...styles,
    color: "#202124",
    borderRadius: "20px",
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "14px",
    lineHeight: "20px",
    textAlign: "left",
    paddingLeft: "10px",
  }),
  menuList: (base) => ({
    ...base,
    textAlign: "left",
    width: "350px",
    zIndex: 5,
  }),
};

const customStyles = {
  headCells: {
    style: {
      fontSize: "12px",
      color: "#9AA0A6",
      fontWeight: "500",
      lineHeight: "15px",
      position: "sticky",
      fontFamily: "Inter",
      fontStyle: "normal",
      background: "#FFFFFF",
      textTransform: "uppercase",
    },
  },
  cells: {
    style: {
      color: "#202124",
      fontSize: "14px",
      fontWeight: "400",
      lineHeight: "20px",
      fontStyle: "normal",
      fontFamily: "Inter",
      background: "#FFFFFF",
    },
  },
};

const columns = [
  {
    name: "Files",
    selector: (row) => (
      <a
        target="_blank"
        className="uploadlibTaps"
        href={process.env.REACT_APP_NODE_API + row.link}
        rel="noreferrer"
      >
        {row.link.substring(row.link.lastIndexOf("/") + 1, row.link.length)}
      </a>
    ),
  },
];

const AssignmentForm = (props) => {
  const {
    setIsUnAuth,
    setMsg,
    setShow,
    setTitle,
    setIsLoaded,
    sandpackFiles,
    fromList,
    setFromList,
    lpData2,
    isOldLpDetail,
    handleNavigateToLpAfterAddTest,
    setLpData2,
    isFromBatch,
    setIsFromLp,
    openSandpackEditor,
    setOpenSandpackEditor,
  } = useContext(AlertContext);
  const { uploadLargeFileToS3, uploadFileToS3 } = useUploadLargeFileToS3();
  const { handleAddOrUpdateLp } = useAddOrUpdateLp();
  const axios = useAxiosPrivate();
  const [topics, setTopics] = useState([]);
  const [showSandpackOptions, setShowSandpackOptions] = useState(false);
  const [formData, setFormData] = useState(
    props.isqueReport
      ? { ...props.selectque, maxtime: props.selectque.timelimit / 60 }
      : {
          topic: "",
          name: "",
          maxtime: "",
          marks: "",
          description: "",
        }
  );
  const [embedLink, setEmbedLink] = useState("");
  const [embedLinksList, setEmbedLinksList] = useState([]);
  const [filesFromComputer, setFilesFromComputer] = useState([]);
  const [filesFromLib, setFilesFromLib] = useState([]);
  const [showFromLibrary, setShowFromLibrary] = useState(false);
  const [filesForLibrary, setFilesForLibrary] = useState([]);
  const [selectedFilesFromLibrary, setSelectedFilesFromLibrary] = useState([]);
  // const [openSandpackEditor, setOpenSandpackEditor] = useState(false);
  const [selectedTemplate, setSeletedTemplate] = useState({
    id: "",
    value: "",
  });

  const editorRef = useRef(null);

  const navigate = useNavigate();
  console.log("::lpdata", lpData2);
  useEffect(() => {
    if (props.isqueReport) {
      setSeletedTemplate(
        !props.selectque.hasOwnProperty("template") ||
          props.selectque.template === null
          ? { id: "", value: "" }
          : props.selectque.template
      );
      setShowSandpackOptions(
        !props.selectque.hasOwnProperty("template") ||
          props.selectque.template === null
          ? false
          : true
      );
      setEmbedLinksList(
        props.selectque.embedLinks ? props.selectque.embedLinks : []
      );
    } else return;
  }, [
    props.isqueReport,
    setSeletedTemplate,
    props.selectque,
    setShowSandpackOptions,
    openSandpackEditor,
  ]);

  useEffect(() => {
    console.log("props.selectque>>>>", props.selectque);
    if (props.isqueReport) {
      let links =
          props.selectque?.link !== null && props.selectque?.link?.length > 0
            ? [...props.selectque.link]
            : [],
        fileDetails = [];
      for (let link = 0; link < links.length; link++) {
        fileDetails.push({
          file: {
            name: links[link].link.substring(
              links[link].link.lastIndexOf("/") + 1,
              links[link].link.length
            ),
          },
          checked: links[link].checked,
          url: `${process.env.REACT_APP_NODE_API}${links[link].link}`,
          link: links[link].link,
        });
      }
      setFilesFromLib(fileDetails);
    } else return;
  }, [props.isqueReport, props.selectque]);

  useEffect(() => {
    const getTopics = async () => {
      setIsLoaded(true);
      // const controller = new AbortController();
      try {
        let response = await axios.get(`node/admin/topic/get/${0}`, {
          headers: {
            "Content-type": "application/json",
          },
          // signal: controller.signal
        });
        console.log("response", response);
        setTopics(
          response.data.map((topic) => {
            return {
              label: topic.name,
              value: topic.id,
            };
          })
        );
        if (props.isqueReport) {
          let filterTopic = response.data.filter(
            (topic) => topic.id === props.selectque.topicid
          );
          let topic =
            filterTopic.length > 0
              ? { value: filterTopic[0].id, label: filterTopic[0].name }
              : { value: "", label: "" };
          setFormData((data) => {
            return { ...data, topic: topic };
          });
        }
      } catch (error) {
        setShow(true);
        setTitle("Error");
        if (error.message.includes("403")) {
          setIsUnAuth(true);
          setMsg("You have been logged-out due to inactivity. Login again.");
        } else setMsg("Something went wrong. Please try again later.");
      } finally {
        setIsLoaded(false);
      }
      // controller.abort()
    };
    getTopics();
    console.log(
      "isOldLpDetail in Assignment",
      isOldLpDetail,
      getDataFromStorage("role")
    );
    return () => getTopics();
  }, []);

  const handleFormChange = (event, field) => {
    if (!field)
      setFormData((data) => {
        return { ...data, [event.target.name]: event.target.value };
      });
    else
      setFormData((data) => {
        return { ...data, [field]: event };
      });
  };

  const handleAddEmbedLinks = () => {
    if (embedLink) {
      let links = [...embedLinksList];
      links.push(embedLink);
      setEmbedLinksList(links);
    }
    setEmbedLink("");
  };

  const handleDeleteEmbeddedLink = (index) => {
    let links = [...embedLinksList];
    links.splice(index, 1);
    setEmbedLinksList(links);
    setEmbedLink("");
  };

  const handleOpenEditor = (event) => {
    event.preventDefault();
    if (selectedTemplate.id === "" || selectedTemplate.id === null) {
      setShow(true);
      setTitle("Warning");
      setMsg("Please select the template");
    } else setOpenSandpackEditor(true); //navigate("/assignment/sandpack")
  };

  const handleAddFiles = () => {
    let newFiles = [...selectedFilesFromLibrary];
    let oldFiles = [...filesFromLib];
    for (let file = 0; file < newFiles.length; file++) {
      oldFiles.push({
        file: {
          name: newFiles[file].link.substring(
            newFiles[file].link.lastIndexOf("/") + 1,
            newFiles[file].link.length
          ),
        },
        checked: false,
        url: `${process.env.REACT_APP_NODE_API}${newFiles[file].link}`,
        link: newFiles[file].link,
      });
    }
    setFilesFromLib(oldFiles);
    setShowFromLibrary(false);
  };

  const handleBackBtn = () => {
    let isUpdateState = true;
    if (isFromBatch) {
      navigate(`/batches/${sessionStorage.getItem("abatchid")}/courses`);
    } else if (fromList.isFromList || isOldLpDetail) {
      navigate("/learningpath");
    } else {
      navigate(`/library/${constants.libTypeIds.ASSIGNMENT}/questionlist`);
      isUpdateState = false;
    }
    if (isUpdateState) {
      props.setselectque([]);
      setIsFromLp(false);
      props.setisqueReport(false);
      setFromList({ isFromList: false, libType: "" });
    }
  };

  const handleSubmit = async (event, from) => {
    event.preventDefault();
    let form = new FormData(),
      payloadData = { ...formData },
      fileDownloadDetails = [],
      fileNames = [],
      qnData = { result: "", success: true },
      isProceed = true,
      numberPattern = /^\d+$/;
    if (
      payloadData.topic.value === "" ||
      payloadData.topic.value === null ||
      payloadData.topic.value === undefined
    ) {
      isProceed = false;
      setShow(true);
      setTitle("Warning");
      setMsg("Please select the topic");
    } else if (
      payloadData.name === "" ||
      payloadData.name === null ||
      payloadData.name === undefined
    ) {
      isProceed = false;
      setShow(true);
      setTitle("Warning");
      setMsg("Please give the name for assignment");
    } else if (
      showSandpackOptions &&
      (selectedTemplate.id === "" || selectedTemplate.id === null)
    ) {
      isProceed = false;
      setShow(true);
      setTitle("Warning");
      setMsg("Please select the template");
    } else if (
      payloadData.maxtime === "" ||
      payloadData.maxtime === null ||
      payloadData.maxtime === undefined ||
      payloadData.maxtime <= 0 ||
      !numberPattern.test(payloadData.maxtime)
    ) {
      isProceed = false;
      setShow(true);
      setTitle("Warning");
      setMsg(
        !numberPattern.test(payloadData.maxtime) || payloadData.maxtime <= 0
          ? "Invalid maximum time"
          : "Please give the name for assignment"
      );
    } else if (
      payloadData.marks === "" ||
      payloadData.marks === null ||
      payloadData.marks === undefined ||
      payloadData.marks <= 0 ||
      !numberPattern.test(payloadData.marks)
    ) {
      isProceed = false;
      setShow(true);
      setTitle("Warning");
      setMsg(
        !numberPattern.test(payloadData.marks) || payloadData.marks <= 0
          ? "Invalid marks"
          : "Please give the name for assignment"
      );
    }

    if (props.isqueReport && from === "new") {
      delete payloadData.id;
    }

    if (isProceed) {
      setIsLoaded(true);
      if (typeof payloadData.topic.value === "number")
        qnData.topic = payloadData.topic.value;
      else qnData.topicname = payloadData.topic.value;
      delete payloadData.topic;
      payloadData.maxtime = payloadData.maxtime * 60;
      qnData = { ...qnData, ...payloadData };
      if (showSandpackOptions) qnData.template = selectedTemplate;
      form.append("qdata", JSON.stringify({ qdata: qnData }));
      form.append("libtypeid", constants.libTypeIds.ASSIGNMENT);
      form.append("libtypename", "assignment");
      if (embedLinksList.length > 0) form.append("embedLinks", embedLinksList);
      if (filesFromComputer.length > 0) {
        form.append("hasfiles", "yes");
        for (let file = 0; file < filesFromComputer.length; file++) {
          fileNames.push(filesFromComputer[file].file.name);
          fileDownloadDetails.push({
            [filesFromComputer[file].file.name]:
              filesFromComputer[file].checked,
          });
          let filePath = "library/misc/" + filesFromComputer[file].file.name;
          if (process.env.REACT_APP_DEPLOYMENT === "AWS")
            await uploadLargeFileToS3(
              filesFromComputer[file].file,
              filePath,
              filesFromComputer[file].file.name.replace(/^.*\./, "")
            );
          else
            form.append(
              filesFromComputer[file].file.name,
              filesFromComputer[file].file
            );
        }
      }
      if (filesFromLib.length > 0)
        form.append("links", JSON.stringify(filesFromLib));
      form.append("fileNames", JSON.stringify(fileNames));

      form.append("isfiledownloadable", JSON.stringify(fileDownloadDetails));
      // const controller = new AbortController();
      axios({
        data: form,
        method: "post",
        url: "node/admin/library/add",
        headers: {
          "Content-Type": "multipart/form-data",
        },
        // signal: controller.signal
      })
        .then(async (res) => {
          if (showSandpackOptions) {
            if (selectedTemplate.value.toLowerCase() !== "react") {
              let files = JSON.parse(JSON.stringify(sandpackFiles));
              files["/index.js"] = {
                code: `//Don't remove the below code
// If you remove, console won't show up.
console.log = function(message) { 
  parent.postMessage({'message':message},"*");
}
console.error = console.debug = console.info = console.log;`,
              };
              for (let file in files) {
                await uploadFileToS3(
                  files[file]?.code,
                  `${process.env.REACT_APP_WEBSITE_HOST}/library/${res.data.data.libid}${file}`,
                  files[file]?.code.slice(
                    files[file]?.code.lastIndexOf(".") + 1
                  ),
                  "uploadAssignment"
                );
              }
            } else if (selectedTemplate.value.toLowerCase() === "react") {
              await uploadFileToS3(
                JSON.stringify(sandpackFiles),
                `${process.env.REACT_APP_WEBSITE_HOST}/library/${res.data.data.libid}/${res.data.data.libid}.json`,
                "json",
                "uploadAssignment"
              );
            }
          }
          if (res.data.resultCode === 1000) {
            if (fromList.isFromList) {
              setFromList({ isFromList: false, libType: "" });

              let newDetails = JSON.parse(JSON.stringify(lpData2)),
                uniqueId = 1,
                libraryData = {
                  isNew: true,
                  published: 0,
                  isEdited: false,
                  title: payloadData.name,
                  id: res.data.data.libid,
                  libTypeId: lpData2.libTypeId,
                  topics: [
                    {
                      topicid: payloadData.topic,
                    },
                  ],
                };

              if (isOldLpDetail) libraryData.status = "new";

              for (let i = 0; i < newDetails.modules.length; i++) {
                if (newDetails.modules[i].id === newDetails.moduleId)
                  newDetails.modules[i].moduleData.push(libraryData);

                for (
                  let j = 0;
                  j < newDetails.modules[i].moduleData.length;
                  j++
                ) {
                  newDetails.modules[i].moduleData[j].moduleDataId = uniqueId;
                  newDetails.modules[i].moduleData[j].key = nanoid();
                  uniqueId++;
                }

                newDetails.modules[i].id = uniqueId;
                uniqueId++;
              }

              newDetails.isAddTest = false;
              handleNavigateToLpAfterAddTest(newDetails);
            } else if (isOldLpDetail) {
              let newDetails = JSON.parse(JSON.stringify(lpData2));

              for (let i = 0; i < newDetails.modules.length; i++) {
                if (newDetails.modules[i].id === newDetails.moduleId) {
                  for (
                    let j = 0;
                    j < newDetails.modules[i].moduleData.length;
                    j++
                  ) {
                    if (
                      newDetails.modules[i].moduleData[j] &&
                      payloadData.id ===
                        newDetails.modules[i].moduleData[j].libId
                    ) {
                      newDetails.modules[i].moduleData[j].libId =
                        res.data.data.libid;
                      newDetails.modules[i].moduleData[j].title =
                        payloadData.name;
                      newDetails.modules[i].moduleData[j].testDuration =
                        payloadData.maxtime;
                      newDetails.modules[i].moduleData[j].moved = "yes";
                    }
                  }
                }
              }

              setLpData2(newDetails);

              return (async () => {
                try {
                  let res = await handleAddOrUpdateLp({}, newDetails.modules);

                  if (res.title === "success") {
                    setShow(true);
                    setTitle("Success");
                    setMsg(res.message);
                    // handleNavigateToLpAfterAddTest(newDetails);
                  }
                } catch (err) {
                  setIsUnAuth(err.hasOwnProperty("isUnAuth"));
                  setShow(true);
                  setTitle("Error");
                  setMsg(err.message);
                }
              })();
            } else {
              setShow(true);
              setTitle("Success");
              setMsg(res.data.message);
              navigate(
                `/library/${constants.libTypeIds.ASSIGNMENT}/questionlist`
              );
            }
          } else {
            setShow(true);
            setTitle("Error");
            setMsg("Something went wrong. Please try again later.");
          }
        })
        .catch((error) => {
          console.log("err", error);
          setShow(true);
          // setTitle("Error");
          // if (error.message.includes("403")) {
          setIsUnAuth(true);
          setMsg("You have been logged-out due to inactivity. Login again.");
          // } else setMsg("Something went wrong. Please try again later.");
        })
        .finally(() => {
          setIsLoaded(false);
        });
      // controller.abort()
    }
  };

  return (
    <section
      className={
        openSandpackEditor
          ? "adminAssignmentContainerSandpack"
          : "adminAssignmentContainer"
      }
    >
      {openSandpackEditor ? (
        selectedTemplate.value?.toLowerCase() === "react" ? (
          <SandpackReactEditor
            isqueReport={props.isqueReport}
            selectedTemplate={selectedTemplate}
            openSandpackEditor={openSandpackEditor}
            setOpenSandpackEditor={setOpenSandpackEditor}
            learnerId
            libDetails={props.selectque}
          />
        ) : (
          <SandPackEditor
            isqueReport={props.isqueReport}
            selectedTemplate={selectedTemplate}
            openSandpackEditor={openSandpackEditor}
            setOpenSandpackEditor={setOpenSandpackEditor}
            learnerId
            libDetails={props.selectque}
          />
        )
      ) : (
        <form className="adminAssignmentForm">
          <div className="adminAssignmentBackBtn">
            <FontAwesomeIcon
              icon={faArrowLeft}
              onClick={handleBackBtn}
              className="adminTestBackBtn"
            />
            &nbsp;
            <span className="adminTestBack" onClick={handleBackBtn}>
              Back
            </span>
          </div>
          <div className="adminAssignmentTopicSec">
            <label
              className="adminAssignementTopicLabel"
              htmlFor="adminAssignmentTopic"
            >
              Topic
            </label>
            <div style={{ marginLeft: "20px" }}>
              <CreatableSelect
                name="topic"
                id="adminAssignmentTopic"
                className="adminAssignmentTopic"
                isClearable
                onChange={(event) => handleFormChange(event, "topic")}
                options={topics}
                value={formData.topic}
                styles={customStylesForTopic}
                components={{ IndicatorSeparator: () => null }}
              />
            </div>
          </div>
          <div className="adminAssignmentNameSec">
            <label
              htmlFor="adminAssignmentName"
              className="adminAssignmentNameLabel"
            >
              Name
            </label>
            <input
              type="text"
              name="name"
              className="adminAssignmentName"
              id="adminAssignmentName"
              value={formData.name}
              onChange={handleFormChange}
              placeholder="Enter the assignment name"
            />
          </div>
          <div className="adminAssignmentInstructionSec">
            <label
              htmlFor="adminAssignmentInstruction"
              className="adminAssignmentInstructionLabel"
            >
              Instruction
            </label>
            <Editor
              tinymceScriptSrc="/tinymce/tinymce.min.js"
              id="adminAssignmentInstruction"
              className="adminAssignmentInstruction"
              onEditorChange={(event) => handleFormChange(event, "description")}
              value={formData.description}
              onInit={(evt, editor) => (editorRef.current = editor)}
              init={{
                license_key: "gpl",
                readonly: true,
                menubar: false,
                branding: false,
                statusbar: false,
                elementpath: false,
                selector: "textarea",
                allow_html_in_named_anchor: true,
                plugins: [
                  "link",
                  "help",
                  "code",
                  "lists",
                  "image",
                  "table",
                  "media",
                  "anchor",
                  "editor",
                  "advlist",
                  "preview",
                  "charmap",
                  "wordcount",
                  "fullscreen",
                  "autoresize",
                  "visualblocks",
                  "searchreplace",
                  "insertdatetime",
                ],
                toolbar:
                  "undo redo | blocks | " +
                  "bold italic underline forecolor | link | alignleft aligncenter " +
                  "alignright alignjustify | bullist numlist outdent indent | " +
                  "removeformat | image | table tabledelete | tableprops tablerowprops tablecellprops | tableinsertrowbefore tableinsertrowafter tabledeleterow | tableinsertcolbefore tableinsertcolafter tabledeletecol | help |",
                content_style:
                  "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
              }}
            />
          </div>
          <div className="adminAssignmentEmbeddedLinkSec">
            <label
              htmlFor="adminAssignmentEmbeddedLink"
              className="adminAssignmentEmbeddedLinkLabel"
            >
              Embedded Links
            </label>
            <div className="adminAssignmentEmbeddedLinkInputSec">
              <input
                type="text"
                id="adminAssignmentEmbeddedLink"
                name="embedLink"
                value={embedLink}
                className="adminAssignmentEmbeddedLink"
                placeholder="Type something here"
                onChange={(event) => setEmbedLink(event.target.value)}
              />
              <AddCircleOutlineIcon
                id="adminAssignmentEmbeddedLinkAddBtn"
                onClick={handleAddEmbedLinks}
              />
            </div>
          </div>
          <div className="adminAssignmentEmbeddedLinkViewSec">
            <ul className="adminAssignmentEmbeddedLinkList">
              {embedLinksList?.map((text, index) => (
                <li key={index}>
                  <span>{text}</span>
                  <FontAwesomeIcon
                    className="faTrashCan"
                    id="adminAssignmentEmbeddedLinkItemTrashIcon"
                    icon={faTrashCan}
                    onClick={() => handleDeleteEmbeddedLink(index)}
                  />
                </li>
              ))}
            </ul>
          </div>
          <div className="adminAssignmentSandpackContainer">
            <div className="adminAssignmentSandpackSec">
              <FormControlLabel
                control={
                  <Checkbox
                    checked={showSandpackOptions}
                    onChange={(event) =>
                      setShowSandpackOptions(event.target.checked)
                    }
                    inputProps={{ "aria-label": "controlled" }}
                  />
                }
                label="Submit assignment on sandpack editor"
              />
            </div>
            {showSandpackOptions ? (
              <React.Fragment>
                <TemplateSelect
                  setSeletedTemplate={setSeletedTemplate}
                  selectedTemplate={selectedTemplate}
                  showSandpackOptions={showSandpackOptions}
                />
                <div className="adminAssignmentOpenSanpackBtn">
                  <button
                    className="btnMediumSecondary"
                    onClick={(event) => handleOpenEditor(event)}
                  >
                    Code Editor
                  </button>
                </div>
              </React.Fragment>
            ) : null}
          </div>
          <div className="adminAssignmentMaxtimeSec">
            <label
              htmlFor="adminAssignmentMaxtime"
              className="adminAssignmentMaxtimeLabel"
            >
              Max time( hours )
            </label>
            <input
              required
              name="maxtime"
              id="adminAssignmentMaxtime"
              type="number"
              className="adminAssignmentMaxtime"
              placeholder="Maxtime"
              value={formData.maxtime}
              onChange={handleFormChange}
            />
          </div>
          <div className="adminAssignmentMarkSec">
            <label
              htmlFor="adminAssignmentMark"
              className="adminAssignmentMarkLabel"
            >
              Marks
            </label>
            <input
              required
              name="marks"
              id="adminAssignmentMark"
              type="number"
              className="adminAssignmentMark"
              placeholder="Marks"
              value={formData.marks}
              onChange={handleFormChange}
            />
          </div>
          <div className="adminAssignmentRefFileContainer">
            <div>
              <UploadComponent
                text="Browse Files to upload"
                showCaption={true}
                files={filesFromComputer}
                setFiles={setFilesFromComputer}
              />
              <FileContainer
                files={filesFromComputer}
                setFiles={setFilesFromComputer}
              />
            </div>
            <div style={{ position: "relative", width: "35%", left: "100px" }}>
              <UploadComponent
                text="Upload Files to upload"
                showCaption={false}
                files={filesFromLib}
                setFiles={setFilesFromLib}
                setShowFromLibrary={setShowFromLibrary}
                setFilesForLibrary={setFilesForLibrary}
              />
              <FileContainer
                files={filesFromLib}
                setFiles={setFilesFromLib}
                setShowFromLibrary={setShowFromLibrary}
              />
            </div>
          </div>
          <div className="adminAssignmentBtnSec">
            {getDataFromStorage("role") === "admin" && props.isqueReport && (
              <button
                type="submit"
                className="adminAssignmentUpdateBtn"
                onClick={(e) => handleSubmit(e, "update")}
              >
                Update
              </button>
            )}
            {(!isOldLpDetail || fromList.isFromList) && (
              <button
                type="submit"
                className="adminAssignmentSaveBtn"
                onClick={(e) => handleSubmit(e, "new")}
              >
                {props.isqueReport ? "Save As New" : "Save"}
              </button>
            )}
          </div>
          <Modal
            show={showFromLibrary}
            onHide={() => setShowFromLibrary(false)}
            centered
          >
            <div>
              <Tab.Container defaultActiveKey="first">
                <Nav variant="pills">
                  <Nav.Item className="question-navItem">
                    <Nav.Link eventKey="first">Content</Nav.Link>
                  </Nav.Item>
                  <Nav.Item className="question-navItem">
                    <Nav.Link eventKey="second">Misc</Nav.Link>
                  </Nav.Item>
                </Nav>

                <Tab.Content>
                  <Tab.Pane eventKey="first">
                    <DataTable
                      selectableRows
                      columns={columns}
                      customStyles={customStyles}
                      className="customDatatable"
                      data={filesForLibrary.contentlinksdata}
                      onSelectedRowsChange={(state) =>
                        setSelectedFilesFromLibrary(state.selectedRows)
                      }
                    />
                  </Tab.Pane>
                  <Tab.Pane eventKey="second">
                    <DataTable
                      selectableRows
                      columns={columns}
                      customStyles={customStyles}
                      className="customDatatable"
                      data={filesForLibrary.misclinksdata}
                      onSelectedRowsChange={(state) =>
                        setSelectedFilesFromLibrary(state.selectedRows)
                      }
                    />
                  </Tab.Pane>
                </Tab.Content>
                <button
                  type="button"
                  id="addLibrary"
                  onClick={handleAddFiles}
                  className="btn btn-primary"
                >
                  <span>Add</span>
                </button>
              </Tab.Container>
            </div>
          </Modal>
        </form>
      )}
    </section>
  );
};

const UploadComponent = (props) => {
  const { setIsUnAuth, setMsg, setShow, setTitle, setIsLoaded } =
    useContext(AlertContext);
  const axios = useAxiosPrivate();

  const handleUpload = async (event) => {
    if (props.showCaption) {
      let newFiles = Array.from(event.target.files);
      let oldFiles = [...props.files];
      console.log(newFiles[0].name);
      for (let file = 0; file < newFiles.length; file++) {
        oldFiles.push({
          file: newFiles[file],
          checked: false,
          url: URL.createObjectURL(newFiles[file]),
          link: `library/misc/${newFiles[file].name}`,
        });
      }
      props.setFiles(oldFiles);
      event.target.value = "";
    } else {
      setIsLoaded(true);
      try {
        let response = await axios.get("node/admin/library/getlinks", {
          headers: {
            "Content-type": "application/json",
          },
        });
        props.setFilesForLibrary(response.data);
        props.setShowFromLibrary(true);
      } catch (error) {
        setShow(true);
        setTitle("Error");
        if (error.message.includes("403")) {
          setIsUnAuth(true);
          setMsg("You have been logged-out due to inactivity. Login again.");
        } else setMsg("Something went wrong. Please try again later.");
      } finally {
        setIsLoaded(false);
      }
    }
  };

  return (
    <div
      className={
        props.showCaption
          ? "adminAssignmentRefFileSec"
          : "adminAssignmentRefFileSec2"
      }
    >
      {props.showCaption ? (
        <input
          multiple
          type="file"
          name="file"
          onChange={handleUpload}
          className="adminAssignmentRefFileInput"
        />
      ) : (
        <input
          multiple
          type="file"
          name="file"
          onClick={(event) => {
            handleUpload();
            event.preventDefault();
          }}
          className="adminAssignmentRefFileInput2"
        />
      )}
      <div className="adminAssignmentRefFileInputSec">
        <div className="adminAssignmentRefFileInputLabel">
          <FontAwesomeIcon
            icon={faArrowUpFromBracket}
            className="adminAssignmentUploadFileIcon"
          />
          {props.text}
        </div>
        {props.showCaption ? (
          <div className="adminAssignmentRefFileInputCaption">
            If you want to make the file downloadable please click the checkbox{" "}
            <FontAwesomeIcon
              icon={faCircleInfo}
              className="adminAssignmentInfoIconforDownload"
            />
          </div>
        ) : null}
      </div>
    </div>
  );
};

const FileContainer = (props) => {
  const { setIsUnAuth, setMsg, setShow, setTitle } = useContext(AlertContext);

  const handleFileDownloadChange = (checked, index) => {
    let files = [...props.files];
    files[index].checked = checked;
    props.setFiles(files);
  };
  const handleDeleteFile = (index) => {
    let files = [...props.files];
    files.splice(index, 1);
    props.setFiles(files);
  };

  const handleGetPreSignedUrl = (linkData) => {
    getPreSignedUrl(linkData)
      .then((res) => {
        window.open(res, "_blank", "noreferrer");
      })
      .catch((err) => {
        if (err.message.includes("403")) {
          setShow(true);
          setIsUnAuth(true);
          setTitle("Error");
          setMsg("You have been logged-out due to inactivity. Login again.");
        }
      });
  };

  return (
    <ul className="adminAssignmentFileLists">
      {props.files?.map((file, index) => (
        <li key={index}>
          <FontAwesomeIcon icon={faFileAlt} />
          {process.env.REACT_APP_DEPLOYMENT === "AWS" ? (
            <p
              className="adminAssignmentFileListName"
              onClick={() => handleGetPreSignedUrl(file.link)}
            >
              {file?.file?.name}
            </p>
          ) : (
            <a
              href={file.url}
              target="_blank"
              rel="noreferrer"
              className="adminAssignmentFileListName"
            >
              {file?.file?.name}
            </a>
          )}
          <div>
            <FontAwesomeIcon
              icon={faTrash}
              className="adminAssignmentFiletrashIcon"
              onClick={() => handleDeleteFile(index)}
            />
          </div>
          <div className="adminAssignmentFileCheckisDownload">
            <input
              type="checkbox"
              checked={file.checked}
              onChange={(event) =>
                handleFileDownloadChange(event.target.checked, index)
              }
            />
          </div>
        </li>
      ))}
    </ul>
  );
};

export default AssignmentForm;
