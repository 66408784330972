import React from "react";

export default function WarningSvg(Props) {
  return Props.component === "interview" ? (
    <svg
      width="48"
      height="48"
      fill="none"
      viewBox="0 0 48 48"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        stroke="#DC6803"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
        d="M23.9998 20V24M23.9998 28H24.0098M22.2898 14.86L13.8198 29C13.6451 29.3024 13.5527 29.6453 13.5518 29.9945C13.5508 30.3437 13.6413 30.6871 13.8142 30.9905C13.9871 31.2939 14.2365 31.5467 14.5375 31.7238C14.8385 31.9009 15.1806 31.9961 15.5298 32H32.4698C32.819 31.9961 33.1611 31.9009 33.4621 31.7238C33.7631 31.5467 34.0124 31.2939 34.1854 30.9905C34.3583 30.6871 34.4488 30.3437 34.4478 29.9945C34.4468 29.6453 34.3544 29.3024 34.1798 29L25.7098 14.86C25.5315 14.5661 25.2805 14.3231 24.981 14.1544C24.6814 13.9858 24.3435 13.8972 23.9998 13.8972C23.656 13.8972 23.3181 13.9858 23.0186 14.1544C22.7191 14.3231 22.468 14.5661 22.2898 14.86Z"
      />
    </svg>
  ) : (
    <svg fill="none" viewBox="0 0 619 272" xmlns="http://www.w3.org/2000/svg">
      <path
        fill="#3F3D56"
        d="M245.744 269.42L86.2874 269.475C78.2767 269.478 71.101 265.338 67.0932 258.402C65.0891 254.934 64.0868 251.129 64.0855 247.323C64.0841 243.518 65.0843 239.712 67.0855 236.243L146.763 91.1937C150.767 84.2543 157.94 80.1102 165.95 80.1074C173.961 80.1047 181.137 84.2438 185.145 91.1804L264.934 236.192C266.932 239.652 267.932 243.452 267.933 247.255C267.932 251.058 266.933 254.864 264.931 258.334C260.928 265.273 253.755 269.417 245.744 269.42ZM66.0898 247.321C66.0896 250.785 83.6851 243.32 85.508 246.476C89.1542 252.786 78.9987 267.478 86.2868 267.475C86.2868 267.475 259.557 263.647 263.199 257.335C265.019 254.178 265.929 250.715 265.928 247.254C265.927 243.792 265.015 240.33 263.191 237.175L183.403 92.1629C179.767 85.8707 173.239 82.1049 165.951 82.1074C158.666 82.1099 66.0901 243.856 66.0898 247.321Z"
      />
      <path
        fill="#F55533"
        d="M212.479 237.432C252.796 237.432 285.479 204.748 285.479 164.432C285.479 124.115 252.796 91.4316 212.479 91.4316C172.163 91.4316 139.479 124.115 139.479 164.432C139.479 204.748 172.163 237.432 212.479 237.432Z"
      />
      <path
        fill="white"
        d="M212.493 209.182C216.194 209.182 219.195 206.181 219.195 202.479C219.195 198.777 216.194 195.776 212.493 195.776C208.791 195.776 205.79 198.777 205.79 202.479C205.79 206.181 208.791 209.182 212.493 209.182Z"
      />
      <path
        fill="white"
        d="M212.464 119.681C211.848 123.773 212.951 124.629 212.958 142.266C212.964 159.904 216.623 183.552 212.486 183.554C208.349 183.555 206.836 134.751 192.469 130.431C175.578 125.353 214.457 106.437 212.464 119.681Z"
      />
      <path
        fill="#3F3D56"
        d="M114.503 201.702L107.908 215.637C115.881 209.92 128.335 204.991 138.245 202.352C129.011 197.891 117.712 190.7 110.962 183.58L114.755 198.304C70.2813 189.246 38.3439 155.128 38.3304 116.045L36.7239 115.492C36.738 156.315 68.2336 192.453 114.503 201.702Z"
      />
    </svg>
  );
}
